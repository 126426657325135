import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCompleteItemsCount,
  selectTotalItemsCount,
  setIsModalOpen,
} from '../../../../reducers/coachOnboardingReducer'
import CustomCheckInput from '../../../common/form/CustomCheckInput'

import classes from './CoachOnboardingStepsAdminModal.module.scss'
import Modal from '../../../common/Modal'
import _ from 'lodash'
import moment from 'moment'

const CoachOnboardingStepsAdminModal = () => {
  const dispatch = useDispatch()
  const onboardingSteps = useSelector(state => state.coachOnboarding.onboardingSteps)
  const onboardingStatus = useSelector(state => state.coachOnboarding.selectedCoachOnboardingStatus.status)
  const onboardingLastModified = useSelector(state => state.coachOnboarding.selectedCoachOnboardingStatus.lastModified)
  const isOpen = useSelector(state => state.coachOnboarding.isModalOpen)
  const completedSteps = useSelector(selectCompleteItemsCount)
  const totalSteps = useSelector(selectTotalItemsCount)

  const onboardingStatusFormatted = _.capitalize(onboardingStatus)
  const lastModifiedDate = moment(onboardingLastModified).format('L')
  const lastModifiedRelativeTime = moment(onboardingLastModified).fromNow()
  const lastModified = `${lastModifiedDate}, ${lastModifiedRelativeTime}`

  const handleClose = () => dispatch(setIsModalOpen(false))

  return (
    <Modal
      isOpen={isOpen}
      contentLabel='CoachOnboardingStepsAdminModal'
      className={classes.modalWrapper}
      onClose={handleClose}
    >
      <div className={classNames(classes.modalContent, 'd-flex flex-column justify-content-center')}>
        <h5 className={classes.title}>Onboarding Status Details</h5>
        <div className='px-2'>
          <div className='d-flex align-items-center justify-content-between'>
            <p className={classes.statusText}>{onboardingStatusFormatted} {lastModified}</p>
            <p className={classes.totalCount}><span className={classes.completedCount}>{completedSteps}</span>/{totalSteps} Complete</p>
          </div>
          <div className={classes.stepsWrapper}>
            <div className={classNames(classes.stepsContainer, 'd-flex flex-column justify-content-center')}>
              {onboardingSteps.map(step => (
                <div key={step.id} className={classNames(classes.step, 'd-flex flex-column')}>
                  <p className={classes.stepTitle}>{step.title}</p>
                  {step.coachOnboardingItems.map(item => (
                    <CustomCheckInput
                      key={item.id}
                      type='checkbox'
                      classNames={{
                        checkBoxInput: `${classes.roundedCheckbox} ${item.completed ? classes.roundedCheckboxComplete : ''}`,
                        checkMark: classes.checkBoxCheckMark,
                      }}
                      id={`step-check-${step.id}`}
                      checked={true}
                      readOnly
                      label={item.title}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CoachOnboardingStepsAdminModal
