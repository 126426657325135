import PropTypes from 'prop-types'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import { useState, useMemo } from 'react'
import Tooltip from '../../common/Tooltip'
import DraggableStudent from './DraggableStudent'
import StudentCard from '../Pairings/StudentCard'
import { ReactComponent as ClapIcon } from '../../../assets/images/common/icons/clap-icon.svg'
import { ReactComponent as MessageIcon } from '../../../assets/images/common/icons/Message.svg'
import { DragOverlay } from '@dnd-kit/core'

import styles from './ManagePairings.module.scss'
import classNames from 'classnames'

const PairingsStudents = ({ event, draggingId, draggedIds }) => {
  const [tabIndex, setTabIndex] = useState(0)

  const eventStudents = useMemo(() => (
    event.students ? [...event.students].filter((s) => !draggedIds.includes(s.id) ) : []
  ), [event.students, draggedIds])

  const attendingStudents = useMemo(() => (
    [...eventStudents].filter((s) => s.status === 'confirmed' || s.status === 'created' )
  ), [eventStudents])

  const notAttendingStudents = useMemo(() => (
    [...eventStudents].filter((s) => s.status === 'declined')
  ), [eventStudents])

  return (
    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} className='h-100'>
      <TabList className={classNames('d-flex justify-content-between px-3', styles.tabList)} >
        <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Attending/No Response</Tab>
        <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Not Attending</Tab>
      </TabList>
      <TabPanel className={styles.tabPanel} selectedClassName={styles.activeTabPanel}>
        <div className={styles.studentsList}>
          <Tooltip/>
          {attendingStudents.length !== 0 ?
            attendingStudents.map((student, index) => (
              <div key={index} className='d-flex align-items-center'>
                <div className='d-flex align-items-center w-75'>
                  <DraggableStudent key={index} student={student} draggingId={draggingId} draggedFrom='communityStudents'/>
                </div>
                {student.note && <MessageIcon data-tip={student.note} data-tip-disable={false} className={styles.messageIcon}/>}
              </div>
            )) :
            <div className={classNames('d-flex flex-column align-items-center', styles.studentsPlaceholder)}>
              <ClapIcon className={styles.clapIcon}/>
              <p className={styles.placeholderTitle}>All Golfers are Paired</p>
              <p className={styles.placeholderMessage}>Congratulations, coach! All your golfers have been paired, don’t forget to save and submit!</p>
            </div>
          }
          <DragOverlay>
            {draggingId !== -1 && <StudentCard student={attendingStudents.find(s => s.id === draggingId)} />}
          </DragOverlay>
        </div>
      </TabPanel>
      <TabPanel className={styles.tabPanel} selectedClassName={styles.activeTabPanel}>
        <div className={styles.studentsList}>
          <Tooltip/>
          {notAttendingStudents.length ?
            notAttendingStudents.map((student, index) => (
              <div key={index} className='d-flex align-items-center'>
                <div className='d-flex align-items-center w-75'>
                  <DraggableStudent student={student} draggingId={draggingId} draggedFrom='communityStudents'/>
                </div>
                {student.note && <MessageIcon data-tip={student.note} data-tip-disable={false} className={styles.messageIcon}/>}
              </div>
            )) :
            <div className={classNames('d-flex flex-column align-items-center', styles.studentsPlaceholder)}>
              <ClapIcon className={styles.clapIcon}/>
              <p className={styles.placeholderTitle}>All Golfers are Paired</p>
              <p className={styles.placeholderMessage}>Congratulations, coach! All your golfers have been paired, don’t forget to save and submit!</p>
            </div>
          }
          <DragOverlay>
            {draggingId !== -1 && <StudentCard student={eventStudents.find(s => s.id === draggingId)}/>}
          </DragOverlay>
        </div>
      </TabPanel>
    </Tabs>
  )
}

PairingsStudents.propTypes = {
  event: PropTypes.object,
  draggingId: PropTypes.number,
  draggedIds: PropTypes.array,
}

export default PairingsStudents
