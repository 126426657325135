import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PageHeader from '../common/PageHeader/PageHeader'
import CoachAcademyEducationView from './Detail/CoachAcademyEducationView'
import AdminEducationModal from './Modals/AdminEducationModal'

import { addEducation, getAllEducations } from '../../actions/educationActions'

const HeaderButton = ({ showAdminModal }) => (
  <button
    className='btn btn-primary text-white pull-right mr-2'
    onClick={showAdminModal}
  >
    <i className='fa fa-plus' aria-hidden='true' /> Create Education
  </button>
)

HeaderButton.propTypes = {
  showAdminModal: PropTypes.func,
}

const CoachAcademyPage = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.user.current_user)
  const isAdmin = currentUser.type === 'Admin'

  const [showAdminModal, setShowAdminModal] = useState(false)
  const [isCreateLoading, setIsCreateLoading] = useState(false)
  const [isEducationLoading, setIsEducationLoading] = useState(false)

  const [formState, setFormState] = useState({
    id: -1,
    name: '',
    description: '',
    price: 0,
    visibility: 0,
    start_date: null,
    end_date: null,
    video_url: '',
    uploadPhotoName: '',
    uploadPhotoFile: '',
    uploadPhotoSrc: '',
    education_sections: [],
    sectionIndex: -1,
    educationLoader: false,
    selectedEducation: {},
    assigningEducation: false,
  })

  useEffect(() => {
    setIsEducationLoading(true)
    dispatch(getAllEducations(currentUser.id))
      .then(() => {
        setIsEducationLoading(false)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateFormState = (newState) => {
    setFormState(prevState => ({ ...prevState, ...newState }))
  }

  const grabPhotoData = photoData => {
    const { uploadPhotoFile, uploadPhotoName, uploadPhotoSrc } = photoData

    updateFormState({
      uploadPhotoName,
      uploadPhotoFile,
      uploadPhotoSrc,
    })
  }

  const grabSectionData = data => {
    const education_sections = [...formState.education_sections]
    const indexOfSection = education_sections
      .map(section => section.order)
      .indexOf(data.order)

    if (indexOfSection > -1) {
      education_sections.splice(indexOfSection, 1, data)
    } else {
      education_sections.push(data)
    }

    updateFormState({ education_sections })
  }

  const addSection = () => {
    let sectionIndex = formState.sectionIndex
    const education_sections = [
      ...formState.education_sections,
      { order: sectionIndex++ },
    ]

    updateFormState({
      sectionIndex,
      education_sections,
    })
  }

  const deleteSection = section => {
    const education_sections = [...formState.education_sections]
    const indexOfSection = education_sections
      .map(section => section.order)
      .indexOf(section.order)

    education_sections.splice(indexOfSection, 1)

    updateFormState({ education_sections })
  }

  const createEducation = () => {
    setIsCreateLoading(true)

    dispatch(addEducation(formState))
      .then(() => {
        setIsCreateLoading(false)
        setShowAdminModal(false)
      })
  }

  const handleInputChange = e => {
    updateFormState({
      [e.target.name]: e.target.value,
    })
  }

  const handleFormStateChange = (key, value) => {
    updateFormState({
      [key]: value,
    })
  }

  return (
    <div>
      <PageHeader
        title='Coach Academy'
        actionComponent={isAdmin ? <HeaderButton showAdminModal={() => setShowAdminModal(true)} /> : null}
      />
      <CoachAcademyEducationView isLoading={isEducationLoading} />
      {isAdmin && showAdminModal && (
        <AdminEducationModal
          showModal={showAdminModal}
          closeModal={() => setShowAdminModal(false)}
          formState={formState}
          grabPhotoData={grabPhotoData}
          handleInputChange={handleInputChange}
          sendSectionData={grabSectionData}
          addSection={addSection}
          deleteSection={deleteSection}
          createEducation={createEducation}
          loader={isCreateLoading}
          handleDateChange={handleFormStateChange}
        />
      )}
    </div>
  )
}

export default CoachAcademyPage
