import PropTypes from 'prop-types'
import React from 'react'
import Axios from 'axios'

import { baseUrl } from './baseUrl'
import MapSidebar from './MapSidebar'
import CommunityMap from './CommunityMap'
import LandingHeader from '../common/LandingHeader'
import Loader from '../common/Op36Loader-web'

import './CommunityMapPage.scss'
import RequestLocationModal from './RequestLocationModal'

class CommunityMapPage extends React.Component {
  state = {
    communitiesLoaded: false,
    isLocated: false,
    isDefaultLocation: true,
    geo_location: { lat: 35.736364, lng: -78.898327 }, // default location for the map to start
    communities: [],
    noCommunityModal: false,
    searchedLocation: "", // used to pre-populate the request form for no community nearby modal
    filters: [],
    mobileLeadModal: false,
    locationTypeModal: false
  };

  componentDidMount() {
    if (window.$zopim && window.$zopim.livechat) {
      window.$zopim.livechat.hideAll();
    }

    window.googleMapsPromise_.then(() => {
      this._getAllCommunities()
      this._getLocation()
    })
  }

  hideChat = () => {
    // in case this didn't catch on componentDidMout
    if (window.$zopim && window.$zopim.livechat) {
      window.$zopim.livechat.hideAll();
    }
  };

  render() {
    const {
      communitiesLoaded,
      isLocated,
      noCommunityModal,
      filters,
      geo_location,
      communities,
      isDefaultLocation,
    } = this.state

    this.hideChat()

    return (
      <div id='CommunityMapPage'>
        <LandingHeader />
        <div className='content-container'>
          <RequestLocationModal
            isOpen={noCommunityModal}
            onClose={this._closeNoCommunitiesModal}
            address={this.state.searchedLocation}
          />

          {communitiesLoaded ? (
            <>
              <div className='map-sidebar'>
                <MapSidebar
                  reCenterMap={this._reCenterMap}
                  filters={filters}
                  handleFilterClick={this._handleFilterClick}
                />
              </div>

              <div className='map-area'>
                <CommunityMap
                  loader={!(communitiesLoaded && isLocated)}
                  geo_location={geo_location}
                  communities={communities}
                  openNoCommunityModal={this._openNoCommunityModal}
                  isDefaultLocation={isDefaultLocation}
                  filters={filters}
                  handleCommunityClick={this._handleCommunityClick}
                />
              </div>
            </>
          ) : (
            <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
              <Loader message='loading community map' />
            </div>
          )}
        </div>
      </div>
    );
  }

  _handleFilterClick = (type) => {
    let filters = [...this.state.filters];
    if (filters.indexOf(type) === -1) {
      filters.push(type);
      this.setState({ filters });
    } else {
      let newFilters = filters.filter((el) => el !== type);
      this.setState({ filters: newFilters });
    }
  };

  _reCenterMap = (lat, lng) => {
    this.setState(
      {
        isLocated: false,
        isDefaultLocation: false,
        geo_location: { lat: lat, lng: lng },
      },
      () => {
        this.setState({ isLocated: true });
      }
    );
  };

  _handleCommunityClick = (id) => {
    const community = this.state.communities.find(c => c.id === parseInt(id, 10))
    const embed = localStorage?.getItem('embed')
    if (community) {
      const target = embed ? '_self' : '_blank'
      window.open(`${process.env.REACT_APP_BASE_URL}/landing/${id}`, target)
    }
  }

  _openNoCommunityModal = (location) => {
    if (location) {
      let locationArr = location.split(", ");
      if (locationArr.length > 3) {
        // removes the street address if one exists
        locationArr.shift();
      }
      if (locationArr[locationArr.length - 1] === "USA") {
        // removes USA from the end if it's there
        locationArr.pop();
      }
      let formattedLocation = locationArr.join(", ");
      this.setState({ searchedLocation: formattedLocation }, () => {
        this.setState({ noCommunityModal: true });
      });
    } else {
      this.setState({ noCommunityModal: true });
    }
  };

  _closeNoCommunitiesModal = () => {
    this.setState({ noCommunityModal: false });
  };

  _getAllCommunities = () => Axios.get(`${baseUrl}communities/map_view`)
    .then(res => this.setState({ communities: res.data.all_communities }))
    .then(() => this.setState({ communitiesLoaded: true }))

  _getLocation = () => {
    // removing logic that looks for the user's current current location
    this.setState({ isLocated: true });
  };

}

CommunityMapPage.propTypes = {
  navigate: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  params: PropTypes.shape({
    communityId: PropTypes.string,
  }),
}

export default CommunityMapPage
