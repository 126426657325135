import PropTypes from 'prop-types'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { deserialize } from '../../../utility/serialization'
import useReportPolling from '../../dashboard/coachDashboard/useReportPolling'
import {
  requestCommunityLeaderboardRanking,
  requestCommunityLeaderboardStats,
  requestExportCommunityLeaderboardRanking,
} from '../../../actions/reportsActions'
import { leaderboardTabs } from '../../../misc/Constants'
import LeaderboardRanking from './LeaderboardRanking'
import LeaderboardExport from './LeaderboardExport'

import styles from './LeaderboardWrapper.module.scss'
import { ReactComponent as ExportIcon } from '../../../assets/images/common/communityLeaderboard/Export.svg'
import { ReactComponent as CoachApprovedIcon } from '../../../assets/images/common/communityLeaderboard/CoachApproved.svg'
import { ReactComponent as TotalLevelUpsIcon } from '../../../assets/images/common/communityLeaderboard/TotalLevelUps.svg'
import { ReactComponent as TotalPointsIcon } from '../../../assets/images/common/communityLeaderboard/TotalPoints.svg'
import { ReactComponent as VideosIcon } from '../../../assets/images/common/communityLeaderboard/Videos.svg'
import { ReactComponent as TrainingSessionsIcon } from '../../../assets/images/common/communityLeaderboard/TrainingSessions.svg'
import { ReactComponent as TotalRoundsIcon } from '../../../assets/images/common/communityLeaderboard/TotalRounds.svg'
import classnames from 'classnames'

const playingItems = [
  { icon: <TotalRoundsIcon/>, key: 'totalRounds', text: 'Total Rounds' },
  { icon: <TotalPointsIcon/>, key: 'totalPoints', text: 'Total Points' },
  { icon: <TotalLevelUpsIcon/>, key: 'totalLevelUps', text: 'Total Level Ups' },
]

const trainingItems = [
  { icon: <TotalRoundsIcon/>, key: 'totalTrainingHours', text: 'Total Training Hours' },
  { icon: <TrainingSessionsIcon/>, key: 'totalTrainingSessions', text: 'Total Training Sessions' },
]

const curriculumItems = [
  { icon: <CoachApprovedIcon/>, key: 'coachApproved', text: 'Coach Approved' },
  { icon: <VideosIcon/>, key: 'videosWatched', text: 'Videos Watched' },
]

const tabsData = [
  {
    tabName: 'All Activities',
    statsItems: null,
  },
  {
    tabName: 'Playing',
    statsItems: playingItems,
  },
  {
    tabName: 'Training',
    statsItems: trainingItems,
  },
  {
    tabName: 'Curriculum',
    statsItems: curriculumItems,
  },
]

const PER_PAGE = 6

const LeaderboardWrapper = ({
  withDetailedLeaderboard,
  withClearFiltersButton,
  filters,
  onClearFiltersClick,
  classNames,
  placeholder,
}) => {
  const [exportId, setExportId] = useState(null)
  const [tabIndex, setTabIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [ranking, setRanking] = useState(null)
  const [stats, setStats] = useState(null)
  const [pagination, setPagination] = useState({
    nextPage: 1,
    totalPages: null,
  })

  const communityId = useSelector(state => state.user.current_user.communities[0].id)
  const [exportUrl, exportError, clearReportPolling] = useReportPolling({ exportId, setExportId })
  const isExportingLeaderboard = !!(exportId || exportUrl || exportError)

  const getCommunityLeaderboardRanking = (...params) =>
    requestCommunityLeaderboardRanking(...params)
      .then(response => {
        const data = deserialize(response.data)

        setRanking(prevState => {
          if (!prevState) {
            return data.leaderboardRanking
          } else {
            return [...prevState, ...data.leaderboardRanking]
          }
        })
        setPagination(prevState => ({
          nextPage: prevState.nextPage + 1,
          totalPages: data.meta.pagination.totalPages,
        }))
      })

  const getCommunityLeaderboardStats = (...params) =>
    requestCommunityLeaderboardStats(...params)
      .then(response => setStats(deserialize(response.data)))

  const handleExportButtonClick = () => {
    requestExportCommunityLeaderboardRanking(communityId, filters)
      .then((response) => {
        setExportId(deserialize(response.data).jobId)
      })
      .catch(() => toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT }))
  }

  const handleFetchMoreData = (nextPage) => {
    setIsLoading(true)
    getCommunityLeaderboardRanking(
      communityId,
      { ...filters, activityTypes: leaderboardTabs[tabIndex] },
      nextPage,
      PER_PAGE
    )
      .catch(() => toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT }))
      .finally(() => setIsLoading(false))
  }

  const clearExportPollingData = () => {
    clearReportPolling()
    setExportId(null)
  }

  useEffect(() => {
    setIsLoading(true)
    setRanking(null)
    setStats(null)
    const actions = [
      getCommunityLeaderboardRanking(
        communityId,
        { ...filters, activityTypes: leaderboardTabs[tabIndex] },
        1,
        PER_PAGE
      ),
      ...withDetailedLeaderboard ? [getCommunityLeaderboardStats(communityId, filters)] : [],
    ]

    Promise.all(actions)
      .catch(() => toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT }))
      .finally(() => {
        setIsLoading(false)
      })
  }, [communityId, filters, tabIndex, withDetailedLeaderboard])

  if (withDetailedLeaderboard) {
    return (
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
        className={classnames('d-flex flex-column', { [classNames?.tabs]: !!classNames.tabs })}
        selectedTabClassName={styles.activeTab}
        selectedTabPanelClassName={styles.activeTabPanel}
      >
        <div
          className={classnames(
            'd-flex align-items-center justify-content-between',
            styles.tabsListWrapper,
            {
              [classNames?.tabList]: !!classNames?.tabList,
            }
          )}
        >
          <TabList className={classnames('flex-shrink-1', styles.tabList)}>
            {tabsData.map((item, index) => (
              <Tab key={index} className={styles.tab}>{item.tabName}</Tab>
            ))}
          </TabList>
          <button
            className={classnames('d-flex align-items-center py-1 px-3 animation-shrink flex-shrink-0', styles.exportButton)}
            onClick={handleExportButtonClick}
            disabled={isLoading || isExportingLeaderboard || ranking?.length === 0}
          >
            Export
            <ExportIcon className={styles.exportIcon} />
          </button>
        </div>

        {tabsData.map((item, index) => (
          <TabPanel key={index}>
            {isExportingLeaderboard ? (
              <LeaderboardExport
                exportUrl={exportUrl}
                exportId={exportId}
                exportError={exportError}
                clearExport={clearExportPollingData}
                onTryAgainButtonClick={handleExportButtonClick}
              />
            ) : (
              <LeaderboardRanking
                rankingStudents={ranking}
                withStats
                stats={stats}
                statsItems={item.statsItems}
                fetchData={handleFetchMoreData}
                withClearFiltersButton={withClearFiltersButton}
                onClearFiltersClick={onClearFiltersClick}
                pagination={pagination}
                placeholder={placeholder}
              />
            )}
          </TabPanel>
        ))}
      </Tabs>
    )
  }

  return (
    <LeaderboardRanking
      rankingStudents={ranking}
      fetchData={handleFetchMoreData}
      pagination={pagination}
    />
  )
}

LeaderboardWrapper.propTypes = {
  withDetailedLeaderboard: PropTypes.bool,
  withClearFiltersButton: PropTypes.bool,
  filters: PropTypes.object,
  onClearFiltersClick: PropTypes.func,
  classNames: PropTypes.shape({
    tabs: PropTypes.string,
    tabList: PropTypes.string,
  }),
  placeholder: PropTypes.node,
}

export default LeaderboardWrapper
