import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import classNames from 'classnames'

import styles from './Modal.module.scss'
import { ReactComponent as CloseIcon } from '../../assets/images/common/icons/modal-X-icon.svg'

const Modal = ({ isOpen, onClose, children, className, fixedTop, isDarkModal, title }) => (
  <ReactModal
    isOpen={isOpen}
    contentLabel='Custom Modal'
    onRequestClose={onClose}
    className={styles.modal}
  >
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
    <div className={styles.modalOverlay} onClick={onClose} />
    <div className={classNames(styles.modalContentContainer, className, fixedTop && styles.fixedTop)}>
      <div className={classNames('d-flex align-items-center pb-3', styles.header)}>
        <h1 className={classNames(styles.title)}>
          {title}
        </h1>
        <button className={classNames('d-flex align-items-center', styles.closeButton)} onClick={onClose}>
          <CloseIcon className={classNames(styles.closeIcon, { [styles.white]: isDarkModal })} aria-hidden='true' />
        </button>
      </div>
      {children}
    </div>
  </ReactModal>
)

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  fixedTop: PropTypes.bool,
  isDarkModal: PropTypes.bool,
  title: PropTypes.string,
}

export default Modal
