import { createSlice, createSelector } from '@reduxjs/toolkit'
import { getRepresentative, getCommunityAllTimeTotals } from '../actions/dashboardActions'
import { getScheduleSessionsByMonth } from '../actions/scheduleSessionActions'
import { getEventsByMonth } from '../actions/eventActions'
import { getDashboardPrograms, getProgramsFilterOptions } from '../actions/programActions'
import moment from 'moment'
import { DATETIME_FORMAT, getMomentInCommunityTimezoneReversed } from '../utility/datesHelper'
import { getCommunityCategories } from '../actions/programCategoryActions'
import { getCommunityActivities, getProgramActivities } from '../actions/activityActions'

const rangeDate = new Date()

export const initialFilters = {
  startDate: new Date(rangeDate.setDate(rangeDate.getDate() - 30)),
  endDate: new Date(),
  courseId: null,
  categoryId: null,
}

const initialState = {
  representative: null,
  sessionsByMonth: {},
  activeCourses: [],
  futureCourses: [],
  optionCourses: [],
  loadingOptionCourses: false,
  selectedMonthDate: new Date(),
  selectedDate: new Date(),
  communityActivities: [],
  loadingCommunityActivities: false,
  programActivities: [],
  loadingProgramActivities: false,
  eventsByMonth: {},
  communityActivitiesTotalPages: 10,
  programActivitiesTotalPages: 10,
  allTimeTotals: null,
  communityCategories: [],
  loadingCategories: false,
  leaderboardFilters: initialFilters,
  leaderboardRanking: [
    [],
    [],
    [],
    [],
  ],
  rankingTotalPages: [10, 10, 10, 10],
  rankingPages: [1, 1, 1, 1],
  tabIndex: 0,
  leaderboardStats: null,
  leaderboardLoader: false,
  clearFiltersCounter: 0,
  exportUrl: null,
  exportId: null,
  exportError: false,
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    addCommunityAnnouncement: (state, action) => {
      state.communityActivities = [action.payload, ...state.communityActivities]
    },
    addProgramAnnouncement: (state, action) => {
      state.programActivities = [action.payload, ...state.programActivities]
    },
    setSelectedMonthDate: (state, action) => {
      state.selectedMonthDate = action.payload
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload
    },
    clearCommunityActivities: (state) => {
      state.communityActivities = []
    },
    clearProgramActivities: (state) => {
      state.programActivities = []
    },
    removeCommunityActivity: (state, action) => {
      state.communityActivities = state.communityActivities.filter(activity => activity.id !== action.payload)
    },
    removeProgramActivity: (state, action) => {
      state.programActivities = state.programActivities.filter(activity => activity.id !== action.payload)
    },
    setLeaderboardFilters: (state, action) => {
      state.leaderboardFilters = { ...state.leaderboardFilters, ...action.payload }
      state.leaderboardRanking = [[], [], [], []]
      state.rankingPages = [1, 1, 1, 1]
    },
    incrementFiltersCounter: (state) => {
      state.clearFiltersCounter += 1
    },
  },
  extraReducers: {
    [getRepresentative.fulfilled]: (state, action) => {
      state.representative = action.payload.user
    },
    [getScheduleSessionsByMonth.fulfilled]: (state, action) => {
      state.sessionsByMonth[state.selectedMonthDate.getMonth()] = action.payload.scheduleSessions
    },
    [getDashboardPrograms.fulfilled]: (state, action) => {
      if (action.payload.filters?.future) {
        state.futureCourses = action.payload.courses
      } else {
        state.activeCourses = action.payload.courses
      }
    },
    [getProgramsFilterOptions.pending]: (state) => {
      state.loadingOptionCourses = true
    },
    [getProgramsFilterOptions.fulfilled]: (state, action) => {
      state.optionCourses = action.payload.courses.map(c => ({ value: c.id, label: c.name, categoryId: c.courseCategoryId }))
      state.loadingOptionCourses = false
    },
    [getCommunityActivities.pending]: (state) => {
      state.loadingCommunityActivities = true
    },
    [getCommunityActivities.rejected]: (state) => {
      state.loadingCommunityActivities = false
    },
    [getCommunityActivities.fulfilled]: (state, action) => {
      state.communityActivitiesTotalPages = action.payload.meta.pagination.total_pages
      state.communityActivities = [...state.communityActivities, ...action.payload.activities]
      state.loadingCommunityActivities = false
    },
    [getProgramActivities.pending]: (state) => {
      state.loadingProgramActivities = true
    },
    [getProgramActivities.rejected]: (state) => {
      state.loadingProgramActivities = false
    },
    [getProgramActivities.fulfilled]: (state, action) => {
      state.programActivitiesTotalPages = action.payload.meta.pagination.total_pages
      state.programActivities = [...state.programActivities, ...action.payload.activities]
      state.loadingProgramActivities = false
    },
    [getEventsByMonth.fulfilled]: (state, action) => {
      state.eventsByMonth[state.selectedMonthDate.getMonth()] = action.payload.events
    },
    [getCommunityAllTimeTotals.fulfilled]: (state, action) => {
      state.allTimeTotals = action.payload
    },
    [getCommunityCategories.pending]: (state) => {
      state.loadingCategories = true
    },
    [getCommunityCategories.fulfilled]: (state, action) => {
      state.communityCategories = action.payload.courseCategories.filter(c => c.name !== 'Other Programs').map(c => ({ value: c.id, label: c.name }))
      state.loadingCategories = false
    },
  },
})

export const {
  addCommunityAnnouncement,
  addProgramAnnouncement,
  setSelectedDate,
  clearCommunityActivities,
  clearProgramActivities,
  setSelectedMonthDate,
  setLeaderboardFilters,
  incrementFiltersCounter,
  removeProgramActivity,
  removeCommunityActivity,
} = dashboardSlice.actions

export default dashboardSlice.reducer

export const sessionsDates = createSelector(
  state => state.dashboard.sessionsByMonth,
  state => state.dashboard.selectedMonthDate,
  state => state.user.current_user.communities[0].timezone,
  (sessions, selectedMonthDate, timezone) =>
    sessions[selectedMonthDate.getMonth()]?.map(s => getMomentInCommunityTimezoneReversed(moment(s.startDate), timezone, DATETIME_FORMAT).toDate())
      || []
)

export const eventsDates = createSelector(
  state => state.dashboard.eventsByMonth,
  state => state.dashboard.selectedMonthDate,
  state => state.user.current_user.communities[0].timezone,
  (events, selectedMonthDate, timezone) =>
    events[selectedMonthDate.getMonth()]?.map(e => getMomentInCommunityTimezoneReversed(moment(e.eventDate), timezone, DATETIME_FORMAT).toDate())
      || []
)

export const selectedDateEvents = createSelector(
  state => state.dashboard.eventsByMonth,
  state => state.dashboard.selectedDate,
  state => state.user.current_user.communities[0].timezone,
  (
    events,
    selectedDate,
    timezone
  ) => {
    const selectedDateEvents = []
    events[selectedDate.getMonth()]?.forEach(e => {
      const studentsProfiles = e.enrolledUsers.map(u => u.avatar?.fileUrl)
      const event = {
        id: e.id,
        seriesName: e.series.title,
        name: `Event #${e.order}`,
        format: e.format,
        location: e.location,
        date: getMomentInCommunityTimezoneReversed(moment(e.eventDate), timezone, DATETIME_FORMAT).toDate(),
        students: studentsProfiles,
      }
      if (event.date.toDateString() === selectedDate.toDateString()) {
        selectedDateEvents.push(event)
      }
    })

    return selectedDateEvents
  }
)

export const selectedDateSessions = createSelector(
  state => state.dashboard.sessionsByMonth,
  state => state.dashboard.selectedDate,
  state => state.user.current_user.communities[0].timezone,
  (
    sessions,
    selectedDate,
    timezone
  ) => {
    const selectedDateSessions = []
    sessions[selectedDate.getMonth()]?.forEach(s => {
      const enrollmentsProfiles = s.enrolledUsers.map(u => u.avatar?.fileUrl)
      const session = {
        programTitle: s.course.name,
        programId: s.course.id,
        categoryColor: s.course.courseCategory?.color,
        sessionId: s.id,
        enrollments: s.enrolledUsers.length,
        enrollmentsProfiles: enrollmentsProfiles,
      }
      session.sessionTitle = s.title
      session.sessionStartDate = getMomentInCommunityTimezoneReversed(moment(s.startDate), timezone, DATETIME_FORMAT).toDate()
      session.sessionEndDate = getMomentInCommunityTimezoneReversed(moment(s.endDate), timezone, DATETIME_FORMAT).toDate()
      session.location = s.location
      if (session.sessionStartDate.toDateString() === selectedDate.toDateString()) {
        selectedDateSessions.push(session)
      }
    })

    return selectedDateSessions
  }
)
