import { useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import Select, { components } from 'react-select'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import colors from '../../../assets/styles/globals.scss'
import { ReactComponent as TableSelectDropDownIcon } from '../../../assets/images/common/icons/drop-down-arrow.svg'
import * as seriesEnrollmentActions from '../../../actions/seriesEnrollmentActions'
import * as Constants from '../../../misc/Constants'

import styles from './SeriesEnrollmentStatusSelect.module.scss'

const selectStyles = {
  container: (provided) => ({
    ...provided, height: '45px',
  }),
  control: (provided) => ({
    ...provided, cursor: 'pointer', border: '0', background: 'none', boxShadow: 'none', padding: '0',
  }),
  option: (provided) => ({
    ...provided, cursor: 'pointer',
  }),
  valueContainer: (provided) => ({
    ...provided, height: '45px', padding: '0', input: { position: 'absolute', height: '0', border: 'none' },
  }),
}

const RSVP_STATUS_MAP = {
  created: 'No Response',
  confirmed: 'Attending',
  declined: 'Not Attending',
}

const REVERSE_RSVP_STATUS_MAP = {
  'No Response': 'created',
  'Attending': 'confirmed',
  'Not Attending': 'declined',
}

const RSVP_STATUS_COLOR = {
  'No Response': colors.lightNavy,
  'Attending': colors.darkTeal,
  'Not Attending': colors.redDarker,
}

const options = Object.values(RSVP_STATUS_MAP).map((v) => ({ status: v }))

const Option = (props) => {
  const { data, innerProps } = props

  return (
    <div {...innerProps} className={styles.enrollmentStatusOption} style={{ cursor: 'pointer' }}>
      <p style={{
        marginBottom: '0',
        color: RSVP_STATUS_COLOR[data.status],
        fontWeight: data.status === 'Select status' ? 400 : 700,
      }}>
        {data.status}
      </p>
    </div>
  )
}

const SingleValue = (props) => {
  const { data, innerProps } = props

  return (
    <div {...innerProps} className='d-flex justify-content-between align-items-center'>
      <p style={{
        marginBottom: '0',
        color: RSVP_STATUS_COLOR[data.status],
        textAlign: 'center',
      }}>
        {data.status}
      </p>
    </div>
  )
}

const DropdownIndicator = (props) => (
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <TableSelectDropDownIcon stroke={colors.lightNavy} style={{ marginRight: '-5.5px' }}/>
    </components.DropdownIndicator>
  )
)

const SeriesEnrollmentStatusSelect = ({ enrollment, isDisabled }) => {
  const dispatch = useDispatch()

  const [selectLoader, setSelectLoader] = useState(false)

  const selectedEnrollmentStatus = useMemo(() => (
    { status: RSVP_STATUS_MAP[enrollment.status] }
  ), [enrollment.status])

  const handleEnrollmentStatusChange = (enrollmentStatus) => {
    setSelectLoader(true)

    dispatch(seriesEnrollmentActions.updateSeriesEnrollment({
      id: enrollment.enrollmentId,
      status: REVERSE_RSVP_STATUS_MAP[enrollmentStatus.status],
    }))
      .then(() => {
        setSelectLoader(false)
      })
      .catch(() => {
        toast.error('Something went wrong. The enrollment status has not been updated.', { position: toast.POSITION.BOTTOM_LEFT })
        setSelectLoader(false)
      })
  }

  return (
    <div className={classNames('seriesEnrollmentStatusSelect', selectLoader && styles.disabled)}>
      <div aria-hidden='true' onClick={() => null}>
        <Select
          name='payment-status-select'
          onClick={() => null}
          value={selectedEnrollmentStatus}
          options={options}
          components={{
            DropdownIndicator: DropdownIndicator,
            Menu: components.Menu,
            IndicatorSeparator: () => null,
            SingleValue,
            Option,
          }}
          isSearchable={false}
          onChange={handleEnrollmentStatusChange}
          styles={selectStyles}
          isDisabled={selectLoader || isDisabled}
          menuPortalTarget={Constants.DOCUMENT_BODY_REFERENCE}
          menuPlacement='auto'
        />
      </div>
    </div>
  )
}

Option.propTypes = {
  data: PropTypes.object,
  innerProps: PropTypes.object,
}

SingleValue.propTypes = {
  data: PropTypes.object,
  innerProps: PropTypes.object,
}

SeriesEnrollmentStatusSelect.propTypes = {
  enrollment: PropTypes.shape({
    enrollmentId: PropTypes.number,
    status: PropTypes.string,
  }),
  isDisabled: PropTypes.bool,
}

export default SeriesEnrollmentStatusSelect
