import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import classNames from 'classnames'

import { ReactComponent as CircleLogo } from '../../../../assets/images/common/icons/circle-logo.svg'
import { resetPassword } from '../../../../actions/authActions'
import SubmitButton from '../../../common/buttons/SubmitButton'
import ButtonLink from '../../../common/buttons/ButtonLink'
import FormInput from '../../Helpers/FormInput'
import FieldError from '../../../common/FieldError'

import classes from './ChangePassword.module.scss'

const customErrorMessages = {
  password: 'Password must have at least 6 characters',
  passwordConfirmation: 'Passwords do not match',
}

const ChangePassword = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const [passwordReset, setPasswordReset] = useState(false)
  const [error, setError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [passwordConfirmationError, setPasswordConfirmationError] = useState('')

  const formInvalid = useMemo(() => {
    const anyEmptyFields = !password || !passwordConfirmation
    const anyErrors = password.length < 6 || password !== passwordConfirmation

    return anyEmptyFields || anyErrors
  }, [password, passwordConfirmation])

  const validatePassword = () => {
    if (password && password.length < 6) {
      setPasswordError(customErrorMessages.password)
    } else {
      setPasswordError('')
    }
  }

  const validatePasswordConfirmation = () => {
    if (password && passwordConfirmation && password !== passwordConfirmation) {
      setPasswordConfirmationError(customErrorMessages.passwordConfirmation)
    } else {
      setPasswordConfirmationError('')
    }
  }
  const handleResetPassword = () => {
    dispatch(resetPassword(password, passwordConfirmation, params.token))
      .then((_) => setPasswordReset(true))
      .catch(() => {
        setError('Invalid reset token')
      })
  }

  return (
    <div className={classes.changePassword}>
      {passwordReset ? (
        <div className={classNames(classes.passwordReset, 'd-flex flex-column justify-content-center align-items-center h-100 w-100')}>
          <header className='d-flex flex-column justify-content-center align-items-center'>
            <CircleLogo className={classes.circleLogo} />
            <h2 className={classes.titleText}>Password Reset Successfully!</h2>
          </header>

          <ButtonLink navigateTo='/log-in' className={classNames(classes.actionLink, 'mt-4 mb-5 mb-md-0')}>
            Back to Log In
          </ButtonLink>
        </div>
      ) : (
        <div className={classNames(classes.passwordInput, 'd-flex flex-column justify-content-center align-items-center h-100 w-100')}>
          <header className='d-flex flex-column justify-content-center align-items-center'>
            <CircleLogo className={classes.circleLogo} />
            <h2 className={classes.titleText}>Reset your Password</h2>
          </header>

          <form className='row w-100'>
            <div className='col-12 col-lg-6 offset-lg-3'>
              {error && <FieldError message={error} style={{ paddingBottom: '5px' }} general/>}

              <label htmlFor='password' className={classes.inputLabel}>
                New Password <span className='required-text'>*</span>
              </label>

              <FormInput
                name='password'
                type='password'
                onChange={(e) => { setPassword(e.target.value); setPasswordError('') }}
                onBlur={() => { validatePassword(); validatePasswordConfirmation() }}
                value={password}
                error={passwordError}
                symbol={<i className={classNames(classes.inputSymbol, 'fa fa-lock')}/>}
                autoComplete='new-password'
              />

              <label htmlFor='passwordConfirmation' className={classes.inputLabel}>
                Confirm New Password <span className='required-text'>*</span>
              </label>

              <FormInput
                name='passwordConfirmation'
                type='password'
                onChange={(e) => { setPasswordConfirmation(e.target.value); setPasswordConfirmationError('') }}
                onBlur={validatePasswordConfirmation}
                value={passwordConfirmation}
                error={passwordConfirmationError}
                symbol={<i className={classNames(classes.inputSymbol, 'fa fa-lock')}/>}
                autoComplete='new-password'
              />

              <SubmitButton
                onClick={(e) => { e.preventDefault(); handleResetPassword() }}
                buttonMessage='Reset Password'
                buttonColor='orange-button'
                className={classes.resetPasswordButton}
                disabled={formInvalid}
              />
            </div>
          </form>

          <footer className={classNames(classes.footer, 'row d-flex flex-column align-items-center')}>
            <div className='col-12 col-lg-6'>
              <span>Remember your password? <Link to={'/log-in'} className={classes.logInLink}>Log In</Link></span>
            </div>
          </footer>
        </div>
      )}
    </div>
  )
}

export default ChangePassword
