import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { DATETIME_FORMAT, getMomentInCommunityTimezone, getMomentInCommunityTimezoneReversed } from '../../../utility/datesHelper'
import { setField } from '../../../reducers/nineHoleEventReducer'
import SubmitButton from '../../common/buttons/SubmitButton'
import CancelButton from '../../common/buttons/CancelButton'
import DatePicker from '../../common/form/DatePicker'
import Input from '../../common/form/Input'
import Modal from '../../common/Modal'
import MCElement from '../../common/MCElement'
import Select from '../../common/form/Select'

import styles from './CreatePairingsModal.module.scss'
import colors from '../../../assets/styles/globals.scss'
import FieldError from '../../common/FieldError'
import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment'

const customErrorMessages = {
  teeTimeSlots: 'Please select the Tee Time Slots.',
  golfersCount: 'Please select the Nr of Golfers.',
}

const GOLFERS_SELECT_OPTIONS = _.range(2, 9).map((value) => ({ value: value, label: value.toString() }))

const CreatePairingsModal = ({ showModal, closeModal }) => {//eslint-disable-line
  const dispatch = useDispatch()
  const pairings = useSelector(state => state.nineHoleEvent.pairings)
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)
  const event = useSelector(state => state.nineHoleEvent)
  const [errors, setErrors] = useState({})
  const [formData, setFormdata] = useState({ ...pairings })

  const handleInputChange = e => {
    const { name, value } = e.target
    setFormdata({ ...formData, [name]: value })
  }

  const handleSelectChange = (e, name) => {
    if (name === 'golfersCount') {
      const nrOfSolts = Math.ceil((event.students.length - (event.respondedEnrollmentsCount - event.confirmedEnrollmentsCount)) / e.value) || 1
      setFormdata({ ...formData, golfersCount: e, teeTimeSlots: nrOfSolts })
    } else if (name === 'startTime') {
      const time = getMomentInCommunityTimezone(moment(e), timezone, DATETIME_FORMAT).toDate()
      setFormdata({ ...formData, startTime: time })
    } else {
      setFormdata({ ...formData, [name]: e })
    }
  }

  const handleHolesCountChange = (e, holeNr) => {
    if (e.target.value > 26) {
      return
    }

    const holesCount = { ...formData.holesCount }
    holesCount[holeNr] = e.target.value ? parseInt(e.target.value, 10) : ''
    setFormdata({ ...formData, holesCount: holesCount })
  }

  const invalidFields = {
    interval: event.format === 'Tee Times' && pairings.interval === '',
    teeTimeSlots: event.format === 'Tee Times' && pairings.teeTimeSlots === '',
    golfersCount: pairings.golfersCount === '',
  }

  const formValid = () => {
    const errors = {};

    ['teeTimeSlots', 'golfersCount'].forEach((type) => {
      if (invalidFields[type]) {
        errors[type] = customErrorMessages[type]
      }
    })

    setErrors(errors)

    return Object.keys(errors).length === 0
  }

  const handleSession = () => {
    if (formValid()) {
      generatePairingGroups()
      exitModal()
    }
  }

  const generatePairingGroups = () => {
    const existingGroups = []
    pairings.pairingGroups.forEach((group) => {
      if (group.id) {
        existingGroups.push({ ...group, _destroy: true })
      }
    })

    let pairingGroups = null
    let index = pairings.groupsIndex - 1
    if (formData.format === 'Tee Times') {
      pairingGroups = _.range(0, formData.teeTimeSlots).map((order) => {
        index += 1
        return (
          {
            index: index,
            users: [],
            startTime: moment(formData.startTime).add(formData.interval * order, 'minutes').toDate(),
          }
        )
      })
    } else {
      pairingGroups = []
      Object.entries(formData.holesCount).forEach(([key, value]) => (
        _.range(0, value, 1).forEach((i) => {
          index += 1
          pairingGroups.push(
            {
              index: index,
              startHole: { value: key, label: key },
              playersGroup: { value: String.fromCharCode(i + 65), label: String.fromCharCode(i + 65) },
              users: [],
            })
        })
      )
      )
    }
    dispatch(setField({ field: 'pairings', value: {
      ...pairings,
      format: formData.format,
      startTime: formData.startTime,
      interval: formData.interval,
      teeTimeSlots: formData.teeTimeSlots,
      golfersCount: formData.golfersCount,
      holesCount: formData.holesCount,
      pairingGroups: [...existingGroups, ...pairingGroups],
      groupsIndex: index + 1,
    } }))
  }

  const exitModal = () => {
    setErrors({})
    closeModal()
  }

  return (
    <Modal
      isOpen={showModal}
      onClose={exitModal}
      className={styles.createPairingsModalContent}
    >
      <div id='CreatePairingsModal'>
        <div className='animated fadeIn'>
          <div>
            <p className={styles.title}>Create Pairings</p>
            <div className='row'>
              <div className='col-12'>
                <label htmlFor='format'>
                  Confirm the Format <span className='required-text'>*</span>
                </label>

                <div
                  onClick={() => handleSelectChange('Tee Times', 'format')}
                  className='d-flex format-option-tee-times mb-2'
                  role='button'
                  onKeyDown={null}
                  tabIndex={0}
                >
                  <MCElement
                    type='radio'
                    onKeyDown={(e) => {
                      e.key === 'Enter' && handleSelectChange({ value: 'Tee Times' }, 'format')
                    }}
                    primaryColor={event.format === 'Tee Times' ? colors.secondaryColor : colors.lightNavy}
                    isSelected={formData.format === 'Tee Times'}
                  />
                  <span className='ml-2'>Tee times</span>
                </div>
                <div
                  onClick={() => handleSelectChange('Shotgun', 'format')}
                  className='d-flex format-option-Shotgun'
                  role='button'
                  onKeyDown={null}
                  tabIndex={0}
                >
                  <MCElement
                    type='radio'
                    onKeyDown={(e) => {
                      e.key === 'Enter' && handleSelectChange({ value: 'Shotgun' }, 'format')
                    }}
                    primaryColor={formData.format === 'Shotgun' ? colors.secondaryColor : colors.lightNavy}
                    isSelected={formData.format === 'Shotgun'}
                  />
                  <span className='ml-2'>Shotgun</span>
                </div>
              </div>

              <div className={styles.hl} />

              <div className='col-6 mb-2'>
                <label htmlFor='Tee Times'>{formData.format === 'Shotgun' ? 'Start Time' : 'First Tee Time'}</label>
                <DatePicker
                  selected={getMomentInCommunityTimezoneReversed(moment(formData.startTime), timezone, DATETIME_FORMAT).toDate()}
                  onChange={(time) => handleSelectChange(time, 'startTime')}
                  inputClassNames={{ inputContainer: classNames('w-50', { 'error-input': !!errors.endDate }) }}
                  showTimeSelect
                  showTimeSelectOnly
                  placeholderText='Select time'
                  timeIntervals={1}
                  timeCaption='time'
                  dateFormat='h:mm aa'
                  autoComplete='off'
                />
              </div>

              {formData.format === 'Tee Times' &&
              <>
                <div className='col-6 mb-2'>
                  <label htmlFor='intervalSelect'>Interval(minutes)</label>
                  <Input
                    name='interval'
                    value={formData.interval}
                    type='number'
                    step='1'
                    onChange={handleInputChange}
                    classNames={{ inputContainerWrapper: styles.intervalInputWrapper }}
                  />
                </div>

                <div className='col-6 mb-3'>
                  <label htmlFor='teeTimeSlots'>Number of Tee Times Slots<span className='required-text'>*</span></label>
                  <Input
                    name='teeTimeSlots'
                    type='number'
                    onChange={handleInputChange}
                    classNames={{ inputContainerWrapper: styles.teeTimeSlotsInputWrapper }}
                    value={formData.teeTimeSlots}
                  />
                  {!!errors.teeTimeSlots && <FieldError message={errors.teeTimeSlots} />}
                </div>
              </>
              }

              <div className='col-6 mb-2'>
                <label htmlFor='usersSelect'>Number of Golfers in a Pairing<span className='required-text'>*</span></label>
                <Select
                  id='usersSelect'
                  placeholder=''
                  isSearchable={false}
                  value={formData.golfersCount}
                  menuPlacement='top'
                  onChange={(e) => handleSelectChange(e, 'golfersCount')}
                  className={styles.selectInput}
                  options={GOLFERS_SELECT_OPTIONS}
                />
                {!!errors.golfersCount && <FieldError message={errors.golfersCount} />}
              </div>

              {formData.format === 'Shotgun' &&
              <>
                <div className='col-12 mt-3'>
                  <label htmlFor='holes1-9'>Holes 1-9</label>
                  <div className={classNames('mt-0', styles.hl)} />
                  <div className='d-flex'>
                    {_.range(1, 10).map((holeNr) => (
                      <div key={holeNr} className={classNames('d-flex flex-column align-items-center', styles.holeInput)}>
                        <label htmlFor={`hole-${holeNr}`}>{holeNr}</label>
                        <Input
                          name={`hole-${holeNr}`}
                          type='number'
                          onChange={(e) => handleHolesCountChange(e, holeNr)}
                          classNames={{ inputContainerWrapper: styles.holeInputWrapper }}
                          value={formData.holesCount[holeNr]}
                        />
                      </div>
                    ))
                    }
                  </div>
                </div>

                <div className='col-12 mt-4'>
                  <label htmlFor='holes1-9'>Holes 10-18</label>
                  <div className={classNames('mt-0', styles.hl)} />
                  <div className='d-flex'>
                    {_.range(10, 19).map((holeNr) => (
                      <div key={holeNr} className={classNames('d-flex flex-column align-items-center', styles.holeInput)}>
                        <label htmlFor={`hole-${holeNr}`}>{holeNr}</label>
                        <Input
                          name={`hole-${holeNr}`}
                          type='number'
                          onChange={(e) => handleHolesCountChange(e, holeNr)}
                          classNames={{ inputContainerWrapper: styles.holeInputWrapper }}
                          value={formData.holesCount[holeNr]}
                        />
                      </div>
                    ))
                    }
                  </div>
                </div>
              </>
              }
            </div>
            <div className='row py-3'>
              <div className='col-6'>
                <CancelButton
                  type='button'
                  buttonMessage='Cancel'
                  className='mt-3'
                  onClick={exitModal}
                />
              </div>
              <div className='col-6'>
                <SubmitButton
                  onClick={handleSession}
                  buttonMessage={pairings.index === undefined ? 'Create' : 'Update'}
                  buttonColor='orange-button'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

CreatePairingsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedSession: PropTypes.object,
  event: PropTypes.shape({
    format: PropTypes.string.isRequired,
  }),
}

export default CreatePairingsModal
