import * as constants from './actionTypes'
import { objectToFormData } from '../utility/formHelper'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleAPIError } from '../utility/actionHelper'
import { serialize, deserialize } from '../utility/serialization'
import { honeybadger } from '../honeybadger'

import Axios from '../axios'

export function successRequestingEvents(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_EVENTS,
      events: response.data.events,
      pagination: response.data.meta.pagination,
    }
  } else {
    return { type: constants.UI_ERROR, error: response.data }
  }
}


// THUNKS HERE
export function getEvents(user_id, filters = {}, page = 1, perPage = 50) {
  return function(dispatch) {
    dispatch(showLoading())
    return requestEvents(user_id, filters, page, perPage)
      .then(response => dispatch(successRequestingEvents(response)))
      .then(_response => dispatch(hideLoading()))
  }
}

export const getEventsByMonth = createAsyncThunk(
  'events/getEventsByMonth',
  async ({ selectedMonthDate }, { rejectWithValue }) => {
    try {
      const { data } = await requestGetEventsByMonth(selectedMonthDate)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getEvent = createAsyncThunk(
  'nineHoleEvent/getEvent',
  async (eventId, { rejectWithValue }) => {
    try {
      const { data } = await requestEvent(eventId)

      const event = data.event

      if (event.enrollments.some(e => !('family_admins_emails' in e.student))) {
        honeybadger.notify(
          'Event has an enrolled student without the "family_admins_emails" attribute',
          {
            name: 'Custom log - missing "family_admins_emails" attribute',
            context: { event },
          }
        )
      }
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const createEvent = createAsyncThunk(
  'nineHoleEvent/createEvent',
  async (event, { rejectWithValue }) => {
    try {
      const { data } = await requestCreateEvent(event)

      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const updateEvent = createAsyncThunk(
  'nineHoleEvent/updateEvent',
  async (event, { rejectWithValue }) => {
    try {
      const { data } = await requestUpdateEvent(event)

      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const deleteEvent = createAsyncThunk(
  'nineHoleEvent/deleteEvent',
  async (eventId, { rejectWithValue }) => {
    try {
      const { data } = await requestDeleteEvent(eventId)

      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const deleteEnrollment = createAsyncThunk(
  'nineHoleEvent/deleteEnrollment',
  async (enrollmentId, { rejectWithValue }) => {
    try {
      const { data } = await requestDeleteEnrollment(enrollmentId)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const updateEnrollments = createAsyncThunk(
  'nineHoleEvent/updateEnrollments',
  async ({ event }, { rejectWithValue }) => {
    try {
      const { data } = await requestUpdateEnrollments(event)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const sendEventReminder = createAsyncThunk(
  'nineHoleEvent/sendEventReminder',
  async ({ eventId, userId }, { rejectWithValue }) => {
    try {
      const { data } = await requestSendEventReminder(eventId, userId)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

// API CALLS
function requestEvents(user_id, { search }, page, perPage) {
  const requestUrl = '/api/v1/events'

  const params = {
    user_id,
    page: page,
    per_page: perPage,
  }

  if (search) {
    params.search = search
  }

  return Axios.get(requestUrl, { params })
}

const requestGetEventsByMonth = (monthDate) => {
  const requestUrl = '/api/v1/events/dashboard_calendar'

  return Axios.get(requestUrl, { params: serialize({ monthDate: monthDate.toDateString() }) })
}

function requestEvent(eventId) {
  const requestUrl = `/api/v1/events/${eventId}`
  return Axios.get(requestUrl)
}

function requestCreateEvent(event) {
  const requestUrl = '/api/v1/events/'

  const formData = objectToFormData(serialize(event))

  return Axios.post(requestUrl, formData)
}

function requestUpdateEvent(event) {
  const requestUrl = '/api/v1/events/' + event.id

  const formData = objectToFormData(serialize(event))

  return Axios.put(requestUrl, formData)
}

function requestDeleteEvent(eventId) {
  const requestUrl = '/api/v1/events/' + eventId
  return Axios.delete(requestUrl)
}

function requestDeleteEnrollment(enrollmentId) {
  const requestUrl = '/api/v1/series_enrollments/' + enrollmentId
  return Axios.delete(requestUrl)
}

function requestUpdateEnrollments(event) {
  const requestUrl = '/api/v1/events/' + event.id + '/enrollments/'
  const data = serialize({ students: event.students })

  return Axios.put(requestUrl, data)
}

function requestSendEventReminder(eventId, userId) {
  const requestUrl = '/api/v1/events/' + eventId + '/send_reminder'
  return Axios.post(requestUrl, { user_id: userId })
}
