import store from './store/store'

import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { Provider } from 'react-redux'
import { RootPage, AuthenticatedApp, AuthorizationRoute } from './guarding'
import HoneybadgerProvider from './honeybadger'

import CommunityMapPage from './components/community-map/CommunityMapPage'
import CommunityLandingPageWrapper from './components/community-landing-page/CommunityLandingPageWrapper'
import CommunityLandingPage from './components/community-landing-page/CommunityLandingPage'
import EmbeddableWrapper from './components/community-landing-page/EmbeddableWrapper'
import AppWrapper from './components/AppWrapper'
import SignUpPage from './components/auth_2/SignUp/SignUpPage'
import LogInPage from './components/auth_2/LogIn/LogInPage'
import ForgotPasswordPage from './components/auth_2/LogIn/ForgotPassword/ForgotPasswordPage'
import ForgotUsernamePage from './components/auth_2/LogIn/ForgotUsernamePage'
import CoachRegistrationFlow from './components/auth/Registration/CoachRegistrationFlow/CoachRegistrationFlow'
import DashboardPage from './components/dashboard/DashboardPage'
import ProShopPage from './components/proShopDisplay'
import ChallengesPage from './components/challenges/ChallengesPage'
import AdminUsersPage from './components/users/Admin/AdminUsersPage'
import StudentsPage from './components/students/StudentsPage'
import ProfilePage from './components/profile/ProfilePage-web'
import AccountPage from './components/account/AccountPage'
import CoachesPage from './components/coaches/CoachesPage'
import FamiliesPage from './components/families/FamiliesPage'
import FamilyPage from './components/families/FamilyPage'
import FamilyDetailPage from './components/families/FamilyDetailPage'
import ObjectivesPage from './components/objectives/ObjectivesPage'
import CurriculumsPage from './components/curriculums/CurriculumsPage'
import RanksPage from './components/ranks/RanksPage'
import ObjectiveDetailsPage from './components/ranks/ObjectiveDetailsPage'
import LeadsPage from './components/leads/LeadsPage'
import SalesLeadsPage from './components/leads/salesLeads/SalesLeadsPage'
import AnnouncementsPage from './components/announcements/AnnouncementsPage'
import CampaignPage from './components/announcements/CampaignPage'
import CommunitiesPage from './components/communities/CommunitiesPage'
import CommunityDetailPage from './components/communities/CommunityDetailPage'
import ReportsPage from './components/reports/ReportsPage'
import ResourceDetailPage from './components/resources/Detail/ResourceDetailPage'
import ResourceSectionsPage from './components/resources/ResourceSections/ResourceSectionsPage'
import ResourceSectionDetailPage from './components/resources/ResourceSections/ResourceSectionDetailPage'
import ClassesPage from './components/classes/GroupPrograms/ClassesPage'
import CoachAcademyPage from './components/education/CoachAcademyPage'
import LessonPlanPage from './components/lessonPlans/LessonPlanPage'
import LessonPlanViewerPage from './components/lessonPlans/viewer/LessonPlanViewerPage'
import LessonPlanBuilderPage from './components/lessonPlans/builder/LessonPlanBuilderPage'
import ConnectStripeRefreshPage from './components/community-settings/PricingTab/ConnectStripeRefreshPage'
import DrillsPage from './components/drills/DrillsPage'
import DrillsBuilder from './components/drills/builder/DrillsBuilder'
import Events from './components/events/Events'
import EventDetail from './components/events/Detail/EventDetail'
import ManagePairings from './components/events/Form/ManagePairings'
import CommunitySettingsPage from './components/community-settings/CommunitySettingsPage'
import ProgramBuilder from './components/classes/ProgramBuilder/ProgramBuilder'
import FourOhFour from './components/common/FourOhFour'
import ErrorBoundary from './components/ErrorBoundary'
import ProgramLandingPage from './components/classes/ProgramLanding/ProgramLandingPage'
import ProgramPresentationPage from './components/classes/ProgramLanding/ProgramPresentationPage/ProgramPresentationPage'
import ProgramRegistrationPage from './components/classes/ProgramRegistration/ProgramRegistrationPage'
import ProgramRegistrationCompletedPage from './components/classes/ProgramRegistration/RegistrationCompletedPage/ProgramRegistrationCompletedPage'
import FamiliesStudentsPage from './components/families/FamiliesStudentsPage'
import MapDetailPageDeprecated from './components/community-map/MapDetailPageDeprecated'
import EventSeries from './components/events/SeriesFlow/EventSeries'
import CreateNewAccount from './components/auth_2/SignUp/CreateNewAccount'
import EmailVerificationRequired from './components/auth_2/SignUp/EmailVerificationRequired'
import ConfirmAccount from './components/auth_2/SignUp/ConfirmAccount'
import Summary from './components/auth_2/SignUp/Summary'
import AddFamilyMember from './components/auth_2/SignUp/AddFamilyMember'
import ProgramDashboardManager from './components/classes/program-dashboard-manager/ProgramDashboardManager'
import OverviewTab from './components/classes/program-dashboard-manager/overview-tab/OverviewTab'
import ScheduleTab from './components/classes/program-dashboard-manager/ScheduleTab'
import LessonPlanTab from './components/classes/program-dashboard-manager/LessonPlanTab'
import SeriesScheduleTab from './components/classes/program-dashboard-manager/SeriesScheduleTab'
import ReportsTab from './components/classes/program-dashboard-manager/ReportsTab'
import EnrollmentsTab from './components/classes/program-dashboard-manager/enrollments-tab/EnrollmentsTab'

const RegisterRedirect = () => {
  const { pathname } = useLocation()

  const newPath = pathname.replace(
    /^\/register\/(\d+)(?:\/invitation\/(\d+))?/,
    (_, c, i) => `/sign-up?step=1&community_id=${c}${i ? `&invitation_id=${i}` : ''}`
  )
  return <Navigate to={newPath === pathname ? '/sign-up?step=1' : newPath} replace />
}

const App = () => (
  <Provider store={store}>
    <HoneybadgerProvider>
      <BrowserRouter>
        <Routes>
          {/* Authentication */}
          <Route path='/' element={<RootPage/>} />

          <Route element={<SignUpPage />}>
            <Route path='/sign-up' element={<CreateNewAccount />} />
            <Route path='/sign-up/email-verification-required' element={<EmailVerificationRequired />} />
            <Route path='/sign-up/confirm-email' element={<ConfirmAccount />} />
            <Route path='/app/confirm-email' element={<Navigate to={`/sign-up/confirm-email${window.location.search}`} replace />} />
          </Route>

          <Route element={<AuthorizationRoute roles={['Admin', 'Coach', 'FamilyAdmin']}/>}>
            <Route element={<SignUpPage />}>
              <Route path='/sign-up/summary' element={<Summary />} />
              <Route path='/sign-up/new-member' element={<AddFamilyMember />} />
            </Route>
          </Route>

          <Route path='/log-in' element={<LogInPage />} />
          <Route path='/forgot-password' element={<ForgotPasswordPage />} />
          <Route path='/forgot-password/:token' element={<ForgotPasswordPage />} />
          <Route path='/forgot-username' element={<ForgotUsernamePage />} />

          <Route path='/register/*' element={<RegisterRedirect />} />
          <Route path='/register-coach' element={<CoachRegistrationFlow />} />
          <Route path='/register-coach/:sales_guy' element={<CoachRegistrationFlow />} />

          {/* Community Map */}
          <Route path='/community-map' element={<CommunityMapPage />} />
          <Route path='/community-map/:communityId' element={<MapDetailPageDeprecated />} />

          {/* Community Landing Page */}
          <Route element={<EmbeddableWrapper />}>
            <Route path='/landing/:communityId' element={<CommunityLandingPageWrapper />} >
              <Route index element={<CommunityLandingPage />} />

              <Route path='class/:courseId' element={<ProgramLandingPage />}>
                <Route index element={<ProgramPresentationPage />} />
                <Route path='register' element={<ProgramRegistrationPage />} />
                <Route path='registration/:registrationId' element={<ProgramRegistrationCompletedPage />} />
              </Route>
            </Route>
          </Route>

          {/* Main App */}
          <Route element={<AuthenticatedApp><ErrorBoundary><AppWrapper /></ErrorBoundary></AuthenticatedApp>}>
            <Route path='/dashboard/*' element={<DashboardPage />} />
            <Route path='/pro-shop' element={<ProShopPage />} />
            {/* <Route path='/profile' element={<ProfilePage />} /> */}
            <Route path='/account' element={<AccountPage />} />

            <Route element={<AuthorizationRoute roles={['Admin', 'Coach', 'FamilyAdmin']} />}>
              <Route path='/family' element={<FamilyPage />} />
              <Route path='/familydetail/:familyId' element={<FamilyDetailPage />} />
              <Route path='/coach-academy' element={<CoachAcademyPage />} />
              <Route element={<AuthorizationRoute level={1} />}>
                <Route path='/profile/:userId' element={<ProfilePage />} />
                <Route path='/app/profile/:userId' element={<ProfilePage />} />
              </Route>
            </Route>

            <Route element={<AuthorizationRoute roles={['Admin', 'Coach']} />}>
              <Route element={<AuthorizationRoute level={1} />}>
                <Route path='/students' element={<StudentsPage />} />
                <Route path='/families' element={<FamiliesPage />} />
                <Route path='/students-families' element={<FamiliesStudentsPage />} />
                {/* <Route path='/family' element={<FamilyPage />} /> */}
                <Route path='/family/:showAdd' element={<FamilyPage />} />
                <Route path='/leads' element={<LeadsPage />} />
                <Route path='/events' element={<Events/>} />
                <Route path='/events/:eventId' element={<EventDetail />} />
                <Route path='app/events/:eventId' element={<EventDetail />} />
                <Route path='/events/:eventId/manage-pairings' element={<ManagePairings />} />
              </Route>

              <Route element={<AuthorizationRoute level={2} />}>
                <Route path='/objectives' element={<ObjectivesPage />} />
                <Route path='/goals' element={<CurriculumsPage />} />
                <Route path='/ranks' element={<RanksPage />} />
                <Route path='/ranks/:rankId/objective/:objectiveId' element={<ObjectiveDetailsPage />} />
                <Route path='/announcements' element={<AnnouncementsPage />} />
                <Route path='/announcements/new-campaign' element={<CampaignPage />} />
                <Route path='/reports' element={<ReportsPage />} />
                <Route path='/resources/:resourceId' element={<ResourceDetailPage />} />
                <Route path='/resource-sections' element={<ResourceSectionsPage />} />
                <Route path='/resource-sections/:resourceSectionId' element={<ResourceSectionDetailPage />} />
                <Route path='/lesson-plan' element={<LessonPlanPage />} />
                <Route path='/lesson-plan/builder' element={<LessonPlanBuilderPage />} />
                <Route path='/lesson-plan/builder/:lessonPlanId/:isEdit' element={<LessonPlanBuilderPage />} />
                <Route path='/lesson-plan/view/:lessonPlanId' element={<LessonPlanViewerPage />} />

                <Route element={<AuthorizationRoute roles={['Coach']}/>}>
                  <Route path='/programs' element={<ClassesPage />} />
                  <Route path='/programs/:id' element={<ProgramDashboardManager />}>
                    <Route path='overview' element={<OverviewTab />} />
                    <Route path='schedule' element={<ScheduleTab />} />
                    <Route path='lesson-plan' element={<LessonPlanTab />} />
                    <Route path='events' element={<SeriesScheduleTab />} />
                    <Route path='reports' element={<ReportsTab />} />
                    <Route path='enrollments' element={<EnrollmentsTab />} />
                    <Route path='*' element={<Navigate to='/' replace />} />
                  </Route>
                  <Route path='/programs/new' element={<ProgramBuilder />} />
                  <Route path='/programs/:id/edit' element={<ProgramBuilder />} />

                  <Route path='/series/new' element={<EventSeries />} />
                  <Route path='/series/:id/edit' element={<EventSeries />} />
                </Route>
              </Route>
            </Route>

            <Route element={<AuthorizationRoute roles={['Admin', 'Coach']} level={2} specialUnlock />}>
              <Route path='/drills' element={<DrillsPage />} />
              <Route path='/drills/builder' element={<DrillsBuilder />} />
              <Route path='/drills/builder/:drillId' element={<DrillsBuilder />} />
            </Route>

            <Route element={<AuthorizationRoute roles={'Coach'} level={2} leadCoachRequired />}>
              <Route path='/community-settings' element={<CommunitySettingsPage />} />
            </Route>

            <Route element={<AuthorizationRoute roles={'Admin'} />}>
              <Route path='/challenges' element={<ChallengesPage />} />
              <Route path='/users' element={<AdminUsersPage />} />
              <Route path='/coaches' element={<CoachesPage />} />
              <Route path='/communities' element={<CommunitiesPage />} />
              <Route path='/communities/:communityId' element={<CommunityDetailPage />} />
              <Route path='/sales-leads' element={<SalesLeadsPage />} />
            </Route>
          </Route>

          <Route element={<AuthorizationRoute roles={'Coach'} level={2} leadCoachRequired />}>
            <Route path='/connect-stripe-refresh' element={<ConnectStripeRefreshPage />} />
          </Route>

          {/* Not found */}
          <Route path='*' element={<FourOhFour />} />
        </Routes>
      </BrowserRouter>
    </HoneybadgerProvider>
  </Provider>
)

export default App
