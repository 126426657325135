import * as constants from "./actionTypes";
import Axios from '../axios'
import moment from "moment";
import { serialize } from '../utility/serialization'

function successRequestingCurriculumReport(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_CURRICULUM_REPORT,
      reports: response.data.curriculum_report
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingEngagementReport(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_ENGAGEMENT_REPORT,
      reports: response.data.engagement_report
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingStatsReport(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_STATS_REPORT,
      reports: response.data.stats_report
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingCommunityReport(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_COMMUNITY_REPORT,
      reports: response.data.community_report
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingCommunityRoundsReport(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_COMMUNITY_ROUNDS_REPORT,
      reports: response.data.community_rounds_report
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingCommunityPracticeReport(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_COMMUNITY_PRACTICE_REPORT,
      reports: response.data.community_practice_report
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingStudents(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_STUDENTS,
      students: response.data.students
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingCoachEngagementReport(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_COACH_ENGAGEMENT_REPORT,
      reports: response.data.coach_engagement_report,
      pages: response.data.meta.pagination.total_pages
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingCommunityEngagementReport(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_COMMUNITY_ENGAGEMENT_REPORT,
      reports: response.data.community_engagement_report
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingSupportActivityReport(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_SUPPORT_ACTIVITY_REPORT,
      reports: response.data.support_activity_report
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingCommunityPriorityReport(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_COMMUNITY_PRIORITY_REPORT,
      reports: response.data.community_priority_report,
      pages: response.data.meta.pagination.total_pages
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// THUNKS HERE
export function getCurriculumReport(report, community) {
  return function(dispatch) {
    return requestReports(report, community).then(response =>
      dispatch(successRequestingCurriculumReport(response))
    );
  };
}

export function getEngagementReport(report, community) {
  return function(dispatch) {
    return requestReports(report, community).then(response =>
      dispatch(successRequestingEngagementReport(response))
    );
  };
}

export function getStatsReport(report, community) {
  return function(dispatch) {
    return requestReports(report, community).then(response =>
      dispatch(successRequestingStatsReport(response))
    );
  };
}

export function getCommunityReport(report, community) {
  return function(dispatch) {
    return requestReports(report, community).then(response =>
      dispatch(successRequestingCommunityReport(response))
    );
  };
}

export function getCommunityRoundsReport(report, community) {
  return function(dispatch) {
    return requestReports(report, community).then(response =>
      dispatch(successRequestingCommunityRoundsReport(response))
    );
  };
}

export function getCommunityPracticeReport(report, community) {
  return function(dispatch) {
    return requestReports(report, community).then(response =>
      dispatch(successRequestingCommunityPracticeReport(response))
    );
  };
}

export function getAllStudents(community) {
  return function(dispatch) {
    return requestStudents(community).then(response =>
      dispatch(successRequestingStudents(response))
    );
  };
}

export function getCoachEngagementReport(report, state, instance) {
  return function(dispatch) {
    return requestCoachEngagementReport(report, state, instance).then(
      response => dispatch(successRequestingCoachEngagementReport(response))
    );
  };
}

export function getCommunityEngagementReport(community_id) {
  return function(dispatch) {
    return requestCommunityEngagementReport(community_id).then(response =>
      dispatch(successRequestingCommunityEngagementReport(response))
    );
  };
}

export function getSupportActivityReport(report) {
  return function(dispatch) {
    return requestReports(report).then(response =>
      dispatch(successRequestingSupportActivityReport(response))
    );
  };
}

export function getCommunityPriorityReport(report, state, instance) {
  return function(dispatch) {
    return requestCommunityPriorityReport(report, state, instance).then(
      response => dispatch(successRequestingCommunityPriorityReport(response))
    );
  };
}
// API CALLS
function requestReports(report, community) {
  let requestUrl = "/api/v1/reports";

  requestUrl += `/${report.reportType}_report`;

  if (community) {
    requestUrl += "?community_id=" + community.id;
  }

  if (report.start_date && report.end_date)
    if (report.reportType === "support_activity") {
      requestUrl +=
        "?start_date=" +
        moment(report.start_date, "MM-DD-YYYY").format("MM-DD-YYYY") +
        "&end_date=" +
        moment(report.end_date, "MM-DD-YYYY").format("MM-DD-YYYY");
    } else if (community) {
      requestUrl +=
        "&start_date=" +
        moment(report.start_date, "MM-DD-YYYY").format("MM-DD-YYYY") +
        "&end_date=" +
        moment(report.end_date, "MM-DD-YYYY").format("MM-DD-YYYY");
    } else {
      requestUrl += "";
    }

  if (report.programSelected > -1)
    requestUrl += "&course_id=" + report.programSelected;

  return Axios.get(requestUrl);
}

function requestCoachEngagementReport(report, state, instance) {
  let requestUrl = "/api/v1/reports";
  let sorted = "";

  requestUrl += `/${report.reportType}_report`;

  if (state && state.sorted && state.sorted.length > 0) {
    switch (state.sorted[0].id) {
      case "coach.first_name":
        sorted = "first_name";
        break;
      case "coach.last_name":
        sorted = "last_name";
        break;
      case "coach.email":
        sorted = "email";
        break;
      case "last_login_date":
        sorted = "last_sign_in_at";
        break;
      default:
        sorted = "";
    }
  }

  return Axios.get(requestUrl, {
    params: {
      page: state && state.page ? state.page + 1 : 1,
      per_page: state && state.pageSize ? state.pageSize : 10,
      sorted: sorted ? sorted : null,
      desc:
        state && state.sorted && state.sorted.length > 0
          ? state.sorted[0].desc
          : null
    }
  });
}

function requestCommunityEngagementReport(community_id) {
  let requestUrl =
    "/api/v1/reports/community_engagement_report?community_id=" + community_id;

  return Axios.get(requestUrl);
}

export function requestCommunityLeaderboardRanking(communityId, filters, page, perPage) {
  const requestUrl = 'api/v1/reports/community_leaderboard_ranking'
  let params = { communityId, page, perPage }
  if (filters) {
    params = {
      ...params,
      ...filters,
      startDate: filters.startDate && moment(filters.startDate).toISOString().split('T')[0],
      endDate: moment(filters.endDate).toISOString().split('T')[0],
    }
  }
  return Axios.get(requestUrl, { params: serialize(params) })
}

export function requestCheckExportStatus(jobId) {
  const requestUrl = `api/v1/jobs/${jobId}`
  return Axios.get(requestUrl)
}

export function requestExportCommunityLeaderboardRanking(communityId, filters) {
  const requestUrl = 'api/v1/reports/export_community_leaderboard_ranking'
  let params = { communityId }
  if (filters) {
    params = {
      ...params,
      ...filters,
      startDate: filters.startDate && moment(filters.startDate).toISOString().split('T')[0],
      endDate: moment(filters.endDate).toISOString().split('T')[0],
    }
  }
  return Axios.post(requestUrl, serialize(params))
}

export function requestCommunityLeaderboardStats(communityId, filters) {
  const requestUrl = 'api/v1/reports/community_leaderboard_stats'
  const params = serialize({
    communityId,
    startDate: filters.startDate && moment(filters.startDate).toISOString().split('T')[0],
    endDate: moment(filters.endDate).toISOString().split('T')[0],
    categoryId: filters.categoryId,
    courseId: filters.courseId,
  })
  return Axios.get(requestUrl, { params })
}

function requestCommunityPriorityReport(report, state, instance) {
  let requestUrl = "/api/v1/reports";
  let sorted = "";

  requestUrl += `/${report.reportType}_report`;

  if (state && state.sorted && state.sorted.length > 0) {
    switch (state.sorted[0].id) {
      case "community":
        sorted = "name";
        break;
      case "support_rating":
        sorted = "support_rating";
        break;
      case "time_in_network":
        sorted = "created_at";
        break;
      case "season_start_date":
        sorted = "season_start_date";
        break;
      case "season_end_date":
        sorted = "season_start_date";
        break;
      case "last_contact_made":
        sorted = "last_contact_made";
        break;
      case "last_attempt_made":
        sorted = "last_contact_made";
        break;
      default:
        sorted = "";
    }
  }

  return Axios.get(requestUrl, {
    params: {
      page: state && state.page ? state.page + 1 : 1,
      per_page: state && state.pageSize ? state.pageSize : 25,
      sorted: sorted ? sorted : null,
      desc:
        state && state.sorted && state.sorted.length > 0
          ? state.sorted[0].desc
          : null
    }
  });
}

function requestStudents(community) {
  var requestUrl = "/api/v1/reports/students?community_id=" + community;

  return Axios.get(requestUrl);
}
