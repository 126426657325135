import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import avatarPlaceholder from '../../../assets/images/mock/default-profile-icon.png'
import SmallMilestoneIcon from '../../common/SmallMilestoneIcon'
import { genderLabel } from '../../../utility/userHelper'

import { ReactComponent as TimeIcon } from '../../../assets/images/common/icons/time-icon.svg'
import { ReactComponent as FlagIcon } from '../../../assets/images/common/icons/simple-flag-icon.svg'

import styles from './PairingGroup.module.scss'
import { DATETIME_FORMAT, getMomentInCommunityTimezoneReversed } from '../../../utility/datesHelper'
import { useSelector } from 'react-redux'

const PairingGroup = ({ pairingGroup, format }) => {
  const navigate = useNavigate()
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)

  return (
    <div className={classNames(styles.pairingGroup, 'd-flex  w-100 mx-auto')}>
      <div className='col-2 p-0'>
        <div className={classNames('pl-2 pr-2 d-flex justify-content-between align-items-center flex-wrap', styles.pairingGroupInfo)}>
          {format === 'Shotgun' ?
            <span className={styles.pairingGroupOrder}>
              <FlagIcon className={styles.flagIcon}/> Hole {pairingGroup.startHole.value}{pairingGroup.playersGroup.value}
            </span> :
            <span className={styles.pairingGroupOrder}>Pair {pairingGroup.order}</span>
          }

          <span className={classNames('d-flex align-items-center', styles.pairingGroupTime)}><TimeIcon className={styles.timeIcon}/>
            {getMomentInCommunityTimezoneReversed(moment(pairingGroup.startTime), timezone, DATETIME_FORMAT).format('LT')}
          </span>
        </div>
      </div>
      <div className='col-10 d-flex w-100 flex-wrap'>
        {pairingGroup.users?.map((student, index) => (
          <button
            className={classNames('d-flex align-items-center px-2', styles.studentCard)}
            onClick={() => navigate(`/profile/${student.id}`)}
            key={index}
          >
            <div className='mr-3 smaller-round-image-wrapper' >
              <img
                alt='student'
                className='round-image'
                src={student.avatar?.fileUrl || avatarPlaceholder}
              />
            </div>
            <SmallMilestoneIcon fromAvatar className={styles.studentLevel} division={student.level} />
            <div className={styles.studentData}>
              <p className={styles.studentName}>{student.firstName} {student.lastName}</p>
              <p>{`Age ${student.age}/ ${genderLabel[student.gender]}`}</p>
            </div>
          </button>
        ))}
      </div>
    </div>
  )
}

PairingGroup.propTypes = {
  pairingGroup: PropTypes.object.isRequired,
  format: PropTypes.string.isRequired,
}

export default PairingGroup
