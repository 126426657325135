import Axios from '../axios'
import { serialize, deserialize } from '../utility/serialization'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleAPIError } from '../utility/actionHelper'

// THUNKS

// TODO: future refactoring is planned
export const getEditProgramSchedule = createAsyncThunk(
  'schedules/getEditProgramSchedule',
  async (programId, { rejectWithValue }) => {
    try {
      const { data } = await requestCourseSchedule(programId)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getShowProgramSchedule = createAsyncThunk(
  'schedules/getShowProgramSchedule',
  async (programId, { rejectWithValue }) => {
    try {
      const { data } = await requestCourseSchedule(programId)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const createProgramSchedule = createAsyncThunk(
  'schedules/createProgramSchedule',
  async (schedule, { rejectWithValue }) => {
    try {
      const { data } = await requestCreateSchedule(schedule)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const updateProgramSchedule = createAsyncThunk(
  'schedules/updateProgramSchedule',
  async (schedule, { rejectWithValue }) => {
    try {
      const { data } = await requestUpdateSchedule(schedule)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

// API CALLS

function requestCourseSchedule(course_id) {
  const requestUrl = '/api/v1/schedules/course/' + course_id

  return Axios.get(requestUrl)
}

function requestCreateSchedule(schedule) {
  const requestUrl = '/api/v1/schedules'

  const scheduleSessions = schedule.scheduleSessions.map(({ title, location, description, startDate, endDate, isInvalid }) => ({
    title,
    location,
    description,
    isInvalid,
    startDate: typeof(startDate) === 'string' ? startDate : startDate.toISOString(),
    endDate: typeof(endDate) === 'string' ? endDate : endDate.toISOString(),
  }))

  const data = serialize({ course_id: schedule.courseId, schedule_sessions: scheduleSessions })
  return Axios.post(requestUrl, data)
}

function requestUpdateSchedule(schedule) {
  const requestUrl = '/api/v1/schedules/' + schedule.id

  const scheduleSessions = schedule.scheduleSessions.map(({ id, title, location, description, startDate, endDate, deleted, isInvalid }) => ({
    id: id ?? null,
    title,
    location,
    description,
    isInvalid,
    deleted: deleted ?? false,
    startDate: typeof(startDate) === 'string' ? startDate : startDate.toISOString(),
    endDate: typeof(endDate) === 'string' ? endDate : endDate.toISOString(),
  }))

  const data = serialize({ ...schedule, scheduleSessions: scheduleSessions })
  return Axios.put(requestUrl, data)
}
