import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { differenceInYears } from 'date-fns'
import classNames from 'classnames'

import { setUserField } from '../../../reducers/signUpReducer'
import ButtonLink from '../../common/buttons/ButtonLink'
import StepButton from '../Helpers/StepButton'
import AccountCard from '../../account/AccountCard'

import classes from './Summary.module.scss'

const Summary = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { user, familyMembers } = useSelector(state => state.signUp)
  const loggedInUser = useSelector(state => state.user.current_user)
  const [redirectUrl] = useState(localStorage.getItem('redirectUrl'))

  const dashboardUrl = localStorage.getItem('embed')
    ? `/dashboard?email=${loggedInUser?.email}&token=${loggedInUser?.authentication_token}`
    : '/dashboard'

  useEffect(() => {
    dispatch(setUserField({ field: 'familyId', value: loggedInUser.family.id }))
    dispatch(setUserField({ field: 'accountSegment', value: loggedInUser.account_segment }))
    dispatch(setUserField({ field: 'firstName', value: loggedInUser.first_name }))
    dispatch(setUserField({ field: 'lastName', value: loggedInUser.last_name }))
    dispatch(setUserField({ field: 'email', value: loggedInUser.email }))
    dispatch(setUserField({ field: 'age', value: differenceInYears(new Date(), new Date(loggedInUser.birthdate)) }))
    dispatch(setUserField({ field: 'avatarUrl', value: loggedInUser?.avatar?.original_file_url }))

    localStorage.removeItem('redirectUrl')
  }, [dispatch, loggedInUser])

  return (
    <aside className={classNames(classes.sideCard, 'd-flex ml-auto')}>
      <div className={classNames(classes.summary, 'd-flex justify-content-center')}>
        <section className={classNames(classes.actionPanel, 'd-flex flex-column')}>
          <h1 className={classes.titleText}>
            {familyMembers.length > 0 ? `Welcome,\n${user.lastName} family` : `Hello ${user.firstName}!`}
          </h1>

          {[user].concat(familyMembers).map((u, index) => (
            <AccountCard
              key={index}
              avatarUrl={u.avatarUrl}
              fullName={`${u.firstName} ${u.lastName}`}
              age={u.age}
              familyAdmin={u.familyAdmin}
            />
          ))}

          {familyMembers.length === 0 && (
            <h3 className={classNames(classes.subtitleText)}>Congratulations! Your account has been created successfully!</h3>
          )}

          <StepButton onClick={() => navigate('/sign-up/new-member?step=1')} color='navy' message='Add Family Member' />

          <ButtonLink navigateTo={redirectUrl || dashboardUrl} className={classNames(classes.actionLink, 'mt-4')}>
            Continue
          </ButtonLink>
        </section>
      </div>
    </aside>
  )
}

export default Summary
