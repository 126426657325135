import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ReactComponent as ShieldCheckMark } from '../../../assets/images/common/icons/shield-check-mark.svg'
import Modal from '../../common/Modal'
import SubmitButton from '../../common/buttons/SubmitButton'
import classes from './SocialSettingsModal.module.scss'

const SocialSettingsModal = ({ showModal, closeModal }) =>
  <Modal
    isOpen={showModal}
    onClose={closeModal}
    className={classes.socialSettingsModalContent}
  >
    <section className='d-flex flex-column justify-content-center align-items-center'>
      <ShieldCheckMark className={classNames(classes.shieldCheckMark, 'mt-2')} />
      <p className={classes.title}>Child Social Feature Settings</p>
      <p className={classes.message}>
        The Operation 36 App rewards juniors for logging when they play, practice, and complete social actions. These actions will be displayed
        to your coach and members of the program at your facility.
        <br/><br/>
        Safety is our #1 priority. If you plan to allow your juniors to post themselves, we recommend you adjust settings for their access.
        If you plan to not give your junior access and to post on their behalf, we recommend leaving the settings all turned on as it will allow
        you to choose to add details optionally when you post.
      </p>
    </section>
    <main className='row py-3'>
      <div className='col-12'>
        <SubmitButton
          onClick={closeModal}
          buttonMessage='Got it!'
          buttonColor='orange-button'
        />
      </div>
    </main>
  </Modal>

SocialSettingsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default SocialSettingsModal
