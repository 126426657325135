import PropTypes from 'prop-types'
import classnames from 'classnames'
import { requiredIf } from '../../../../utility/prop-types-helper'
import { formatDates } from '../../../../utility/datesHelper'
import { useSelector } from 'react-redux'
import PackageSelect from '../../../classes/ProgramBuilder/Pricing/PackageSelect'
import MCElement from '../../MCElement'
import EnrolledStudentsTooltip from '../../../classes/enrollments-details/EnrolledStudentsTooltip'

import classes from './ItemCard.module.scss'
import programClasses from './ProgramCard.module.scss'
import colors from '../../../../assets/styles/globals.scss'
import { ReactComponent as GolferIcon } from '../../../../assets/images/common/icons/golfer-half-icon.svg'
import { ReactComponent as CalendarIcon } from '../../../../assets/images/common/icons/calendar-icon.svg'

const ProgramCard = ({
/* eslint-disable react/prop-types */
  item: program,
  hasNewCategory,
  categoryTitle,
  isSelectable,
  isChecked,
  onCheckChange,
  withAdditionalSelect: withPackages,
  onSelect,
  onSelectDelete,
  className,
/* eslint-enable react/prop-types */
}) => {
  const communityTimezone = useSelector(state => state.user.current_user.communities[0].timezone)
  const unavailableItemsMap = useSelector(state => state.dualPanelSelector.students?.unavailableItems?.entities)

  return (
    <>
      <div className={classnames('d-flex flex-column', className, classes.itemWrapper)}>
        {hasNewCategory && <h4 className={programClasses.categoryTitle}>{categoryTitle}</h4>}
        <div className='d-flex'>
          {isSelectable && (
            <div className='flex-shrink-0 mt-1 d-flex align-items-start'>
              <MCElement
                type='checkbox'
                isSelected={isChecked}
                clicked={() => onCheckChange(isChecked, program)}
              />
            </div>
          )}
          <div className='flex-grow-1 ml-5 d-flex flex-column'>
            <span className={classes.title}>{program.name}</span>
            <div className='d-flex align-items-center'>
              <CalendarIcon className={programClasses.icon} />
              <span className={classnames('ml-1', classes.subtitle)}>{formatDates(program.startDate, program.endDate, communityTimezone)}</span>
            </div>
            <div className='d-flex align-items-center'>
              <div className='d-flex align-items-center' data-for={`enrollmentsTooltip-${program.id}`} data-tip>
                <GolferIcon className={programClasses.icon} fill={colors.primaryNavy} />
                <span className='ml-1 enrollments-count'>{program.enrollments.length}</span>
              </div>
              <span className={classnames('ml-1', classes.subtitle)}>{program.meetingTimes}</span>
            </div>
            {isSelectable && withPackages && isChecked && (
              <div className='mt-2 d-flex flex-column'>
                <span className={classes.selectLabel}>Select Packages That Include 9 Hole Events</span>
                <PackageSelect
                  communityPackages={program.packages}
                  packages={program.selectedPackages || []}
                  onAddPackage={(pkg) => onSelect(pkg, program)}
                  onDeletePackage={(pkg) => onSelectDelete(pkg, program)}
                  noCreateOption
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <EnrolledStudentsTooltip
        program={program}
        enrollments={program.enrollments}
        unavailableSectionTitle='Already-enrolled Students'
        isEnrollmentUnavailableFn={!!unavailableItemsMap && ((enrollment) => !!unavailableItemsMap[enrollment.user.id])}
      />
    </>
  )
}

ProgramCard.propTypes = {
  // ...ItemCardProps,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    meetingTimes: PropTypes.string.isRequired,
    enrollments: PropTypes.array.isRequired,
    packages: requiredIf(PropTypes.array, ({ withAdditionalSelect }) => withAdditionalSelect),
    selectedPackages: PropTypes.array,
  }).isRequired,
}

export default ProgramCard
