/* eslint-disable max-lines-per-function */
import moment from 'moment'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import classes from './CoachAcademyEducationView.module.scss'
import { getEducation, getUserEducation, updateEducation } from '../../../actions/educationActions'
import Loader from '../../common/Op36Loader-web'
import EducationOverview from './EducationOverview'
import EducationVideoModal from './Modals/EducationVideoModal'
import EducationProgressBar from '../EducationProgressBar'
import AdminEducationModal from '../Modals/AdminEducationModal'
import axiosInstance from '../../../axios'

const CoachAcademyEducationView = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.user.current_user)
  const educations = useSelector(state => state.educations)
  const education = useSelector(state => state.education)
  const userEducation = useSelector(state => state.userEducation)

  const [isEditLoading, setIsEditLoading] = useState(false)
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)
  const [isAdminEditModalOpen, setIsAdminEditModalOpen] = useState(false)
  const [isEducationLoading, setIsEducationLoading] = useState(true)

  const [editFormState, setEditFormState] = useState({
    detailView: 'overview',
    showVideoModal: false,
    showAdminEditModal: false,
    selectedLesson: {},
    id: -1,
    name: '',
    description: '',
    price: 0,
    start_date: null,
    end_date: null,
    visibility: 0,
    video_url: '',
    uploadPhotoName: '',
    uploadPhotoFile: '',
    uploadPhotoSrc: '',
    education_sections: [],
    sectionIndex: -1,
    selectedEducation: {},
    deleted_sections: [],
    deleted_lessons: [],
  })

  useEffect(() => {
    if (educations?.length) {
      dispatch(getEducation(educations?.[0]?.id))
        .then(() => {
          if (currentUser.type === 'Coach' && educations?.[0]?.user_education) {
            dispatch(getUserEducation(educations[0].user_education?.id))
          }
          setIsEducationLoading(false)
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [educations])

  // TODO: Refactor this logic after release
  useEffect(() => {
    if (education.id && !userEducation.length) {
      axiosInstance.post(`api/v1/educations/${education.id}/assign_free`, {
        user_id: currentUser.id,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [education])

  const updateEditFormState = (newState) => {
    setEditFormState(prevState => ({ ...prevState, ...newState }))
  }

  const grabPhotoData = photoData => {
    const { uploadPhotoFile, uploadPhotoName, uploadPhotoSrc } = photoData

    updateEditFormState({
      uploadPhotoName,
      uploadPhotoFile,
      uploadPhotoSrc,
    })
  }

  const grabSectionData = data => {
    const education_sections = [...editFormState.education_sections]
    const indexOfSection = education_sections
      .map(section => section.order)
      .indexOf(data.order)

    if (indexOfSection > -1) {
      education_sections.splice(indexOfSection, 1, data)
    } else {
      education_sections.push(data)
    }

    updateEditFormState({ education_sections })
  }

  const addSection = () => {
    let sectionIndex = editFormState.sectionIndex
    const education_sections = [...editFormState.education_sections]

    if (education_sections.length > 0) {
      const lastSectionIndex = education_sections.at(-1).order
      sectionIndex = lastSectionIndex + 1
    } else {
      sectionIndex++
    }

    education_sections.push({ order: sectionIndex })

    updateEditFormState({
      sectionIndex,
      education_sections,
    })
  }

  const deleteSectionFromAPI = (section) => {
    const deleted_sections = [...editFormState.deleted_sections, section.id]
    updateEditFormState({ deleted_sections })
  }

  const deleteSection = (section) => {
    const education_sections = [...editFormState.education_sections]
    const indexOfSection = education_sections
      .map((section) => section.order)
      .indexOf(section.order)

    const deletedSection = education_sections.splice(indexOfSection, 1)
    education_sections.splice(indexOfSection, 1)

    updateEditFormState({ education_sections })

    if (deletedSection[0].id && deletedSection[0].id > -1) {
      deleteSectionFromAPI(deletedSection[0])
    }
  }

  const deleteLessonFromAPI = (lesson) => {
    const deleted_lessons = [...this.state.deleted_lessons, lesson.id]

    updateEditFormState({ deleted_lessons })
  }

  const updateEducation_ = () => {
    setIsEditLoading(true)
    dispatch(updateEducation(editFormState))
      .then(() => dispatch(getEducation(education.id)))
      .then(() => {
        setIsEditLoading(false)
        setIsAdminEditModalOpen(false)
        toast.success('Success. Updated Education!', {
          position: toast.POSITION.BOTTOM_LEFT,
        })
      })
  }

  const handleInputChange = e => {
    updateEditFormState({
      [e.target.name]: e.target.value,
    })
  }

  const handleFormStateChange = (key, value) => {
    updateEditFormState({
      [key]: value,
    })
  }

  const convertVisibilityToInt = (vis) => {
    switch (vis) {
      case 'all_coaches':
        return 0
      case 'paid_coaches':
        return 1
      case 'academy_coaches':
        return 2
      default:
        return 0
    }
  }

  const setEducationState = () => {
    const {
      id,
      name,
      description,
      video_url,
      photo,
      education_sections,
      price,
      start_date,
      end_date,
      visibility,
    } = education

    updateEditFormState({
      id,
      name,
      description,
      price: price * 100,
      start_date: start_date ? moment(start_date) : null,
      end_date: end_date ? moment(end_date) : null,
      visibility: convertVisibilityToInt(visibility),
      video_url,
      uploadPhotoSrc: photo && photo.file_url,
      education_sections,
      selectedEducation: education,
    })
  }

  const toggleAdminEditModal = () => {
    setEducationState()
    setIsAdminEditModalOpen(true)
  }

  const toggleVideoModal = (selectedLesson) => {
    setIsVideoModalOpen(true)
    updateEditFormState({ selectedLesson })
  }

  const openCourse = () => {
    const firstLesson = education.education_sections[0].education_lessons[0]
    const courseStartedButNotCompleted = userEducation &&
      userEducation?.completed_lessons?.length > 0 &&
      !userEducation.is_completed

    const openedLesson =
      currentUser.type === 'Coach' && courseStartedButNotCompleted
        ? chooseFirstUncompletedLesson()
        : firstLesson

    toggleVideoModal(openedLesson)
  }

  const chooseFirstUncompletedLesson = () => {
    const educationSections = education.education_sections
    const educationLessons = educationSections.flatMap(section => section.education_lessons)
    const firstUncompletedLesson = educationLessons.find(
      lesson => !Object.keys(userEducation.completed_lessons).includes(String(lesson.id))
    )

    return firstUncompletedLesson
  }

  if (isEducationLoading) {
    return <Loader message='loading education details' />
  }

  return (
    <div id='EducationDetailPage' className='animated fadeIn'>
      <div className='col-md-8 mx-auto mt-4 pb-4'>
        <div className='mb-4'>
          <div className='d-flex align-items-center justify-content-between'>
            <h5 className={classNames(classes.educationTitle, 'mb-0')}>{education.name}</h5>
            <div>
              {currentUser.type === 'Admin' && (
                <button
                  className='btn btn-dark mr-2'
                  onClick={toggleAdminEditModal}
                >
                  Edit Education
                </button>
              )}
              {currentUser.type !== 'Admin' && (
                <button
                  className='btn btn-primary start-course-button'
                  style={{ transform: 'scale(1)' }}
                  onClick={openCourse}
                >
                  {userEducation?.completed_lessons?.length > 0 ? 'Continue Course' : 'Start Course'}
                </button>
              )}
            </div>
          </div>

          {currentUser.type === 'Coach' &&
            userEducation &&
            userEducation.completed_lessons && (
            <EducationProgressBar
              education={education}
              userEducation={userEducation}
            />
          )}
        </div>

        <EducationOverview education={education} />
      </div>

      {isVideoModalOpen && (
        <EducationVideoModal
          key={userEducation.id}
          showModal={isVideoModalOpen}
          closeModal={() => setIsVideoModalOpen(false)}
          closeCompleteModal={(_education) => setIsVideoModalOpen(false)}
          education={education}
          selectedLesson={editFormState.selectedLesson}
          isCoach={currentUser.type === 'Coach'}
        />
      )}

      {currentUser.type === 'Admin' && isAdminEditModalOpen && (
        <AdminEducationModal
          current_user={currentUser}
          showModal={isAdminEditModalOpen}
          closeModal={() => setIsAdminEditModalOpen(false)}
          formState={editFormState}
          grabPhotoData={grabPhotoData}
          handleInputChange={handleInputChange}
          handleDateChange={handleFormStateChange}
          sendSectionData={grabSectionData}
          addSection={addSection}
          deleteSection={deleteSection}
          deleteLesson={deleteLessonFromAPI}
          updateEducation={updateEducation_}
          loader={isEditLoading}
          isEditModal
        />
      )}
    </div>
  )
}

export default CoachAcademyEducationView
