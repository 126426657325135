import classNames from 'classnames'
import classes from './ImageWithOverlay.module.scss'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import { useState } from 'react'
import EventCard from '../common/dual-panel-selector/items/EventCard'

const ImageWithOverlay = ({ src, alt, height, viewMore, video, activity }) => {
  const [display, setDisplay] = useState('media')
  const imgHeight = `calc(${height} - 2.5rem)`

  const series = Array.isArray(activity?.series) ? activity.series : []
  const courses = Array.isArray(activity?.courses) ? activity.courses : []
  const events = [...series, ...courses]
  const count = series.length + courses.length

  const placeHolder = () => (
    <div style={{ height: imgHeight }} className={classes.placeHolder}>
      <i className='fa fa-picture-o'/>
      <h2>NO MEDIA TO SHOW</h2>
    </div>
  )

  const renderMore = () => <div style={{ height: imgHeight }} className={classes.eventsContainer}>
    <span className={classes.title}>{`Summary (${count})`}</span>
    {events.map((event) => (
      <EventCard key={event.id} item={event}/>
    ))}
  </div>

  const renderMedia = () => {
    if (video) {
      return <ReactPlayer url={video} height= {imgHeight} width={'100%'} controls/>
    } else if (src) {
      return <img src={src} alt={alt} className={classes.image} style={{ height: imgHeight }}/>
    } else {
      return placeHolder()
    }
  }

  return <div className={classes.imageContainer} style={{ height }}>
    {display === 'media' ? renderMedia() : renderMore()}
    <div className={classes.overlay}>
      <span className={classes.overlay_text}>{alt}</span>
      {viewMore && <button className={classNames(classes.viewMore)}
        onClick={() => setDisplay((prev) => prev === 'media' ? 'more' : 'media')}>{display === 'media' ? 'View More' : 'View Media'}</button>}
    </div>
  </div>
}

ImageWithOverlay.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  viewMore: PropTypes.func,
  video: PropTypes.string,
  activity: PropTypes.object,
}

export default ImageWithOverlay
