import classnames from 'classnames'
import { requiredIf } from '../../../../utility/prop-types-helper'
import { useSelector } from 'react-redux'
import { displayWithCurrency } from '../../../../utility/currency'
import { numbersToPercentage } from '../../../../utility/numbersHelper'
import LoadingSpinner from '../../../common/LoadingSpinner'

import classes from './StatsPanel.module.scss'
import { ReactComponent as InfoCircleIcon } from '../../../../assets/images/common/icons/info-circle-icon.svg'
import { ReactComponent as DollarIcon } from '../../../../assets/images/common/icons/dollar-icon.svg'
import { ReactComponent as BlocksIcon } from '../../../../assets/images/common/icons/blocks-icon.svg'
import PropTypes from 'prop-types'

const BusinessSummaryWidget = ({ isLoading, businessSummary, packages }) => {
  const currency = useSelector(state => state.user.current_user.communities[0].currency)

  const withPackages = packages?.length > 0

  if (isLoading) {
    return (
      <div className={classnames('d-flex align-items-center justify-content-center', classes.infoSection)}>
        <LoadingSpinner
          text='Loading Business Summary...'
          classNames={{
            wrapper: 'px-4',
            spinner: 'fa-3x',
            text: classes.loaderText,
          }}
        />
      </div>
    )
  }

  return (
    <div className={classnames('p-3 d-flex flex-column', classes.infoSection)}>
      <div className='mb-3 d-flex justify-content-between'>
        <h3 className={classes.title}>Business Summary</h3>
        <InfoCircleIcon role='button' className={classes.icon} />
      </div>
      <div className='d-flex mb-4'>
        {withPackages && (
          <DollarIcon className='flex-shrink-0 mr-2' />
        )}
        <div className='d-flex flex-column'>
          <span className={classes.subtitle}>Revenue Generated</span>
          {withPackages ? (
            <span className={classes.totalRevenue}>
              {displayWithCurrency(businessSummary?.totalRevenue, currency, true)}
            </span>
          ) : (
            <span className={classnames('mt-1', classes.orangeHighlight)}>No packages added</span>
          )}
        </div>
      </div>
      {withPackages ? (
        <>
          <div className={classnames('mb-2', classes.statsItem)}>
            <span>
              <BlocksIcon className='mr-2' />
              Package Breakdown
            </span>
          </div>
          <div className='mb-3'>
            {packages?.map((p, index) => (
              <div key={index} className={classnames('mb-2 d-flex justify-content-between', classes.statsItem)}>
                <span>{p.name}</span>
                <div className='d-flex align-items-center'>
                  <span className={classes.orangeHighlight}>{p.enrollmentsCount}</span>
                  <span
                    className={classnames('ml-1', classes.statsItem, classes.smallerFontSize)}>({displayWithCurrency(p.price, currency, true)})</span>
                </div>
              </div>
            ))}
          </div>
          <div className={classnames('pt-3', classes.topSeparator)}>
            <div className={classnames('mb-3 d-flex justify-content-between', classes.statsItem)}>
              <span>Average Revenue/Enrollment</span>
              <span>{displayWithCurrency(businessSummary?.averageEnrollmentRevenue, currency)}</span>
            </div>
            <div className={classnames('mb-3 d-flex justify-content-between', classes.statsItem)}>
              <span>Golfers Enrolled</span>
              {businessSummary?.golferLimit !== null ? (
                <span>{businessSummary?.enrollmentsSize}/{businessSummary?.golferLimit}</span>
              ) : (
                <span>{businessSummary?.enrollmentsSize}</span>
              )}
            </div>
            {businessSummary?.golferLimit !== null && (
              <>
                <div className={classnames('mb-3 d-flex justify-content-between', classes.statsItem)}>
                  <span>Capacity</span>
                  <span>{numbersToPercentage(businessSummary?.enrollmentsSize, businessSummary?.golferLimit, true)}</span>
                </div>
                <div className={classnames('mb-3 d-flex justify-content-between', classes.statsItem)}>
                  <span>Potential Revenue</span>
                  <span>{displayWithCurrency(businessSummary?.potentialRevenue, currency, true)}</span>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <div className='mb-3 d-flex flex-column align-items-center'>
            <DollarIcon className={classnames('mb-3', classes.placeholderIcon)} />
            <h5 className={classnames('mb-3', classes.placeholderTitle)}>
              Add Pricing to Unlock
            </h5>
            <p className={classnames('mb-0', classes.placeholderText)}>
              To view revenue metrics, you would need to use the packages/pricing feature.&nbsp;
              This can be added before or after a program for internal tracking.&nbsp;
              <a
                href='https://operation36.supporthero.io/article/show/172657'
                target='_blank'
                className={classes.link}
              >
                Learn More Here
              </a>
            </p>
          </div>
          {businessSummary?.golferLimit !== null && (
            <div className={classnames('pt-3 d-flex justify-content-between', classes.topSeparator, classes.statsItem)}>
              <span>Golfers Enrolled</span>
              <span>{businessSummary?.enrollmentsSize}/{businessSummary?.golferLimit}</span>
            </div>
          )}
        </>
      )}
    </div>
  )
}

BusinessSummaryWidget.propTypes = {
  isLoading: PropTypes.bool,
  businessSummary: requiredIf(PropTypes.object, ({ isLoading }) => !isLoading),
  packages: requiredIf(PropTypes.array, ({ isLoading }) => !isLoading),
}

export default BusinessSummaryWidget
