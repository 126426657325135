import React from 'react'
import PropTypes from 'prop-types'
import '../../../assets/styles/components/common/form/TextAreaInput.scss'
import classNames from 'classnames'

const TextAreaInput = ({ name, rows, cols, className, onChange, placeholder, value, error, maxLength }) => {
  let wrapperClass = 'textarea-input'
  if (error && error.length > 0){
    wrapperClass += ' has-error'
  }

  return (
    <div className={wrapperClass}>
      <div className='field'>
        <textarea
          type='text'
          rows={rows}
          cols={cols}
          name={name}
          className={classNames('form-control', className)}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          maxLength={maxLength ? maxLength : ''}/>
        {error && <div className='alert alert-danger'>{error}</div>}
      </div>
    </div>
  )
}

TextAreaInput.propTypes = {
  name: PropTypes.string.isRequired,
  rows: PropTypes.string.isRequired,
  cols: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  maxLength: PropTypes.number,
}

export default TextAreaInput
