import PropTypes from 'prop-types'

import classes from './CustomCheckInput.module.scss'
import clsNames from 'classnames'

import { ReactComponent as CheckMarkIcon } from '../../../assets/images/common/icons/checkbox-checkmark.svg'


const CustomCheckInput = ({ id, type = 'checkbox', classNames, label, readOnly, ...props }) => (
  <div className={clsNames(classes.wrapper, 'd-flex align-items-center')}>
    <input
      className={
        clsNames({
          [classNames?.checkBoxInput]: type === 'checkbox',
          [classNames?.radioInput]: type === 'radio',
        })
      }
      id={id}
      type={type}
      readOnly={readOnly}
      {...props}
    />
    {type === 'checkbox' && (
      <span
        className={
          clsNames(classes.checkMark, { [classes.checkMarkReadOnly]: readOnly }, classNames?.checkMark)
        }
      >
        <CheckMarkIcon />
      </span>
    )}
    {type === 'radio' && (
      <span className={clsNames(classes.radioDot, classNames?.radioDot)} />
    )}
    {!!label && <label htmlFor={id} className={classes?.label}>{label}</label>}
  </div>
)

CustomCheckInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.any,
  classNames: PropTypes.object,
  readOnly: PropTypes.bool,
}

export default CustomCheckInput
