import PropTypes from 'prop-types'
import classnames from 'classnames'
import { calculateAge, genderLabel } from '../../../../utility/userHelper'
import StudentWithAvatar from '../../../students/StudentWithAvatar'
import TooltipText from '../../TooltipText'
import MCElement from '../../MCElement'

import { ReactComponent as PackageIcon } from '../../../../assets/images/common/icons/package-icon.svg'
import { ReactComponent as XIcon } from '../../../../assets/images/common/icons/X-icon.svg'
import classes from './ItemCard.module.scss'
import studentClasses from './StudentCard.module.scss'
import { useSelector } from 'react-redux'

const EnrollmentSource = ({ student }) => {
  if (!student.addedFrom) {
    return null
  }

  const addedFromLevel1Key = Object.keys(student.addedFrom)[0]
  const addedFromLevel1Value = student.addedFrom[addedFromLevel1Key]
  const addedFromLevel2Key = Object.keys(addedFromLevel1Value)[0]
  const addedFromLevel2Value = addedFromLevel1Value[addedFromLevel2Key]

  if (student.addedFrom.students) {
    return <TooltipText className={classes.title} text='Manually Added' />
  } else if (typeof addedFromLevel1Value === 'string') {
    return <TooltipText className={classes.title} text={addedFromLevel1Value} />
  } else {
    return (
      <div className='d-flex flex-column'>
        <TooltipText className={classes.title} text={addedFromLevel2Value.courseName} />
        <div className='d-flex align-items-center'>
          <PackageIcon className={classnames('mr-2', studentClasses.icon)} />
          <TooltipText className={studentClasses.package} text={addedFromLevel2Value.packageName} />
        </div>
      </div>
    )
  }
}

EnrollmentSource.propTypes = {
  student: PropTypes.shape({
    addedFrom: PropTypes.object,
  }),
}

/* eslint-disable-next-line react/prop-types */
const StudentCard = ({ item: student, isSelectable, isChecked, onCheckChange, onRemove, className }) => {
  const unavailableItemsMap = useSelector(state => state.dualPanelSelector.students?.unavailableItems?.entities)
  const isDisabled = !!unavailableItemsMap && unavailableItemsMap[student.id]

  return (
    <div
      className={classnames(
        'd-flex align-items-center',
        className,
        classes.itemWrapper,
        {
          [classes.selected]: !isSelectable,
          [classes.disabled]: isDisabled,
        })}
    >
      {isSelectable && (
        <MCElement
          type='checkbox'
          isSelected={isChecked}
          clicked={() => onCheckChange(isChecked, student)}
          disabled={isDisabled}
        />
      )}
      <div className={classnames('d-flex justify-content-between flex-grow-1', studentClasses.details, { 'ml-5': isSelectable })}>
        <div className='d-flex flex-grow-1'>
          <StudentWithAvatar student={student} />
          <div className='d-flex flex-column'>
            <TooltipText
              className={classes.title}
              text={`${student.firstName} ${student.lastName}`}
            />
            <span className={classes.subtitle}>Age {calculateAge(student.birthdate)} / {genderLabel[student.gender]}</span>
          </div>
        </div>
        {!isSelectable && (
          <div className='d-flex align-items-center'>
            <EnrollmentSource student={student} />
          </div>
        )}
      </div>
      {!isSelectable && (
        <button
          className={classnames('mx-4 animation-shrink', classes.xButton)}
          onClick={() => onRemove(student)}
        >
          <XIcon />
        </button>
      )}
    </div>
  )
}

StudentCard.propTypes = {
  // ...ItemCardProps,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    gender: PropTypes.number.isRequired,
    birthdate: PropTypes.string.isRequired,
  }).isRequired,
}

export default StudentCard
