import { useSelector, useDispatch } from 'react-redux'
import { useLayoutEffect, useState, useMemo, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { UncontrolledPopover } from 'reactstrap'
import { DndContext } from '@dnd-kit/core'
import moment from 'moment'

import PageHeader from '../../common/PageHeader/PageHeader'
import PageFooter from '../../common/PageFooter/PageFooter'
import CreatePairingsModal from '../Detail/CreatePairingsModal'
import PublishPairingsModal from '../Modal/ConfirmationModal'
import SubmitButton from '../../common/buttons/SubmitButton'
import ExitModal from '../../common/ExitModal/ExitModal'
import ActionSubmittedModal from '../Modal/ActionSubmittedModal'
import Loader from '../../common/Op36Loader-web'
import Toggle from '../../common/form/Toggle'
import { getMomentInCommunityTimezone, DATETIME_FORMAT } from '../../../utility/datesHelper'

import { ReactComponent as WarningIcon } from '../../../assets/images/common/icons/warning-icon.svg'
import { ReactComponent as ClapIcon } from '../../../assets/images/common/icons/clap-icon.svg'
import { ReactComponent as EditIcon } from '../../../assets/images/common/icons/simple-edit-icon.svg'
import { ReactComponent as TableSelectDropDownIcon } from '../../../assets/images/common/icons/select-drop-down-icon.svg'

import * as commonActions from '../../../actions/commonActions'
import * as eventActions from '../../../actions/eventActions'
import * as pairingsActions from '../../../actions/pairingsActions'
import styles from './ManagePairings.module.scss'
import classNames from 'classnames'
import { setField, pairingsFormData, deletePairingGroup } from '../../../reducers/nineHoleEventReducer'
import mobilePairings from '../../../assets/images/mock/mobile-pairings.webp'
import TeeTimesPairings from './TeeTimesPairings'
import ShotgunPairings from './ShotGunPairings'
import PairingsStudents from './PairingsStudents'

const text = 'Are you sure you want to submit\
\nscores, the following will happen:\
\n\n  1. Your Golfers will be emailed\
\n\n  2. Pairings will show up in the mobile app in the schedule\
\n\n  3. RSVP window will close'

const ManagePairings = () => { //eslint-disable-line max-lines-per-function
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const event = useSelector(state => state.nineHoleEvent)
  const { pairings, pairings: { pairingGroups } } = event

  const formData = useSelector(pairingsFormData)
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)

  const [showPairingsModal, setShowPairingsModal] = useState(false)
  const [showPublishPairingsModal, setShowPublishPairingsModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showDraftConfirmationModal, setShowDraftConfirmationModal] = useState(false)
  const [draggingId, setDraggingId] = useState(-1)
  const [draggingFromGroupId, setDraggingFromGroupId] = useState(-1)
  const [isOver, setIsOver] = useState({ groupIndex: -1, index: -1 })
  const [draggedIds, setDraggedIds] = useState([])
  const [showExitModal, setShowExitModal] = useState(false)
  const [eventLoader, setEventLoader] = useState(false)

  const filteredPairingGroups = useMemo(() => (
    pairingGroups.filter(s => !s._destroy)
  ), [pairingGroups])

  const fetchEvent = useCallback(() => {
    setEventLoader(true)
    dispatch(eventActions.getEvent(params.eventId)).unwrap().then(() => {
      setEventLoader(false)
    })
  }, [dispatch, params.eventId])

  useEffect(() => {
    if (event.id === -1) {
      fetchEvent()
    } else if (pairings.id === -1) {
      setShowPairingsModal(true)
    }
  }, [fetchEvent, event.id, pairings.id])

  useEffect(() => {
    const ids = []
    filteredPairingGroups.forEach(pairingGroup => {
      pairingGroup.users.forEach(user => user?.id && ids.push(user.id))
    })
    setDraggedIds(ids)
  }, [filteredPairingGroups])

  const navigateToEvent = () => {
    navigate(`/events/${event.id}?activeTab=pairings`)
  }

  const saveChangesButton = {
    text: 'Save Changes',
    onClick: () => setShowPublishPairingsModal(true),
    disabled: pairings.id === -1 && !pairingGroups.length,
  }

  const onDragStart = (e) => {
    e.active.data.current.draggedFrom === 'communityStudents' ? setDraggingId(e.active.id) : setDraggingFromGroupId(e.active.id)
  }

  const onDragEnd = (e) => {
    if (e.active.data.current.draggedFrom === 'communityStudents') {
      setDraggingId(-1)
      setIsOver({ groupIndex: -1, index: -1 })
      const { index, pairingGroup } = e.over.data.current

      const pairIndex = pairingGroups.findIndex(group => group.index === pairingGroup.index)

      const updatedPairs = [...pairingGroups]
      const updatedUsers = [...updatedPairs[pairIndex].users]
      updatedUsers[index] = e.active.data.current
      updatedPairs[pairIndex] = { ...updatedPairs[pairIndex], users: updatedUsers }

      dispatch(setField({ field: 'pairings', value: { ...pairings, pairingGroups: updatedPairs } }))
      setDraggedIds([...draggedIds.filter(id => id !== updatedPairs[pairIndex].users[index].id), e.active.id])
    } else {
      setDraggingFromGroupId(-1)
      setIsOver({ groupIndex: -1, index: -1 })
      const { index, pairingGroup } = e.over.data.current
      const pairIndex = pairingGroups.findIndex(group => group.index === pairingGroup.index)

      const oldIndex = e.active.data.current.draggedFrom.index
      const oldPairIndex = pairingGroups.findIndex(group => group.index === e.active.data.current.draggedFrom.pairIndex)

      const updatedPairs = [...pairingGroups]
      const updatedUsers = [...updatedPairs[pairIndex].users]

      if (pairIndex === oldPairIndex) {
        updatedUsers[oldIndex] = updatedUsers[index]
        updatedUsers[index] = e.active.data.current

        updatedPairs[pairIndex] = { ...updatedPairs[pairIndex], users: updatedUsers }
      } else {
        const oldUpdatedUsers = [...updatedPairs[oldPairIndex].users]

        oldUpdatedUsers[oldIndex] = updatedUsers[index] ? { ...updatedUsers[index] } : null
        updatedUsers[index] = e.active.data.current

        updatedPairs[pairIndex] = { ...updatedPairs[pairIndex], users: updatedUsers }
        updatedPairs[oldPairIndex] = { ...updatedPairs[oldPairIndex], users: oldUpdatedUsers }
      }

      dispatch(setField({ field: 'pairings', value: { ...pairings, pairingGroups: updatedPairs } }))
    }
  }

  const onDragOver = (e) => {
    if (!e.over) { return }

    const { index, pairingGroup } = e.over.data.current

    setIsOver({ groupIndex: pairingGroup.index, index: index })
  }

  const generatePairingGroups = (n) => {
    const pairsToAdd = []

    if (event.format === 'Tee Times') {
      for (let i = 1; i <= n; i++) {
        const startTime = pairingGroups[pairingGroups.length - 1]
          ? moment(pairingGroups[pairingGroups.length - 1].startTime).add(pairings.interval * i, 'minutes').toDate()
          : pairings.startTime
        pairsToAdd.push({
          index: pairings.groupsIndex + i - 1,
          startTime: startTime,
          users: [],
        })
      }
    } else if (event.format === 'Shotgun') {
      for (let i = 1; i <= n; i++) {
        pairsToAdd.push({
          index: pairings.groupsIndex + i - 1,
          startTime: moment(event.eventDate).toDate(),
          startHole: { label: '', value: null },
          playersGroup: { label: '', value: null },
          users: [],
        })
      }
    }

    return pairsToAdd
  }

  const handleAddPairing = () => {
    const pairsToAdd = generatePairingGroups(1)

    dispatch(setField({
      field: 'pairings', value: { ...pairings, pairingGroups: [...pairingGroups, ...pairsToAdd], groupsIndex: pairings.groupsIndex + 1 },
    }))
  }

  const removeStudent = (pairingGroup, index) => {
    const pairIndex = pairingGroups.findIndex(group => group.index === pairingGroup.index)
    const updatedPairs = [...pairingGroups]
    const updatedUsers = [...updatedPairs[pairIndex].users]
    const userId = updatedUsers[index].id
    updatedUsers[index] = null
    updatedPairs[pairIndex] = { ...updatedPairs[pairIndex], users: updatedUsers }

    dispatch(setField({ field: 'pairings', value: { ...pairings, pairingGroups: updatedPairs } }))
    setDraggedIds(draggedIds.filter(id => id !== userId))
  }

  const removePairingGroup = (pairingGroup) => {
    const ids = pairingGroup.users.map(golfer => golfer?.id)
    setDraggedIds(draggedIds.filter(id => !ids.includes(id)))
    dispatch(deletePairingGroup(pairingGroup))
  }

  const publishPairings = ({ status = 'created' }) => {
    setEventLoader(true)
    dispatch(pairingsActions.createPairings({ pairings: { ...formData, status, notify: status === 'created' } }))
      .finally(() => { setEventLoader(false) })
    setShowPublishPairingsModal(false)
    status === 'created' ? setShowConfirmationModal(true) : setShowDraftConfirmationModal(true)
  }

  const updatePairings = ({ status = 'created' }) => {
    setEventLoader(true)
    dispatch(pairingsActions.updatePairings({ pairings: { ...formData, status, notify: status === 'created' ? pairings.notifyStudents : false } }))
      .finally(() => { setEventLoader(false) })
    setShowPublishPairingsModal(false)
    status === 'created' ? setShowConfirmationModal(true) : setShowDraftConfirmationModal(true)
  }

  const draftPairings = () => {
    pairings.status === '' ? publishPairings({ status: 'draft' }) : updatePairings({ status: 'draft' })
  }

  useLayoutEffect(() => {
    dispatch(commonActions.showSideBar(false))
    dispatch(commonActions.showNavBar(false))
  }, [dispatch])

  const groupsSortFunction = (a, b) => {
    if (a.startHole.value > b.startHole.value || !a.startHole.value) {
      return 1
    } else if (a.startHole.value < b.startHole.value) {
      return -1
    }

    if (a.playersGroup.value < b.playersGroup.value || !a.playersGroup.value) {
      return -1
    } else if (a.playersGroup.value > b.playersGroup.value) {
      return 1
    } else {
      return 0
    }
  }

  const handlePairTimeChange = (startTime, pairingGroup) => {
    const time = getMomentInCommunityTimezone(moment(startTime), timezone, DATETIME_FORMAT)?.toDate()
    const index = pairingGroups.findIndex(group => group.index === pairingGroup.index)
    const updatedPairs = [...pairingGroups]
    updatedPairs[index] = { ...updatedPairs[index], startTime: time }

    updatedPairs.sort((a, b) => a.startTime > b.startTime ? 1 : -1)

    dispatch(setField({ field: 'pairings', value: { ...pairings, pairingGroups: updatedPairs } }))
  }

  const handlePairHoleChange = (field, value, pairingGroup) => {
    const index = pairingGroups.findIndex(group => group.index === pairingGroup.index)
    const updatedPairs = [...pairingGroups]
    updatedPairs[index] = { ...updatedPairs[index], [field]: value }

    updatedPairs.sort(groupsSortFunction)

    dispatch(setField({ field: 'pairings', value: { ...pairings, pairingGroups: updatedPairs } }))
  }

  const autogenerate = (type) => {
    const students = [...event.students].filter((s) => s.status !== 'declined')
    const nrOfSolts = (Math.ceil(students.length / pairings.golfersCount.value) || 1) - filteredPairingGroups.length
    const pairingGroupsToFill = [...pairingGroups, ...generatePairingGroups(nrOfSolts)]

    const sortedStudents = type === 'age'
      ? students.sort((a, b) => a.age > b.age ? 1 : -1)
      : students.sort((a, b) => a.level > b.level ? 1 : -1)

    const groupsFilled = pairingGroupsToFill.map(group => ({
      ...group,
      users: group._destroy ? [] : sortedStudents.splice(0, pairings.golfersCount.value),
    }))

    dispatch(setField({ field: 'pairings', value: { ...pairings, pairingGroups: groupsFilled, groupsIndex: pairings.groupsIndex + nrOfSolts } }))
  }

  const HeaderActionButton = () => (
    <div className='d-flex justify-content-center align-items-center pull-right'>
      <button
        className='btn btn-primary text-white pull-right mr-2'
        onClick={() => setShowPairingsModal(true)}
      >
        <i className='fa fa-pencil-square-o' aria-hidden='true'></i>
      </button>
      <button id='autogenerateButton' className='btn btn-primary pl-3 pr-3 pull-right'>
        <div className='d-flex align-items-center'>
          <span className='mr-2'>Autogenerate</span>
          <TableSelectDropDownIcon stroke='#fff' style={{ marginLeft: '2px' }}/>
        </div>
      </button>
      <UncontrolledPopover
        placement='bottom'
        target='autogenerateButton'
        trigger='legacy'
      >
        <div className={classNames('d-flex flex-column', styles.headerButtonActions)}>
          <p aria-hidden='true' onClick={() => autogenerate('age')}>Based on Age</p>
          <p aria-hidden='true' onClick={() => autogenerate('level')}>Based on Level</p>
        </div>
      </UncontrolledPopover>
    </div>
  )

  const PublishPairingsModalButton = (
    <SubmitButton
      onClick={pairings.status ? updatePairings : publishPairings}
      buttonMessage='Publish pairings'
      buttonColor='orange-button'
    />
  )

  const DraftPairingsModalButton = (
    <SubmitButton
      onClick={draftPairings}
      buttonMessage='Save as Draft'
      buttonColor='navy-button'
    />
  )

  const CloseConfirmationModalButton = (
    <SubmitButton
      onClick={navigateToEvent}
      buttonMessage='Close'
      buttonColor='navy-button'
    />
  )

  const GoBackToEditButton = (
    <SubmitButton
      onClick={() => setShowDraftConfirmationModal(false)}
      buttonMessage='Go back to editing'
      buttonColor='orange-button'
    />
  )

  const extraContent = (
    pairings.status === 'created' ?
      <div className='d-flex align-items-center py-3'>
        <Toggle
          name='notifyStudents'
          checked={pairings.notifyStudents}
          onChange={() => dispatch(setField({ field: 'pairings', value: { ...pairings, notifyStudents: !pairings.notifyStudents } }))}
        />
        <label htmlFor='enableProgramCapacity' className='ml-3 mb-0'>  Notify Students of Change</label>
      </div> :
      'You can save as draft and publish later.'
  )

  if (eventLoader) {
    return <Loader message='Loading event'/>
  }

  return (
    <div id='ManagePairings' className={classNames('d-flex', styles.managePairings)}>
      <div className='fixed-top'>
        <PageHeader
          title='Manage Pairings'
          backButton
          actionComponent={HeaderActionButton()}
          onClickBack={() => setShowExitModal(true)}
        />
      </div>
      <DndContext onDragStart={onDragStart} onDragEnd={onDragEnd} onDragOver={onDragOver}>
        <div className={classNames('col-3 px-0', styles.eventStudents)}>
          <PairingsStudents event={event} draggingId={draggingId} draggedIds={draggedIds} />
        </div>
        <div className={classNames('col-9 pr-0', styles.eventPairs)}>
          <p className={styles.headerMessage}>Drag and Drop Students into Pairs</p>
          <div className={styles.pairsList}>
            {pairings.format === 'Tee Times' ?
              <TeeTimesPairings
                pairings={pairings}
                filteredPairingGroups={filteredPairingGroups}
                handlePairTimeChange={handlePairTimeChange}
                removePairingGroup={removePairingGroup}
                removeStudent={removeStudent}
                isOver={isOver}
                draggingFromGroupId={draggingFromGroupId}
                event={event}
              />
              :
              <ShotgunPairings
                pairings={pairings}
                filteredPairingGroups={filteredPairingGroups}
                handlePairHoleChange={handlePairHoleChange}
                removePairingGroup={removePairingGroup}
                removeStudent={removeStudent}
                isOver={isOver}
                draggingFromGroupId={draggingFromGroupId}
                event={event}
              />
            }
            <div className='d-flex'>
              <div className='mb-3'>
                <SubmitButton buttonMessage='Add Pairing' iconType='addButton' onClick={() => handleAddPairing(1)}/>
              </div>
            </div>
          </div>
        </div>
      </DndContext>
      <PageFooter
        actionButtons={[saveChangesButton]}
      />

      <CreatePairingsModal
        showModal={showPairingsModal}
        closeModal={() => { setShowPairingsModal(false) }}
      />

      <PublishPairingsModal
        showModal={showPublishPairingsModal}
        closeModal={() => setShowPublishPairingsModal(false)}
        submitButton={PublishPairingsModalButton}
        secondButton={pairings.status !== 'created' && DraftPairingsModalButton}
        title='Publish Pairings'
        text={text}
        extraContent={extraContent}
        image={mobilePairings}
      />

      <ActionSubmittedModal
        showModal={showConfirmationModal}
        closeModal={() => setShowConfirmationModal(false)}
        firstButton={CloseConfirmationModalButton}
        title='Your Pairings have been published successfully.'
        message={pairings.notifyStudents ? 'Your students will be notified in the mobile app!' : ''}
        icon={<ClapIcon stroke='white'/>}
      />

      <ActionSubmittedModal
        showModal={showDraftConfirmationModal}
        closeModal={() => setShowDraftConfirmationModal(false)}
        firstButton={GoBackToEditButton}
        secondButton={CloseConfirmationModalButton}
        title='Your Pairings have been saved as draft.'
        icon={<EditIcon/>}
      />

      <ExitModal
        showModal={showExitModal}
        closeModal={() => setShowExitModal(false)}
        onSubmit={navigateToEvent}
        icon={<WarningIcon className={styles.warningIcon}/>}
        title='Attention!'
        message='Are you sure you want to stop creating your pairings? If you exit now, all your progress will be deleted.'
        submitButtonMessage='Back to Pairings'
      />
    </div>
  )
}

export default ManagePairings
