
import classes from './PostAnnouncementModal.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import TextAreaInput from '../../common/form/TextAreaInput'
import { useState } from 'react'
import Toggle from '../../common/form/Toggle'
import { ReactComponent as PhotoIcon } from '../../../assets/images/common/icons/photo-icon.svg'
import { ReactComponent as VideoIcon } from '../../../assets/images/common/icons/video-icon.svg'
import { ReactComponent as PlayVideoIcon } from '../../../assets/images/common/icons/play-video-icon.svg'
import { ReactComponent as Vector } from '../../../assets/images/common/icons/vector.svg'
import { ReactComponent as PlusSign } from '../../../assets/images/common/icons/plus-sign.svg'
import { useRef } from 'react'
import classNames from 'classnames'
import { useCallback } from 'react'
import { toast } from 'react-toastify'
import { selectSelectedPrograms, selectSelectedSeries } from '../../../reducers/programDashboardReducer'
import { requestPostActivityAnnouncement } from '../../../actions/activityActions'
import { addProgramAnnouncement, addCommunityAnnouncement } from '../../../reducers/dashboardReducer'
import PropTypes from 'prop-types'
import axios from 'axios'
import { requestPresignedUrl } from '../../../actions/announcementsActions'
import Loader from '../../common/Op36Loader-web'
import CoachHeaderInfo from '../../activity/CoachHeaderInfo'
import LoadingBar from 'react-top-loading-bar'

const PostAnnouncementForm = ({ onClose, isProgram, nextStep, programsText }) => {
  const [message, setMessage] = useState('')
  const [sendAsEmail, setSendAsEmail] = useState(true)
  const [selectedAttachment, setSelectedAttachment] = useState(null)
  const [attachmentPreview, setAttachmentPreview] = useState(null)
  const [videoUpload, setVideoUpload] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const dispatch = useDispatch()
  const selectedPrograms = useSelector(selectSelectedPrograms)
  const selectedSeries = useSelector(selectSelectedSeries)
  const user = useSelector(state => state.user.current_user)
  const [loading, setLoading] = useState(false)

  const handleRemoveImage = () => {
    setSelectedAttachment(null)
  }

  const hiddenFileInput = useRef(null)

  const handleUploadClick = () => {
    hiddenFileInput.current.click()
  }

  const onUploadImage = useCallback((event) => {
    const file = event.target.files[0]

    if (file) {
      setSelectedAttachment(file)
      const uploadPhotoPreview = URL.createObjectURL(file)
      setAttachmentPreview(uploadPhotoPreview)
    }
  }, [])


  const submitEnabled = message.length > 0 && (selectedPrograms.length > 0 || selectedSeries.length > 0)

  const onSubmit = async () => {
    const selectedProgramIds = selectedPrograms.map(program => program?.id)
    const selectedSeriesIds = selectedSeries.map(series => series.id)
    const formData = new FormData()

    if (selectedAttachment) {
      if (selectedAttachment.type.includes('video')) {
        const response = await requestPresignedUrl(selectedAttachment)
        const { data: { file_url, key } } = response
        setVideoUpload(true)
        setUploadProgress(0)
        await axios.put(file_url, selectedAttachment, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            setUploadProgress(percentCompleted)
          },
        })
        setVideoUpload(false)
        formData.append('attachment_key', key)
      } else {
        formData.append('attachment', selectedAttachment)
      }
    }
    formData.append('activity[caption]', message)
    formData.append('send_email', sendAsEmail)
    selectedProgramIds.forEach((programId) => formData.append('course_ids[]', programId))
    selectedSeriesIds.forEach((seriesId) => formData.append('series_ids[]', seriesId))

    setLoading(true)
    requestPostActivityAnnouncement(formData).then((data) => {
      if (data.status === 201) {
        onClose()
        if (isProgram) {
          dispatch(addProgramAnnouncement(data.data.activity))
        } else {
          dispatch(addCommunityAnnouncement(data.data.activity))
        }
        toast.success('Announcement posted successfully', { position: toast.POSITION.TOP_RIGHT })
      } else {
        toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT })
      }
      setLoading(false)
      setMessage('')
      setSelectedAttachment(null)
      setAttachmentPreview(null)
      setSendAsEmail(false)
    })
  }

  return <>
    <CoachHeaderInfo coach={user} />
    {loading || videoUpload ? <div className={classes.loadingContainer}>
      <LoadingBar color={'#f63'} progress={uploadProgress} onLoaderFinished={() => setUploadProgress(0)}/>
      <Loader
        message={videoUpload ? 'UPLOADING VIDEO' : 'POSTING ANNOUNCEMENT'}
      /></div> :
      <div className={classes.bodyContainer}>
        <TextAreaInput className={classes.textArea} placeholder={'Type your message here'} value={message}
          onChange={(e) => setMessage(e.target.value)} rows={null} name='announcement-message'/>
        <button className={classes.addProgramsButton} onClick={() => nextStep(1)}>
          <PlusSign/>
          <span>{programsText}</span>
        </button>
        <div className={classes.toggleSwitch}>
          <Toggle onChange={() => setSendAsEmail(!sendAsEmail)} defaultChecked={sendAsEmail}/>
          <span className={classes.toggleText}>Send as email</span>
        </div>
        <div className={classes.footerContainer}>
          <div className={classes.addMediaWrapper}>
            {selectedAttachment && <div className={classes.selectedImageContainer}>
              { selectedAttachment.type.includes('video') ?
                <div className={classes.selectedImage}><PlayVideoIcon className={classes.playVideo} /></div> :
                <img src={attachmentPreview} alt='Selected' className={classes.selectedImage} />
              }
              <button className={classes.removeImageButton} onClick={handleRemoveImage}>x</button>
            </div>}
            <button className={classNames(classes.addMediaContainer, {
              [classes.addMediaContainerSecondary]: !!selectedAttachment,
            })} onClick={handleUploadClick}>
              <div className={classes.imagesContainer}>
                <PhotoIcon/>
                <VideoIcon/>
              </div>
              <span className={classes.addMediaText}>{`${selectedAttachment ? 'Media Uploaded' : 'Add media (optional)'}`}</span>
              <input className={classes.addMediaText} type='file' style={{ display: 'none' }} ref={hiddenFileInput} onChange={onUploadImage}/>
            </button>
          </div>
          <div>
            <button className={classes.submitButton} disabled={!submitEnabled} onClick={onSubmit}>
              <span>{'Post'}</span>
              <div className={classNames(classes.vectorContainer, { [classes.vectorContainerDisabled]: !submitEnabled })}>
                <Vector/>
              </div>
            </button>
          </div>
        </div>
      </div>}</>
}

PostAnnouncementForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  programsText: PropTypes.string.isRequired,
  isProgram: PropTypes.bool.isRequired,
}

export default PostAnnouncementForm
