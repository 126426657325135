import moment from 'moment'

export const genderLabel = {
  0: 'Other',
  1: 'Male',
  2: 'Female',
  3: 'Other',
}

export const calculateAge = (birthdate) => {
  const currentDate = moment()
  return moment.duration(currentDate.diff(moment(birthdate))).years()
}

export const isUserFromSameCommunity = (user, other) => {
  // Note: Admins and newly registered users do NOT have communities
  if (user.type === 'Admin' || other.type === 'Admin' || other.communities.length === 0) {
    return false
  }

  return user.communities[0].id === other.communities[0].id
}

export const isUserFromSameFamily = (user, other) =>
  user.family.id === other.family.id

export const isUserCoachInCommunity = (user, other) =>
  isUserFromSameCommunity(user, other) && user.type === 'Coach'

export const isAdminInFamily = (user, other) =>
  isUserFromSameFamily(user, other) && user.family_admin

export const hasUserWriteAccess = (user, other) => {
  const isCoachInCommunity = isUserCoachInCommunity(user, other)
  const isCurrentUser = user.id === other.id
  // TODO: Not sure why we have type === 'Student' here
  const isAdminInFamily_ = isAdminInFamily(user, other) && user.type === 'Student'

  return isCoachInCommunity || isCurrentUser || isAdminInFamily_
}
