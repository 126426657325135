import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { reset } from '../../reducers/signUpReducer'
import StudentDashboardPage from './studentDashboard/StudentDashboardPage'
import OldDashboardPage from './OldDashboardPage'

const DashboardPage = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user.current_user)

  useEffect(() => {
    dispatch(reset())
  }, [dispatch])

  return (
    user.type === 'Student' ? (
      <StudentDashboardPage />
    ) : (
      <OldDashboardPage />
    )
  )
}

export default DashboardPage
