import PropTypes from 'prop-types'
import StudentCard from '../Pairings/StudentCard'
import { useDraggable } from '@dnd-kit/core'

const DraggableStudent = ({ student, draggingId, draggedFrom, dragged, removeStudent }) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: student.id,
    data: { ...student, draggedFrom },
  })
  return (
    <div ref={setNodeRef} className='d-flex align-items-center mx-auto' >
      <StudentCard
        dragging={student.id === draggingId}
        dragged={dragged}
        draggingId={draggingId}
        key={student.id}
        student={student}
        removeStudent={removeStudent}
        attributes={attributes}
        listeners={listeners}
      />
    </div>
  )
}

DraggableStudent.propTypes = {
  student: PropTypes.shape({
    id: PropTypes.number,
    note: PropTypes.string,
  }),
  draggingId: PropTypes.number,
  draggedFrom: PropTypes.string,
  dragged: PropTypes.bool,
  removeStudent: PropTypes.func,
}

export default DraggableStudent
