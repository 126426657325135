
import classes from './PostAnnouncementButton.module.scss'
import PropTypes from 'prop-types'
import { ReactComponent as PhotoIcon } from '../../../assets/images/common/icons/photo-icon.svg'
import { ReactComponent as VideoIcon } from '../../../assets/images/common/icons/video-icon.svg'
import { ReactComponent as AnnouncementIcon } from '../../../assets/images/common/icons/announcement-icon.svg'


const PostAnnouncementButton
 = ({ openModal }) => (
   <div className={classes.container} onClick={openModal} role='button' tabIndex={0} onKeyDown>
     <div className={classes.inputContainer}>
       <AnnouncementIcon />
       <span className={classes.textField}>
      Post an announcement...
       </span>
     </div>
     <PhotoIcon />
     <VideoIcon />
   </div>
 )

PostAnnouncementButton
  .propTypes = {
    openModal: PropTypes.func.isRequired,
  }

export default PostAnnouncementButton

