import { useCallback, useEffect, useMemo, useState } from 'react'
import { NavLink, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Nav, Navbar, NavItem } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import classnames from 'classnames'

import { setCurrentTab } from '../../../actions/commonActions'
import { deleteProgram, getDashboardProgram } from '../../../actions/programActions'
import { resetDashboard, selectAllEnrollments } from '../../../reducers/programDashboardReducer'
import { checkLicensePermission } from '../../../utility/communityLicense'
import Loader from '../../common/Op36Loader-web'
import LiveModal from '../ProgramBuilder/Modals/LiveModal'
import ConfirmationModal from '../../common/ConfirmationModal'
import Header from './Header'
import AddStudentsModal from './add-students-modal/AddStudentsModal'
import AddStudentsConfirmationModal from './add-students-modal/AddStudentsConfirmationModal'

import classes from './ProgramDashboardManager.module.scss'

const ProgramDashboardManager = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const dispatch = useDispatch()

  const licenseType = useSelector(state => state.user.current_user.communities[0].license_type)
  const enrollments = useSelector(selectAllEnrollments)
  const { program, isLoading } = useSelector(state => state.programDashboard)

  const [isAddStudentsModalOpen, setAddStudentsModalOpen] = useState(false)
  const [isLiveModalOpen, setLiveModalOpen] = useState(false)
  const [isLoadingDeleteProgram, setIsLoadingDeleteProgram] = useState(false)
  const [isDeleteStudentConfirmationModalOpen, setIsDeleteStudentConfirmationModalOpen] = useState(false)
  const [isAddStudentsConfirmationModalOpen, setIsAddStudentsConfirmationModalOpen] = useState(false)

  const programIdParams = params.id
  const withLiveModal = location.state?.showLiveModal

  const hasUnapprovedEnrollments = useMemo(() => (
    enrollments.filter(enrollment => enrollment.status === 'applied').length > 0
  ), [enrollments])

  const handleOpenAddStudentsModal = useCallback(() => setAddStudentsModalOpen(true), [])

  const handleDeleteProgram = async () => {
    try {
      setIsLoadingDeleteProgram(true)
      await dispatch(deleteProgram(program.id)).unwrap()
      navigate('/programs')
    } catch (err) {
      toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT })
      setIsDeleteStudentConfirmationModalOpen(false)
      setIsLoadingDeleteProgram(false)
    }
  }

  const handleCloseConfirmDeleteModal = () => setIsDeleteStudentConfirmationModalOpen(false)

  const withAddStudentsAction = checkLicensePermission(licenseType, ['standard_legacy'])
    && (program?.status === 'active' || program?.status === 'future')

  const tabsData = useMemo(() => ([
    { name: 'Overview', route: 'overview' },
    ...program?.withSchedule ? [{ name: 'Schedule', route: 'schedule' }] : [],
    ...program?.lessonPlan ? [{ name: 'Lesson Plan', route: 'lesson-plan' }] : [],
    ...program?.series.length > 0 ? [{ name: '9 Hole Events', route: 'events' }] : [],
    { name: 'Reports', route: 'reports' },
    {
      name: 'Manage Enrollments',
      route: 'enrollments',
      withDot: program?.hasManualEnrollmentsApproval && hasUnapprovedEnrollments,
    },
  ]), [program, hasUnapprovedEnrollments])

  useEffect(() => {
    dispatch(setCurrentTab('Programs'))
    dispatch(getDashboardProgram(programIdParams)).unwrap()
      .catch(() => toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT }))

    if (withLiveModal) {
      setLiveModalOpen(true)
    }

    return () => dispatch(resetDashboard())
  }, [dispatch, programIdParams, withLiveModal])

  useEffect(() => {
    const navigateToCorrectTab = () => {
      if (program.hasManualEnrollmentsApproval && hasUnapprovedEnrollments) {
        navigate(`/programs/${program.id}/enrollments`, { replace: true })
      } else {
        navigate(`/programs/${program.id}/overview`, { replace: true })
      }
    }

    if (program) {
      if (location.pathname === `/programs/${program?.id}`) {
        navigateToCorrectTab()
      } else {
        const parts = location.pathname.split('/')
        const tabRoute = parts[parts.length - 1]
        if (!tabsData.some(item => item.route === tabRoute)) {
          navigateToCorrectTab()
        }
      }
    }
  }, [hasUnapprovedEnrollments, location.pathname, navigate, program, tabsData])

  if (!program || isLoading) {
    return (
      <Loader message='Loading Program...' />
    )
  }

  return (
    <>
      <div className={classnames('d-flex flex-column', classes.dashboardManagerPage)}>
        <Header
          isAddStudentsModalOpen={isAddStudentsModalOpen}
          isLoadingDeleteProgram={isLoadingDeleteProgram}
          handleOpenAddStudentsModal={handleOpenAddStudentsModal}
          handleDeleteProgramClick={() => setIsDeleteStudentConfirmationModalOpen(true)}
          withAddStudentsAction={withAddStudentsAction}
        />
        <Navbar className={classes.navbar}>
          <Nav className={classes.nav} tabs>
            {tabsData.map((item, index) => (
              <NavItem key={index} className={classes.navItem}>
                <NavLink
                  to={item.route}
                  className={({ isActive }) => classnames(classes.navLink, { [classes.active]: isActive })}
                >
                  {item.name}
                  {item.withDot && <div className={classes.dot}/>}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Navbar>
        <main className={classes.main}>
          <Outlet context={{ onOpenAddStudentsModal: handleOpenAddStudentsModal, withAddStudentsAction: withAddStudentsAction }} />
        </main>
      </div>
      {withAddStudentsAction && (
        <>
          {isAddStudentsModalOpen && (
            <AddStudentsModal
              isOpen={isAddStudentsModalOpen}
              onClose={() => setAddStudentsModalOpen(false)}
              onProceed={() => setIsAddStudentsConfirmationModalOpen(true)}
            />
          )}
          <AddStudentsConfirmationModal
            isOpen={isAddStudentsConfirmationModalOpen}
            onClose={() => setIsAddStudentsConfirmationModalOpen(false)}
          />
        </>
      )}
      {withLiveModal && isLiveModalOpen && (
        <LiveModal
          program={program}
          showModal={isLiveModalOpen}
          onClose={() => setLiveModalOpen(false)}
        />
      )}
      <ConfirmationModal
        showConfirmationModal={isDeleteStudentConfirmationModalOpen}
        closeConfirmationModal={handleCloseConfirmDeleteModal}
        confirmationMessage='Are you sure you want to delete this program? It will permanently delete the enrollments.'
        confirmationDenied={handleCloseConfirmDeleteModal}
        confirmationProceed={handleDeleteProgram}
      />
    </>
  )
}

export default ProgramDashboardManager
