import { useSelector } from 'react-redux'

import ManageRoster from '../../Form/ManageRoster'
import {
  addStudent,
  deleteStudent,
  addCourse,
  deleteCourse,
  updateStudent,
  updateCourse,
  selectAllStudents,
} from '../../../../reducers/seriesReducer'

const ManageSeriesRoster = () => {
  const { courses, students } = useSelector(state => state.series)
  const allStudents = useSelector(selectAllStudents)

  return (
    <ManageRoster
      title='Populate Master Roster'
      selectedStudents={allStudents}
      getSelectedStudent={id => students.entities[id]}
      getSelectedProgram={id => courses.entities[id]}
      addStudentCallback={addStudent}
      deleteStudentCallback={deleteStudent}
      addProgramCallback={addCourse}
      deleteProgramCallback={deleteCourse}
      updateStudentCallback={updateStudent}
      updateProgramCallback={updateCourse}
    />
  )
}

export default ManageSeriesRoster
