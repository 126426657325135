import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './StudentRow.module.scss'
import { ReactComponent as StarIcon } from '../../../assets/images/common/communityLeaderboard/favorite-icon.svg'
import StudentWithAvatar from '../../students/StudentWithAvatar'

const rankClass = {
  1: styles.firstPlace,
  2: styles.secondPlace,
  3: styles.thirdPlace,
}
const rankOrder = ['st', 'nd', 'rd']

const StudentRow = ({ rank, student }) => (
  <div className={classnames(styles.communityLeaderboardStudentRow, { [rankClass[rank]]: rank <= 3 }, { [styles.topRank]: rank <= 3 })}>
    <div className={styles.studentInfo}>
      <div className={styles.rank}>{rank}{rankOrder[rank - 1]}</div>
      <StudentWithAvatar student={student} />
      <p className={styles.name}>{student.name}</p>
    </div>
    <div className={styles.medal}/>
    <div className={styles.studentInfo}>
      <StarIcon/>
      <div>{student.points}</div>
    </div>
  </div>
)

StudentRow.propTypes = {
  rank: PropTypes.number,
  student: PropTypes.shape({
    name: PropTypes.string,
    points: PropTypes.arrayOf(PropTypes.number),
  }),
}

export default StudentRow
