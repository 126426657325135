import moment from 'moment-timezone'

export const getMomentInCommunityTimezone = (currentMoment, communityTimezone, format) => {
  const currentTimeDate = currentMoment.format(format)
  return moment.tz(currentTimeDate, communityTimezone)
}

export const getMomentInCommunityTimezoneReversed = (currentMoment, communityTimezone, format) => {
  const currentTimeDate = currentMoment?.tz(communityTimezone).format(format)
  return moment(currentTimeDate)
}

const formatDate = (date, timezone) => moment(date).tz(timezone).format('ll')

export const formatDates = (startDate, endDate, timezone) =>
  `${formatDate(startDate, timezone)} - ${formatDate(endDate, timezone)}`

export const formatSessionTimeRange = (start, end, timezone) =>
  `${moment(start).tz(timezone).format('hh:mm a')} - ${moment(end).tz(timezone).format('hh:mm a')}`

export const formatDayTime = (date, timezone) =>
  moment(date).tz(timezone).format('dddd [at] hA')

/**
 * Calculates the time spent by a community in the network and returns the result in days, months, or years, depending on the largest time interval.
 * @param date - The entry date into the network.
 * @returns {[number,string]} - Array containing the number of time units and the time unit used.
 */
export const getCommunityTimeInNetwork = (date) => {
  const momentDate = moment(date)
  const now = moment()
  const diff = now.diff(momentDate, 'seconds')

  const duration = moment.duration(diff, 'seconds')

  if (duration.years() > 0) {
    return [duration.years(), `Year${duration.years() !== 1 ? 's' : ''}`]
  } else if (duration.months() > 0) {
    return [duration.months(), `Month${duration.months() !== 1 ? 's' : ''}`]
  } else {
    return [duration.days(), `Day${duration.days() !== 1 ? 's' : ''}`]
  }
}

export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const DATE_FORMAT = 'YYYY-MM-DD'
