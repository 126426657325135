import globals from '../assets/styles/globals.scss'
import moment from 'moment'

export const getColorNameFromStatus = program => {
  switch (program.status) {
    case 'completed':
      return 'green'
    case 'active':
      return moment(program.startDate).isAfter(moment(), 'day') ? 'gray' : 'blue'
    default:
      return 'gray'
  }
}

export const colorNameToData = {
  green: {
    header: {
      color: globals.headerDarkGreen,
    },
    banner: {
      color: globals.darkTeal,
      backgroundColor: globals.lighterTeal,
      text: () => 'Program Complete',
    },
  },
  blue: {
    header: {
      color: globals.headerDarkBlue,
    },
    banner: {
      color: globals.darkBlue,
      backgroundColor: globals.lighterBlue,
      text: (program) => `Program Active: ${moment(program.endDate)
        .diff(moment(), 'days')} Days Left`,
    },
  },
  gray: {
    header: {
      color: globals.headerDarkGray,
    },
    banner: {
      color: globals.slateGray,
      backgroundColor: globals.lightGray,
      text: (program) => {
        if (program.status === 'future') {
          return `Future Program - Registration Opens In ${moment(program.registrationStartsAt)
            .diff(moment(), 'days')} Days`
        }

        return `Program Starts In ${moment(program.startDate)
          .diff(moment(), 'days')} Days.`
      },
    },
  },
}
