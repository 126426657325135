import React from 'react'
import Avatar from '../common/avatar/avatar.js'
import classes from './Like.module.scss'
import PropTypes from 'prop-types'

const Like = ({ user }) => (
  <div className={classes.container}>
    <Avatar
      imageUrl={user.avatar ? user.avatar.file_url : null}
      altName={user.first_name + ' ' + user.last_name}
    />
    <h6>
      {user.first_name} {user.last_name}
    </h6>
  </div>
)

Like.propTypes = {
  user: PropTypes.object.isRequired,
}
export default Like
