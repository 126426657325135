import PropTypes from 'prop-types'

import Input from '../../common/form/Input'

import classes from './FormInput.module.scss'

const FormInput = (props) => (
  <Input
    classNames={{ ...classes }}
    {...props}
  />
)

FormInput.propTypes = {
  props: PropTypes.object,
}

export default FormInput
