import { createSlice, createSelector } from '@reduxjs/toolkit'
import { createUserSignIn, createAuthFamilyMember } from '../actions/authActions'

const defaultUserData = {
  type: 'Student',
  familyAdmin: false,
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  birthDate: null,
  age: null,
  phone: '',
  gender: null,
  password: '',
  passwordConfirmation: '',
  avatar: null,
  avatarUrl: '',
  allowPostingContent: true,
  allowPostingComments: true,
  errors: {},
}

const initialState = {
  user: {
    type: 'Student',
    accountSegment: '',
    familyAdmin: true,
    firstName: '',
    lastName: '',
    email: '',
    birthDate: null,
    age: null,
    phone: '',
    gender: null,
    password: '',
    passwordConfirmation: '',
    avatar: null,
    avatarUrl: '',
    errors: {},
  },
  familyMembers: [],
  editingUser: defaultUserData,
}

const signUpReducer = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    reset: () => initialState,

    setUserField: (state, action) => {
      const { field, value } = action.payload

      state.user[field] = value
    },

    setUserError: (state, action) => {
      const { field, value } = action.payload

      state.user.errors[field] = value
    },

    setEditingUserField: (state, action) => {
      const { field, value } = action.payload

      state.editingUser[field] = value
    },

    setEditingUserError: (state, action) => {
      const { field, value } = action.payload

      state.editingUser.errors[field] = value
    },
  },
  extraReducers: {
    // Create New Account
    [createUserSignIn.fulfilled]: (state, action) => {
      state.user.familyId = action.payload.user?.family?.id
    },
    [createUserSignIn.rejected]: (state, action) => {
      state.user.errors = Object.fromEntries(Object.entries(action.payload).map(([key, value]) => [key, value[0]]))
    },

    // Add New Member
    [createAuthFamilyMember.fulfilled]: (state, _) => {
      state.familyMembers = [...state.familyMembers, state.editingUser]
      state.editingUser = defaultUserData
    },
    [createAuthFamilyMember.rejected]: (state, action) => {
      state.editingUser.errors = Object.fromEntries(Object.entries(action.payload).map(([key, value]) => [key, value[0]]))
    },
  },
})

export const {
  reset,
  setUserField,
  setUserError,
  setEditingUserField,
  setEditingUserError,
} = signUpReducer.actions

export const userFormData = createSelector(
  state => state.signUp.user,
  userFields => ({
    type: userFields.type,
    accountSegment: userFields.accountSegment,
    familyAdmin: userFields.familyAdmin,
    firstName: userFields.firstName,
    lastName: userFields.lastName,
    email: userFields.email,
    birthdate: userFields.birthDate?.toDate(),
    phone: userFields.phone,
    gender: userFields.gender,
    password: userFields.password,
    passwordConfirmation: userFields.passwordConfirmation,
    communityId: localStorage.getItem('communityId'),
    invitationId: localStorage.getItem('invitationId'),
    origin: localStorage.getItem('origin') || 'free_web',
    utmSource: localStorage.getItem('utmSource'),
    utmCampaign: localStorage.getItem('utmCampaign'),
    utmMedium: localStorage.getItem('utmMedium'),
    utmTerm: localStorage.getItem('utmTerm'),
    utmContent: localStorage.getItem('utmContent'),
    utmId: localStorage.getItem('utmId'),
    avatarAttributes: userFields.avatar ? {
      photo: userFields.avatar,
      name: userFields.avatar.name,
    } : {},
  })
)

export const editingUserFormData = createSelector(
  state => state.signUp.editingUser,
  state => state.signUp.user,
  (userFields, primaryUser) => ({
    type: userFields.type,
    familyId: primaryUser.familyId,
    familyAdmin: userFields.familyAdmin,
    firstName: userFields.firstName,
    lastName: primaryUser.lastName,
    email: userFields.familyAdmin ? userFields.email : null,
    username: userFields.familyAdmin ? null : userFields.username,
    birthdate: userFields.birthDate?.toDate(),
    phone: userFields.phone,
    gender: userFields.gender,
    password: userFields.password,
    passwordConfirmation: userFields.passwordConfirmation,
    allowPostingContent: userFields.allowPostingContent,
    allowPostingComments: userFields.allowPostingComments,
    avatarAttributes: userFields.avatar ? {
      photo: userFields.avatar,
      name: userFields.avatar.name,
    } : {},
  })
)

export default signUpReducer.reducer
