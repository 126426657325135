import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as MenuIcon } from '../../assets/images/common/icons/more-icon-vertical.svg'
import { ReactComponent as EditIcon } from '../../assets/images/common/icons/edit-icon.svg'
import { ReactComponent as GolferIcon } from '../../assets/images/common/icons/golfer-half-icon.svg'

import styles from './SeriesRow.module.scss'
import classNames from 'classnames'
import colors from '../../assets/styles/globals.scss'
import { pluralize } from '../../utility/helperFunctions'

const SeriesRow = ({ series, isFirstChild, handleSeriesMenuClick }) => (
  <div className={classNames(styles.seriesCard, 'w-100', isFirstChild && styles.firstChild)}>
    <div
      className={classNames('col-9 py-2 d-flex flex-column justify-content-center h-100', styles.seriesInfo)}
      aria-hidden='true'
    >
      <span>{series.title}</span>
      {series.status === 'draft' &&
        <div className={classNames('mt-1', styles.draftLabel)}>
          <EditIcon fill='#fff' className={styles.editIcon}/>
          <span>Draft</span>
        </div>
      }
    </div>

    <div
      className={classNames('col-2 d-flex flex-column justify-content-center', styles.rosterInfo)}
      aria-hidden='true'
    >
      <span>Master Roster</span>
      <div>
        <GolferIcon className={classNames('mr-1', styles.golfersCountIcon)} fill={colors.primaryNavy}/>
        <span className={classNames('mr-1', styles.golfersCount)}>{series.students.length}</span>
        <span className={styles.golfersCountLabel}>{pluralize('golfer', series.students.length)}</span>
      </div>
    </div>

    <div
      className={classNames('col-2', styles.actionsCol)}
      onClick={() => handleSeriesMenuClick(series.id)}
      onKeyDown={null}
      role='button'
      tabIndex={0}
    >
      <button className={styles.seriesButton} id='seriesMenuButton'>
        <MenuIcon style={{ width: 16, height: 16 }}fill={colors.primaryNavy}/>
      </button>
    </div>
  </div>
)

SeriesRow.propTypes = {
  series: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    events: PropTypes.array,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    nextEventDate: PropTypes.string,
    students: PropTypes.array,
    status: PropTypes.string,
  }),
  isFirstChild: PropTypes.bool,
  handleSeriesMenuClick: PropTypes.func.isRequired,
}

export default SeriesRow
