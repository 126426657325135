import React from 'react'
import ReactPlayer from 'react-player'
import PropTypes from 'prop-types'

const EducationOverview = ({ education }) => (
  <div className='animated fadeIn'>
    <p className='font-weight-bold py-3'>{education.description}</p>
    {education.video_url && (
      <div className='player-wrapper'>
        <ReactPlayer
          className='react-player'
          url={education.video_url}
          width='100%'
          height='100%'
          controls
        />
      </div>
    )}
  </div>
)

EducationOverview.propTypes = {
  education: PropTypes.shape({
    description: PropTypes.string,
    video_url: PropTypes.string,
  }),
}

export default EducationOverview
