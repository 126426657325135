// UI ACTIONS
export const IS_LOADING = 'IS_LOADING'
export const IS_SHOW_ACTIVITY_MENU = 'IS_SHOW_ACTIVITY_MENU'
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB'
export const IS_SHOW_NAV_BAR = 'IS_SHOW_NAV_BAR'
export const IS_SHOW_TAB_BAR = 'IS_SHOW_TAB_BAR'
export const IS_SHOW_SIDE_BAR = 'IS_SHOW_SIDE_BAR'
export const IS_HIDDEN_LANDING_HEADER = 'IS_HIDDEN_LANDING_HEADER'
export const SET_SIDE_BAR_STATE = 'SET_SIDE_BAR_STATE'

// UI ERROR
export const UI_ERROR = 'UI_ERROR'

// AUTH ACTIONS
export const REGISTER_USER = 'REGISTER_USER'
export const SIGNIN_USER = 'SIGNIN_USER'
export const REFRESH_USER = 'REFRESH_USER'
export const SIGN_OUT = 'SIGN_OUT'
export const PASSWORD_RESET = 'PASSWORD_RESET'
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST'
export const FORGOT_USERNAME_REQUEST = 'FORGOT_USERNAME_REQUEST'
export const GUARDIAN_CONSENT_REQUEST = 'GUARDIAN_CONSENT_REQUEST'

// USER ACTIONS
export const ADD_USER = 'ADD_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'
export const ADD_USER_ACTIVITIES = 'ADD_USER_ACTIVITIES'
export const ADD_USER_PLAY_ACTIVITIES = 'ADD_USER_PLAY_ACTIVITIES'
export const ADD_USER_GOAL_ACTIVITIES = 'ADD_USER_GOAL_ACTIVITIES'
export const ADD_USER_TRAIN_ACTIVITIES = 'ADD_USER_TRAIN_ACTIVITIES'
export const ADD_USER_OBJECTIVE_ACTIVITIES = 'ADD_USER_OBJECTIVE_ACTIVITIES'
export const ADD_USER_STATS = 'ADD_USER_STATS'
export const ADD_USER_GOALS = 'ADD_USER_GOALS'
export const ADD_USER_COURSES = 'ADD_USER_COURSES'
export const ADD_USER_COMPLETED_COURSES = 'ADD_USER_COMPLETED_COURSES'
export const ADD_USER_ARCHIVED_COURSES = 'ADD_USER_ARCHIVED_COURSES'
export const ADD_USER_TEMPLATE_COURSES = 'ADD_USER_TEMPLATE_COURSES'
export const ADD_USER_FEATURED_COURSES = 'ADD_USER_FEATURED_COURSES'
export const ADD_USER_DIVISION = 'ADD_USER_DIVISION'
export const ADD_DIVISION_DATA = 'ADD_DIVISION_DATA'
export const UPDATE_TRACK_GOAL = 'UPDATE_TRACK_GOAL'

export const PROGRESS_RANK = 'PROGRESS_RANK'
export const GET_USER_CURRICULUM = 'GET_USER_CURRICULUM'

// USERS ACTIONS
export const ADD_USERS = 'ADD_USERS'
export const GET_SEARCHED_USERS = 'GET_SEARCHED_USERS'
export const DELETE_USER = 'DELETE_USER'

// COACHES ACTIONS
export const ADD_COACHES = 'ADD_COACHES'
export const CREATE_COACH = 'CREATE_COACH'
export const UPDATE_COACH = 'UPDATE_COACH'
export const REMOVE_STUDENTS = 'REMOVE_STUDENTS'

// COURSE ACTIONS
export const CREATE_COURSE = 'CREATE_COURSE'
export const ADD_COURSES = 'ADD_COURSES'
export const GET_COURSE = 'GET_COURSE'
export const CREATE_COURSE_SESSION = 'CREATE_COURSE_SESSION'
export const DELETE_COURSE_SESSION = 'DELETE_COURSE_SESSION'
export const UPDATE_COURSE_SESSION = 'UPDATE_COURSE_SESSION'
export const CREATE_COURSE_SESSION_TASK = 'CREATE_COURSE_SESSION_TASK'
export const UPDATE_COURSE_SESSION_TASK = 'UPDATE_COURSE_SESSION_TASK'

// GOAL ACTIONS
export const GET_GOAL = 'GET_GOAL'
export const UPDATE_GOAL = 'UPDATE_GOALS'
export const CREATE_GOAL = 'CREATE_GOAL'
export const ADD_GOALS = 'ADD_GOALS'
export const DELETE_GOAL = 'DELETE_GOAL'
export const CREATE_GOAL_TASK = 'CREATE_GOAL_TASK'
export const UPDATE_GOAL_TASK = 'UPDATE_GOAL_TASK'
export const CREATE_GOAL_REVIEW = 'CREATE_GOAL_REVIEW'
export const UPDATE_GOAL_REVIEW = 'UPDATE_GOAL_REVIEW'
export const DELETE_GOAL_REVIEW = 'DELETE_GOAL_REVIEW'
export const GET_GOAL_STUDENTS = 'GET_GOAL_STUDENTS'
export const GET_GOAL_INACTIVE_STUDENTS = 'GET_GOAL_INACTIVE_STUDENTS'

// SUBJECT ACTIONS
export const ADD_SUBJECTS = 'ADD_SUBJECTS'

// NOTIFICATION ACTIONS
export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS'
export const ADD_NEW_NOTIFICATIONS = 'ADD_NEW_NOTIFICATIONS'
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS'


// ACTIVITY ACTIONS
export const ADD_ACTIVITY = 'ADD_ACTIVITY'
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY'
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY'
export const UPDATE_TRAIN_ACTIVITY = 'UPDATE_TRAIN_ACTIVITY'
export const GET_ACTIVITY = 'GET_ACTIVITY'
export const ADD_COURSE_ACTIVITIES = 'ADD_COURSE_ACTIVITIES'
export const POST_COURSE_ACTIVITY = 'POST_COURSE_ACTIVITY'

// ACTIVITY LIKE ACTIONS
export const ADD_ACTIVITY_LIKES = 'ADD_ACTIVITY_LIKES'
export const POST_ACTIVITY_LIKE = 'POST_ACTIVITY_LIKE'
export const DELETE_ACTIVITY_LIKE = 'DELETE_ACTIVITY_LIKE'

// ACTIVITY COMMENT ACTIONS
export const ADD_ACTIVITY_COMMENTS = 'ADD_ACTIVITY_COMMENTS'
export const POST_ACTIVITY_COMMENT = 'POST_ACTIVITY_COMMENT'
export const POST_USER_ACTIVITY_COMMENT = 'POST_USER_ACTIVITY_COMMENT'
export const DELETE_ACTIVITY_COMMENT = 'DELETE_ACTIVITY_COMMENT'

// ACTIVITY INFORMATION ACTIONS
export const ADD_PLAY_TYPES = 'ADD_PLAY_TYPES'
export const ADD_PRACTICE_TYPES = 'ADD_PRACTICE_TYPES'

// OBJECTIVE ACTIONS
export const ADD_RANK_OBJECTIVES = 'ADD_RANK_OBJECTIVES'
export const ADD_RANKS = 'ADD_RANKS'
export const ADD_SOCIAL_OBJECTIVES = 'ADD_SOCIAL_OBJECTIVES'
export const UPDATE_OBJECTIVE = 'UPDATE_OBJECTIVE'
export const UPDATE_OBJECTIVE_SOCIAL = 'UPDATE_OBJECTIVE_SOCIAL'
export const COMPLETE_OBJECTIVE = 'COMPLETE_OBJECTIVE'
export const REMOVE_OBJECTIVE = 'REMOVE_OBJECTIVE'

// RANK ACTIONS
export const ADD_MANY_RANKS = 'ADD_MANY_RANKS'

// LEADERBOARD ACTIONS
export const ADD_WEEKLY_LEADERBOARD = 'ADD_WEEKLY_LEADERBOARD'
export const ADD_MONTHLY_LEADERBOARD = 'ADD_MONTHLY_LEADERBOARD'
export const ADD_ALL_TIME_LEADERBOARD = 'ADD_ALL_TIME_LEADERBOARD'

// COMMUNITIES ACTIONS
export const ADD_COMMUNITIES = 'ADD_COMMUNITIES'
export const GET_COMMUNITY = 'GET_COMMUNITY'
export const POST_COMMUNITY = 'POST_COMMUNITY'
export const UPDATE_COMMUNITY = 'UPDATE_COMMUNITY'
export const GET_COMMUNITY_STUDENTS = 'GET_COMMUNITY_STUDENTS'
export const GET_COMMUNITY_STATS = 'GET_COMMUNITY_STATS'
export const GET_COMMUNITY_PIPELINE_STATS = 'GET_COMMUNITY_PIPELINE_STATS'
export const GET_COMMUNITY_INVITATIONS = 'GET_COMMUNITY_INVITATIONS'
export const COMPLETE_COMMUNITY_UPSELL = 'COMPLETE_COMMUNITY_UPSELL'
export const INCOMPLETE_COMMUNITY_UPSELL = 'INCOMPLETE_COMMUNITY_UPSELL'
export const GET_COMMUNITY_STUDENTS_DATES = 'GET_COMMUNITY_STUDENTS_DATES'
export const HANDLE_MAP_FILTER = 'HANDLE_MAP_FILTER'
export const GET_COMMUNITY_KEY_METRICS = 'GET_COMMUNITY_KEY_METRICS'
export const CLEAR_COMMUNITY_KEY_METRICS = 'CLEAR_COMMUNITY_KEY_METRICS'
export const GET_COMMUNITIES_PAGINATED = 'GET_COMMUNITIES_PAGINATED'
export const STUDENTS_LOADING = 'STUDENTS_LOADING'

// COMMUNITY FLAGS ACTIONS
export const GET_COMMUNITY_FLAGS = 'GET_COMMUNITY_FLAGS'
export const CREATE_COMMUNITY_FLAG = 'CREATE_COMMUNITY_FLAG'
export const UPDATE_COMMUNITY_FLAG = 'UPDATE_COMMUNITY_FLAG'
export const DELETE_COMMUNITY_FLAG = 'DELETE_COMMUNITY_FLAG'

// COMMUNITY STAGES ACTIONS
export const GET_COMMUNITY_STAGES = 'GET_COMMUNITY_STAGES'
export const CREATE_COMMUNITY_STAGE = 'CREATE_COMMUNITY_STAGE'
export const DELETE_COMMUNITY_STAGE = 'DELETE_COMMUNITY_STAGE'

// ANNOUNCEMENTS ACTIONS
export const ADD_ANNOUNCEMENTS = 'ADD_ANNOUNCEMENTS'
export const POST_ANNOUNCEMENT = 'POST_ANNOUNCEMENT'

// FAMILY ACTIONS
export const ADD_FAMILIES = 'ADD_FAMILIES'
export const ADD_FAMILY = 'ADD_FAMILY'
export const UPDATE_FAMILY = 'UPDATE_FAMILY'
export const ADD_FAMILY_MEMBER = 'ADD_FAMILY_MEMBER'
export const ADD_FAMILY_ENROLLMENTS = 'ADD_FAMILY_ENROLLMENTS'
export const ADD_FAMILY_ENROLLMENT = 'ADD_FAMILY_ENROLLMENT'
export const INVITE_FAMILY = 'INVITE_FAMILY'
export const GET_FAMILY_STATS = 'GET_FAMILY_STATS'
export const SEND_FAMILY_REMINDER = 'SEND_FAMILY_REMINDER'

// DRILL ACTIONS
export const GET_LESSON_PLAN_DRILLS = 'GET_LESSON_PLAN_DRILLS'
export const GET_DRILLS = 'GET_DRILLS'
export const GET_MY_DRILLS = 'GET_MY_DRILLS'
export const GET_MY_ACTIVITIES = 'GET_MY_ACTIVITIES'
export const GET_PUBLIC_DRILLS = 'GET_PUBLIC_DRILLS'
export const GET_PUBLIC_ACTIVITIES = 'GET_PUBLIC_ACTIVITIES'
export const GET_MY_PINNED_DRILLS = 'GET_MY_PINNED_DRILLS'
export const GET_MY_FAVORITE_DRILLS = 'GET_MY_FAVORITE_DRILLS'
export const GRAB_ONE_DRILL = 'GRAB_ONE_DRILL'
export const ADD_DRILL = 'ADD_DRILL'
export const UPDATE_DRILL = 'UPDATE_DRILL'
export const PIN_DRILL = 'PIN_DRILL'
export const ARCHIVE_DRILL = 'ARCHIVE_DRILL'
export const FAVORITE_DRILL = 'FAVORITE_DRILL'
export const TOP_DRILL_MAKERS = 'TOP_DRILL_MAKERS'
export const CLEAR_SELECTED_DRILL = 'CLEAR_SELECTED_DRILL'

// TAG ACTIONS
export const GET_TAGS = 'GET_TAGS'

// REPORT ACTIONS
export const GET_CURRICULUM_REPORT = 'GET_CURRICULUM_REPORT'
export const GET_ENGAGEMENT_REPORT = 'GET_ENGAGEMENT_REPORT'
export const GET_STATS_REPORT = 'GET_STATS_REPORT'
export const GET_STUDENTS = 'GET_STUDENTS'
export const GET_COMMUNITY_REPORT = 'GET_COMMUNITY_REPORT'
export const GET_COMMUNITY_ROUNDS_REPORT = 'GET_COMMUNITY_ROUNDS_REPORT'
export const GET_COMMUNITY_PRACTICE_REPORT = 'GET_COMMUNITY_PRACTICE_REPORT'
export const GET_COACH_ENGAGEMENT_REPORT = 'GET_COACH_ENGAGEMENT_REPORT'
export const GET_COMMUNITY_ENGAGEMENT_REPORT =
  'GET_COMMUNITY_ENGAGEMENT_REPORT'
export const GET_SUPPORT_ACTIVITY_REPORT = 'GET_SUPPORT_ACTIVITY_REPORT'
export const GET_COMMUNITY_PRIORITY_REPORT = 'GET_COMMUNITY_PRIORITY_REPORT'

// INVITATION ACTIONS
export const GET_INVITATION = 'GET_INVITATION'
export const ADD_INVITATION = 'ADD_INVITATION'
export const DELETE_INVITATION = 'DELETE_INVITATION'

// EDUCATION ACTIONS
export const GET_EDUCATION = 'GET_EDUCATION'
export const GET_EDUCATIONS = 'GET_EDUCATIONS'
export const CREATE_EDUCATION = 'CREATE_EDUCATION'
export const UPDATE_EDUCATION = 'UPDATE_EDUCATION'

// USER EDUCATION ACTIONS
export const GET_USER_EDUCATION = 'GET_USER_EDUCATION'
export const COMPLETE_LESSON = 'COMPLETE_LESSON'
export const INCOMPLETE_LESSON = 'INCOMPLETE_LESSON'

// RESOURCE ACTIONS
export const GET_RESOURCES = 'GET_RESOURCES'
export const GET_RESOURCE = 'GET_RESOURCE'
export const GET_RECENT_RESOURCES = 'GET_RECENT_RESOURCES'
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE'
export const CREATE_RESOURCE = 'CREATE_RESOURCE'
export const DELETE_RESOURCE = 'DELETE_RESOURCE'
export const GET_RESOURCE_SECTIONS = 'GET_RESOURCE_SECTIONS'
export const GET_RESOURCE_SECTION = 'GET_RESOURCE_SECTION'
export const CREATE_RESOURCE_SECTION = 'CREATE_RESOURCE_SECTION'
export const UPDATE_RESOURCE_SECTION = 'CREATE_RESOURCE_SECTION'
export const GET_RESOURCE_CATEGORIES = 'GET_RESOURCE_CATEGORIES'
export const CREATE_RESOURCE_CATEGORY = 'CREATE_RESOURCE_CATEGORY'

// EVENT ACTIONS
export const GET_EVENTS = 'GET_EVENTS'

// REPRESENTATIVE ACTIONS
export const GET_REPRESENTATIVES = 'GET_REPRESENTATIVES'

// REPRESENTATIVE NOTE ACTIONS
export const GET_REPRESENTATIVE_NOTES = 'GET_REPRESENTATIVE_NOTES'
export const CREATE_REPRESENTATIVE_NOTE = 'CREATE_REPRESENTATIVE_NOTE'
export const UPDATE_REPRESENTATIVE_NOTE = 'UPDATE_REPRESENTATIVE_NOTE'
export const DELETE_REPRESENTATIVE_NOTE = 'DELETE_REPRESENTATIVE_NOTE'

// REPRESENTATIVE ACTION TYPE ACTIONS
export const GET_REPRESENTATIVE_ACTION_TYPES =
  'GET_REPRESENTATIVE_ACTION_TYPES'

// SUPPORT STATS ACTIONS
export const GET_ONBOARDING_NEEDED_ACCOUNTS = 'GET_ONBOARDING_NEEDED_ACCOUNTS'
export const GET_FLAGGED_ACCOUNTS = 'GET_FLAGGED_ACCOUNTS'
export const GET_CONTACT_PRIORITY_ACCOUNTS = 'GET_CONTACT_PRIORITY_ACCOUNTS'
export const GET_ACCOUNT_OVERVIEW = 'GET_ACCOUNT_OVERVIEW'
export const GET_TEAM_ACTIVITY = 'GET_TEAM_ACTIVITY'
export const GET_ACCOUNT_ENGAGEMENT = 'GET_ACCOUNT_ENGAGEMENT'
export const GET_UPSELL_PRODUCT_USAGE = 'GET_UPSELL_PRODUCT_USAGE'

// LEAD ACTIONS
export const GET_LEADS = 'GET_LEADS'
export const UPDATE_LEAD = 'UPDATE_LEAD'
export const GET_LEAD_NOTES = 'GET_LEAD_NOTES'
export const CREATE_LEAD_NOTE = 'CREATE_LEAD_NOTE'
export const DELETE_LEAD = 'DELETE_LEAD'
export const GET_ADMIN_COUNTS = 'GET_ADMIN_COUNTS'

// PAGE VIEW ACTIONS (Community Map)
export const GET_VIEWS = 'GET_VIEWS'

// ACCOLADE ACTIONS
export const CREATE_ACCOLADE = 'CREATE_ACCOLADE'
export const GET_ALL_ACCOLADES = 'GET_ALL_ACCOLADES'
export const GET_COACH_ACCOLADES = 'GET_COACH_ACCOLADES'
export const DESTROY_ACCOLADE = 'DESTROY_ACCOLADE'

// UPSELL PRODUCT ACTIONS
export const CREATE_UPSELL_PRODUCT = 'CREATE_UPSELL_PRODUCT'
export const UPDATE_UPSELL_PRODUCT = 'UPDATE_UPSELL_PRODUCT'
export const DESTROY_UPSELL_PRODUCT = 'DESTROY_UPSELL_PRODUCT'
export const GET_UPSELL_PRODUCTS = 'GET_UPSELL_PRODUCTS'

// CHALLENGES ACTIONS
export const GET_ALL_CHALLENGES = 'GET_ALL_CHALLENGES'
export const UPDATE_CHALLENGE = 'UPDATE_CHALLENGE'
export const CREATE_CHALLENGE = 'CREATE_CHALLENGE'
export const DESTROY_CHALLENGE = 'DESTROY_CHALLENGE'

//PRO SHOP ACTIONS
export const GET_PROSHOP_DIVISION_LEADERBOARD =
  'GET_PROSHOP_DIVISION_LEADERBOARD'
export const GET_PROSHOP_COACH_DIVISION_LEADERBOARD =
  'GET_PROSHOP_COACH_DIVISION_LEADERBOARD'
export const GET_PROSHOP_COMMUNITY_LEADERBOARD =
  'GET_PROSHOP_COMMUNITY_LEADERBOARD'
export const GET_PROSHOP_PHOTO_WALL = 'GET_PROSHOP_PHOTO_WALL'
export const GET_PROSHOP_COMMUNITY_STATS = 'GET_PROSHOP_COMMUNITY_STATS'
export const GET_ALL_PRO_SHOP = 'GET_ALL_PRO_SHOP'
export const ADD_COACH_AD = 'ADD_COACH_AD'
export const POST_PINNED_ADS = 'POST_PINNED_ADS'
export const DELETE_COACH_AD = 'DELETE_COACH_AD'

// LESSON PLAN ACTIONS
export const CREATE_LESSON_PLAN_DRAFT = 'CREATE_LESSON_PLAN_DRAFT'
export const GRAB_LESSON_PLANS = 'GRAB_LESSON_PLANS'
export const UPDATE_LESSON_PLAN_DRAFT = 'UPDATE_LESSON_PLAN_DRAFT'
export const LESSON_PLAN_PIN = 'LESSON_PLAN_PIN'
export const DELETE_LESSON_PLAN = 'DELETE_LESSON_PLAN'
export const GRAB_ONE_LESSON_PLAN = 'GRAB_ONE_LESSON_PLAN'
export const GRAB_LP_TEMPLATES = 'GRAB_LP_TEMPLATES'
// SALES LEADS ACTIONS
export const GET_SALES_LEADS = 'GET_SALES_LEADS'
export const UPDATE_SALES_LEAD = 'UPDATE_SALES_LEAD'

// SKILL LEVEL ACTIONS
export const GET_SKILL_LEVELS = 'GET_SKILL_LEVELS'

// ADMIN TAG ACTIONS
export const GET_ALL_ADMIN_TAGS = 'GET_ALL_ADMIN_TAGS'
export const GET_USER_ADMIN_TAGS = 'GET_USER_ADMIN_TAGS'
export const ADD_USER_ADMIN_TAG = 'ADD_USER_ADMIN_TAG'
export const REMOVE_USER_ADMIN_TAG = 'REMOVE_USER_ADMIN_TAG'
export const CREATE_NEW_ADMIN_TAG = 'CREATE_NEW_ADMIN_TAG'
export const REMOVE_ADMIN_TAG = 'REMOVE_ADMIN_TAG'
export const UPDATE_ADMIN_TAG = 'UPDATE_ADMIN_TAG'
export const GET_COMMUNITY_ADMIN_TAGS = 'GET_COMMUNITY_ADMIN_TAGS'
export const ADD_COMMUNITY_ADMIN_TAG = 'ADD_COMMUNITY_ADMIN_TAG'
export const REMOVE_COMMUNITY_ADMIN_TAG = 'REMOVE_COMMUNITY_ADMIN_TAG'

//CONSENT ACTIONS
export const GET_USER_CONSENTS = 'GET_USER_CONSENTS'
export const POST_USER_CONSENTS = 'POST_USER_CONSENTS'
export const POST_FAMILY_CONSENTS = 'POST_FAMILY_CONSENTS'

// FEATURE ACTIONS
export const TOGGLE_COMMUNITY_FEATURE = 'TOGGLE_COMMUNITY_FEATURE'

// CURRENCIES
export const GET_CURRENCIES = 'GET_CURRENCIES'

//MATERIALS ACTIONS
export const GET_MATERIALS = 'GET_MATERIALS'
