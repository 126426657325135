import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { ReactComponent as GolferIcon } from '../../../../assets/images/common/icons/golfer-half-icon.svg'
import { ReactComponent as FlagIcon } from '../../../../assets/images/common/icons/weavy-flag-icon.svg'
import { ReactComponent as GroupIcon } from '../../../../assets/images/common/icons/users-group-icon.svg'
import { setUserField } from '../../../../reducers/signUpReducer'
import ProgressBar from '../../Helpers/ProgressBar'

import classes from './AboutYou.module.scss'

const AboutYou = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const setAccountSegment = (value) => {
    dispatch(setUserField({ field: 'accountSegment', value: value }))
  }

  return (
    <div className={classNames(classes.aboutYou, 'd-flex flex-column')}>
      <header className={classes.header}>
        <ProgressBar step={1} label='About You'/>
      </header>

      <main className={classNames(classes.mainContent, 'd-flex flex-column align-items-center justify-content-start')}>
        <div className='w-100'>
          <h1 className={classes.titleText}>Let&apos;s get to know you... Which describes you best?</h1>
        </div>

        <section className={classNames(classes.accountSegments, 'row mb-2')}>
          <div className={classNames(classes.categoryWrapper, 'col-12 col-lg-8 offset-lg-2 d-flex flex-column')}>
            <article
              className={classNames(classes.categoryCard, 'd-flex align-items-start')}
              aria-hidden='true'
              onClick={() => { setAccountSegment('individual'); navigate('?step=2') }}
            >
              <GolferIcon className={classes.individualIcon} />
              <div>
                <h6 className={classes.categoryTitle}>Individual Sign-Up</h6>
                <p className={classes.categoryDetails}>I want to start my personal golf journey and improve my skills with Op 36.</p>
              </div>
            </article>
          </div>
          <div className={classNames(classes.categoryWrapper, 'col-12 col-lg-8 offset-lg-2 d-flex flex-column')}>
            <article
              className={classNames(classes.categoryCard, 'd-flex align-items-start')}
              aria-hidden='true'
              onClick={() => { setAccountSegment('family'); navigate('?step=2') }}
            >
              <GroupIcon className={classes.familyIcon} />
              <div>
                <h6 className={classes.categoryTitle}>Family Sign-Up</h6>
                <p className={classes.categoryDetails}>I&apos;m signing up my child and might also participate in the program.</p>
              </div>
            </article>
          </div>
          <div className={classNames(classes.categoryWrapper, 'col-12 col-lg-8 offset-lg-2 d-flex flex-column')}>
            <article
              className={classNames(classes.categoryCard, 'd-flex align-items-start')}
              aria-hidden='true'
              onClick={() => { setAccountSegment('professional'); navigate('?step=2') }}
            >
              <FlagIcon className={classes.professionalIcon} />
              <div>
                <h6 className={classes.categoryTitle}>Golf Professional</h6>
                <p className={classes.categoryDetails}>I&apos;m a Coach interested in offering an Operation 36 Program.</p>
              </div>
            </article>
          </div>
        </section>
      </main>
    </div>
  )
}

export default AboutYou
