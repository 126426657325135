
import classNames from 'classnames'
import classes from './CoachOnboardingLoadingSkeleton.module.scss'

const CoachOnboardingLoadingSkeleton = () => (
  <div className={classes.container}>
    <div className={classNames(classes.loadingAnimation, classes.skeletonTitle)} />
    <div className={classNames(classes.loadingAnimation, classes.skeletonSubTitle)} />
    <div className={classNames(classes.loadingAnimation, classes.skeletonCaption)} />
    <div className={classNames(classes.loadingAnimation, classes.skeletonCaption)} />
    <div className={classNames(classes.loadingAnimation, classes.skeletonBar)} />
    <div className={classNames(classes.loadingAnimation, classes.skeletonBox)} />
    <div className={classNames(classes.loadingAnimation, classes.skeletonFooter)} />
  </div>
)

export default CoachOnboardingLoadingSkeleton
