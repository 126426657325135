import PropTypes from 'prop-types'

import { ReactComponent as GolferIcon } from '../../../../../assets/images/common/icons/golfer-half-icon.svg'
import StockProfilePic from '../../../../../assets/images/mock/default-profile-icon.png'

import styles from './SessionCard.module.scss'

const EventCard = ({ event }) => (
  <div className={styles.scheduleSessionCard}>
    <div className={styles.header}>
      <p className={styles.title}>{`${event.seriesName} - ${event.name}`}</p>
    </div>
    <p className={styles.subtitle}>{event.format}</p>

    <div>
      <i className={`fa fa-clock-o ${styles.faIcon}`}/>
      <span className={styles.text}>
        {event.date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toLowerCase()}
      </span>
    </div>

    {
      event.location &&
      <div>
        <i className={`fa fa-map-marker ${styles.faIcon}`}/><span className={styles.text}>{event.location}</span>
      </div>
    }

    <div className={styles.enrollments}>
      <GolferIcon className={styles.icon}/>
      <span className={styles.textStudents}>{event.students.length} Students</span>
    </div>

    <div className={styles.studentsProfiles}>
      {event.students.slice(0, 4).map((url, index) =>
        <div
          className={styles.profileImg}
          key={index}
          style={{
            backgroundImage: url
              ? `url(${url})`
              : `url(${StockProfilePic})`,
          }}
        />
      )}
      {event.students.length > 4 && <div className={styles.moreProfiles}>+ {event.students.length - 4} more</div>}
    </div>

  </div>
)

EventCard.propTypes = {
  event: PropTypes.shape({
    date: PropTypes.object,
    location: PropTypes.string,
    id: PropTypes.number,
    seriesName: PropTypes.string,
    name: PropTypes.string,
    format: PropTypes.string,
    students: PropTypes.array,
  }),
}

export default EventCard
