import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import classNames from 'classnames'

import { resendConfirmationEmail } from '../../../actions/authActions'
import { ReactComponent as OrangeCheckMarkRound } from '../../../assets/images/common/icons/orange-check-mark-round.svg'

import classes from './EmailVerificationRequired.module.scss'

const EmailVerificationRequired = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [resendDisabled, setResendDisabled] = useState(false)

  const navigateToLogIn = !['sign-up', 'log-in'].includes(location.state?.from)
  const email = location.state?.email
  const firstName = location.state?.firstName

  useEffect(() => {
    if (navigateToLogIn) {
      navigate('/log-in', { replace: true })
    }
  }, [navigate, navigateToLogIn])

  const resendEmail = () => {
    setResendDisabled(true)

    dispatch(resendConfirmationEmail(email))
      .then(() => (
        toast.success('A new confirmation email has been sent. You can send a new one in 2 minutes.', { position: toast.POSITION.TOP_RIGHT })
      ))
      .finally(() => setTimeout(() => setResendDisabled(false), 120000))
  }

  return (
    <div className={classNames(classes.mainCard, 'd-flex align-self-center m-auto')}>
      <div className={classNames(classes.emailVerificationRequired, 'd-flex flex-column')}>
        <div className={classNames(classes.mainContent, 'd-flex flex-column')}>
          <header className={classNames(classes.header, 'w-100 d-flex align-items-center')}>
            <section>
              <h1 className={classes.titleText}>{firstName ? `Hello ${firstName}!` : 'Email not confirmed!'}</h1>

              <h4 className={classes.subtitleText}>
                {firstName ? (
                  'You\'re almost there! Just confirm your account.'
                ) : (
                  'You need to confirm your account before logging in.'
                )}
              </h4>
            </section>

            {firstName && (
              <OrangeCheckMarkRound className={classNames(classes.orangeCheckMarkRoundIcon, 'ml-auto')} />
            )}
          </header>

          <section className={classNames(classes.details, 'w-100 d-flex flex-column justify-content-center mt-5')}>
            <p className={classes.mainText}>
              We&apos;ve sent a confirmation email to <span className={classes.emailText}>{email}</span>.
              Please check your inbox and click on the link to activate your account.
            </p>

            <section className={classNames(classes.smallText, 'mt-3')}>
              Didn&apos;t get the email? It should arrive within 2 minutes, but you can resend it below.

              <div
                aria-hidden='true'
                onClick={resendEmail}
                className={classNames(classes.resendEmail, { [classes.disabled]: resendDisabled }, 'd-flex align-items-center')}
              >
                <i className='fa fa-paper-plane mr-2' />
                <p>Resend Email</p>
              </div>
            </section>

            {firstName && (
              <p className={classNames(classes.mainText, 'mt-5')}>
                Thanks for joining Operation 36!
              </p>
            )}
          </section>
        </div>
      </div>
    </div>
  )
}

export default EmailVerificationRequired
