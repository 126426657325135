import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { getShowProgramSchedule } from '../../../actions/scheduleActions'
import Loader from '../../common/Op36Loader-web'
import SessionsList from '../../schedules/SessionsList'

import classes from './ScheduleTab.module.scss'
import dashboardClasses from './ProgramDashboardManager.module.scss'
import { ReactComponent as InfoCircleIcon } from '../../../assets/images/common/icons/info-circle-icon.svg'
import { ReactComponent as EditIcon } from '../../../assets/images/common/icons/edit-icon.svg'
import { ReactComponent as OpenEyeIcon } from '../../../assets/images/common/icons/open-eye-icon.svg'
import { ReactComponent as ClosedEyeIcon } from '../../../assets/images/common/icons/closed-eye-icon.svg'

const ScheduleTab = () => {
  const dispatch = useDispatch()

  const { program, isLoadingScheduleTab, schedule } = useSelector(state => state.programDashboard)
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)

  const [showPastSessions, setShowPastSessions] = useState(false)

  useEffect(() => {
    dispatch(getShowProgramSchedule(program.id))
      .unwrap()
      .catch(() => toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT }))
  }, [dispatch, program.id])

  if (isLoadingScheduleTab) {
    return <Loader message='Loading Schedule...' />
  }

  return (
    <div className={dashboardClasses.tabPanelContentWidth}>
      <div className='my-3 d-flex justify-content-between'>
        <button
          className={classnames('d-flex align-items-center', classes.action)}
          onClick={() => setShowPastSessions(prevState => !prevState)}
        >
          {showPastSessions ? <ClosedEyeIcon className={classes.icon} /> : <OpenEyeIcon className={classes.icon} />}
          <span className='ml-2'>{showPastSessions ? 'Hide' : 'Show'} past sessions</span>
        </button>
        <div className='d-flex align-items-center'>
          <Link
            className={classnames('d-flex align-items-center', classes.action)}
            to={`/programs/${program.id}/edit?step=2`}
          >
            <EditIcon className={classes.icon} />
            <span className='mx-2'>Edit Schedule</span>
          </Link>
          <InfoCircleIcon className={classes.icon} />
        </div>
      </div>
      <SessionsList
        schedule={schedule}
        timezone={timezone}
        isEditable
        showPastSessions={showPastSessions}
        className='h-100'
      />
    </div>
  )
}

ScheduleTab.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.number,
    schedule: PropTypes.shape({
      scheduleSessions: PropTypes.array,
    }),
  }),
}

export default ScheduleTab
