import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import Placeholder from '../../common/CustomPlaceholder'
import PairingGroup from './PairingGroup'

import { ReactComponent as Calendar } from '../../../assets/images/common/icons/circle-calendar.svg'
import colors from '../../../assets/styles/globals.scss'
import styles from './PairingsPage.module.scss'
import classNames from 'classnames'

const PairingsPage = ({ event }) => {
  const navigate = useNavigate()
  return (
    <div className={classNames(styles.pairingsPage, 'd-flex flex-column table-container')}>
      {event.pairings.pairingGroups.length ?
        <div className={styles.pairingGroups}>
          {event.pairings.status === 'draft' &&
            <div className={styles.draftPairingsMessage}>
              <i className='fa fa-exclamation-triangle' />
              <span className='ml-3'>Attention, this is a draft state, you must publish your pairings for your students to be notified!</span>
            </div>
          }
          {event.pairings.pairingGroups.map((pairingGroup, index) => (
            <PairingGroup key={index} pairingGroup={pairingGroup} format={event.pairings.format}/>
          ))}
        </div>
        :
        <Placeholder
          title='No Pairings Created'
          icon={<Calendar fill={colors.placeholderGray} width={100} height={100} className='mb-3'/>}
          subtext='Click Create Pairings to setup pairings.  You can always begin working and save as a draft before sending out to your golfers'
          handleAction={() => navigate(`/events/${event.id}/manage-pairings`)}
          buttonText='Create Pairings'
        />
      }
    </div>
  )
}

PairingsPage.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    pairings: PropTypes.shape({
      status: PropTypes.string,
      pairingGroups: PropTypes.array,
      format: PropTypes.string,
    }),
  }),
}

export default PairingsPage
