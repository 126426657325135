import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'

import AboutYou from './Steps/AboutYou'
import PrimaryInformation from './Steps/PrimaryInformation'
import SecondaryInformation from './Steps/SecondaryInformation'

import classes from './CreateNewAccount.module.scss'

const CreateNewAccount = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const loggedInUser = useSelector(state => state.user.current_user)
  const step = searchParams.get('step')

  useEffect(() => {
    if (loggedInUser?.id) {
      navigate('/sign-up/summary', { replace: true })
    } else if (step < '1' || step > '3') {
      navigate('/sign-up?step=1', { replace: true })
    }
  }, [dispatch, step, navigate, loggedInUser?.id])

  return (
    <aside className={classNames(classes.mainCard, 'd-flex align-self-center m-auto')}>
      {
        step === '1' && <AboutYou /> ||
        step === '2' && <PrimaryInformation /> ||
        step === '3' && <SecondaryInformation/>
      }
    </aside>
  )
}

export default CreateNewAccount
