import Modal from '../../common/Modal'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './ActionSubmittedModal.module.scss'

const ActionSubmittedModal = ({ showModal, closeModal, title, message, icon, firstButton, secondButton }) => (
  <Modal
    isOpen={showModal}
    onClose={closeModal}
    contentLabel='Exit Event Modal'
    className={styles.actionSubmittedModal}
    autoFocus
  >
    <div className={classNames('d-flex flex-column align-items-center', styles.modalContainer)}>
      <div className={classNames('d-flex justify-content-center align-items-center', styles.iconWrapper)}>
        {icon}
      </div>
      <div className={styles.modalContent}>
        <p className={styles.title}>{title}</p>
        <p className='mt-3'>{message}</p>
      </div>
      <div className='w-100 pull-bottom'>
        {firstButton}
        {secondButton}
      </div>
    </div>
  </Modal>
)

ActionSubmittedModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  message: PropTypes.string,
  firstButton: PropTypes.object,
  secondButton: PropTypes.object,
}

export default ActionSubmittedModal
