import StatsPanel from './StatsPanel'
import { useSelector } from 'react-redux'
import GenericFeedComponent from './GenericFeedComponent'

const OverviewTab = () => {
  const { program } = useSelector(state => state.programDashboard)

  return <div className='h-100 d-flex'>
    <GenericFeedComponent id={program.id} type='program' />
    <StatsPanel />
  </div>
}


export default OverviewTab
