import classnames from 'classnames'
import moment from 'moment'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import LeaderboardWrapper from '../../../common/leaderboard/LeaderboardWrapper'
import { colorNameToData, getColorNameFromStatus } from '../../../../utility/program-dashboard-helper'
import { selectAllEnrollments } from '../../../../reducers/programDashboardReducer'

import classes from './StatsPanel.module.scss'
import { ReactComponent as GolferHalfIcon } from '../../../../assets/images/common/icons/golfer-half-icon.svg'
import { ReactComponent as FlagCircleIcon } from '../../../../assets/images/common/icons/flag-icon.svg'
import { ReactComponent as InfoCircleIcon } from '../../../../assets/images/common/icons/info-circle-icon.svg'

const Placeholder = () => {
  const { program } = useSelector(state => state.programDashboard)
  const enrollments = useSelector(selectAllEnrollments)
  const withoutEnrollments = enrollments.length === 0

  const bodyContent = useMemo(() => {
    switch (program?.status) {
      case 'future':
        if (withoutEnrollments) {
          return ({
            text: `Registration Opens in ${moment(program.registrationStartsAt).diff(moment(), 'days')} Days`,
            link: 'https://operation36.supporthero.io/article/show/172087',
            linkText: 'Learn More About Program States',
          })
        }
        break
      case 'active':
        if (withoutEnrollments) {
          return ({
            text: 'Share & Market your Program to your community!',
            link: 'https://operation36golf.com/resource-sections/4',
            linkText: 'Learn More About Marketing',
          })
        } else {
          return ({
            text: 'Students earn points throughout the program by logging playing sessions, training sessions ' +
              'and watching curriculum videos. Their accumulated points will be displayed here.',
            link: 'https://operation36.supporthero.io/article/show/171596',
            linkText: 'Learn More About Leaderboards',
          })
        }
      case 'draft':
        return ({
          text: 'This program is currently in Draft State and does not allow student enrollments. ' +
            'Publish and promote your program to start accepting students.',
          link: 'https://operation36.supporthero.io/article/show/172087',
          linkText: 'Learn More About Program States',
        })
      case 'completed':
        return ({
          text: 'Uh oh! No activities were logged during the program. Remind your students to log their' +
            'playing sessions, training sessions, and watch curriculum videos in the mobile app.',
          link: 'https://operation36.supporthero.io/article/show/171596',
          linkText: 'Learn More About Leaderboards',
        })
      default:
        return null
    }
  }, [program.registrationStartsAt, program.status, withoutEnrollments])

  return (
    <div className={classnames('d-flex flex-column align-items-center', classes.placeholder)}>
      <GolferHalfIcon className={classes.icon} />
      <h4 className={classes.title}>
        {withoutEnrollments && program.status !== 'completed' ? 'No Students Enrolled' : 'No Activities'}
      </h4>
      <div className='d-flex flex-column'>
        <span className={classnames('mb-3', classes.text)}>{bodyContent.text}</span>
        <a href={bodyContent.link} target='_blank' className={classes.link}>{bodyContent.linkText}</a>
      </div>
    </div>
  )
}

const LeaderboardWidget = () => {
  const { program } = useSelector(state => state.programDashboard)
  const enrollments = useSelector(selectAllEnrollments)

  const isFutureStartDate = moment(program?.startDate).isAfter(moment(), 'day')

  const filters = useMemo(() => ({
    startDate: isFutureStartDate ? null : program?.startDate,
    endDate: isFutureStartDate ? new Date() : program?.endDate,
    courseId: program?.id,
  }), [isFutureStartDate, program?.endDate, program?.id, program?.startDate])

  const colorName = useMemo(() => getColorNameFromStatus(program), [program])

  return (
    <div className={classnames('d-flex flex-column', classes.leaderboardWidget)}>
      <div
        className='px-3 py-2 d-flex justify-content-between align-items-center'
        style={{ backgroundColor: colorNameToData[colorName].banner.backgroundColor }}
      >
        <div className='d-flex align-items-center'>
          <FlagCircleIcon width={20} height={20} className='mr-2' style={{ fill: colorNameToData[colorName].banner.color }} />
          <span
            style={{ color: colorNameToData[colorName].banner.color }}
            className={classes.bannerText}
          >
            {colorNameToData[colorName].banner['text'](program)}
          </span>
        </div>
        <InfoCircleIcon width={20} height={20} className={classes.infoCircleIcon} />
      </div>
      <div className='d-flex flex-grow-1 flex-column'>
        <div className={classnames('p-3 d-flex justify-content-between align-items-center', classes.header)}>
          <h3 className={classes.title}>{isFutureStartDate ? 'Students Enrolled' : 'Program Activity Leaderboard'}</h3>
          <span className={classes.timePeriodDetails}>
            {isFutureStartDate
              ? '(All Time Stats)'
              : `${moment(program?.startDate)
                .format('MM/DD/YY')} - ${moment(program?.endDate)
                .format('MM/DD/YY')}`}
          </span>
        </div>
        {enrollments.length === 0 ? (
          <div className={classes.placeholderContainer}>
            <Placeholder />
          </div>
        ) : (
          <LeaderboardWrapper
            withDetailedLeaderboard={!isFutureStartDate}
            filters={filters}
            classNames={{
              tabs: 'flex-grow-1',
              tabList: 'mx-3',
            }}
            placeholder={<Placeholder />}
          />
        )}
      </div>
    </div>
  )
}


export default LeaderboardWidget
