import classNames from 'classnames'
import classes from './ActivitySkeleton.module.scss'

const ActivitySkeleton = () => (
  <article className={classes.card}>
    <header className={classes.header}>
      <div className={classNames(classes.skeletonAvatar, classes.loadingAnimation)} />
      <div className={classes.titleSection}>
        <div className={classNames(classes.skeletonTitle, classes.loadingAnimation)} />
        <div className={classNames(classes.skeletonSubTitle, classes.loadingAnimation)} />
      </div>
    </header>
    <section className={classes.body}>
      <div className={classNames(classes.skeletonText, classes.loadingAnimation)} />
      <div className={classNames(classes.skeletonText, classes.loadingAnimation)} />
      <div className={classNames(classes.skeletonShortText, classes.loadingAnimation)} />
    </section>
  </article>
)

export default ActivitySkeleton
