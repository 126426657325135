import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { ReactComponent as BackArrowIcon } from '../../../assets/images/common/icons/programBuilder/back-arrow-icon.svg'
import { ReactComponent as GolferIcon } from '../../../assets/images/common/icons/golfer-half-icon.svg'
import { resetState } from '../../../reducers/seriesReducer'
import ExitModal from '../ExitModal/ExitModal'

import styles from './FlowHeader.module.scss'

const FlowHeader = ({ isPublished, onSave, setShowSaveModal, loader, title }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showExitModal, setShowExitModal] = useState(false)

  const onSubmit = () => {
    navigate('/events')
    dispatch(resetState())
  }

  return (
    <section className={`d-flex align-items-center ${styles['header-container']}`}>
      <div aria-hidden='true' className={`d-flex align-items-center ${styles['back-section']}`} onClick={() => setShowExitModal(true)}>
        <BackArrowIcon aria-hidden='true' className='flex-shrink-0 mr-3' />
        <p className={`text-nowrap ${styles.title}`}>{title}</p>
      </div>

      <button
        className={`btn btn-primary ml-auto ${styles['action-button']}`}
        disabled={loader}
        onClick={isPublished ? () => onSave(false) : () => setShowSaveModal(true)}
      >
        {isPublished ? 'Save' : 'Save or Publish'}
      </button>

      <ExitModal
        showModal={showExitModal}
        closeModal={() => setShowExitModal(false)}
        onSubmit={onSubmit}
        icon={<GolferIcon className={styles.golferIcon}/>}
        title='Continue Editing'
        message='Are you sure you want to stop editing? All progress will be lost if you stop now. Continue Editing and Publish your Event.'
        submitButtonMessage='Continue Editing'
      />
    </section>
  )
}

FlowHeader.propTypes = {
  isPublished: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  setShowSaveModal: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}

export default FlowHeader
