import * as types from "../actions/actionTypes";
import update from "immutability-helper";
import { saveState } from '../store/localStorage';

// eslint-disable-next-line complexity
const coursesReducer = (courses = [], action) => {
  var currentCourseIndex = null;
  var currentCourse = null;
  var currentEnrollmentIndex = null;
  var enrollments = null;
  let new_courses = [courses.courses];
  var sessions = [];

  switch (action.type) {
    case types.ADD_COURSES:
      return { ...courses, courses: action.courses };
    case types.GET_COURSE:
      return Object.assign({}, courses, { course: action.course });
    case types.REMOVE_STUDENTS:
      return Object.assign({}, courses, { course: action.course });
    case types.CREATE_COURSE_SESSION:
      sessions = courses.sessions;
      sessions = sessions.filter(session => session.id !== action.session.id);
      return {
        ...courses,
        sessions: [...courses.sessions, Object.assign({}, action.session)]
      };
    case types.DELETE_COURSE_SESSION:
      return {
        ...courses,
        sessions: courses.sessions.filter(
          session => Object.assign({}, session).id !== action.session.id
        )
      };
    case types.UPDATE_COURSE_SESSION:
      return {
        ...courses,
        sessions: courses.sessions.map(oldSession => {
          if (oldSession.id !== action.session.id) {
            return oldSession;
          }
          return { ...oldSession, ...action.session };
        })
      };
    case types.CREATE_COURSE:
      return {
        ...courses,
        courses: [...courses.courses, Object.assign({}, action.course)]
      };

    case types.ADD_USER_COURSES:
      if (action.pagination?.page > 1) {
        return {
          ...courses,
          courses: [...courses.courses, ...action.courses],
          pagination: action.pagination,
        }
      } else {
        return {
          ...courses,
          courses: action.courses,
          pagination: action.pagination,
        }
      }
    case types.ADD_USER_COMPLETED_COURSES:
      return { ...courses, completed_courses: action.courses };
    case types.ADD_USER_ARCHIVED_COURSES:
      return { ...courses, archived_courses: action.courses };
    case types.ADD_USER_TEMPLATE_COURSES:
      return { ...courses, template_courses: action.courses };
    case types.ADD_USER_FEATURED_COURSES:
      return { ...courses, featured_courses: action.courses };

    case types.CREATE_COURSE_SESSION_TASK:
      return {
        ...courses,
        sessions: courses.sessions.map(oldSession => {
          if (oldSession.id !== action.sessionTask.session_id) {
            return oldSession;
          }
          return {
            ...oldSession,
            sessions_tasks: Object.assign({}, action.sessionTask)
          };
        })
      };

    case types.UPDATE_COURSE_SESSION_TASK:
      return {
        ...courses,
        sessions: courses.sessions.map(oldSession => {
          if (oldSession.id !== action.session_task.session_id) {
            return oldSession;
          }
          return {
            ...oldSession,
            sessions_tasks: oldSession.session_tasks.map(oldSessionTask => {
              if (oldSessionTask.id !== action.session_task.id) {
                return oldSessionTask;
              }
              return Object.assign({}, oldSessionTask, action.session_task);
            })
          };
        })
      };

    default:
      return courses;
  }
}

export default coursesReducer
