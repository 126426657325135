import PropTypes from 'prop-types'
import classnames from 'classnames'

import Modal from '../../common/Modal'

import classes from './ConfirmationModal.module.scss'
import { ReactComponent as GolferIcon } from '../../../assets/images/common/icons/golfer-half-icon.svg'


const ConfirmationModal = ({ showModal, closeModal, submitButton, secondButton, title, text, image, extraContent }) => (
  <Modal
    isOpen={showModal}
    onClose={closeModal}
    contentLabel='Program Builder Live Modal'
    className={classes['modal']}
    autoFocus
  >
    <div className={classes['wrapper']}>
      <div className='d-flex'>
        <div className={classes['text-content']}>
          <div className={classnames('d-flex align-items-center', classes['title-container'])}>
            <div className={classnames('d-flex justify-content-center align-items-center', classes['icon-wrapper'])}>
              <GolferIcon className={classes['golfer-icon']} fill='#fff' width={'30px'} height={'30px'} />
            </div>
            <span className={classes['title']}>{title}</span>
          </div>
          <div className={classes['text-container']}>{text}</div>
          <div className={classnames('mt-3', classes['text-container'])}>{extraContent}</div>
        </div>
        <div className={classnames('d-flex justify-content-end', classes['image'])}>
          <img className={classes['mobile-leaderboard']} src={image} alt='mobile leaderboard' />
        </div>
      </div>
      {submitButton}
      {secondButton}
    </div>
  </Modal>
)

ConfirmationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  submitButton: PropTypes.object,
  secondButton: PropTypes.object,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  extraContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
}

export default ConfirmationModal
