import PropTypes from 'prop-types'
import avatarPlaceholder from '../../assets/images/mock/default-profile-icon.png'
import SmallMilestoneIcon from '../common/SmallMilestoneIcon'
import './StudentWithAvatar.scss'

const StudentWithAvatar = ({ student, withName }) => (
  <a
    href={`${window.location.origin}/profile/${student.id}`}
    target='_blank'
    className='d-flex align-items-center student-with-avatar'
  >
    <div className='mr-3 smaller-round-image-wrapper' >
      <img
        alt='student'
        className='round-image'
        src={student.avatar?.fileUrl || avatarPlaceholder}
      />
    </div>
    <SmallMilestoneIcon fromAvatar className='student-level' division={student.currentFormalDivision} />
    {withName && (
      <div>
        <p className='student-title'>{student.firstName} {student.lastName}</p>
      </div>
    )}
  </a>
)

StudentWithAvatar.propTypes = {
  student: PropTypes.object.isRequired,
  withName: PropTypes.bool,
}

export default StudentWithAvatar
