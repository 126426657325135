import PropTypes from 'prop-types'
import classnames from 'classnames'
import EnrolledStudentsTooltip from './EnrolledStudentsTooltip'

import styles from './EnrollmentsProgressBar.module.scss'

const EnrollmentsProgressBar = ({ program, enrollments, withTooltip, classNames }) => {
  const enrollmentsCount = enrollments.length

  return (
    <>
      <div
        className={classnames(styles.golfersInfo, { [classNames?.golfersInfo]: !!classNames?.golfersInfo })}
        data-for={`enrollmentsTooltip-${program.id}`}
        data-tip
      >
        <div className={classnames(styles.golfersCount, { [classNames?.golfersCount]: !!classNames?.golfersCount })}>
          {program.golferLimit ?
            <span>{enrollmentsCount === 0 ? 'No Enrollments' : `${enrollmentsCount} / ${program.golferLimit} golfers`}</span>
            :
            <><span className={styles.count}>{enrollmentsCount}</span> golfers</>
          }
        </div>
        {!!program.golferLimit &&
          <div className={classnames('mt-1 progress', styles.progressBackground)}>
            <div
              className={styles.progressBar}
              role='progressbar'
              style={{ width: `${enrollmentsCount * 100 / program.golferLimit}%` }}
              aria-valuenow='25'
              aria-valuemin='0'
              aria-valuemax='100' />
          </div>
        }
      </div>
      {withTooltip && <EnrolledStudentsTooltip program={program} enrollments={enrollments} />}
    </>
  )
}

EnrollmentsProgressBar.propTypes = {
  program: PropTypes.shape({
    id: PropTypes.number.isRequired,
    golferLimit: PropTypes.number.isRequired,
  }).isRequired,
  enrollments: PropTypes.array.isRequired,
  withTooltip: PropTypes.bool,
  classNames: PropTypes.shape({
    golfersCount: PropTypes.string,
    golfersInfo: PropTypes.string,
  }),
}

export default EnrollmentsProgressBar
