import PropTypes from 'prop-types'
import { setField } from '../../../reducers/nineHoleEventReducer'
import { useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as userActions from '../../../actions/userActions'
import moment from 'moment'
import colors from '../../../assets/styles/globals.scss'
import Error from '../../common/ErrorMessage'
import SubmitButtom from '../../common/buttons/SubmitButton'
import StudentScoreRow from './StudentScoreRow'
import { ReactComponent as GolferIcon } from '../../../assets/images/common/icons/golfer-half-icon.svg'
import { ReactComponent as FlagIcon } from '../../../assets/images/common/icons/simple-flag-icon.svg'
import { ReactComponent as TimeIcon } from '../../../assets/images/common/icons/time-icon.svg'
import { DATETIME_FORMAT, getMomentInCommunityTimezoneReversed } from '../../../utility/datesHelper'

import './LogScores.scss'
import { deserialize } from '../../../utility/serialization'

const LogScores = ({ handleAddGolfer, setScoresSaved, isQuickLog }) => {
  const dispatch = useDispatch()
  const { id, students, pairings } = useSelector(state => state.nineHoleEvent)
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)
  const errors = useSelector(state => state.nineHoleEvent.errors.students)
  const [loadingStats, setLoadingStats] = useState(true)

  useEffect(() => {
    if (!loadingStats) {
      return
    }

    const newStudents = [...students]
    const studentsWithoutStats = students.filter(s => !s.stats).map(s => s.id)

    if (studentsWithoutStats.length === 0) {
      setLoadingStats(false)
      return
    }

    userActions.getActivitiesStats(studentsWithoutStats, id).then(response => {
      const data = deserialize(response.data)
      data.studentsStats.forEach(a => {
        const index = newStudents.findIndex(s => s.id === a.userId)
        newStudents[index] = { ...newStudents[index], stats: a.stats }
      })
      dispatch(setField({ field: 'students', value: newStudents }))
    })
    setLoadingStats(false)
  }, [dispatch, students, id, loadingStats])

  const filteredStudents = useMemo(() => {
    if (students?.length === 0) {
      return []
    } else if (isQuickLog) {
      return [...students].sort((a, b) => a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase()))
    } else {
      return [...students]
        .filter(s => s.status !== 'declined')
        .sort((a, b) => a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase()))
    }
  }, [students, isQuickLog])

  const unpairedStudents = useMemo(() => {
    if (students?.length === 0) {
      return []
    } else {
      return [...students]
        .filter(s => !s.pairingGroupId && s.status !== 'declined')
        .sort((a, b) => a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase()))
    }
  }, [students])

  return (
    <div id='LogScores' className='d-flex flex-column table-container'>
      {filteredStudents?.length > 0 ?
        <>
          <table className='table table-responsive animated fadeIn w-100'>
            <thead>
              <tr>
                <th className='golfer-header'>Golfer</th>
                <th className='centered-text'>Level Attempts</th>
                <th className='centered-text'>Baseline Score</th>
                <th className='centered-text'>Record Score</th>
                <th>Level</th>
                <th>Greens</th>
                <th>Putts</th>
                <th>Score</th>
                <th>Milestone</th>
                <th className='centered-text'>Remove</th>
              </tr>
            </thead>
            <tbody>
              {!pairings.pairingGroups.length ?
                filteredStudents.map((std) =>
                  <StudentScoreRow key={std.id} std={std} setScoresSaved={setScoresSaved} />
                ) :
                <>
                  {pairings.pairingGroups.map((group) => {
                    const pairedStudents = filteredStudents.filter(u => group.users.map(s => s.id).includes(u.id) )
                    return (
                      <>
                        {pairedStudents.length ?
                          <tr className='pairing-group-header'>
                            <div className='p-2 d-flex justify-content-between align-items-center pairing-group-info'>
                              {pairings.format === 'Shotgun' ?
                                <span className='pairing-group-order'>
                                  <FlagIcon className='flag-icon'/> Hole {group.startHole.value}{group.playersGroup.value}
                                </span> :
                                <span className='pairing-group-order'>Pair {group.order}</span>
                              }

                              <span className='d-flex align-items-center pairing-group-time'><TimeIcon className='time-icon'/>
                                {getMomentInCommunityTimezoneReversed(moment(group.startTime), timezone, DATETIME_FORMAT).format('LT')}
                              </span>
                            </div>
                          </tr> : null
                        }
                        {pairedStudents.map((std) => (
                          <StudentScoreRow key={std.id} std={std} setScoresSaved={setScoresSaved} />
                        ))}
                      </>
                    )
                  })}
                  {unpairedStudents.length ?
                    <tr className='pairing-group-header'>
                      <div className='p-2 d-flex justify-content-between align-items-center pairing-group-info'>
                        <span className='pairing-group-order'>Unpaired students</span>
                      </div>
                    </tr> : null
                  }
                  {unpairedStudents.map((std) => (
                    <StudentScoreRow key={std.id} std={std} setScoresSaved={setScoresSaved} />
                  ))
                  }
                </>
              }
            </tbody>
          </table>
          {errors?.length > 0 && (
            <div className='row mb-4'>
              <div className='col-md-8 mx-auto'>
                <Error error={errors.join('\n')}/>
              </div>
            </div>
          )}
        </>
        :
        <div className='no-students-placeholder'>
          <GolferIcon id='golferIcon' fill={colors.lightNavy} width={'64px'} height={'64px'}/>
          <p className='placeholder-title'>No Golfers Attending</p>
          <div className='centered-text'>Looks there are no golfers attending this event. You can manually add below.</div>
        </div>
      }
      <div className='d-flex justify-content-center w-100'>
        <div className='add-golfer-button'>
          <SubmitButtom buttonMessage='Add Golfer' iconType='addButton' onClick={() => handleAddGolfer()}/>
        </div>
      </div>
    </div>
  )
}

LogScores.propTypes = {
  handleAddGolfer: PropTypes.func.isRequired,
  setScoresSaved: PropTypes.func.isRequired,
  isQuickLog: PropTypes.bool.isRequired,
}

export default LogScores
