import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { pluralize } from '../../../utility/helperFunctions'

import EventHeader from './Header/EventHeader'
import PageFooter from '../../common/PageFooter/PageFooter'
import EventForm from '../Form/EventForm'
import Loader from '../../common/Op36Loader-web'
import EventInfo from './Leaderboard/EventInfo'
import Leaderboard from './Leaderboard/Leaderboard'
import GuestList from './GuestList'
import PairingsPage from './PairingsPage'
import ConfirmationModal2 from '../../common/ConfirmationModal2'
import EventInProgressAlert from '../EventInProgressAlert'
import SubmitButton from '../../common/buttons/SubmitButton'
import PublishPairingsModal from '../Modal/ConfirmationModal'
import ActionSubmittedModal from '../Modal/ActionSubmittedModal'
import mobilePairings from '../../../assets/images/mock/mobile-pairings.webp'
import { ReactComponent as LeaderboardIcon } from '../../../assets/images/common/icons/leaderboard-icon.svg'
import { ReactComponent as ClapIcon } from '../../../assets/images/common/icons/clap-icon.svg'
import { ReactComponent as EditIcon } from '../../../assets/images/common/icons/edit-icon.svg'

import * as eventActions from '../../../actions/eventActions'
import * as commonActions from '../../../actions/commonActions'
import * as pairingsActions from '../../../actions/pairingsActions'
import colors from '../../../assets/styles/globals.scss'

import styles from './EventDetail.module.scss'
import { setField, eventFormData, pairingsFormData } from '../../../reducers/nineHoleEventReducer'
import { setField as setFieldSeries } from '../../../reducers/seriesReducer'
import Placeholder from './Placeholder'

const text = 'Are you sure you want to submit\
\nscores, the following will happen:\
\n\n  1. Your Golfers will be emailed\
\n\n  2. Pairings will show up in the mobile app in the schedule\
\n\n  3. RSVP window will close'

const EditIconComponent = (
  <div className={styles.editIconWrapper}>
    <EditIcon fill='#fff' width='40px' height='40px'/>
  </div>
)


const EventDetail = ({ eventId }) => { // eslint-disable-line max-lines-per-function, complexity
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const event = useSelector(state => state.nineHoleEvent)
  const formData = useSelector(eventFormData)
  const formDataPairings = useSelector(pairingsFormData)

  const [searchParams, setSearchParams] = useSearchParams()

  const [showForm, setShowForm] = useState(false)
  const [eventLoader, setEventLoader] = useState(true)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showDeleteEnrollmentModal, setShowDeleteEnrollmentModal] = useState(false)
  const [showPublishPairingsModal, setShowPublishPairingsModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showLoadScoresModal, setShowLoadScoresModal] = useState(false)
  const [selectedEnrollmentId, setSelectedEnrollmentId] = useState(null)
  const [tabIndex, setTabIndex] = useState(0)

  const completed = event.status === 'completed'
  const scoresPassedDue = event.status === 'ongoing'
  const localScores = JSON.parse(localStorage.getItem('draftScores'))
  const eventScores = localScores && localScores[event.id] && localScores[event.id]['scores']
  const savedScoresCount = eventScores ? Object.values(eventScores).filter(value => value.score > 0).length : 0

  const orderedTabs = useMemo(() => (
    completed ? ['leaderboard'] : event.pairings.id !== -1
      ? ['leaderboard', 'pairings', 'roster'] : ['roster', 'pairings', 'leaderboard']
  ), [completed, event.pairings.id])

  useEffect(() => {
    dispatch(commonActions.setCurrentTab('EventDetail'))
    dispatch(commonActions.showSideBar(!showForm))
    dispatch(commonActions.showNavBar(!showForm))
  }, [dispatch, showForm])

  useEffect(() => {
    dispatch(commonActions.setCurrentTab('EventDetail'))
    dispatch(commonActions.showSideBar(!showForm))
    dispatch(commonActions.showNavBar(!showForm))
  }, [dispatch, showForm])

  const onClickBack = () => {
    navigate('/events')
  }

  const openEditModal = ({ action }) => {
    if (action) { dispatch(setField({ field: 'action', value: action })) }
    setShowForm(!showForm)
  }

  const closeEditModal = () => {
    fetchEvent()
    setShowForm(false)
  }

  const fetchEvent = useCallback(() => {
    setEventLoader(true)
    dispatch(eventActions.getEvent(params.eventId || eventId)).unwrap().then((res) => {
      setEventLoader(false)
      if (res.event.status === 'created' && res.event.pairings.id !== -1) {
        setTabIndex(1)
      }
    })
  }, [dispatch, params.eventId, eventId])

  const handleDeleteEnrollment = () => {
    dispatch(eventActions.deleteEnrollment(selectedEnrollmentId)).unwrap()
      .catch(() => {
        toast.error('Something went wrong.', { position: toast.POSITION.TOP_RIGHT })
      })
      .finally(() => {
        setShowDeleteEnrollmentModal(false)
      })
  }

  const updateEvent = () => {
    setEventLoader(true)
    dispatch(eventActions.updateEvent(formData)).unwrap()
      .then(() => {
        toast.success(`Success! Updated ${event.name}`, {
          position: toast.POSITION.BOTTOM_LEFT,
        })
        removeDraftScores()
        _.delay(handleEventSubmittedClick, 5000)
        setEventLoader(false)
      })
      .catch(() => setEventLoader(false))
  }

  const updateEnrollments = () => {
    setEventLoader(true)
    dispatch(eventActions.updateEnrollments({ event })).unwrap()
      .then(() => {
        toast.success(`Success! Updated ${event.name}`, {
          position: toast.POSITION.BOTTOM_LEFT,
        })
        handleEventSubmittedClick()
      })
      .catch(() => toast.error('Something went wrong. Please try again.', {
        position: toast.POSITION.BOTTOM_LEFT,
      }))
      .finally(() => setEventLoader(false))
  }

  const handleEventSubmittedClick = () => {
    if (!showForm) {
      return
    }

    fetchEvent()
    // triggers fetching series
    dispatch(setFieldSeries({ field: 'upcomingSeries', value: null }))
    dispatch(setFieldSeries({ field: 'completedSeries', value: null }))
    setShowForm(false)
    updateTabIndex(0)
  }

  const deleteEvent = () => {
    dispatch(eventActions.deleteEvent(event.id)).unwrap().then(() => {
      toast.success(`Successfully deleted ${event.name}`, {
        position: toast.POSITION.BOTTOM_LEFT,
      })
      navigate('/events')
    })
  }

  const publishPairings = () => {
    setEventLoader(true)
    dispatch(pairingsActions.updatePairings({ pairings: { ...formDataPairings, status: 'created', notify: true } }))
      .finally(() => { setEventLoader(false) })
    setShowPublishPairingsModal(false)
    setShowConfirmationModal(true)
  }

  const removeStudent = (id) => {
    setSelectedEnrollmentId(id)
    setShowDeleteEnrollmentModal(true)
  }

  const sendEmailButton = {
    text: 'Send an Email',
    icon: 'fa fa-envelope',
    onClick: () => navigate('/announcements/new-campaign', { state: { smartToggle: 'email', students: event.students } }),
  }

  const pairingsActionButton = {
    text: event.pairings.id !== -1 ? 'Edit Pairings' : 'Create Pairings',
    onClick: () => navigate('manage-pairings'),
  }

  const publishPairingsButton = {
    text: 'Publish Pairings',
    onClick: () => setShowPublishPairingsModal(true),
  }

  const logScoresButton = {
    text: 'Log Scores',
    onClick: () => handleLogScoresClick(),
  }

  const editScoresButton = {
    text: 'Edit Scores',
    onClick: () => handleLogScoresClick(),
  }

  const CloseConfirmationModalButton = (
    <SubmitButton
      onClick={() => setShowConfirmationModal(false)}
      buttonMessage='Close'
      buttonColor='navy-button'
    />
  )

  const handleLogScoresClick = () => {
    const scores = JSON.parse(localStorage.getItem('draftScores'))
    if (scores && scores[event.id]) {
      setShowLoadScoresModal(true)
    } else {
      openEditModal({ action: 'Log Scores' })
    }
  }

  const loadScores = () => {
    setShowLoadScoresModal(false)
    if (eventScores) {
      const updatedStudents = event.students.map((std) => {
        const studentData = eventScores[std.id]
        if (studentData) {
          return {
            ...std,
            score: studentData['score'],
            greens: studentData['greens'],
            putts: studentData['putts'],
            yardage: studentData['yardage'],
            fullTeeYardage: studentData['fullTeeYardage'],
            level: studentData['level'],
          }
        }
        return std
      })
      dispatch(setField({ field: 'students', value: updatedStudents }))
    }
    openEditModal({ action: 'Log Scores' })
  }

  const removeDraftScores = () => {
    const localScores = JSON.parse(localStorage.getItem('draftScores'))
    delete localScores[event.id]
    localStorage.setItem('draftScores', JSON.stringify(localScores))
  }

  const startFromScratch = () => {
    removeDraftScores()
    setShowLoadScoresModal(false)
    openEditModal({ action: 'Log Scores' })
  }

  const placeholderButton = (
    <SubmitButton
      onClick={eventScores ? loadScores : () => openEditModal({ action: 'Log Scores' })}
      buttonMessage={eventScores ? 'Continue Logging' : 'Log Scores'}
      buttonColor='orange-button'
    />
  )

  const placeholderMessage = (
    eventScores ?
      `You have ${savedScoresCount} ${pluralize('score', savedScoresCount)} logged so far.
      Continue logging your scores and complete the event to see the leaderboard.` :
      'Click below to begin logging scores for this event.  You can always start logging and save your progress as you go.'
  )

  const PublishPairingsModalButton = (
    <SubmitButton
      onClick={publishPairings}
      buttonMessage='Publish pairings'
      buttonColor='orange-button'
    />
  )

  const PairingsPanel = () => (
    <>
      <div className={classNames('col-12 px-0', styles.pairingsPage)}>
        <PairingsPage event={event}/>
      </div>
      <PageFooter actionButtons={event.pairings.status === 'draft' ? [pairingsActionButton, publishPairingsButton] : [pairingsActionButton]}/>
    </>
  )

  const RosterPanel = () => (
    <>
      <div className={classNames('col-12 px-0', styles.guestList)}>
        <GuestList event={event} removeStudent={removeStudent}/>
      </div>
      <PageFooter actionButtons={[sendEmailButton, logScoresButton, pairingsActionButton]}/>
    </>
  )

  const LeaderboardPanel = () => (
    completed ? (
      <>
        <div className={classNames('row mx-0', styles.leaderboardPage)}>
          <div className='col-md-4'>
            <EventInfo/>
          </div>
          <div className='col-md-8'>
            <Leaderboard/>
          </div>
        </div>
        <PageFooter actionButtons={[editScoresButton]}/>
      </>
    ) : (
      <div>
        <Placeholder
          logScores={eventScores ? loadScores : () => openEditModal({ action: 'Log Scores' })}
          eventInProgress={!!eventScores}
          savedScoresCount={savedScoresCount}
          title={eventScores ? 'Scoring "In-Progress"' : 'No Leaderboard'}
          icon={<LeaderboardIcon fill={eventScores ? colors.primaryOrange : colors.placeholderGray} className='mb-3'/>}
          button={placeholderButton}
          message={placeholderMessage}
        />
        <PageFooter actionButtons={[sendEmailButton, logScoresButton]}/>
      </div>
    )
  )


  useEffect(() => {
    fetchEvent()
  }, [fetchEvent])

  const updateTabIndex = (index) => {
    setSearchParams({ activeTab: orderedTabs[index] })
    setTabIndex(index)
  }

  useEffect(() => {
    const tabIndex = orderedTabs.findIndex((tab) => tab === searchParams.get('activeTab'))
    if (tabIndex !== -1) {
      setTabIndex(tabIndex)
    }
  }, [searchParams, orderedTabs])

  return (
    <div className={styles.eventDetail} style={{ marginLeft: !showForm ? '-15px' : '0' }} >
      {showForm ?
        <EventForm
          isEdit
          onClickBack={closeEditModal}
          updateEvent={updateEvent}
          updateEnrollments={updateEnrollments}
          handleEventSubmittedClick={handleEventSubmittedClick}
          eventLoader={eventLoader}
        /> :
        eventLoader
          ? (<Loader message='loading event' />)
          : (
            <div className='w-100'>
              <EventHeader
                className='sticky-top'
                event={event}
                onClickBack={onClickBack}
                openEditModal={openEditModal}
                handleLogScoresClick={handleLogScoresClick}
                setShowDeleteModal={setShowDeleteModal}
              />
              {!event.series?.id ?
                <div className={classNames('row mx-0', styles.leaderboardPageQuickLog)}>
                  <div className='col-md-4 h-100'>
                    <EventInfo/>
                  </div>
                  <div className='col-md-8 h-100'>
                    <Leaderboard/>
                  </div>
                </div> :
                <>
                  {(eventScores || scoresPassedDue) &&
                    <EventInProgressAlert scoresPassedDue={scoresPassedDue} onClick={loadScores}/>
                  }
                  <Tabs className='pb-5' selectedIndex={tabIndex} onSelect={(index) => updateTabIndex(index)}>
                    {completed ?
                      <>
                        <TabList className={classNames(styles.tabList, 'd-flex')} >
                          <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Leaderboard</Tab>
                        </TabList>
                        <TabPanel className={styles.tabPanel}>
                          <LeaderboardPanel/>
                        </TabPanel>
                      </> :
                      event.pairings.id !== -1 && event.pairings.status !== 'draft' ?
                        <>
                          <TabList className={classNames(styles.tabList, 'd-flex')} >
                            <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Leaderboard</Tab>
                            <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Pairings</Tab>
                            <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Roster</Tab>
                          </TabList>
                          <TabPanel className={styles.tabPanel}><LeaderboardPanel /></TabPanel>
                          <TabPanel className={styles.tabPanel}><PairingsPanel /></TabPanel>
                          <TabPanel className={styles.tabPanel}><RosterPanel /></TabPanel>
                        </> :
                        <>
                          <TabList className={classNames(styles.tabList, 'd-flex')} >
                            <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Roster</Tab>
                            <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Pairings</Tab>
                            <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Leaderboard</Tab>
                          </TabList>
                          <TabPanel className={styles.tabPanel}><RosterPanel /></TabPanel>
                          <TabPanel className={styles.tabPanel}><PairingsPanel /></TabPanel>
                          <TabPanel className={styles.tabPanel}><LeaderboardPanel /></TabPanel>
                        </>
                    }
                  </Tabs>
                </>
              }
            </div>
          )
      }
      <ConfirmationModal2
        showModal={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        onDeny={() => setShowDeleteModal(false)}
        denyMessage='Cancel'
        onProceed={() => deleteEvent()}
        proceedMessage='Delete Event'
        icon={<i className='fa fa-exclamation-triangle' style={{ fontSize: '72px', color: colors.secondaryColor }} />}
        title='Are you sure?'
        message='This will delete all activities for the golfers of this event.'
      />

      <ConfirmationModal2
        showModal={showDeleteEnrollmentModal}
        closeModal={() => setShowDeleteEnrollmentModal(false)}
        onDeny={() => setShowDeleteEnrollmentModal(false)}
        denyMessage='Cancel'
        onProceed={() => handleDeleteEnrollment()}
        proceedMessage='Remove Student'
        icon={<i className='fa fa-exclamation-triangle' style={{ fontSize: '72px', color: colors.secondaryColor }} />}
        title='Are you sure?'
        message='This will remove the student from this event.'
      />

      <ConfirmationModal2
        showModal={showLoadScoresModal}
        closeModal={() => setShowLoadScoresModal(false)}
        onDeny={startFromScratch}
        denyMessage='Start from Scratch'
        onProceed={loadScores}
        proceedMessage='Load Scores'
        icon={EditIconComponent}
        title='Load draft scores?'
        message='There are draft scores saved for this event. Do you want to load them?'
      />

      <PublishPairingsModal
        showModal={showPublishPairingsModal}
        closeModal={() => setShowPublishPairingsModal(false)}
        submitButton={PublishPairingsModalButton}
        title='Publish Pairings'
        text={text}
        image={mobilePairings}
      />

      <ActionSubmittedModal
        showModal={showConfirmationModal}
        closeModal={() => setShowConfirmationModal(false)}
        firstButton={CloseConfirmationModalButton}
        title='Your Pairings have been published successfully.'
        message='Your students will be notified in the mobile app!'
        icon={<ClapIcon stroke='white'/>}
      />
    </div>
  )
}

EventDetail.propTypes = {
  eventId: PropTypes.number,
}

export default EventDetail
