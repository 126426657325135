import PropTypes from 'prop-types'
import { useState } from 'react'
import moment from 'moment'
import { displayWithCurrency } from '../../../../utility/currency'
import { calculateAge, genderLabel } from '../../../../utility/userHelper'

import { AutoSizer, Column, Table } from 'react-virtualized'
import TooltipText from '../../../common/TooltipText'
import RegistrationActions from './RegistrationActions'
import ColumnPackageSelect from './ColumnPackageSelect'
import ColumnPaymentStatusSelect from './ColumnPaymentStatusSelect'
import Tooltip from '../../../common/Tooltip'

import StudentWithAvatar from '../../../students/StudentWithAvatar'
import 'react-virtualized/styles.css'
import './GolfersTable.scss'
import colors from '../../../../assets/styles/globals.scss'

const GolfersTable = ({ program, enrollments }) => {
  const [packagesSelectOpen, setPackagesSelectOpen] = useState(false)

  const totalFixedWidth = 1260

  const headerRenderer = ({ label, className }) => (
    <div className={className}>
      <span>{label}</span>
    </div>
  )

  const headerRowRenderer = ({ className, columns, style }) => (
    <div className={`${className} table-header`} role='row' style={style}>
      {columns}
    </div>
  )

  return (
    <div id='GolfersTable' className='mt-3 pb-1 flex-grow-1 flex-shrink-0'>
      <Tooltip offset={{ top: -5 }} />
      <AutoSizer>
        {({ width, height }) => {
          const remainingWidth = Math.max(width, totalFixedWidth) - totalFixedWidth
          return (
            <Table
              className='table-body'
              rowClassName='tr'
              height={height}
              headerHeight={50}
              width={Math.max(width, totalFixedWidth)}
              rowHeight={70}
              rowCount={enrollments.length}
              rowGetter={({ index }) => enrollments[index]}
              headerRowRenderer={headerRowRenderer}
            >
              <Column
                width={230 + remainingWidth * 0.3}
                key='students'
                label='Student'
                headerClassName='td'
                headerRenderer={() =>
                  headerRenderer({
                    label: 'Student',
                  })
                }
                dataKey='enrollment'
                cellRenderer={
                  ({ cellData }) => (
                    <div className='d-flex align-items-center cell'>
                      <StudentWithAvatar student={cellData.user} />
                      <div className='golfer-info'>
                        <TooltipText text={`${cellData.user.firstName} ${cellData.user.lastName}`} className='golfer-name' />
                        <span className='golfer-age-gender'>Age {calculateAge(cellData.user.birthdate)} / {genderLabel[cellData.user.gender]}</span>
                      </div>
                    </div>
                  )
                }
              />
              <Column
                width={230 + remainingWidth * 0.18}
                key='package'
                label='Package'
                headerClassName='td'
                headerRenderer={() =>
                  headerRenderer({
                    label: 'Package',
                    className: 'package-column-header',
                  })
                }
                dataKey='enrollment'
                cellRenderer={
                  ({ cellData }) => (
                    <div key={cellData.id} className='package-column cell'>
                      <ColumnPackageSelect
                        course={program}
                        enrollment={cellData}
                        menuOpen={packagesSelectOpen}
                        setMenuOpen={setPackagesSelectOpen}
                      />
                    </div>
                  )
                }
              />
              <Column
                width={180 + remainingWidth * 0.1}
                key='tuition'
                label='Tuition'
                headerClassName='td'
                headerRenderer={() =>
                  headerRenderer({
                    label: 'Tuition',
                    className: 'tuition-column-header',
                  })
                }
                dataKey='enrollment'
                cellRenderer={
                  ({ cellData }) => (
                    <div className='tuition-column cell' style={{ color: cellData.package ? colors.darkNavy : colors.placeholderGray }}>
                      {cellData.package ? displayWithCurrency(cellData.transactionAmount, cellData.currency, true) : '--'}
                    </div>
                  )
                }
              />
              <Column
                width={280 + remainingWidth * 0.26}
                key='paymentStatus'
                label='Payment status'
                headerClassName='td'
                headerRenderer={() =>
                  headerRenderer({
                    label: 'Payment status',
                    className: 'payment-status-column-header',
                  })
                }
                dataKey='enrollment'
                cellRenderer={
                  ({ cellData }) => (
                    <div key={cellData.id} className='payment-status-column cell'>
                      <ColumnPaymentStatusSelect
                        enrollment={cellData}
                      />
                    </div>
                  )
                }
              />
              <Column
                width={160 + remainingWidth * 0.1}
                key='dateJoined'
                label='Date joined'
                headerClassName='td'
                headerRenderer={() =>
                  headerRenderer({
                    label: 'Date joined',
                    className: 'date-joined-column-header',
                  })
                }
                dataKey='enrollment'
                cellRenderer={
                  ({ cellData }) => (
                    <div
                      className='date-joined-column cell'
                      style={{ color: cellData.status === 'applied' ? colors.placeholderGray : colors.darkNavy }}
                    >
                      {cellData.status === 'applied' ? '--' : moment(cellData.createdAt)
                        .format('MM/DD/YYYY')}
                    </div>
                  )
                }
              />
              <Column
                width={180}
                key='actions'
                label='Actions'
                headerClassName='td'
                headerRenderer={() =>
                  headerRenderer({
                    label: 'Actions',
                    className: 'actions-column-header',
                  })
                }
                dataKey='enrollment'
                cellRenderer={
                  ({ cellData }) => (
                    <div key={cellData.id} className='actions-column cell'>
                      <RegistrationActions course={program} enrollment={cellData} />
                    </div>
                  )
                }
              />
            </Table>
          )
        }}
      </AutoSizer>
    </div>
  )
}

GolfersTable.propTypes = {
  program: PropTypes.shape({
    id: PropTypes.number,
    availablePackages: PropTypes.array,
    hasMore: PropTypes.bool,
  }),
  enrollments: PropTypes.array,
}

export default GolfersTable
