import _ from 'lodash'
import classnames from 'classnames'
import Modal from '../../../common/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'

import { categoryCompare } from '../../../../utility/programHelper'
import { DUAL_PANEL_SELECTABLE_TYPES, PER_PAGE } from '../../../../constants/dual-panel-selector-constants'
import {
  addSelectedProgram,
  addSelectedStudent, deleteSelectedProgram,
  deleteSelectedStudent, resetSelectedData,
  selectEnrolledStudents,
  selectSelectedPrograms,
  selectSelectedStudents, updateSelectedStudent,
} from '../../../../reducers/programDashboardReducer'
import { reset, selectProgramsData, selectStudentsData, setSearchValue, setUnavailableStudents } from '../../../../reducers/dualPanelSelectorReducer'
import { getProgramsDualPanelSelectorThunk } from '../../../../actions/programActions'
import { getStudentsDualPanelSelector } from '../../../../actions/usersActions'
import DualPanelSelector from '../../../common/dual-panel-selector/DualPanelSelector'

import classes from './AddStudentsModal.module.scss'
import { ReactComponent as FlagIcon } from '../../../../assets/images/common/icons/Flag.svg'
import SubmitButton from '../../../common/buttons/SubmitButton'
import PropTypes from 'prop-types'

const SummaryPlaceholder = () => (
  <div className={classes.placeholder}>
    <FlagIcon className={classes.icon} />
    <h2 className={classes.title}>No Students Added</h2>
    <p className={classes.description}>
      Add your participants individually or by program from the menu on the left.
    </p>
  </div>
)

const AddStudentsModal = ({ onProceed, ...props }) => {
  const dispatch = useDispatch()

  const communityId = useSelector(state => state.user.current_user.communities[0].id)
  const enrolledStudents = useSelector(selectEnrolledStudents)
  const studentsData = useSelector(selectStudentsData)
  const programsData = useSelector(selectProgramsData)
  const selectedStudentsMap = useSelector(state => state.programDashboard.addStudentsModal.selectedStudents.entities)
  const selectedStudents = useSelector(selectSelectedStudents)
  const selectedPrograms = useSelector(selectSelectedPrograms)

  const deleteStudent = (std, course = null) => {
    if (course) {
      const student = selectedStudentsMap[std.id]

      if (!student) {
        return
      }

      const from = { ...student.addedFrom }
      delete from[course.id]

      if (_.isEmpty(from)) {
        dispatch(deleteSelectedStudent(student.id))
      } else {
        dispatch(updateSelectedStudent({ ...student, addedFrom: from }))
      }
    } else {
      dispatch(deleteSelectedStudent(std.id))
    }
  }

  const addStudent = (std, course = null) => {
    if (!selectedStudentsMap[std.id]) {
      if (course) {
        dispatch(addSelectedStudent({ ...std, addedFrom: { [course.id]: course.name } }))
      } else {
        dispatch(addSelectedStudent({ ...std, addedFrom: { students: true } }))
      }
    }
  }

  const handleCheckStudent = (checked, student) => {
    if (checked) {
      deleteStudent(student)
    } else {
      addStudent(student)
    }
  }

  const handleCheckProgram = (checked, course) => {
    if (checked) {
      course.enrollments.map((e) => {
        deleteStudent(e.user, course)
      })
      dispatch(deleteSelectedProgram(course.id))
    } else {
      dispatch(addSelectedProgram(course))
      course.enrollments.map((e) => {
        if (!studentsData.unavailableItems.entities[e.userId]) {
          addStudent(e.user, course)
        }
      })
    }
  }

  const fetchPrograms = useCallback((page, searchTerm, completed) =>
    dispatch(getProgramsDualPanelSelectorThunk({ page, perPage: PER_PAGE, searchTerm, completed }))
      .unwrap()
      .catch(() => toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT }))
  , [dispatch])

  const fetchStudents = useCallback((page, searchTerm) =>
    dispatch(getStudentsDualPanelSelector({ page, perPage: PER_PAGE, communityId, searchTerm }))
      .unwrap()
      .catch(() => toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT }))
  , [communityId, dispatch])

  useEffect(() => {
    dispatch(resetSelectedData())
    dispatch(setUnavailableStudents(enrolledStudents))
    return () => dispatch(reset())
  }, [dispatch, enrolledStudents])

  const leftPanelProps = {
    tabTypes: [DUAL_PANEL_SELECTABLE_TYPES.students, DUAL_PANEL_SELECTABLE_TYPES.programs],
    tabProps: {
      [DUAL_PANEL_SELECTABLE_TYPES.students]: {
        titleText: `Select Students (${selectedStudents.length})`,
        allItems: studentsData.items,
        searchValue: studentsData.searchValue,
        onSearchValueChange: (searchValue) => dispatch(setSearchValue({ searchValue, key: DUAL_PANEL_SELECTABLE_TYPES.students })),
        getSelectedItem: (id) => selectedStudentsMap[id],
        fetchItems: fetchStudents,
        pagination: studentsData.pagination,
        onCheckChange: handleCheckStudent,
      },
      [DUAL_PANEL_SELECTABLE_TYPES.programs]: {
        titleText: `Select Programs (${selectedPrograms.length})`,
        allItems: programsData.items,
        withCompletedSwitcher: true,
        searchValue: programsData.searchValue,
        onSearchValueChange: (searchValue) => dispatch(setSearchValue({ searchValue, key: DUAL_PANEL_SELECTABLE_TYPES.programs })),
        getSelectedItem: (id) => selectedPrograms.find(program => program.id === id),
        fetchItems: fetchPrograms,
        pagination: programsData.pagination,
        onCheckChange: handleCheckProgram,
        itemCategoryTitle: (program) => program.category ? program.category.name : 'Other Programs',
        itemCategoryCompare: categoryCompare,
      },
    },
  }

  const rightPanelProps = {
    selectedItems: selectedStudents,
    selectedItemType: DUAL_PANEL_SELECTABLE_TYPES.students,
    summaryPlaceholder: <SummaryPlaceholder />,
    onRemove: (student) => handleCheckStudent(true, student),
  }

  return (
    <Modal className={classes.modal} title='Add Students' {...props}>
      <div className={classnames('d-flex flex-column', classes.modalContentWrapper)}>
        <DualPanelSelector
          className={classes.dualPanelSelector}
          {...leftPanelProps}
          {...rightPanelProps}
        />
        <div className='mt-3 d-flex justify-content-end'>
          <SubmitButton
            disabled={!selectedStudents.length}
            className={classes.button}
            buttonMessage='Add Students'
            onClick={() => {
              props.onClose()
              onProceed()
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

AddStudentsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
}

export default AddStudentsModal
