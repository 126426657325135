import PropTypes from 'prop-types'
import classnames from 'classnames'
import { requiredIf } from '../../../../utility/prop-types-helper'
import { formatDates } from '../../../../utility/datesHelper'
import { useSelector } from 'react-redux'
import ChoiceInput from '../../form/ChoiceInput'
import classes from './ItemCard.module.scss'
import programClasses from './ProgramCard.module.scss'
import colors from '../../../../assets/styles/globals.scss'
import { ReactComponent as GolferIcon } from '../../../../assets/images/common/icons/golfer-half-icon.svg'
import { ReactComponent as CalendarIcon } from '../../../../assets/images/common/icons/calendar-icon.svg'
import EnrolledStudentsTooltip from '../../../classes/enrollments-details/EnrolledStudentsTooltip'

const SeriesCard = ({
  item: series,
  isSelectable,
  isChecked,
  onCheckChange,
}) => {
  const communityTimezone = useSelector(state => state.user.current_user.communities[0].timezone)

  return (
    <div className={classnames('d-flex flex-column', classes.itemWrapper)}>
      <div className='d-flex'>
        {isSelectable && (
          <div className='flex-shrink-0 mt-1 d-flex align-items-start'>
            <ChoiceInput
              classNames={{ input: 'mr-3' }}
              color='orange'
              checked={isChecked}
              onChange={() => onCheckChange(isChecked, series)}
            />
          </div>
        )}
        <div className='flex-grow-1 ml-5 d-flex flex-column'>
          <span className={classes.title}>{series.title}</span>
          <div className='d-flex align-items-center'>
            <CalendarIcon className={programClasses.icon} />
            <span className={classnames('ml-1', classes.subtitle)}>{formatDates(series.startDate, series.endDate, communityTimezone)}</span>
          </div>
          <div className='d-flex align-items-center'>
            <div className='d-flex align-items-center' data-for={`enrollmentsTooltip-${series.id}`} data-tip>
              <GolferIcon className={programClasses.icon} fill={colors.primaryNavy} />
              <span className='ml-1 enrollments-count'>{series.students.length}</span>
              <span className={classes.date}>{series.subtitle}</span>
            </div>
            <span className={classnames('ml-1', classes.subtitle)}>{series.meetingTimes}</span>
          </div>
        </div>
        <EnrolledStudentsTooltip
          program={series}
          enrollments={series.students}
          unavailableSectionTitle='Already-enrolled Students'
          isSeries={true}
        />
      </div>
    </div>
  )
}

SeriesCard.propTypes = {
  // ...ItemCardProps,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    meetingTimes: PropTypes.string.isRequired,
    students: PropTypes.array.isRequired,
    subtitle: PropTypes.string,
    packages: requiredIf(PropTypes.array, ({ withAdditionalSelect }) => withAdditionalSelect),
    selectedPackages: PropTypes.array,
  }).isRequired,
  hasNewCategory: PropTypes.bool,
  categoryTitle: PropTypes.string,
  isSelectable: PropTypes.bool,
  isChecked: requiredIf(PropTypes.bool, ({ isSelectable }) => isSelectable),
  onCheckChange: requiredIf(PropTypes.func, ({ isSelectable }) => isSelectable),
  withAdditionalSelect: PropTypes.bool,
  onSelect: requiredIf(PropTypes.func, ({ withAdditionalSelect }) => withAdditionalSelect),
  onSelectDelete: requiredIf(PropTypes.func, ({ withAdditionalSelect }) => withAdditionalSelect),
  className: PropTypes.string,
}

export default SeriesCard
