import * as types from './actionTypes'
import * as common from './commonActions'
import * as Constants from '../misc/Constants.js'

import Axios from '../axios'
import { serialize } from '../utility/serialization'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleAPIError } from '../utility/actionHelper.js'

// export function like_activity(activityId){
//   return { type: types.POST_ACTIVITY_LIKE, activityId: activityId };
// }

// export function addCommentToActivity(activityId, user, comment){
//   return { type: types.POST_ACTIVITY_COMMENT, activityId: activityId, user: user, comment: comment };
// }

export function successRequestingActivities(response) {
  if (response.status === 200) {
    return {
      type: types.ADD_COURSE_ACTIVITIES,
      activities: response.data.activities,
      pagination: response.data.meta.pagination,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingActivity(response) {
  if (response.status === 200) {
    return { type: types.GET_ACTIVITY, activity: response.data.activity }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function succcessDeletingActivity(response) {
  if (response.status === 201) {
    return { type: types.DELETE_ACTIVITY, activity: response.data.activity }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingActivityLikes(response) {
  if (response.status === 200) {
    return { type: types.ADD_ACTIVITY_LIKES, likes: response.data.likes }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingActivityComments(response) {
  if (response.status === 200) {
    return {
      type: types.ADD_ACTIVITY_COMMENTS,
      comments: response.data.comments,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successPostingActivityLike(response, activity) {
  if (response.status === 201) {
    return {
      type: types.POST_ACTIVITY_LIKE,
      like: response.data.like,
      activity: activity,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingUpdateActivity(response) {
  if (response.status === 201) {
    return { type: types.UPDATE_ACTIVITY, activity: response.data.activity }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingUpdateTrainActivity(response) {
  if (response.status === 201) {
    return { type: types.UPDATE_ACTIVITY, activity: response.data.activity }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successDeletingActivityLike(response, activityId) {
  if (response.status === 201) {
    return { type: types.DELETE_ACTIVITY_LIKE, activity_id: activityId }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successPostingActivityComment(response, activity) {
  if (response.status === 201) {
    return {
      type: types.POST_ACTIVITY_COMMENT,
      comment: response.data.comment,
      activity: activity,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successDeletingActivityComment(
  response,
  activityId,
  commentId
) {
  if (response.status === 204) {
    return {
      type: types.DELETE_ACTIVITY_COMMENT,
      activity_id: activityId,
      comment_id: commentId,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successPostingActivityPlay(response) {
  if (response.status === 201) {
    return {
      type: types.POST_COURSE_ACTIVITY,
      activity: response.data.activity,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successPostingActivity(response) {
  if (response.status === 201) {
    return {
      type: types.POST_COURSE_ACTIVITY,
      activity: response.data.activity,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successPostingActivities(response) {
  if (response.status === 201) {
    return { type: types.POST_COURSE_ACTIVITY, activity: response.data }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

// THUNKS HERE
export function getFamilyActivities(
  familyId,
  page,
  perPage = Constants.PER_PAGE_DEFAULT
) {
  return function (dispatch) {
    if (page <= 1) {
      // Don't show if past the first page
      // CALL LOADING CHANGED TO TRUE
      dispatch(common.loadingChanged(true))
    }
    return requestFamilyActivities(familyId, page, perPage)
      .then((response) => dispatch(successRequestingActivities(response)))
      .then(
        // AFTER COMPLETION OF RESPONSE, STOP SPINNER
        //if(page <= 1){
        // CALL LOADING CHANGED TO TRUE
        () => dispatch(common.loadingChanged(false))
        //}
      )
  }
}

export const getCommunityActivities = createAsyncThunk(
  'actions/getCommunityActivities',
  async ({ id, page, perPage, type }, { rejectWithValue }) => {
    try {
      const { data } = await requestCommunityActivities(id, page, perPage, type)
      return data
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getProgramActivities = createAsyncThunk(
  'actions/getProgramActivities',
  async ({ id, page, perPage, type }, { rejectWithValue }) => {
    try {
      const { data } = await requestGetProgramActivities(id, page, perPage, type)
      return data
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)


export function getActivity(activityId) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestActivity(activityId)
      .then((response) => dispatch(successRequestingActivity(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function updateActivity(activity) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestUpdateActivity(activity)
      .then((response) =>
        dispatch(successRequestingUpdateActivity(response, activity))
      )
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function deleteActivity(activity) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestDeleteActivity(activity).then((response) =>
      dispatch(succcessDeletingActivity(response))
    )
  }
}

export function updateTrainActivity(activity, trainSession) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestUpdateTrainActivity(activity, trainSession)
      .then((response) =>
        dispatch(successRequestingUpdateTrainActivity(response, activity))
      )
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function getActivityLikes(activityId) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestActivityLikes(activityId)
      .then((response) => dispatch(successRequestingActivityLikes(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function getActivityComments(activityId) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestActivityComments(activityId)
      .then((response) => dispatch(successRequestingActivityComments(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function postActivityLike(activityId, activity) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestPostActivityLike(activityId)
      .then((response) =>
        dispatch(successPostingActivityLike(response, activity))
      )
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function deleteActivityLike(activityId) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestDeleteActivityLike(activityId)
      .then((response) =>
        dispatch(successDeletingActivityLike(response, activityId))
      )
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function postActivityComment(activityId, text, activity) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestPostActivityComment(activityId, text)
      .then((response) =>
        dispatch(successPostingActivityComment(response, activity))
      )
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function deleteActivityComment(comment) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestDeleteActivityComment(comment.id)
      .then((response) => dispatch(successDeletingActivityComment(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function postActivitySocial(activity) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestPostActivitySocial(activity)
      .then((response) => dispatch(successPostingActivity(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function coachPostActivitySocial(activity) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestCoachPostActivitySocial(activity)
      .then((response) => dispatch(successPostingActivity(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function postActivityPlay(activity) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestPostActivityPlay(activity)
      .then((response) => dispatch(successPostingActivity(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function coachPostActivityPlay(activity) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestCoachPostActivityPlay(activity)
      .then((response) => dispatch(successPostingActivity(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function postActivityTrain(activity) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestPostActivityTrain(activity)
      .then((response) => dispatch(successPostingActivity(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function coachPostActivityTrain(activity, trainSession) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestCoachPostActivityTrain(activity, trainSession)
      .then((response) => dispatch(successPostingActivity(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function postActivityObjective(activity, userCurriculum) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestPostActivityObjective(activity, userCurriculum)
      .then((response) => dispatch(successPostingActivity(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function postActivityObjectives(rank_id, objective_id, users) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestPostActivityObjectives(rank_id, objective_id, users)
      .then((response) => dispatch(successPostingActivities(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function postActivityRank(activity, userCurriculum) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestPostActivityRank(activity, userCurriculum)
      .then((response) => dispatch(successPostingActivity(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}


export const postActivityAnnouncement = createAsyncThunk(
  'dashboard/postAnnouncement',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestPostActivityAnnouncement(data)
      return response
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export function postActivityGoal(activity) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestPostActivityGoal(activity)
      .then((response) => dispatch(successPostingActivity(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

// // API CALLS
export const requestGetProgramActivities = (courseId, page, perPage, type) => {
  const requestUrl = '/api/v1/courses/' + courseId + '/activities'
  const params = { page, perPage, type }

  return Axios.get(requestUrl, { params: serialize(params) })
}

function requestFamilyActivities(familyId, page, perPage, sinceTS) {
  const requestUrl = '/api/v1/families/' + familyId + '/activities'
  return Axios.get(requestUrl, {
    params: {
      page: page,
      per_page: perPage,
    },
  })
}

export function requestCommunityActivities(communityId, page, perPage, type) {
  const requestUrl = `/api/v1/communities/${communityId}/activities`
  return Axios.get(requestUrl, {
    params: {
      page: page,
      per_page: perPage,
      type: type,
    },
  })
}


function requestActivity(activityId) {
  const requestUrl = '/api/v1/activities/' + activityId
  return Axios.get(requestUrl)
}

function requestUpdateActivity(activity) {
  const requestUrl = '/api/v1/activities/' + activity.activityId

  const data = new FormData()

  if (activity.yardage === 'fullTee') {
    data.append('activity[yardage]', activity.fullTeeYardage)
    data.append('activity[is_full_tee]', true)
  } else {
    data.append('activity[yardage]', activity.yardage)
    data.append('activity[is_full_tee]', false)
  }

  data.append('activity[play_type_id]', activity.format)
  data.append('activity[user_id]', activity.userId)
  data.append('activity[score]', activity.score)
  data.append('activity[greens]', activity.greens || '')
  data.append('activity[putts]', activity.putts || '')
  data.append('activity[location]', activity.location)
  data.append('activity[holes]', activity.holes)
  data.append('activity[activity_date]', activity.activityDate)

  return Axios.put(requestUrl, data)
}

export function requestDeleteActivity(activity) {
  const requestUrl = '/api/v1/activities/' + activity.id
  return Axios.delete(requestUrl)
}

function requestUpdateTrainActivity(activity, trainSession) {
  const requestUrl = '/api/v1/activities/' + activity.activityId

  const data = new FormData()
  data.append('activity[user_id]', activity.userId)
  data.append('activity[activity_date]', activity.activityDate)
  // Iterate through each 'trainSession' and add it to our data

  data.append('activity[practice_sessions]', JSON.stringify(trainSession))

  return Axios.put(requestUrl, data)
}

function requestActivityLikes(activityId) {
  const requestUrl = '/api/v1/activities/' + activityId + '/likes'
  return Axios.get(requestUrl)
}

function requestActivityComments(activityId) {
  const requestUrl = '/api/v1/activities/' + activityId + '/comments'
  return Axios.get(requestUrl)
}

function requestPostActivityLike(activityId) {
  const requestUrl = '/api/v1/activities/' + activityId + '/like'
  return Axios.post(requestUrl)
}

function requestDeleteActivityLike(activityId) {
  const requestUrl = '/api/v1/activities/' + activityId + '/like'
  return Axios.delete(requestUrl)
}

function requestPostActivityComment(activityId, text) {
  const requestUrl = '/api/v1/comments'
  return Axios.post(requestUrl, {
    activity_id: activityId,
    text: text,
  })
}

function requestDeleteActivityComment(commentId) {
  const requestUrl = '/api/v1/comments/' + commentId
  return Axios.delete(requestUrl)
}

function requestPostActivitySocial(activity) {
  const requestUrl = '/api/v1/activity_social'

  const data = new FormData()
  data.append('activity[photo]', activity.uploadPhotoFile)
  data.append('activity[objective_id]', activity.objectiveId)
  data.append('activity[caption]', activity.description)

  return Axios.post(requestUrl, data)
}

function requestCoachPostActivitySocial(activity) {
  const requestUrl = '/api/v1/activity_social'

  const data = new FormData()
  data.append('activity[photo]', activity.uploadPhotoFile)
  data.append('activity[objective_id]', activity.objectiveId)
  data.append('activity[caption]', activity.description)

  return Axios.post(requestUrl, data)
}

function requestCoachPostActivityPlay(activity) {
  const requestUrl = '/api/v1/activity_play'

  const data = new FormData()
  // data.append("activity[street]", activity.location)

  if (activity.yardage === 'fullTee') {
    data.append('activity[yardage]', activity.fullTeeYardage)
    data.append('activity[is_full_tee]', true)
  } else {
    data.append('activity[yardage]', activity.yardage)
    data.append('activity[is_full_tee]', false)
  }

  if (!activity.activityDate) { activity.activityDate = new Date() }

  if (activity.shareScore) {
    data.append('activity[share_score]', true)
  }

  data.append('activity[user_id]', activity.userId)
  data.append('activity[play_type_id]', activity.format)
  data.append('activity[caption]', activity.description)
  data.append('activity[score]', activity.score)
  data.append('activity[greens]', activity.greens)
  data.append('activity[putts]', activity.putts)
  data.append('activity[location]', activity.location)
  data.append('activity[holes]', activity.holes)
  data.append('activity[activity_date]', activity.activityDate)

  return Axios.post(requestUrl, data)
}

function requestPostActivityPlay(activity) {
  const requestUrl = '/api/v1/activity_play'

  const data = new FormData()
  if (activity.yardageSelectedOption === 'fullTee') {
    data.append('activity[yardage]', activity.fullTeeYardage)
    data.append('activity[is_full_tee]', true)
  } else {
    data.append('activity[yardage]', activity.yardageSelectedOption)
    data.append('activity[is_full_tee]', false)
  }

  if (activity.shareScore) {
    data.append('activity[share_score]', true)
  }

  if (!activity.activityDate) { activity.activityDate = new Date() }

  // data.append("activity[street]", activity.location)
  data.append('activity[photo]', activity.uploadPhotoFile)
  data.append('activity[play_type_id]', activity.gameTypeSelectedOption)
  data.append('activity[caption]', activity.description)
  data.append('activity[score]', activity.score)
  data.append('activity[greens]', activity.greens)
  data.append('activity[putts]', activity.putts)
  data.append('activity[location]', activity.location)

  data.append('activity[holes]', activity.holesSelectedOption)

  return Axios.post(requestUrl, data)
}

function requestPostActivityTrain(activity) {
  const requestUrl = '/api/v1/activity_practice'

  const data = new FormData()
  data.append('activity[photo]', activity.uploadPhotoFile)
  data.append('activity[caption]', activity.description)

  if (!activity.activityDate) { activity.activityDate = new Date() }

  // Iterate through each 'trainSession' and add it to our data
  const trainSession = activity.trainSession
  let i = 0
  for (const key in trainSession) {
    if (trainSession.hasOwnProperty(key)) {
      const practiceTypeId = trainSession[key]['type']
      const duration = trainSession[key]['duration']

      data.append('activity[sessions][' + i + '][duration]', duration)
      data.append(
        'activity[sessions][' + i + '][practice_type_id]',
        practiceTypeId
      )
      i++
    }
  }

  return Axios.post(requestUrl, data)
}

function requestCoachPostActivityTrain(activity, trainSession) {
  const requestUrl = '/api/v1/activity_practice'

  const data = new FormData()

  if (!activity.activityDate) { activity.activityDate = new Date() }

  data.append('activity[user_id]', activity.userId)
  data.append('activity[caption]', activity.description)
  data.append('activity[activity_date]', activity.activityDate)

  // Iterate through each 'trainSession' and add it to our data
  let i = 0
  for (const key in trainSession) {
    if (trainSession.hasOwnProperty(key)) {
      const practiceTypeId = trainSession[key]['type']
      const duration = trainSession[key]['duration']
      if (duration > 0) {
        data.append('activity[sessions][' + i + '][duration]', duration)
        data.append(
          'activity[sessions][' + i + '][practice_type_id]',
          practiceTypeId
        )
        i++
      }
    }
  }

  return Axios.post(requestUrl, data)
}

function requestPostActivityObjective(activity, userCurriculum) {
  const requestUrl = '/api/v1/activity_objective'

  const data = new FormData()
  //data.append("activity[rank_id]", activity.currentEnrollment.current_rank_id)

  data.append('activity[rank_id]', userCurriculum.rank_id)
  data.append('activity[objective_id]', activity.issueTrophyFormObjectiveId)
  data.append('activity[user_id]', activity.issueTrophyFormUserId)

  return Axios.post(requestUrl, data)
}

function requestPostActivityObjectives(rank_id, objective_id, users) {
  const requestUrl = '/api/v1/activity_objectives'

  const data = new FormData()
  //data.append("activity[rank_id]", activity.currentEnrollment.current_rank_id)
  data.append('rank_id', rank_id)
  data.append('objective_id', objective_id)
  data.append('users', users)

  return Axios.post(requestUrl, data)
}

function requestPostActivityRank(activity, userCurriculum) {
  const requestUrl = '/api/v1/activity_rank'

  const data = new FormData()
  //data.append("activity[rank_id]", activity.currentEnrollment.current_rank_id);
  data.append('activity[rank_id]', userCurriculum.rank_id)
  data.append('activity[user_id]', userCurriculum.user_id)

  return Axios.post(requestUrl, data)
}


export function requestPostActivityAnnouncement(announcement) {
  const requestUrl = '/api/v1/activity_announcement'
  return Axios.post(requestUrl, announcement)
}

function requestPostActivityGoal(announcement) {
  const requestUrl = '/api/v1/activity_goal'

  const data = new FormData()
  data.append('activity[goal_id]', announcement.goalId)
  data.append('activity[user_id]', announcement.userId)

  return Axios.post(requestUrl, data)
}
