import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import './EventRow.scss'
import Avatar from '../../assets/images/mock/StockProfilePic.png'
import colors from '../../assets/styles/globals.scss'
import { ReactComponent as MedalistIcon } from '../../assets/images/common/icons/medalist.svg'

const EventRow = ({ event, timezone }) => {
  const navigate = useNavigate()

  const navigateTo = useCallback(
    () => {
      const { id } = event

      navigate(`/events/${id}`, { state: { eventId: id } })
    },
    [navigate, event]
  )

  const eventDateInTimezone = moment(event.eventDate).tz(timezone)

  return (
    <div
      id='EventRow'
      className='w-100'
      onClick={navigateTo}
      onKeyDown={null}
      role='button'
      tabIndex={0}
    >
      <div className='event-card'>
        <div className='py-2 col-1 date-col'>
          <span className='month-name'>{ eventDateInTimezone.format('MMM') }</span>
          <span className='day-number'>{ eventDateInTimezone.format('DD') }</span>
          <span className='day-name'>{ eventDateInTimezone.format('ddd') }</span>
        </div>
        <div className='vl'></div>
        <div className='col-6 d-flex flex-column justify-content-center name-col'>
          <div className='pt-2 pb-1'>{event.name}</div>
        </div>
        <div className='col-2 d-flex flex-column justify-content-center align-items-center leaderboard-col'>
          <div className='leaderboard-label'>
            <div className='pt-1 label-text'> View Leaderboard </div>
          </div>
          {event.passed_count > 0 && <div className='pt-1'>{event.passed_count} Student{event.passed_count > 1 && 's'} Passed</div>}
        </div>
        <div className='col-2 d-flex winner-col'>
          {event.winners?.length === 1 ? (
            <div className='d-flex event-winner align-items-center'>
              <div className='smaller-round-image-wrapper '>
                <img
                  src={event.winners[0]?.avatar?.file_url || Avatar}
                  className='round-image'
                  alt={event.winners[0].first_name}
                />
              </div>
              <div className='ml-2 d-flex flex-column'>
                {event.winners[0].first_name} {event.winners[0].last_name}
                <div className='d-flex align-items-center badge-text'>
                  <MedalistIcon fill={colors.darkTeal}/>
                  <span className='ml-2'>Medalist</span>
                </div>
              </div>
            </div>
          ) : (
            <div className='d-flex w-100 align-items-center badge-text'>
              <MedalistIcon width={32} height={32} fill={colors.darkTeal} className='mx-2'/>
              <span>{event.winners?.length || 'No'} Medalists</span>
            </div>
          )}
        </div>
        <div className='col-1 right-arrow-col'>
          <div><i className='fa fa-chevron-right'/></div>
        </div>
      </div>
    </div>
  )
}

EventRow.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    passed_count: PropTypes.number,
    winner: PropTypes.object,
    eventDate: PropTypes.instanceOf(Date),
    winners: PropTypes.array,
  }),
  timezone: PropTypes.string.isRequired,
}

export default EventRow
