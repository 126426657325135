import * as constants from "./actionTypes";
import * as common from "./commonActions";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import Axios from '../axios'

export function successRequestingGetCourse(response) {
  if (response.status === 200) {
    return { type: constants.GET_COURSE, course: response.data.course };
  } else {
    console.log("ERROR ON GETTING COURSE");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingCreateCourse(response, course) {
  if (response.status === 201) {
    return { type: constants.CREATE_COURSE, course: response.data.course };
  } else {
    console.log("ERROR ON CREATING COURSE");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingCommunityCourses(response) {
  if (response.status === 200) {
    return { type: constants.ADD_COURSES, courses: response.data.courses };
  } else {
    console.log("ERROR ON GETTING COURSES");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// THUNKS HERE

export function getCommunityCourses(community_id) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestCommunityCourses(community_id)
      .then(response => dispatch(successRequestingCommunityCourses(response)))
      .then(repsonse => dispatch(common.loadingChanged(false)));
  };
}

export function getCourse(course) {
  return function(dispatch) {
    dispatch(showLoading())
    return requestGetCourse(course)
      .then(response => {
        dispatch(successRequestingGetCourse(response))
        dispatch(hideLoading())
        return response.data
      })
  }
}

export function createCourseFromProgram(course_id, course) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true))
    return requestCreateCourseFromProgram(course_id, course)
      .then(response => {
        dispatch(successRequestingCreateCourse(response, course_id))
        dispatch(common.loadingChanged(false))
        return response.data
      })
  }
}

// API CALLS

function requestCreateCourseFromProgram(course_id, course) {
  let requestUrl = "/api/v1/courses/create_from_program";
  return Axios.post(requestUrl, {
    course_id: course_id,
    is_draft: course.is_draft
  });
}

function requestCommunityCourses(course_id) {
  let requestUrl = "/api/v1/communities/" + course_id + "/courses";
  return Axios.get(requestUrl);
}

function requestGetCourse(course_id, mode) {
  let requestUrl = '/api/v1/courses/' + course_id
  let params = { mode }

  return Axios.get(requestUrl, { params: params } )
}
