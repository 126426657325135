import PropTypes from 'prop-types'
import _ from 'lodash'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { DUAL_PANEL_SELECTABLE_TYPES } from '../../../constants/dual-panel-selector-constants'
import { fullTeeYardageByLevel, yardageByLevel } from '../../../utility/curriculumHelper'
import { categoryCompare } from '../../../utility/programHelper'
import { getStudentsDualPanelSelector } from '../../../actions/communitiesActions'
import { getProgramsDualPanelSelector } from '../../../actions/programActions'
import DualPanelSelector from '../../common/dual-panel-selector/DualPanelSelector'

import classes from './ManageRoster.module.scss'
import { ReactComponent as PersonIcon } from '../../../assets/images/common/icons/person-icon.svg'

const text = 'Quickly populate your event roster by selecting programs/packages, or by selecting individual students.'

const SummaryPlaceholder = () => (
  <div className={classes.placeholder}>
    <PersonIcon className={classes.icon} />
    <p className={classes.title}>No Students Added</p>
    <p className={classes.description}>
      Add your participants individually or by program from the menu on the left.
    </p>
  </div>
)

const PER_PAGE = 10

// eslint-disable-next-line max-lines-per-function
const ManageRoster = ({
  title,
  selectedStudents,
  getSelectedStudent,
  getSelectedProgram,
  addStudentCallback,
  deleteStudentCallback,
  addProgramCallback,
  deleteProgramCallback,
  updateStudentCallback,
  updateProgramCallback,
}) => {
  const communityId = useSelector(state => state.user.current_user.communities[0].id)
  const dispatch = useDispatch()
  const [studentsData, setStudentsData] = useState({ searchValue: '', students: null, pagination: { page: 0, is_last_page: false } })
  const [programsData, setProgramsData] = useState({ searchValue: '', programs: null, pagination: { page: 0, is_last_page: false } })

  const fetchPrograms = useCallback((page, searchTerm) => getProgramsDualPanelSelector({
    page,
    perPage: PER_PAGE,
    withPackages: true,
    searchTerm,
  })
    .then(data => setProgramsData(prevState => ({
      ...prevState,
      programs: page === 1 ? [...data.courses] : [...prevState.programs, ...data.courses],
      pagination: data.meta.pagination,
    })))
    .catch(() => toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT }))
  , [])

  const fetchStudents = useCallback((page, searchTerm) => getStudentsDualPanelSelector({
    page,
    perPage: PER_PAGE,
    communityId,
    searchTerm,
  })
    .then(data => setStudentsData(prevState => ({
      ...prevState,
      students: page === 1 ? [...data.students] : [...prevState.students, ...data.students],
      pagination: data.meta.pagination,
    })))
    .catch(() => toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT }))
  , [communityId])

  const addStudent = (std, course = null, pkg = null) => {
    const currentFormalDivision = std.currentFormalDivision || std.current_formal_division
    const studentData = {
      id: std.id,
      user_id: std.id,
      firstName: std.firstName || std.first_name,
      lastName: std.lastName || std.last_name,
      first_name: std.firstName || std.first_name,
      last_name: std.lastName || std.last_name,
      current_formal_division: currentFormalDivision,
      currentFormalDivision: currentFormalDivision,
      level: currentFormalDivision,
      stats: std.stats,
      yardage: yardageByLevel[currentFormalDivision],
      fullTeeYardage: fullTeeYardageByLevel[currentFormalDivision],
      avatar: std.avatar,
      gender: std.gender,
      birthdate: std.birthdate,
    }

    const index = selectedStudents.findIndex(s => s.id === std.id)
    if (index === -1) {
      if (course?.id) {
        if (pkg?.id) {
          dispatch(addStudentCallback({
            ...studentData,
            addedFrom: { [course.id]: { [pkg.id]: { packageName: pkg.name, courseName: course.name } } },
          }))
        } else {
          dispatch(addStudentCallback({ ...studentData, addedFrom: { [course.id]: course.name } }))
        }
      } else {
        dispatch(addStudentCallback({ ...studentData, addedFrom: { students: true } }))
      }
    } else {
      if (course?.id) {
        if (pkg?.id) {
          dispatch(updateStudentCallback({
            ...studentData,
            addedFrom: {
              ...selectedStudents[index].addedFrom,
              [course.id]: selectedStudents[index].addedFrom[course.id]
                ? { [pkg.id]: { packageName: pkg.name, courseName: course.name } }
                : {
                  ...selectedStudents[index].addedFrom[course.id],
                  [pkg.id]: { packageName: pkg.name, courseName: course.name },
                },
            },
          }))
        } else {
          dispatch(updateStudentCallback({ ...studentData, addedFrom: { ...selectedStudents[index].addedFrom, [course.id]: course.name } }))
        }
      } else {
        dispatch(updateStudentCallback({ ...studentData, addedFrom: { ...selectedStudents[index].addedFrom, students: true } }))
      }
    }
  }

  const deleteStudent = (std, course = null, packageId = null, isLastPackage) => {
    if (course?.id) {
      const index = selectedStudents.findIndex(s => s.id === std.id)
      if (index === -1) {
        return
      }
      const from = { ...selectedStudents[index].addedFrom }
      if (packageId) {
        const fromCourse = { ...from[course.id] }
        delete fromCourse[packageId]
        if (_.isEmpty(fromCourse)) {
          isLastPackage ? from[course.id] = course.name : delete from[course.id]
        }
      } else {
        delete from[course.id]
      }
      if (_.isEmpty(from)) {
        dispatch(deleteStudentCallback(std.id))
      } else {
        dispatch(updateStudentCallback({ ...selectedStudents[index], addedFrom: from }))
      }
    } else {
      dispatch(deleteStudentCallback(std.id))
    }
  }

  const handleSelectPackage = (pkg, course) => {
    const newPackages = course.selectedPackages ? [...course.selectedPackages, pkg] : [pkg]
    dispatch(updateProgramCallback({ ...course, selectedPackages: newPackages }))

    course.enrollments.map((e) => {
      const std = e.user

      if (e.packageId === pkg.id) {
        addStudent(std, course, pkg)
      } else if (!course.selectedPackages || course.selectedPackages.length === 0) {
        deleteStudent(std, course)
      }
    })
  }

  const handleDeletePackage = (pkg, course) => {
    const isLastPackage = course.selectedPackages.length === 1
    course.enrollments.map((e) => {
      const std = e.user
      if (e.packageId === pkg.id) {
        deleteStudent(std, course, pkg.id, isLastPackage)
      } else if (isLastPackage) {
        addStudent(std, course)
      }
    })

    const selectedPackages = [...course.selectedPackages]
    const index = selectedPackages.findIndex(p => p.id === pkg.id)
    selectedPackages.splice(index, 1)
    dispatch(updateProgramCallback({ ...course, selectedPackages: selectedPackages }))
  }

  const handleCheckProgram = (checked, course) => {
    if (checked) {
      course.enrollments.map((e) => {
        deleteStudent(e.user, course)
      })
      dispatch(deleteProgramCallback(course.id))
    } else {
      dispatch(addProgramCallback(course))
      course.enrollments.map((e) => {
        addStudent(e.user, course)
      })
    }
  }

  const handleCheckStudent = (checked, student) => {
    if (checked) {
      deleteStudent(student)
    } else {
      addStudent(student)
    }
  }

  const handleStudentsSearchChange = (searchValue) => setStudentsData(prevState => ({ ...prevState, searchValue }))

  const handleProgramsSearchChange = (searchValue) => setProgramsData(prevState => ({ ...prevState, searchValue }))

  const leftPanelProps = {
    tabTypes: [DUAL_PANEL_SELECTABLE_TYPES.programsWithPackages, DUAL_PANEL_SELECTABLE_TYPES.students],
    tabProps: {
      [DUAL_PANEL_SELECTABLE_TYPES.programsWithPackages]: {
        allItems: programsData.programs,
        searchValue: programsData.searchValue,
        onSearchValueChange: handleProgramsSearchChange,
        getSelectedItem: getSelectedProgram,
        fetchItems: fetchPrograms,
        pagination: programsData.pagination,
        onCheckChange: handleCheckProgram,
        onSelectChange: handleSelectPackage,
        onSelectDelete: handleDeletePackage,
        itemCategoryTitle: (program) => program.category ? program.category.name : 'Other Programs',
        itemCategoryCompare: categoryCompare,
      },
      [DUAL_PANEL_SELECTABLE_TYPES.students]: {
        allItems: studentsData.students,
        searchValue: studentsData.searchValue,
        onSearchValueChange: handleStudentsSearchChange,
        getSelectedItem: getSelectedStudent,
        fetchItems: fetchStudents,
        pagination: studentsData.pagination,
        onCheckChange: handleCheckStudent,
      },
    },
    withSwitcherInsteadOfTabs: true,
    switcherPageTitle: title,
    switcherPageInfoText: text,
  }

  const rightPanelProps = {
    selectedItems: selectedStudents,
    selectedItemType: DUAL_PANEL_SELECTABLE_TYPES.students,
    summaryTitle: `Roster Summary (${selectedStudents.length})`,
    summaryPlaceholder: <SummaryPlaceholder />,
    onRemove: (student) => handleCheckStudent(true, student),
  }

  return (
    <DualPanelSelector
      className='h-100'
      withSwitcherInsteadOfTabs
      {...leftPanelProps} {...rightPanelProps}
    />
  )
}

ManageRoster.propTypes = {
  title: PropTypes.string,
  selectedStudents: PropTypes.array.isRequired,
  getSelectedStudent: PropTypes.func.isRequired,
  getSelectedProgram: PropTypes.func.isRequired,
  addStudentCallback: PropTypes.func.isRequired,
  updateStudentCallback: PropTypes.func.isRequired,
  deleteStudentCallback: PropTypes.func.isRequired,
  addProgramCallback: PropTypes.func.isRequired,
  updateProgramCallback: PropTypes.func.isRequired,
  deleteProgramCallback: PropTypes.func.isRequired,
}

export default ManageRoster
