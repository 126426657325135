import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import { ReactComponent as ModalXIcon } from '../../../assets/images/common/icons/modal-X-icon.svg'

import './Goals.scss'
import PropTypes from 'prop-types'

const styles = {
  video: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
}

class CurriculumGoalView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isVideoVisible: true,
    }
  }

  renderButtonLinks = () => {
    if (this.props.objective.links.length > 0) {
      return this.props.objective.links.map(link => (
        <a
          key={link.id}
          className='btn btn-primary objective-btn-link'
          href={link.attachment_url}
          alt={link.name}
        >
          {link.name}
        </a>
      ))
    } else {
      return null
    }
  }

  openVideo = () => this.setState({ isVideoVisible: true })

  closeVideo = () => this.setState({ isVideoVisible: false })

  render() {
    return (
      <div className='container curriculum-container'>
        <div className='custom-goal-header'>
          <button
            className='mb-3 back-curriculms-btn'
            onClick={this.props.decrementPage}
          >
            <i
              className='fa fa-arrow-left back-curriculms-icon'
              aria-hidden='true'
            />
          </button>
          <div className='custom-goal-header-info-wrapper curriculum-wrapper'>
            <div className='custom-goal-header-wrapper curriculum-header-wrapper'>
              <div className='custom-goal-header-title-wrapper curriculum-title-wrapper'>
                <h4 className='custom-goal-header-title curriculum-header-title'>
                  {this.props.objective.name}
                </h4>
                <i
                  className='fa fa-trophy objective-view-points-icon'
                  aria-hidden='true'
                />
                <h3 className='objective-view-points'>
                  {this.props.objective.points} pts
                </h3>
              </div>
              <p className='custom-goal-description'>
                {this.props.objective.description}
              </p>
            </div>
          </div>
        </div>

        <div className='curriculum-video-detail-container'>
          {this.state.isVideoVisible && (
            <div className='detail-video-wrapper'>
              <ModalXIcon className='video-close-button' onClick={this.closeVideo} />
              <ReactPlayer
                url={this.props.objective.video.attachment_url}
                style={styles.video}
                width='100%'
                height='100%'
                controls
              />
            </div>
          )}
          <div className='objective-view-desc-wrapper'>
            {this.renderButtonLinks()}
            {!this.state.isVideoVisible && (
              <button
                onClick={this.openVideo}
                className='btn btn-primary objective-btn-link'
              >
                Open Video
              </button>
            )}
          </div>
        </div>
      </div>
    )
  }
}

CurriculumGoalView.propTypes = {
  objective: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    points: PropTypes.number,
    photo: PropTypes.object,
    video: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      attachable_type: PropTypes.string,
      attachment_url: PropTypes.string,
      file_url: PropTypes.string,
      small_file_url: PropTypes.string,
      large_file_url: PropTypes.string,
      original_file_url: PropTypes.string,
      attachable_id: PropTypes.number,
      created_at: PropTypes.string,
      updated_at: PropTypes.string,
    }),
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      attachable_type: PropTypes.string,
      attachment_url: PropTypes.string,
      file_url: PropTypes.string,
      small_file_url: PropTypes.string,
      large_file_url: PropTypes.string,
      original_file_url: PropTypes.string,
      attachable_id: PropTypes.number,
      created_at: PropTypes.string,
      updated_at: PropTypes.string,
    })),
  }),
  decrementPage: PropTypes.func,
  subject: PropTypes.object,
}

export default CurriculumGoalView
