import PropTypes from 'prop-types'
import CoachHeaderInfo from '../CoachHeaderInfo'
import ImageWithOverlay from '../ImageWithOverlay'
import classes from './ActivityAnnouncementModal.module.scss'
import { Modal } from 'reactstrap'
import classNames from 'classnames'
import Comment from '../Comment'
import Avatar from '../../common/avatar/avatar'
import { ReactComponent as CloseIcon } from '../../../assets/images/common/icons/modal-X-icon.svg'
import { ReactComponent as CommentIcon } from '../../../assets/images/common/icons/comment-icon.svg'
import { ReactComponent as Checkmark } from '../../../assets/images/common/icons/checkmark-icon.svg'
import { ReactComponent as CommentLarge } from '../../../assets/images/common/icons/comment-icon-large.svg'
import { useCallback, useEffect, useState } from 'react'
import { getWebActivityComments, getWebActivityLikes, postWebActivityComment } from '../../../actions/announcementsActions'
import { pluralize } from '../../../utility/helperFunctions'
import Like from '../Like'

const ActivityAnnouncementModal = ({ isOpen, onClose, activity, text }) => {
  const file_url = activity.photo?.large_file_url
  const video_url = activity.video?.attachment_url

  const [comments, setComments] = useState([])
  const [readBys, setReadBys] = useState([])
  const [commentsText, setCommentsText] = useState('')
  const [showComments, setShowComments] = useState(true)

  const fetchComments = useCallback(() => {
    getWebActivityComments(activity.id).then((response) => {
      setComments(response.data.comments.reverse())
    })
  }, [activity.id])

  const fetchReadBys = useCallback(() => {
    getWebActivityLikes(activity.id).then((response) => {
      setReadBys(response.data.likes)
    })
  }, [activity.id])

  useEffect(() => {
    fetchComments()
    fetchReadBys()
  }, [fetchComments, fetchReadBys])

  const renderComments = () => {
    if (comments && comments.length > 0) {
      return comments.map((comment) => (
        <Comment
          key={comment.id}
          user={comment.user}
          body={comment.text}
          web
          time={comment.created_at}
        />
      ))
    } else {
      return (
        <div className={classes.commentsPlaceholder}>
          <CommentLarge/>
          <span>No Comments to Show</span>
        </div>
      )
    }
  }

  const renderReadBys = () => {
    if (readBys && readBys.length > 0) {
      return readBys.map((readBy) => (
        <Like
          key={readBy.id}
          user={readBy.user}
          body={readBy.text}
          web
          time={readBy.created_at}
        />
      ))
    } else {
      return (
        <div className={classes.commentsPlaceholder}>
          <span>This comment has not been read</span>
        </div>
      )
    }
  }

  const handlePostComment = () => {
    postWebActivityComment(activity.id, commentsText).then(() => {
      fetchComments()
      setCommentsText('')
    })
  }

  return <Modal isOpen={isOpen} onClose={onClose} className={classes.modal} >
    <div className={classes.container}>
      <div className={classes.leftPanel}>
        <div className={classes.imageContainer}>
          <ImageWithOverlay alt={text} height={'90vh'} viewMore={() => {}} video={video_url} src={file_url} activity={activity}/>
        </div>
      </div>
      <div className={classes.rightContainer}>
        <div className={classes.closeButtonContainer}>
          <button className={classes.closeButton} onClick={onClose}>
            <CloseIcon className={classNames(classes.closeIcon)} aria-hidden='true' />
          </button>
        </div>
        <CoachHeaderInfo coach={activity.user} />
        <div className={classes.captionContainer}>
          <span className={classes.caption}>{activity.caption}</span>
        </div>
        <div className='claps-comments-wrapper mx-3'>
        </div>
        <div className={classes.commentsContainer}>
          <button className={classes.readBy} onClick={() => setShowComments(false)}>
            <Checkmark />
            <span className={classes.readByText}>{`Read by ${activity.like_count || 0}`}</span>
          </button>
          <button className={classes.comments} onClick={() => setShowComments(true)}>
            <CommentIcon />
            <span className={classes.commentsText}>{`${activity.comment_count} ${pluralize('Comment', activity.comment_count)}`}</span>
          </button>
        </div>
        <div className={classes.commentSection}>
          {showComments ? renderComments() : renderReadBys()}
        </div>
        <div className={classes.postCommentWrapper}>
          <Avatar
            imageUrl={activity.user?.avatar?.file_url}
            hasBorder
            altName={activity.user.first_name + ' ' + activity.user?.last_name}
          />
          <input
            type='text'
            value={commentsText}
            onChange={(e) => setCommentsText(e.target.value)}
            name='Comment Form'
            className='form-control'
            placeholder={'Write a comment...'}
          />
          <button className='btn btn-link' onClick={handlePostComment}>
            <i className='fa fa-paper-plane' />
          </button>
        </div>
      </div>
    </div>
  </Modal>
}


ActivityAnnouncementModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  activity: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
}

export default ActivityAnnouncementModal
