import * as types from './actionTypes'
import * as common from './commonActions'
import Axios from '../axios'

export function successRequestingAnnouncements(response) {
  if (response.status === 200) {
    return {
      type: types.ADD_ANNOUNCEMENTS,
      announcements: response.data.announcements,
      pages: response.data.meta.pagination?.total_pages
        ? response.data.meta.pagination.total_pages
        : null,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingPostAnnouncement(response) {
  if (response.status === 201) {
    return {
      type: types.POST_ANNOUNCEMENT,
      announcement: response.data.announcement,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function getAnnouncements(page, per_page) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestAnnouncements(page, per_page)
      .then((response) => dispatch(successRequestingAnnouncements(response)))
      .then((_response) => dispatch(common.loadingChanged(false)))
  }
}

export function postAnnouncement(announcement) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestPostAnnouncement(announcement)
      .then((res) => {
        dispatch(successRequestingPostAnnouncement(res))
        return res.data.announcement
      })
      .then((announcement) => {
        dispatch(common.loadingChanged(false))
        return announcement
      })
  }
}

export function postWebEmail(announcement) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestPostWebEmail(announcement)
      .then((res) => {
        dispatch(successRequestingPostAnnouncement(res))
        return res.data.announcement
      })
      .then((announcement) => {
        dispatch(common.loadingChanged(false))
        return announcement
      })
  }
}

export function requestAnnouncements(page, per_page) {
  let requestUrl = '/api/v1/announcements'
  if (page && per_page) {
    requestUrl += `?page=${page}&per_page=${per_page}`
  }
  return Axios.get(requestUrl)
}

function requestPostAnnouncement(announcement) {
  const requestUrl = '/api/v1/announcements'

  const recipients = []
  const courses = announcement.courses
  for (let i = 0; i < courses.length; i++) {
    const course = courses[i]
    recipients.push(course.value)
  }

  const data = {
    send_email: announcement.sendEmail,
    subject: announcement.subject,
    activity: {
      message: announcement.message,
    },
    course_ids: recipients,
    recipients: [],
  }

  return Axios.post(requestUrl, data)
}

export function requestPostWebEmail(announcement) {
  const requestUrl = '/api/v1/announcements'
  return Axios.post(requestUrl, announcement)
}

export function requestPostWebActivityAnnouncement(announcement) {
  const requestUrl = '/api/v1/activity_announcement'
  return Axios.post(requestUrl, announcement)
}

export function requestPresignedUrl(file) {
  const requestUrl = '/api/v1/aws_videos'
  return Axios.post(requestUrl, { file_name: file.name, content_type: file.type })
}

export function getWebActivityComments(activityId) {
  const requestUrl = `/api/v1/activities/${activityId}/comments`
  return Axios.get(requestUrl)
}

export function postWebActivityComment(activityId, comment) {
  const requestUrl = '/api/v1/comments'
  return Axios.post(requestUrl, { text: comment, activity_id: activityId })
}

export function getWebActivityLikes(activityId) {
  const requestUrl = `/api/v1/activities/${activityId}/likes`
  return Axios.get(requestUrl)
}
