import PropTypes from 'prop-types'
import { formatDayTime } from '../../../../utility/datesHelper'
import { useSelector } from 'react-redux'
import classes from './EventCard.module.scss'
import programClasses from './ProgramCard.module.scss'
import { ReactComponent as GolferIcon } from '../../../../assets/images/common/icons/golfer-half-icon.svg'

const EventCard = ({ item: item }) => {

  const communityTimezone = useSelector(state => state.user.current_user.communities[0].timezone)

  const itemColor = item.category?.color || item.course_category?.color || '#E0BBE4'
  return (
    <div className={classes.wrapper} style={{ borderLeftColor: itemColor }}>
      <div className={classes.titleContainer}>
        <span className={classes.title}>{item.title || item.name}</span>
        <span className={classes.subtitle}>{item.subtitle || item.meetingTimes || formatDayTime(item.startDate, communityTimezone)}</span>
      </div>
      <div className={classes.golferContainer}>
        <GolferIcon className={programClasses.icon}/>
        <span className={classes.students}>{item.students?.length || item.enrollments?.length}</span>
      </div>
    </div>)

}

EventCard.propTypes = {
  // ...ItemCardProps,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    name: PropTypes.string,
    startDate: PropTypes.string.isRequired,
    enrollments: PropTypes.array,
    students: PropTypes.array,
    subtitle: PropTypes.string,
    meetingTimes: PropTypes.string,
    course_category: {
      color: PropTypes.string,
    },
    category: {
      color: PropTypes.string,
    },
  }).isRequired,
}

export default EventCard
