import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { withMaterials } from '../../../../reducers/programRegistrationReducer'
import QRCode from 'qrcode.react'
import { Col, Container, Row } from 'reactstrap'
import { getTitle } from '../../../../utility/materials'
import ButtonLink from '../../../common/buttons/ButtonLink'

import './RegistrationCompletedFooter.scss'
import AppMock from '../../../../assets/images/mock/successful-registration-phone-details.webp'
import AppBackgroundMock from '../../../../assets/images/mock/successful-registration-background-details.webp'

const RegistrationCompletedFooter = ({ isEmbedded }) => {
  const navigate = useNavigate()
  const hasMaterials = useSelector(withMaterials)
  const acceptedMaterials = Object.values(useSelector(state => state.programRegistration.acceptedMaterials))

  const navigateToDashboard = useCallback((e) => {
    e.preventDefault()
    navigate('/dashboard')
  }, [navigate])

  const linkAttributes = useMemo(() => ({
    linkMessage: hasMaterials ? `Get your ${getTitle(acceptedMaterials[0])}!!!`
      : isEmbedded ? 'Go to Dashboard' : 'Go to the App!',
    linkHref: hasMaterials ? acceptedMaterials[0].url
      : isEmbedded ? '/dashboard' : 'https://qrco.de/bbQ6EO',
    isExternal: hasMaterials ? true
      : !isEmbedded,
    target: hasMaterials ? '_blank'
      : '_self',
    onClick: isEmbedded && !hasMaterials ? navigateToDashboard : null,
  }), [acceptedMaterials, hasMaterials, isEmbedded, navigateToDashboard])

  return (
    <Container
      id='RegistrationCompletedFooter'
      fluid
      className={classNames({ 'd-md-none': !hasMaterials, doubleActionButtons: hasMaterials || !isEmbedded })}
    >
      <Row className='h-100 justify-content-center align-items-center content-wrapper'>
        <Col className='d-block p-2 d-md-none'>
          <ButtonLink
            noTopMargin
            navigateTo={linkAttributes.linkHref}
            onClick={linkAttributes.onClick}
            isExternal={linkAttributes.isExternal}
            target={linkAttributes.target}
          >
            {linkAttributes.linkMessage}
          </ButtonLink>

          {(hasMaterials || !isEmbedded) && (
            <ButtonLink navigateTo={'/dashboard'} linkColor='dark-navy' className='w-100' >
              Go to Dashboard
            </ButtonLink>
          )}
        </Col>
        {hasMaterials && (
          <>
            <Col md={8} className='pl-5 d-none d-md-block py-3'>
              <h4>Scan the QR Code and download the mobile app:</h4>
            </Col>
            <Col md={4} className='h-100 d-none d-md-flex d-md-none position-relative justify-content-center'>
              <div className='d-flex flex-column align-items-center app-details-wrapper'>
                <div className='d-flex align-items-center'>
                  <img className='app-mock-background-img' src={AppBackgroundMock} alt='background details' />
                  <img className='app-mock-img' src={AppMock} alt='phone details' />
                </div>
              </div>
              <div className='qr-code-wrapper'>
                <QRCode style={{ height: '100%', width: '100%' }} value='https://qrco.de/bbQ6EO' />
              </div>
            </Col>
          </>
        )}
      </Row>
    </Container>
  )
}

RegistrationCompletedFooter.propTypes = {
  isEmbedded: PropTypes.bool,
}

export default RegistrationCompletedFooter
