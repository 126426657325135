import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UncontrolledPopover } from 'reactstrap'
import PropTypes from 'prop-types'

import { clearNotifications, getUnreadNotifications } from '../../../../../actions/notificationActions'
import { ReactComponent as GolferIcon } from '../../../../../assets/images/common/icons/golfer-half-icon.svg'
import { ReactComponent as GeneralNotificationIcon } from '../../../../../assets/images/common/icons/bell.svg'
import StockProfilePic from '../../../../../assets/images/mock/default-profile-icon.png'
import NotificationsPopover from '../../../../common/NotificationsPopover'

import classes from './SessionCard.module.scss'

const SessionCard = ({ session }) => {
  const dispatch = useDispatch()
  const notifications = useSelector(state => state.notifications)
  const currentUser = useSelector(state => state.user.current_user)
  const [notificationsLoader, setNotificationsLoader] = useState(false)
  const enrollmentNotifications = notifications.filter(
    (n) => n.type === 'NotificationApply' && n.enrollment.course_id === session.programId && n.read === false
  )

  const handleClearNotifications = () => {
    setNotificationsLoader(true)
    dispatch(clearNotifications(currentUser.id)).then(() => {
      setNotificationsLoader(false)
    })
  }

  const refreshNotifications = () => {
    setNotificationsLoader(true)
    dispatch(getUnreadNotifications(currentUser.id)).then(() => {
      setNotificationsLoader(false)
    })
  }

  return (
    <div
      className={classes.sessionCard}
      style={{ borderLeftColor: session.categoryColor || '#ff6633' }}
    >
      <div className={classes.header}>
        <p className={classes.title}>{session.programTitle}</p>
        {enrollmentNotifications.length > 0 &&
          <>
            <button
              className={classes.notificationButton}
              id={`ProgramNotificationsButton-${session.sessionId}`}
              onClick={(e) => e.stopPropagation()}
            >
              <GeneralNotificationIcon className={classes.notificationIcon} />
              <div className={classes.badge} />
            </button>
            <UncontrolledPopover
              placement='bottom'
              target={`ProgramNotificationsButton-${session.sessionId}`}
              trigger='legacy'
            >
              <NotificationsPopover
                notifications={enrollmentNotifications}
                refreshNotifications={refreshNotifications}
                clearNotifications={handleClearNotifications}
                loader={notificationsLoader}
              />
            </UncontrolledPopover>
          </>
        }
      </div>
      <p className={classes.subtitle}>{session.sessionTitle}</p>

      <div>
        <i className={`fa fa-clock-o ${classes.faIcon}`}/>
        <span className={classes.text}>
          {`${session.sessionStartDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toLowerCase()} -
          ${session.sessionEndDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toLowerCase()}`}
        </span>
      </div>

      {
        session.location &&
        <div>
          <i className={`fa fa-map-marker ${classes.faIcon}`}/><span className={classes.text}>{session.location}</span>
        </div>
      }

      <div className={classes.enrollments}>
        <GolferIcon className={classes.icon}/>
        <span className={classes.textStudents}>{session.enrollments} Students</span>
      </div>

      <div className={classes.studentsProfiles}>
        {session.enrollmentsProfiles.slice(0, 4).map((url, index) =>
          <div
            className={classes.profileImg}
            key={index}
            style={{
              backgroundImage: url
                ? `url(${url})`
                : `url(${StockProfilePic})`,
            }}
          />
        )}
        {session.enrollmentsProfiles.length > 4 && <div className={classes.moreProfiles}>+ {session.enrollmentsProfiles.length - 4} more</div>}
      </div>

    </div>
  )
}

SessionCard.propTypes = {
  session: PropTypes.shape({
    programTitle: PropTypes.string,
    sessionTitle: PropTypes.string,
    enrollmentsProfiles: PropTypes.array,
    enrollments: PropTypes.number,
    location: PropTypes.string,
    sessionStartDate: PropTypes.object,
    sessionEndDate: PropTypes.object,
    programId: PropTypes.number,
    sessionId: PropTypes.number,
    categoryColor: PropTypes.string,
  }),
}

export default SessionCard
