import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import PropTypes from 'prop-types'
import { useState } from 'react'
import classnames from 'classnames'
import Selector, { TabProps } from './Selector'
import StateSwitcher from '../StateSwitcher'
import classes from './DualPanelSelector.module.scss'
import { DUAL_PANEL_SELECTABLE_TYPES } from '../../../constants/dual-panel-selector-constants'
import EventCard from './items/EventCard'
import ItemCard from './items/ItemCard'

const SWITCHER_OPTIONS = {
  [DUAL_PANEL_SELECTABLE_TYPES.students]: 'Select Students',
  [DUAL_PANEL_SELECTABLE_TYPES.programs]: 'Select Programs',
  [DUAL_PANEL_SELECTABLE_TYPES.programsWithPackages]: 'Select Programs',
  [DUAL_PANEL_SELECTABLE_TYPES.series]: 'Select Events',
}

const TABS = {
  [DUAL_PANEL_SELECTABLE_TYPES.students]: 'All Students',
  [DUAL_PANEL_SELECTABLE_TYPES.programs]: 'Programs',
  [DUAL_PANEL_SELECTABLE_TYPES.programsWithPackages]: 'Programs',
  [DUAL_PANEL_SELECTABLE_TYPES.series]: '9 Hole Events',
}

const DualPanelSelector = ({
  tabTypes,
  tabProps,
  withSwitcherInsteadOfTabs,
  switcherPageTitle,
  switcherPageInfoText,
  selectedItems,
  selectedItemType,
  summaryTitle = `Summary (${selectedItems.length})`,
  summaryPlaceholder,
  onRemove,
  isAnnouncement,
  className,
}) => {
  const [switcherSelectedValue, setSwitcherSelectedValue] = useState(tabTypes[0])

  return (
    <div className={classnames('d-flex', className)}>
      {withSwitcherInsteadOfTabs ? (
        <div className={classnames('w-50 d-flex flex-column', classes.panel)}>
          {!!switcherPageTitle && <h3 className={classnames('mb-3', classes.title)}>{switcherPageTitle}</h3>}
          <StateSwitcher
            options={tabTypes.map(t => ({ type: t, text: SWITCHER_OPTIONS[t] }))}
            selectedType={switcherSelectedValue}
            initialTypeSelected={tabTypes[0]}
            stateSwitcher={setSwitcherSelectedValue}
            position='left'
            rounded
            color='orange'
          />
          {!!switcherPageInfoText && <p>{switcherPageInfoText}</p>}
          <Selector
            className='flex-grow-1 '
            type={switcherSelectedValue}
            infoText={switcherPageInfoText}
            {...tabProps[switcherSelectedValue]}
          />
        </div>
      ) : (
        <Tabs
          className={classnames('w-50 h-100 pt-0')}
          selectedTabClassName={classes.activeTab}
          selectedTabPanelClassName={classnames(classes.panel, classes.activeTabPanel, { [classes.bordered]: !withSwitcherInsteadOfTabs })}
        >
          <TabList className={classes.tabList}>
            {tabTypes.map((type, index) => (
              <Tab key={index} className={classes.tab}>{TABS[type]}</Tab>
            ))}
          </TabList>

          {tabTypes.map((type, index) => (
            <TabPanel key={index}>
              <Selector className='h-100' type={type} {...tabProps[type]} />
            </TabPanel>
          ))}
        </Tabs>
      )}
      <div className={classnames(
        'w-50 d-flex flex-column',
        classes.panel,
        classes.right,
        {
          [classes.withoutSwitcher]: !withSwitcherInsteadOfTabs,
          [classes.bordered]: !withSwitcherInsteadOfTabs,
        }
      )}>
        <h3 className={classes.title}>{summaryTitle}</h3>

        {selectedItems.length > 0 ? selectedItems.map((i, index) => isAnnouncement ? (
          <EventCard
            className='mb-3'
            key={index}
            type={selectedItemType}
            item={i}
            onRemove={onRemove}
          />
        ) : (
          <ItemCard
            className='mb-3'
            key={index}
            type={selectedItemType}
            item={i}
            onRemove={onRemove}
          />
        )
        ) : summaryPlaceholder}
      </div>
    </div>
  )
}

DualPanelSelector.propTypes = {
  // Left panel
  tabTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DUAL_PANEL_SELECTABLE_TYPES))).isRequired,
  tabProps: PropTypes.objectOf(PropTypes.shape({
    ...TabProps,
  })).isRequired,
  withSwitcherInsteadOfTabs: PropTypes.bool,
  switcherPageTitle: PropTypes.string,
  switcherPageInfoText: PropTypes.string,
  // Right panel
  selectedItems: PropTypes.array.isRequired,
  selectedItemType: PropTypes.oneOf(Object.values(DUAL_PANEL_SELECTABLE_TYPES)).isRequired,
  summaryTitle: PropTypes.string,
  summaryPlaceholder: PropTypes.node.isRequired,
  onRemove: PropTypes.func,
  className: PropTypes.string,
  isAnnouncement: PropTypes.bool,
}

export default DualPanelSelector
