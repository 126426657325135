import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { createAuthFamilyMember } from '../../../actions/authActions'
import { toast } from 'react-toastify'

import NewMemberAboutData from './Steps/NewMemberAboutData'
import NewMemberLogInData from './Steps/NewMemberLogInData'
import ChildSocialSettings from './Steps/ChildSocialSettings'

import classes from './AddFamilyMember.module.scss'

const AddFamilyMember = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const step = searchParams.get('step')
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    if (step < '1' || step > '3') {
      navigate('/sign-up/new-member?step=1', { replace: true })
    }
  }, [dispatch, step, navigate])

  const handleCreateUser = (user) => {
    setLoader(true)

    dispatch(createAuthFamilyMember(user)).unwrap()
      .then(() => navigate('/sign-up/summary', { replace: true }))
      .catch(() => toast.error('Something went wrong. Please check all steps for errors and fix them.', { position: toast.POSITION.TOP_RIGHT }))
      .finally(() => setLoader(false))
  }

  return (
    <aside className={classNames(classes.mainCard, 'd-flex align-self-center m-auto')}>
      {
        step === '1' && <NewMemberAboutData /> ||
        step === '2' && <NewMemberLogInData loader={loader} onCreateUser={handleCreateUser}/> ||
        step === '3' && <ChildSocialSettings loader={loader} onCreateUser={handleCreateUser}/>
      }
    </aside>
  )
}

export default AddFamilyMember
