import { useSelector } from 'react-redux'
import ActivityFeed from './ActivityFeed'
import PropTypes from 'prop-types'
import { getCommunityActivities, getProgramActivities } from '../../../../actions/activityActions'
import { clearCommunityActivities, clearProgramActivities, removeCommunityActivity, removeProgramActivity } from '../../../../reducers/dashboardReducer'


const GenericFeedComponent = ({ id, type }) => {

  const activitySelectors = useSelector(state => ({
    feedActivities: type === 'community' ? state.dashboard.communityActivities : state.dashboard.programActivities,
    loadingFeedActivities: type === 'community' ? state.dashboard.loadingCommunityActivities : state.dashboard.loadingProgramActivities,
    activitiesTotalPages: type === 'community' ? state.dashboard.communityActivitiesTotalPages : state.dashboard.programActivitiesTotalPages,
    clearActivities: type === 'community' ? clearCommunityActivities : clearProgramActivities,
    getActivities: type === 'community' ? getCommunityActivities : getProgramActivities,
    removeActivity: type === 'community' ? removeCommunityActivity : removeProgramActivity,
  }))


  return <ActivityFeed id={id} {...activitySelectors} isProgram={type === 'program'}/>
}

GenericFeedComponent.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string,
}

export default GenericFeedComponent
