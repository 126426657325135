import ReactTooltip from 'react-tooltip'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import moment from 'moment'
import CoachDashboardScheduleSessionCard from './CoachDashboardScheduleSessionCard'
import EmptySchedule from './EmptySchedule'
import CoachDashboardScheduleEventCard from './CoachDashboardScheduleEventCard'
import { getScheduleSessionsByMonth } from '../../../actions/scheduleSessionActions'
import { getEventsByMonth } from '../../../actions/eventActions'
import { selectedDateEvents, selectedDateSessions } from '../../../reducers/dashboardReducer'
import { ReactComponent as InfoIcon } from '../../../assets/images/common/icons/info-circle-icon-dark.svg'
import styles from './CoachDashboardSchedule.module.scss'

const CoachDashboardSchedule = ({ communityId }) => {
  const { sessionsByMonth, selectedDate, eventsByMonth, selectedMonthDate, activeCourses } = useSelector(state => state.dashboard)
  const events = useSelector(selectedDateEvents)
  const sessions = useSelector(selectedDateSessions)
  const dispatch = useDispatch()
  const noPrograms = activeCourses?.length === 0 && events.length === 0

  useEffect(() => {
    !sessionsByMonth[selectedMonthDate.getMonth()] && dispatch(getScheduleSessionsByMonth({ selectedMonthDate }))
    !eventsByMonth[selectedMonthDate.getMonth()] && dispatch(getEventsByMonth({ selectedMonthDate }))
  }, [communityId, selectedMonthDate, dispatch, eventsByMonth, sessionsByMonth])


  const getSchduleTitle = () => {
    if (new Date().toDateString() === selectedDate.toDateString()){
      return 'Today\'s Schedule'
    } else {
      return `${moment(selectedDate).format('MMMM Do')} Schedule`
    }
  }

  return (
    <div className={styles.coachDashboardSchedule}>
      <div className={styles.scheduleTitle}>
        <p className={styles.scheduleTitleText}>{getSchduleTitle()}</p>
        <InfoIcon data-for='infoTooltip' data-tip/>
        <ReactTooltip id='infoTooltip'
          effect='solid'
          delayUpdate={5}
          delayHide={5}
          place={'top'}
          className={styles.infoTooltip}
        ><p>Browse your calendar to see upcoming Programs and 9 Hole Events</p></ReactTooltip>
      </div>
      {noPrograms ?
        <EmptySchedule
          title='No Active Programs'
          text='Create a Program or a 9 Hole Event Series and Publish it to your students!'
          noActivePrograms={noPrograms}
        />
        :
        sessions.length === 0 && events.length === 0 ? (
          <EmptySchedule
            title='No Programs Today'
            text='Enjoy your time off, or explore other days in your calendar.'
          />
        ) : (
          <>
            {sessions.map((session, index) =>
              <CoachDashboardScheduleSessionCard key={index} session={session}/>
            )}
            {events.map((e, index) =>
              <CoachDashboardScheduleEventCard key={index} event={e}/>
            )}
          </>
        )
      }
    </div>
  )
}

CoachDashboardSchedule.propTypes = {
  noPrograms: PropTypes.bool,
  sessions: PropTypes.array,
  communityId: PropTypes.number,
}

export default CoachDashboardSchedule
