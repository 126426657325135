import React from 'react'
import moment from 'moment'
import Loader from '../../common/Op36Loader-web'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { withRouting } from '../../common/hooks'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as coachActions from '../../../actions/coachActions'
import * as userActions from '../../../actions/userActions'
import * as reportsActions from '../../../actions/reportsActions'
import * as coachOnboardingActions from '../../../actions/coachOnboardingActions'
import { selectCompleteItemsCount, selectTotalItemsCount, setIsModalOpen } from '../../../reducers/coachOnboardingReducer'

import SubmitButton from '../../../components/common/buttons/SubmitButton'
import LoadingSpinner from '../../common/LoadingSpinner'

// ASSETS
import './CoachStats.scss'

const getColor = (value) => {
  switch (value) {
    case 0:
      return '#ed213a'
    case 1:
      return '#fddb10'
    case 2:
      return '#33d9b2'
    default:
      return ''
  }
}

class CoachStats extends React.Component {
  state = {
    coachId: this.props.coach.coach.id,
    status: 'static',
    savedPhone: this.props.coach.coach.phone
      ? this.props.coach.coach.phone
      : null,
    savedEmail: this.props.coach.coach.email
      ? this.props.coach.coach.email
      : null,
    email: this.props.coach.coach.email ? this.props.coach.coach.email : null,
    phone: this.props.coach.coach.phone ? this.props.coach.coach.phone : null,
    contactLoader: false,
  }

  componentDidUpdate(prev) {
    const { coachOnboarding: { onboardingStatusUpdateError } } = this.props

    if (onboardingStatusUpdateError && onboardingStatusUpdateError !== prev.onboardingStatusUpdateError) {
      toast.error('Something went wrong. Could not update the onboarding status, please try again.', { toastId: 'onboarding-status-update' })
    }
  }

  render() {
    const { coach, makeLeadCoach } = this.props
    const { contactLoader } = this.state
    return (
      <div id='CoachStats'>
        <div className='coach-contact-container'>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <h4>Contact Info</h4>
            <span
              role='button'
              tabIndex={0}
              onClick={this._toggleEdit}
              onKeyDown={(e) => e.key === 'Enter' && this._toggleEdit}
              id='contact-info-edit'
            >
              {this.state.status === 'static' ? 'Edit' : 'Close'}
            </span>
          </div>

          {this._renderContactInfo(coach, contactLoader)}
        </div>
        {this._renderOnboardingDetails()}
        {!coach.coach.is_lead_coach && (
          <button
            className='lead-coach-button'
            onClick={() => makeLeadCoach(coach.coach.id)}
          >
            -Make Lead Coach-
          </button>
        )}
        <div className='row'>
          <div className='col-6'>
            <h2
              style={{
                color: getColor(coach.completed_express_training.score.value),
              }}
            >
              {coach.completed_express_training.value}
            </h2>
            <p>Completed Old Express Training</p>
          </div>
          <div className='col-6'>
            <h2
              style={{
                color: getColor(
                  coach.completed_updated_express_training.score.value
                ),
              }}
            >
              {coach.completed_updated_express_training.value}
            </h2>
            <p>Completed Updated Express Training</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <h2
              style={{
                color: getColor(coach.events_count.score.value),
              }}
            >
              {coach.events_count.value}
            </h2>
            <p>Events Created</p>
          </div>
          <div className='col-6'>
            <h2
              style={{
                color: getColor(coach.programs_count.score.value),
              }}
            >
              {coach.programs_count.value}
            </h2>
            <p>Classes Created</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <h2
              style={{
                color: getColor(coach.likes_count.score.value),
              }}
            >
              {coach.likes_count.value}
            </h2>
            <p>Likes Given</p>
          </div>
          <div className='col-6'>
            <h2
              style={{
                color: getColor(coach.comments_count.score.value),
              }}
            >
              {coach.comments_count.value}
            </h2>
            <p>Comments Created</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <h2
              style={{
                color: getColor(coach.private_goal_count.score.value),
              }}
            >
              {coach.private_goal_count.value}
            </h2>
            <p>Private Goals Issued</p>
          </div>
          <div className='col-6'>
            <h2
              style={{
                color: getColor(coach.announcements_count.score.value),
              }}
            >
              {coach.announcements_count.value}
            </h2>
            <p>Announcements Created</p>
          </div>
        </div>
      </div>
    )
  }

  _handleInputChange = (event) => {
    const { name, value } = event.target
    this.setState({
      [name]: value ? value : null,
    })
  }

  _toggleEdit = () => {
    if (this.state.status === 'static') {
      this.setState({
        status: 'edit',
      })
    } else {
      this.setState({
        status: 'static',
      })
    }
  }

  _renderContactInfo = (coach, loader) => {
    if (loader) {
      return <Loader message='saving' />
    }
    if (this.state.status === 'static') {
      return (
        <div>
          <p>
            <span>Email:</span>{' '}
            {this.state.savedEmail
              ? this.state.savedEmail
              : 'No E-Mail Provided'}
          </p>
          <p>
            <span>Phone:</span>{' '}
            {this.state.savedPhone
              ? this.state.savedPhone
              : 'No Phone Number Provided'}
          </p>
        </div>
      )
    } else {
      return (
        <form>
          <table>
            <tbody>
              <tr>
                <td className='input-label'>Email: </td>
                <td>
                  <input
                    type='email'
                    name='email'
                    value={this.state.email ? this.state.email : ''}
                    placeholder='-none-'
                    onChange={this._handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td className='input-label'>Phone: </td>
                <td>
                  <input
                    type='text'
                    name='phone'
                    value={this.state.phone ? this.state.phone : ''}
                    placeholder='-none-'
                    onChange={this._handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <button
            onClick={this._handleSave}
            onKeyDown={(e) => e.key === 'Enter' && this._handleSave}
            id='form-save'
            className='d-flex justify-content-center'
          >
            Save
          </button>
        </form>
      )
    }
  }

  _handleSave = () => {
    if (
      this.state.email === this.state.savedEmail &&
      this.state.phone === this.state.savedPhone
    ) {
      this.setState({ status: 'static' })
    } else {
      this.setState({ contactLoader: true })
      this._updateCoach(this.state.coachId)
    }
  }

  _updateCoach = async (id) => {
    const user = { id: id, phone: this.state.phone, email: this.state.email }
    this.props.user_actions.updateUser(user).then((_res) =>
      this.setState({
        status: 'static',
        contactLoader: false,
        savedPhone: this.state.phone,
        savedEmail: this.state.email,
      })
    )
  }

  _renderOnboardingDetails = () => {
    const {
      completedSteps,
      totalSteps,
      coach,
      setIsOnboardingStepsModalVisible,
      coachOnboarding: { onboardingSteps, isOnboardingStepsLoading },
    } = this.props

    const onboardingStatus = _.capitalize(coach.coach.coach_onboarding_status)
    const lastModifiedDate = moment(coach.coach.coach_onboarding_last_modified).format('L')
    const lastModifiedRelativeTime = moment(coach.coach.coach_onboarding_last_modified).fromNow()
    const lastModified = `${lastModifiedDate}, ${lastModifiedRelativeTime}`

    return (
      <div className='coach-onboarding-details'>
        <div className='d-flex justify-content-between mb-3'>
          <h6 className='onboarding-section-title'>Onboarding Status</h6>
          <button
            onClick={() => setIsOnboardingStepsModalVisible(true)}
            className='onboarding-details-button'
            disabled={isOnboardingStepsLoading || onboardingSteps.length === 0}
          >
            View Details
            {isOnboardingStepsLoading && (
              <LoadingSpinner classNames={{ wrapper: 'mt-0', spinner: 'fa-lg' }} />
            )}
          </button>
        </div>
        <div className='d-flex align-items-center justify-content-between'>
          <p className='onboarding-status-text'>{onboardingStatus} {lastModified}</p>
          <p className='total-count'><span className='completed-count'>{completedSteps}</span>/{totalSteps} Complete</p>
        </div>
        {this._renderOnboardingButton()}
      </div>
    )
  }

  _updateOnboardingStatus = (actionType) => {
    const {
      coach,
      coach_onboarding_actions: { updateOnboardingStatusForAdmin },
      params,
    } = this.props

    updateOnboardingStatusForAdmin({ userId: coach.coach.id, actionType, communityId: params.communityId })
  }

  _renderOnboardingButton = () => {
    const { coach, coachOnboarding: { isAdminOnboardingStatusUpdating } } = this.props
    const coachOnboardingStatus = coach.coach.coach_onboarding_status

    if (coachOnboardingStatus === 'started') {
      return (
        <SubmitButton
          onClick={() => this._updateOnboardingStatus('complete')}
          className='btn btn-primary onboarding-action-button'
          buttonMessage='Complete Training'
          loading={isAdminOnboardingStatusUpdating}
        />
      )
    }

    return (
      <SubmitButton
        onClick={() => this._updateOnboardingStatus('reset')}
        className='btn btn-primary onboarding-action-button'
        buttonMessage='Reset Training'
        loading={isAdminOnboardingStatusUpdating}
      />
    )
  }
}

function mapStateToProps(state, _ownProps) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    coaches: state.coaches,
    communities: state.communities.communities,
    completedSteps: selectCompleteItemsCount(state),
    totalSteps: selectTotalItemsCount(state),
    coachOnboarding: state.coachOnboarding,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    coach_actions: bindActionCreators(coachActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    reports_actions: bindActionCreators(reportsActions, dispatch),
    coach_onboarding_actions: bindActionCreators(coachOnboardingActions, dispatch),
    setIsOnboardingStepsModalVisible: bindActionCreators(setIsModalOpen, dispatch),
  }
}

CoachStats.propTypes = {
  user_actions: PropTypes.shape({
    updateUser: PropTypes.func,
  }),
  coach: PropTypes.shape({
    coach: PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
      phone: PropTypes.string,
      is_lead_coach: PropTypes.bool,
      coach_onboarding_status: PropTypes.string,
      coach_onboarding_last_modified: PropTypes.string,
    }),
    completed_express_training: PropTypes.shape({
      score: PropTypes.shape({
        value: PropTypes.number,
      }),
      value: PropTypes.string,
    }),
    completed_updated_express_training: PropTypes.shape({
      score: PropTypes.shape({
        value: PropTypes.number,
      }),
      value: PropTypes.string,
    }),
    events_count: PropTypes.shape({
      score: PropTypes.shape({
        value: PropTypes.number,
      }),
      value: PropTypes.number,
    }),
    programs_count: PropTypes.shape({
      score: PropTypes.shape({
        value: PropTypes.number,
      }),
      value: PropTypes.number,
    }),
    likes_count: PropTypes.shape({
      score: PropTypes.shape({
        value: PropTypes.number,
      }),
      value: PropTypes.number,
    }),
    comments_count: PropTypes.shape({
      score: PropTypes.shape({
        value: PropTypes.number,
      }),
      value: PropTypes.number,
    }),
    private_goal_count: PropTypes.shape({
      score: PropTypes.shape({
        value: PropTypes.number,
      }),
      value: PropTypes.number,
    }),
    announcements_count: PropTypes.shape({
      score: PropTypes.shape({
        value: PropTypes.number,
      }),
      value: PropTypes.number,
    }),
  }),
  params: PropTypes.shape({
    communityId: PropTypes.string,
  }),
  coach_onboarding_actions: PropTypes.shape({
    getCoachOnboardingSteps: PropTypes.func,
    updateOnboardingStatusForAdmin: PropTypes.func,
  }),
  setIsOnboardingStepsModalVisible: PropTypes.func,
  makeLeadCoach: PropTypes.func,
  completedSteps: PropTypes.number,
  totalSteps: PropTypes.number,
  coachOnboarding: PropTypes.shape({
    isAdminOnboardingStatusUpdating: PropTypes.bool,
    onboardingStatusUpdateError: PropTypes.bool,
    onboardingSteps: PropTypes.array,
    isOnboardingStepsLoading: PropTypes.bool,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(CoachStats))
