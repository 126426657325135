import PropTypes from 'prop-types'
import { useMemo } from 'react'
import ReactTooltip from 'react-tooltip'
import { defaultUserEnrollmentCompareFunction } from '../../../utility/helperFunctions'
import classes from './EnrolledStudentsTooltip.module.scss'
import StockProfilePic from '../../../assets/images/mock/default-profile-icon.png'
import { requiredIf } from '../../../utility/prop-types-helper'
import StudentWithAvatar from '../../students/StudentWithAvatar'

const StudentRow = ({ student, isSeries }) => (
  <div className={classes.studentRow}>
    {isSeries ? <StudentWithAvatar student={student}/> : <div
      className={classes.profileImg}
      key={student.id}
      style={{
        backgroundImage: student.avatar
          ? `url(${student.avatar.fileUrl})`
          : `url(${StockProfilePic})`,
      }}
    />}
    <span className={classes.studentName}>{student.firstName} {student.lastName}</span>
  </div>
)

StudentRow.propTypes = {
  id: PropTypes.number,
  student: PropTypes.shape({
    id: PropTypes.number,
    avatar: PropTypes.shape({
      fileUrl: PropTypes.string,
    }),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  isSeries: PropTypes.bool,
}

const EnrolledStudentsTooltip = ({ program, enrollments, isEnrollmentUnavailableFn, unavailableSectionTitle, isSeries }) => {
  const enrollmentsCount = enrollments.length
  const [activeEnrollments, appliedEnrollments, unavailableEnrollments] = useMemo(() => {
    const appliedEnrollments = []
    const activeEnrollments = []
    const unavailableEnrollments = []

    enrollments.forEach(enrollment => {
      if (!!isEnrollmentUnavailableFn && isEnrollmentUnavailableFn(enrollment)) {
        unavailableEnrollments.push(enrollment)
      } else if (enrollment.status === 'applied') {
        appliedEnrollments.push(enrollment)
      } else if (enrollment.status === 'active' || enrollment.status === 'completed') {
        activeEnrollments.push(enrollment)
      }
    })

    return [activeEnrollments, appliedEnrollments, unavailableEnrollments]
  }, [isEnrollmentUnavailableFn, enrollments])


  return (
    <ReactTooltip
      id={`enrollmentsTooltip-${program.id}`}
      effect='solid'
      delayUpdate={1000}
      delayHide={100}
      place={'bottom'}
      className={classes.enrollmentsTooltip}
    >
      <div>
        {!isSeries && <div className={classes.tooltipSection}>
          <p className={classes.tooltipTitle}>Enrolled: {enrollmentsCount}</p>
          <p className={classes.tooltipTitle}>Capacity: {program.golferLimit ? program.golferLimit : 'Not set'}</p>
        </div> }
        {unavailableEnrollments.length > 0 && !isSeries && (
          <div className={classes.tooltipSection}>
            <h5 className={classes.tooltipTitle}> {unavailableSectionTitle}</h5>
            {unavailableEnrollments
              .toSorted(defaultUserEnrollmentCompareFunction)
              .map((enrollment, idx) => <StudentRow student={enrollment.user} key={idx} />)
            }
          </div>
        )}
        {appliedEnrollments.length > 0 && !isSeries &&
          <div className={classes.tooltipSection}>
            <h5 className={classes.tooltipTitle}>Needs Approval:</h5>
            {appliedEnrollments
              .toSorted(defaultUserEnrollmentCompareFunction)
              .map((enrollment, idx) => <StudentRow student={enrollment.user} key={idx} />)
            }
          </div>
        }
        {activeEnrollments.length > 0 && !isSeries && (
          <div className={classes.tooltipSection}>
            <h5 className={classes.tooltipTitle}>Enrolled:</h5>
            {activeEnrollments
              .toSorted(defaultUserEnrollmentCompareFunction)
              .map((enrollment, idx) => <StudentRow student={enrollment.user} key={idx} />)
            }
          </div>
        )}

        {enrollments.length > 0 && isSeries && (
          <div className={classes.tooltipSection}>
            {enrollments
              .map((enrollment, idx) => <StudentRow student={enrollment} key={idx} isSeries={true}/>)
            }
          </div>
        )}
      </div>
    </ReactTooltip>
  )
}

EnrolledStudentsTooltip.propTypes = {
  program: PropTypes.shape({
    id: PropTypes.number.isRequired,
    golferLimit: PropTypes.number,
  }).isRequired,
  enrollments: PropTypes.array.isRequired,
  isEnrollmentUnavailableFn: PropTypes.func,
  unavailableSectionTitle: requiredIf(PropTypes.string, ({ isEnrollmentUnavailableFn }) => !!isEnrollmentUnavailableFn ),
  isSeries: PropTypes.bool,
}

export default EnrolledStudentsTooltip
