import { useSelector } from 'react-redux'
import { pluralize } from '../../../../utility/helperFunctions'
import classnames from 'classnames'
import { selectAllEnrollmentsRosterData } from '../../../../reducers/programDashboardReducer'
import GolfersTable from './GolfersTable'
import SubmitButton from '../../../common/buttons/SubmitButton'

import classes from './EnrollmentsTab.module.scss'
import { ReactComponent as InfoCircleIcon } from '../../../../assets/images/common/icons/info-circle-icon-outline.svg'
import { ReactComponent as GolferHalfIcon } from '../../../../assets/images/common/icons/golfer-half-icon.svg'
import { useOutletContext } from 'react-router-dom'

const EnrollmentsTab = () => {
  const { onOpenAddStudentsModal, withAddStudentsAction } = useOutletContext()

  const { program } = useSelector(state => state.programDashboard)
  const enrollments = useSelector(selectAllEnrollmentsRosterData)

  return (
    <div className={classnames('pt-3 d-flex flex-column', classes.enrollmentsTab)}>
      <div className='ml-3 d-flex align-items-center'>
        <span className={classes.userCount}>
          {`${enrollments.length}`}&nbsp;
          {`${program.golferLimit ? `/ ${program.golferLimit}` : ''}`}&nbsp;
          {program.golferLimit ? pluralize('Golfer', program.golferLimit) : pluralize('Golfer', enrollments.length)}
        </span>
        <InfoCircleIcon role='button' className={classnames('ml-2', classes.icon)} />
      </div>
      {enrollments.length === 0 ? (
        <div className={classnames('d-flex flex-column align-items-center mx-auto', classes.placeholder)}>
          <GolferHalfIcon className={classnames('mb-2', classes.icon)} />
          <h2 className={classes.title}>No Enrollments Yet</h2>
          {withAddStudentsAction ? (
            <SubmitButton
              buttonMessage='Add Students'
              onClick={onOpenAddStudentsModal}
            />
          ) : (
            <p className={classes.text}>Share this program with the students in your community!</p>
          )}
        </div>
      ) : (
        <GolfersTable program={program} enrollments={enrollments} />
      )}
    </div>
  )
}

export default EnrollmentsTab
