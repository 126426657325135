import PropTypes from 'prop-types'
import { useState } from 'react'
import classes from './HorizontalSelector.module.scss'
import classNames from 'classnames'

const HorizontalSelector = ({ tabs, onSelect }) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]?.key || '')

  const handleSelect = (key) => {
    setSelectedTab(key)
    if (onSelect) { onSelect(key) }
  }

  return (
    <div className={classes.container}>
      {tabs.map(({ key, label }) => (
        <button
          key={key}
          onClick={() => handleSelect(key)}
          className={classNames(classes.button, { [classes.selected]: selectedTab === key })}
        >
          {label}
        </button>
      ))}
    </div>
  )
}


HorizontalSelector.propTypes = {
  tabs: PropTypes.object,
  onSelect: PropTypes.func,
}

export default HorizontalSelector
