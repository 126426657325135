import Droppable from './Droppable'
import Select from '../../common/form/Select'
import StudentCard from '../Pairings/StudentCard'
import DraggableStudent from './DraggableStudent'
import _ from 'lodash'
import classNames from 'classnames'
import styles from './ManagePairings.module.scss'
import { ReactComponent as XIcon } from '../../../assets/images/common/icons/X-icon.svg'
import { DragOverlay, defaultDropAnimation, defaultDropAnimationSideEffects } from '@dnd-kit/core'

const HOLE_NR_SELECT_OPTIONS = _.range(1, 19).map((value) => ({ value: value, label: value.toString() }))
const HOLE_GROUP_SELECT_OPTIONS = _.range(0, 26).map((value) => ( { value: String.fromCharCode(value + 65), label: String.fromCharCode(value + 65) }))

const ShotgunPairings = ({
  event,
  pairings,
  filteredPairingGroups,
  handlePairHoleChange,
  removePairingGroup,
  removeStudent,
  isOver,
  draggingFromGroupId,
}) => (
  filteredPairingGroups.map((pairingGroup, pairIndex) => (
    <div key={pairIndex} className={classNames('d-flex flex-column mt-3 p-3', styles.pairWrapper)}>
      <div className='d-flex justify-content-between ml-2 w-100'>
        <div className={classNames('d-flex align-items-center', styles.groupInfo)}>
          <span className='mr-4'>Pairing Group {pairIndex + 1}</span>
          <span className='mr-3'>Select Hole</span>
          <Select
            id='intervalSelect'
            placeholder=''
            isSearchable={false}
            value={pairingGroup.startHole}
            onChange={(e) => handlePairHoleChange('startHole', e, pairingGroup)}
            className='mr-2'
            options={HOLE_NR_SELECT_OPTIONS}
            classNames={{
              menuList: () => styles.selectMenuList,
            }}
          />
          <Select
            id='intervalSelect'
            placeholder=''
            isSearchable={false}
            value={pairingGroup.playersGroup}
            onChange={(e) => handlePairHoleChange('playersGroup', e, pairingGroup)}
            options={HOLE_GROUP_SELECT_OPTIONS}
            classNames={{
              menuList: () => styles.selectMenuList,
            }}
          />
        </div>
        <XIcon className={classNames('pull-right', styles.removePairButton)} onClick={() => removePairingGroup(pairingGroup)}/>
      </div>
      <div className='d-flex flex-wrap w-100'>
        {_.range(0, pairings.golfersCount.value).map((index) => (
          <Droppable key={`${pairIndex}${index}`} id={`${pairIndex}${index}`} data={{ index: index, pairingGroup: pairingGroup }}>
            <div
              key={index}
              className={classNames(
                'mx-2 mt-4',
                styles.golferWrapper,
                isOver.index === index && isOver.groupIndex === pairingGroup.index && styles.isOver
              )}>
              {pairingGroup.users[index] &&
                <>
                  <DraggableStudent
                    key={index}
                    student={pairingGroup.users[index]}
                    draggingId={draggingFromGroupId}
                    draggedFrom={{ pairIndex: pairingGroup.index, index: index }}
                    dragged={true}
                    removeStudent={() => removeStudent(pairingGroup, index)}
                  />
                  <DragOverlay dropAnimation={{
                    ...defaultDropAnimation,
                    duration: 200,
                    sideEffects: defaultDropAnimationSideEffects({
                      styles: {
                        active: {
                          opacity: '1',
                        },
                      },
                    }),
                  }}>
                    {draggingFromGroupId !== -1 && <StudentCard student={event.students.find((s) => s.id === draggingFromGroupId)}/>}
                  </DragOverlay>
                </>
              }
            </div>
          </Droppable>
        ))}
      </div>
    </div>
  ))
)

export default ShotgunPairings
