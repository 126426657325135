import React, { useCallback, useEffect, useState } from 'react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import _ from 'lodash'

import PageHeader from '../common/PageHeader/PageHeader'
import EventForm from './Form/EventForm'
import ConfirmationModal2 from '../common/ConfirmationModal2'
import Series from './Series'
import CompletedEvents from './CompletedEvents'
import DefaultMultiActionsPopover from '../common/PageHeader/DefaultMultiActionsPopover'

import styles from './Events.module.scss'
import { ReactComponent as EditIcon } from '../../assets/images/common/icons/edit-icon.svg'

import { useSelector, useDispatch } from 'react-redux'
import { eventFormData, resetState, setField } from '../../reducers/nineHoleEventReducer'
import * as commonActions from '../../actions/commonActions'
import * as eventActions from '../../actions/eventActions'
import * as communitiesActions from '../../actions/communitiesActions'
import * as constants from '../../misc/Constants'

const EditIconComponent = (
  <div className={styles.editIconWrapper}>
    <EditIcon fill='#fdfdfd' width={'40px'} height={'40px'}/>
  </div>
)

const Events = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const currentUser = useSelector(state => state.user.current_user)
  const event = useSelector(state => state.nineHoleEvent)
  const formData = useSelector(eventFormData)

  const [showForm, setShowForm] = useState(false)
  const [eventLoader, setEventLoader] = useState(false)
  const [studentsLoader, setStudentsLoader] = useState(false)
  const [showLoadScoresModal, setShowLoadScoresModal] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [searchEvents, setSearchEvents] = useState('')
  const [searchUpcomingSeries, setSearchUpcomingSeries] = useState('')
  const [searchCompletedSeries, setSearchCompletedSeries] = useState('')
  const [activePageUpcomingSeries, setActivePageUpcomingSeries] = useState(1)
  const [activePageCompletedSeries, setActivePageCompletedSeries] = useState(1)
  const [activePageEvents, setActivePageEvents] = useState(1)


  const localScores = JSON.parse(localStorage.getItem('draftScores'))
  const eventScores = localScores && localScores[-1] && localScores[-1]['scores']

  const fetchEvents = useCallback((filters) => {
    dispatch(
      eventActions.getEvents(currentUser.id, filters, activePageEvents, constants.PER_PAGE_DEFAULT)
    ).then(() => {
      setActivePageEvents(1)
    })
  }, [dispatch, currentUser.id, activePageEvents])


  const createEvent = () => {
    setEventLoader(true)
    dispatch(eventActions.createEvent(formData)).unwrap()
      .then(() => {
        toast.success(`Success! Created ${event.name}`, {
          position: toast.POSITION.BOTTOM_LEFT,
        })
        removeDraftScores()
        _.delay(handleEventSubmittedClick, 5000)
        setEventLoader(false)
      })
      .catch(() => setEventLoader(false))
  }

  const handleEventSubmittedClick = () => {
    setShowForm(false)
    fetchEvents()
  }

  const quickLog = () => {
    dispatch(resetState())
    eventScores ? setShowLoadScoresModal(true) : setShowForm(true)
  }

  const loadScores = () => {
    setStudentsLoader(true)
    communitiesActions.getStudentsByIds(currentUser.communities[0].id, Object.keys(eventScores)).then((communityStudents) => {
      const updatedStudents = []
      const studentErrors = []
      Object.keys(eventScores).forEach((userId) => {
        const std = communityStudents.find(std => std.id === parseInt(userId))
        const savedData = eventScores[userId]

        if (!std) {
          studentErrors.push(`Student ${savedData['firstName']} ${savedData['lastName']} could not be loaded.`)
        } else {
          updatedStudents.push({
            userId: std.id,
            firstName: std.firstName,
            lastName: std.lastName,
            currentFormalDivision: std.currentFormalDivision,
            stats: std.stats,
            avatar: std.avatar,
            addedFrom: std.addedFrom,
            birthdate: std.birthdate,
            gender: std.gender,
            score: savedData['score'],
            greens: savedData['greens'],
            putts: savedData['putts'],
            yardage: savedData['yardage'],
            fullTeeYardage: savedData['fullTeeYardage'],
            level: savedData['level'],
          })
        }

        if (studentErrors) {
          dispatch(setField({ field: 'errors', value: { students: studentErrors } }))
        }
        dispatch(setField({ field: 'students', value: updatedStudents }))
        dispatch(setField({ field: 'action', value: 'Log Scores' }))
        setStudentsLoader(false)
        setShowLoadScoresModal(false)
      })
      setShowForm(true)
    })
  }

  const removeDraftScores = () => {
    const localScores = JSON.parse(localStorage.getItem('draftScores'))
    delete localScores[event.id]
    localStorage.setItem('draftScores', JSON.stringify(localScores))
  }

  const startFromScratch = () => {
    removeDraftScores()
    setShowLoadScoresModal(false)
    setShowForm(true)
  }

  const closeForm = () => {
    dispatch(resetState())
    setShowForm(false)
  }

  useEffect(() => {
    dispatch(commonActions.setCurrentTab('Events'))
  }, [dispatch])

  useEffect(() => {
    if (!_.isEmpty(location.state) && location.state.smartToggle) {
      setShowForm(true)
    }
  }, [location.state])

  useEffect(() => {
    dispatch(commonActions.showSideBar(!showForm))
    dispatch(commonActions.showNavBar(!showForm))
  }, [dispatch, showForm])

  return (
    <div className={styles['events-page']} style={{ marginLeft: !showForm ? '-15px' : '0' }} >
      { showForm ?
        <EventForm
          isQuickLog
          onClickBack={closeForm}
          createEvent={createEvent}
          handleEventSubmittedClick={handleEventSubmittedClick}
          eventLoader={eventLoader}
          studentsLoader={studentsLoader}
        /> :
        <div>
          <PageHeader
            title='9 Hole Events'
            actionComponent={(
              <DefaultMultiActionsPopover
                title='Create Event'
                actions={[
                  { label: 'Create Event Series', link: true, to: '/series/new' },
                  { label: 'Quick Log an Event', onClick: quickLog },
                ]}
              />
            )}
          />

          <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <TabList className={styles.tabList}>
              <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Upcoming Series</Tab>
              <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Completed Series</Tab>
              <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}> Completed Events</Tab>
            </TabList>
            <TabPanel className={styles.tabPanel}>
              <Series
                typeCompleted={false}
                searchTerm={searchUpcomingSeries}
                setSearchTerm={setSearchUpcomingSeries}
                activePage={activePageUpcomingSeries}
                setActivePage={setActivePageUpcomingSeries}
                loadScores={loadScores}
              />
            </TabPanel>
            <TabPanel className={styles.tabPanel}>
              <Series
                typeCompleted={true}
                searchTerm={searchCompletedSeries}
                setSearchTerm={setSearchCompletedSeries}
                activePage={activePageCompletedSeries}
                setActivePage={setActivePageCompletedSeries}
                loadScores={loadScores}
              />
            </TabPanel>
            <TabPanel className={styles.tabPanel}>
              <CompletedEvents
                quickLog={quickLog}
                showform={showForm}
                searchTerm={searchEvents}
                setSearchTerm={setSearchEvents}
                activePage={activePageEvents}
                setActivePage={setActivePageEvents}
                loadScores={loadScores}
              />
            </TabPanel>
          </Tabs>
        </div>
      }
      <ConfirmationModal2
        showModal={showLoadScoresModal}
        closeModal={() => setShowLoadScoresModal(false)}
        onDeny={startFromScratch}
        denyMessage='Start from Scratch'
        onProceed={loadScores}
        proceedMessage='Load Scores'
        icon={EditIconComponent}
        title='Load draft scores?'
        message='There are draft scores saved for this event. Do you want to load them?'
      />
    </div>
  )
}
export default Events
