import * as types from '../actions/actionTypes';

// state = [] : Start out with no accounts
export default function tags(state = [], action){
  switch(action.type){

    case types.GET_TAGS:
      return action.tags;

    default:
      return state;
  }
}
