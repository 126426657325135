import { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { components } from 'react-select'
import ReactTooltip from 'react-tooltip'
import PropTypes from 'prop-types'

import { ReactComponent as PackageIcon } from '../../../../assets/images/common/icons/package-icon.svg'
import { getCommunityPackages } from '../../../../actions/packagesActions'
import { displayWithCurrency } from '../../../../utility/currency'
import colors from '../../../../assets/styles/globals.scss'
import PackageFormContainer from '../../../community-settings/PricingTab/PackageFormContainer'
import Select from '../../../common/form/Select'
import Tooltip from '../../../common/Tooltip'
import TooltipText from '../../../common/TooltipText'
import InfoModal from '../../../common/InfoModal'
import './PackageSelect.scss'

const PackageSelect = ({ communityPackages, packages, errors, onAddPackage, onDeletePackage, noCreateOption }) => {
  const dispatch = useDispatch()

  const current_user = useSelector(state => state.user.current_user)
  const currency = current_user.communities[0].currency

  const [showPackageFormModal, setShowPackageFormModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [packages])

  const options = useMemo(() => {
    const pkgs = communityPackages.length > 0 ? communityPackages.filter(pkg => !packages.map(pkg => pkg.id).includes(pkg.id)) : []
    if (noCreateOption) {
      return [...pkgs]
    } else {
      return [...pkgs, { id: -1, name: current_user.lead_coach ? '+ Create a new package' : 'Need more packages?' }]
    }
  }, [communityPackages, packages, current_user, noCreateOption])

  const attachCreatedPackage = (pkg) => {
    onAddPackage(pkg)
    dispatch(getCommunityPackages())
  }

  /* eslint-disable react/prop-types */
  const Option = ({ data, ...props }) => {

    if (data.id === -1) {
      return (
        <components.Option {...props}>
          <div
            className='create-package'
            aria-hidden='true'
            onClick={() => { current_user.lead_coach ? setShowPackageFormModal(true) : setShowInfoModal(true) }}
          >
            <p>{data.name}</p>
          </div>
        </components.Option>
      )
    } else {
      return (
        <components.Option {...props}>
          <div key={data.id} className='option'>
            <div className='d-flex align-items-center package-card' key={data.id}>
              <div className='left-package-icon'>
                <PackageIcon id='packageIcon' fill={colors.placeholderGray}/>
              </div>
              <div className='package-info'>
                <p className='title truncated-text'>{data.name}</p>
                <div className='d-flex justify-content-start align-items-center'>
                  <p className='price'>{displayWithCurrency(data.price, currency)}</p>
                  {data.description &&
                    <>
                      <span className='separating-dot'>·</span>
                      <p className='description truncated-text'>{data.description}</p>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </components.Option>
      )
    }
  }

  return (
    <div id='PackageSelect'>
      <Tooltip offset={{ top: '-10' }} />
      {packages.length > 0 && (
        <div className='line' />
      )}
      {packages.map(pkg => (
        <div key={pkg.id}>
          <div className='d-flex align-items-center package-card' key={pkg.id}>
            <div className='left-package-icon'>
              <PackageIcon id='packageIcon' fill={colors.placeholderGray}/>
            </div>
            <div className='package-info'>
              <TooltipText className='title' text={pkg.name} />
              <div className='d-flex justify-content-start align-items-center'>
                <p className='price'>{displayWithCurrency(pkg.price, currency)}</p>
                {pkg.description &&
                  <>
                    <span className='separating-dot'>·</span>
                    <TooltipText className='description' text={pkg.description} />
                  </>
                }
              </div>
            </div>
            <div className='right-delete-icon ml-auto' aria-hidden='true' onClick={() => onDeletePackage(pkg)}>
              <i className='fa fa-times'></i>
            </div>
          </div>
          <div className='line' />
        </div>
      ))}

      <Select
        name='package-selector'
        value={-1}
        options={options}
        components={{
          Option,
        }}
        onChange={onAddPackage}
        placeholder='Select package to attach'
        isSearchable={false}
        menuPlacement='auto'
        minMenuHeight={300}
        menuPosition='fixed'
        error={errors?.packagesIds}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      />

      <PackageFormContainer
        showPackageFormModal={showPackageFormModal}
        setShowPackageFormModal={setShowPackageFormModal}
        requestCallback={attachCreatedPackage}
      />

      <InfoModal
        showModal={showInfoModal}
        closeModal={() => setShowInfoModal(false)}
        title='Only lead coaches can create or manage packages'
      >
        <div style={{ color: colors.lightNavy }}>
          <p style={{ marginBottom: '0' }}>
            If you need to create another package, please ask your community&apos;s lead coach to create it for you.
          </p>
        </div>
      </InfoModal>
    </div>
  )
}

PackageSelect.propTypes = {
  communityPackages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.number,
    description: PropTypes.string,
  })),
  packages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.number,
    description: PropTypes.string,
  })),
  errors: PropTypes.shape({
    packagesIds: PropTypes.arrayOf(PropTypes.string),
  }),
  onAddPackage: PropTypes.func,
  onDeletePackage: PropTypes.func,
  noCreateOption: PropTypes.bool,
}

export default PackageSelect
