export const initializePendo = (pendoClient, user) => {
  // TODO: Revert after testing Coach Onboarding on staging.
  // if (process.env.NODE_ENV !== 'production' || process.env.REACT_APP_BASE_URL !== 'https://operation36golf.com') {
  //   console.log('[Pendo] Skipped (Not in production)') // eslint-disable-line no-console
  //   return
  // }

  // TODO: Delete after testing Coach Onboarding on staging.
  if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_BASE_URL === 'http://localhost:3001') {
    console.log('[Pendo] Skipped (development)') // eslint-disable-line no-console
    return
  }

  if (!pendoClient || typeof pendoClient.initialize !== 'function') {
    return
  }

  if (!user || user.type !== 'Coach') {
    return
  }

  console.log('[Pendo] Initializing') // eslint-disable-line no-console

  pendoClient.initialize({
    visitor: {
      id: user.id, // Required if user is logged in
      // email:        // Recommended if using Pendo Feedback, or NPS Email
      // full_name:    // Recommended if using Pendo Feedback
      // role:         // Optional
      login: user.email || user.username,
      created_at: user.created_at,
      community_id:
        user.communities.length > 0 ? user.communities[0].id : 0,
      community_name:
        user.communities.length > 0 ? user.communities[0].name : 'admin',
      device_id: user.device_id,
      family_id: user.family.id,
      family_admin: user.family_admin,
      first_name: user.first_name,
      last_name: user.last_name,
      is_golfer: user.is_golfer,
      user_type: user.type,
      coach_permission:
        user.user_settings && user.user_settings.coach_permission,
      license_type: user.communities.length > 0 ? user.communities[0].license_type : 'admin',
      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: user.communities.length > 0 ? user.communities[0].id : 0, // Highly recommended
      name:
        user.communities.length > 0 ? user.communities[0].name : 'admin', // Optional
      sales_rep_id:
        user.communities.length > 0
          ? user.communities[0].sales_rep_id
          : 0,
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    },
  })
}
