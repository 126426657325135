import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'


import { ReactComponent as Op36Icon } from '../../assets/images/common/icons/sidebarMenu/Op36.svg'
import StockProfilePic from '../../assets/images/mock/StockProfilePic.png'

import classes from './LandingHeader.module.scss'

const LandingHeader = () => {
  const user = useSelector(state => state.user.current_user)

  return (
    <header className={classes.landingHeader}>
      <a href='https://operation36.golf' target='_blank'>
        <Op36Icon className={classes.op36Icon}/>
      </a>

      {user && (
        <>
          <div className={classNames(classes.avatarWrapper, 'small-round-image-wrapper ml-auto')} data-for='userTooltip' data-tip>
            <img
              className='round-image'
              src={user.avatar ? user.avatar.file_url : StockProfilePic}
              alt={`${user.first_name} ${user.last_name}`}
            />
          </div>

          <ReactTooltip
            id='userTooltip'
            offset={{ top: '4', left: '2' }}
            effect='solid'
            place='bottom'
            delayUpdate={100}
            delayHide={100}
            className={classes.tooltipWrapper}
          >
            <div className={classNames(classes.userTooltip, 'd-flex flex-column p-0')}>
              <Link to='/dashboard'>Dashboard</Link>
              <Link to='/log-in' className={classes.logOut}>Log Out</Link>
            </div>
          </ReactTooltip>
        </>
      )}
    </header>
  )
}

export default LandingHeader
