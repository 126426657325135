import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import Select from '../../common/form/Select'
import TextInput from '../../common/form/TextInput'
import StudentWithAvatar from '../../students/StudentWithAvatar'

import { updateStudent, deleteStudent } from '../../../reducers/nineHoleEventReducer'
import { ReactComponent as XIcon } from '../../../assets/images/common/icons/X-icon.svg'
import { DIVISION_SELECT_OPTIONS } from '../../../misc/Constants'

import colors from '../../../assets/styles/globals.scss'

const yardages = {
  1: 225,
  2: 450,
  3: 900,
  4: 1350,
  5: 1800,
  6: 'fullTee',
}

const StudentScoreRow = ({ std, setScoresSaved }) => {
  const dispatch = useDispatch()

  const removeStudent = (stdId) => {
    dispatch(deleteStudent(stdId))
  }

  const handleStudentFieldChange = (std, field) => {
    const student = { ...std }
    const { fieldName, fieldValue } = field
    student[fieldName] = fieldValue
    setScoresSaved(false)
    dispatch(updateStudent(student))
  }

  const handleDivisionChange = (std, opt) => {
    dispatch(updateStudent({ ...std, level: opt.value, yardage: yardages[opt.value] }))
  }

  const getMilestonesText = (std) => {
    const record = std.stats ? parseInt(std.stats[std.level].recordScore) : 0
    const improvedBy = record ? record - std.score : null
    let text = ''
    if (std.score > 8 ) {
      if (std.score <= 36) {
        text = `Level ${std.level} Passed!`
      } else if (std.score && std.score < record) {
        text = `New Personal Best ${std.score}`
        if (improvedBy) {
          text += ` Improved by ${improvedBy} ${improvedBy > 1 ? 'strokes' : 'stroke'}!`
        }
      }
    }
    return text
  }

  const getLabel = (std) => {
    if (std.level > 5) {
      if (std.fullTeeYardage > 1800) {
        return `Level ${getLevel(std.fullTeeYardage)}`
      } else {
        return 'Full Tee'
      }
    } else {
      return `Level ${std.level}`
    }
  }

  const getLevel = (yardage) => {
    if (yardage > 1800 && yardage <= 2300) {
      return 6
    } else if (yardage > 2300 && yardage <= 2600) {
      return 7
    } else if (yardage > 2600 && yardage <= 2900) {
      return 8
    } else if (yardage > 2900 && yardage <= 3200) {
      return 9
    } else if (yardage > 3200) {
      return 10
    } else {
      return null
    }
  }

  const customStyles = {
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? colors.lightGray : null,
      color: colors.darkNavy,
      cursor: 'pointer',
    }),
  }

  return (
    <tr
      className={'students-table-row'}
    >
      <td style={{ width: '24%' }} className='golfer-data'>
        <StudentWithAvatar student={std} withName/>
      </td>
      <td style={{ width: '8%', color: colors.primaryOrange }} className='centered-text'>
        {std.stats && std.stats[std.level].levelAttempts || '-'}
      </td>
      <td style={{ width: '8%', color: colors.primaryOrange }} className='centered-text'>
        {std.stats && std.stats[std.level].baselineScore || '-'}
      </td>
      <td style={{ width: '8%', color: colors.primaryOrange }} className='centered-text'>
        {std.stats && std.stats[std.level].recordScore || '-'}
      </td>
      <td style={{ width: '20%' }} >
        <div className='d-flex'>
          <Select
            id='divisionSelect'
            className='mr-5 w-100'
            styles={customStyles}
            isSearchable={false}
            value={{ value: std.level, label: getLabel(std) }}
            onChange={(opt) => handleDivisionChange(std, opt)}
            options={DIVISION_SELECT_OPTIONS}
            menuPlacement='auto'
          />
          <div className='ml-2'>
            {std.level > 5 &&
          <TextInput
            name='fullTeeYardage'
            className='col-input'
            value={std.fullTeeYardage}
            onChange={(e) => handleStudentFieldChange(std, { fieldName: 'fullTeeYardage', fieldValue: e.target.value })}
          />}
          </div>
        </div>
      </td>
      <td>
        <TextInput
          name='greens'
          type='number'
          className='col-input'
          value={std.greens ? std.greens.toString() : ''}
          onChange={(e) => handleStudentFieldChange(std, { fieldName: 'greens', fieldValue: e.target.value })}
        />
      </td>
      <td>
        <TextInput
          name='putts'
          type='number'
          className='col-input'
          value={std.putts ? std.putts.toString() : ''}
          onChange={(e) => handleStudentFieldChange(std, { fieldName: 'putts', fieldValue: e.target.value })}
        />
      </td>
      <td>
        <TextInput
          name='score'
          type='number'
          className='col-input'
          value={std.score ? std.score.toString() : ''}
          onChange={(e) => handleStudentFieldChange(std, { fieldName: 'score', fieldValue: e.target.value })}
        />
      </td>
      <td style={{ width: '13%' }} className='milestones-col'>
        {getMilestonesText(std)}
      </td>
      <td style={{ width: '10%' }} className='centered-text'>
        <XIcon className='trash-icon' fill={colors.red} onClick={() => removeStudent(std.id)}/>
      </td>
    </tr>
  )
}

StudentScoreRow.propTypes = {
  std: PropTypes.shape({
    id: PropTypes.number,
    score: PropTypes.string,
    putts: PropTypes.string,
    greens: PropTypes.string,
    level: PropTypes.number,
    stats: PropTypes.object,
    fullTeeYardage: PropTypes.string,
  }),
  setScoresSaved: PropTypes.func.isRequired,
}

export default StudentScoreRow

