import PropTypes from 'prop-types'
import { ReactComponent as CommentIcon } from '../../assets/images/common/icons/comment-icon.svg'
import { ReactComponent as Checkmark } from '../../assets/images/common/icons/checkmark-icon.svg'
import { ReactComponent as DownArrow } from '../../assets/images/common/icons/richTextEditor/chevron-down.svg'
import moment from 'moment'
import classes from './ActivityAnnouncementCard.module.scss'
import ImageWithOverlay from './ImageWithOverlay'
import { pluralize } from '../../utility/helperFunctions'
import { useState, useMemo, useEffect, useRef } from 'react'
import ActivityAnnouncementModal from './modals/ActivityAnnouncementModal'
import CoachHeaderInfo from './CoachHeaderInfo'
import { Popover } from 'reactstrap'
import ConfirmationModal from '../common/ConfirmationModal'
import { requestDeleteActivity } from '../../actions/activityActions'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'

const ActivityAnnouncement = ({ activity, removeActivity }) => {
  const fileUrl = activity.photo?.original_file_url
  const videoUrl = activity.video?.attachment_url
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const [dropdown, setDropdown] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(false)

  const buttonRef = useRef(null)

  const openModal = () => {
    setModalOpen(true)
  }

  const onClose = () => {
    setModalOpen(false)
  }

  const openDropdown = (e) => {
    e.stopPropagation()
    setDropdown(true)
  }

  const onDeletePress = (e) => {
    e.stopPropagation()
    setDropdown(false)
    setConfirmationModal(true)
  }

  const closeConfirmationModal = () => {
    setConfirmationModal(false)
  }

  const deleteAnnouncement = () => {
    requestDeleteActivity(activity).then((response) => {
      if (response.status === 201) {
        toast.success('Announcement deleted successfully', { position: toast.POSITION.TOP_RIGHT })
        dispatch(removeActivity(activity.id))
      } else {
        toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT })
      }
      setConfirmationModal(false)
    })
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target)

      ) {
        setDropdown(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const programsText = useMemo(() => {
    const programsCount = (activity.courses?.length || 0) + (activity.series?.length || 0)
    const othersText = ` + ${programsCount - 1} Others`

    if (programsCount > 0) {
      return `${activity.courses?.[0]?.name || activity.series?.[0]?.title}` + `${programsCount > 1 ? othersText : ''}`
    } else {
      return 'No Programs / Events'
    }
  }, [activity.courses, activity.series])

  const confirmationMesssage = `Are you sure you want to delete ${activity.user.first_name} ${activity.user.last_name}'s announcement?`

  return (
    <>
      <div className={classes.container} onClick={openModal} role='button' tabIndex={0} onKeyPress={openModal}>
        <button onClick={openDropdown} id={`Popover${activity.id}`} ref = {buttonRef} >
          <DownArrow />
        </button>
        <Popover
          placement='bottom'
          isOpen={dropdown}
          target={`Popover${activity.id}`}
          toggle={setDropdown}
        >
          <div className='activity-dropdown-container'>
            <button
              className='btn btn-link activity-dropdown-delete'
              onClick={onDeletePress}
            >
              {'Delete'}
            </button>
          </div>
        </Popover>
        <div className={classes.coachHeader}>
          <CoachHeaderInfo coach={activity.user} />
        </div>
        <span className={classes.caption}>{activity.caption}</span>
        <span className={classes.fromNow}>{moment(activity.activity_date).fromNow()}</span>
        <div className={classes.imageContainer}>
          {(fileUrl || videoUrl) && <ImageWithOverlay alt={programsText} height={'30vh'} video={videoUrl} src={fileUrl} />}
        </div>
        <div className={classes.commentsContainer}>
          <div className={classes.comments}>
            <CommentIcon />
            <span className={classes.commentsText}>{`${activity.comment_count} ${pluralize('Comment', activity.comment_count)}`}</span>
          </div>
          <div className={classes.readBy}>
            <Checkmark />
            <span className={classes.readByText}>{`Read by ${activity.like_count || 0}`}</span>
          </div>
        </div>
      </div>
      <ConfirmationModal
        showConfirmationModal={confirmationModal}
        closeConfirmationModal={closeConfirmationModal}
        confirmationMessage={confirmationMesssage}
        confirmationDenied={closeConfirmationModal}
        confirmationProceed={deleteAnnouncement}
      />
      {modalOpen && <ActivityAnnouncementModal
        isOpen={modalOpen}
        onClose={onClose}
        activity={activity}
        text={programsText} />}
    </>
  )
}

ActivityAnnouncement.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.number.isRequired,
    caption: PropTypes.string.isRequired,
    activity_date: PropTypes.string.isRequired,
    photo: PropTypes.shape({
      original_file_url: PropTypes.string,
    }),
    video: PropTypes.shape({
      attachment_url: PropTypes.string,
    }),
    courses: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    series: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
      })
    ),
    comment_count: PropTypes.number.isRequired,
    like_count: PropTypes.number,
    user: PropTypes.object.isRequired,
  }).isRequired,
  removeActivity: PropTypes.func.isRequired,
}

export default ActivityAnnouncement
