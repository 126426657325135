import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CustomCheckInput from '../../../common/form/CustomCheckInput'

// ASSETS
import './SimpleEducationLesson.scss'

class SimpleEducationLesson extends Component {
  state = { completed: this.props.isCompleted }

  toggleCompleted = () => {
    this.setState({
      completed: !this.state.completed,
    })
  }

  renderIcon = () => {
    const { isCompleted } = this.props

    return (
      <CustomCheckInput
        type='checkbox'
        classNames={{
          checkBoxInput: isCompleted ? 'roundedCheckboxComplete' : 'roundedCheckbox',
          checkMark: isCompleted ? 'checkBoxCheckMark' : 'noCheckMark',
        }}
        checked={isCompleted}
        readOnly
      />
    )
  }

  render() {
    const { lesson, selectLesson, selectedLesson, isCoach } = this.props
    return (
      <div id='SimpleEducationLesson'>
        <button
          className={`row lesson-button  ${selectedLesson.id === lesson.id &&
            'selected-lesson'}`}
          onClick={() => selectLesson(lesson)}
        >
          <p>{lesson.name}</p>
          {isCoach && this.renderIcon()}
        </button>
      </div>
    )
  }
}

SimpleEducationLesson.propTypes = {
  lesson: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
  index: PropTypes.number,
  selectLesson: PropTypes.func,
  selectedLesson: PropTypes.shape({
    id: PropTypes.string,
  }),
  isCoach: PropTypes.bool,
  isCompleted: PropTypes.bool,
}

export default SimpleEducationLesson
