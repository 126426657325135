import { useDroppable } from '@dnd-kit/core'
import PropTypes from 'prop-types'

const Droppable = (props) => {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
    data: props.data,
  })
  const style = {
    color: isOver ? 'green' : undefined,
  }

  return (
    <div ref={setNodeRef} style={style}>
      {props.children}
    </div>
  )
}

Droppable.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
  children: PropTypes.node,
}

export default Droppable
