import * as constants from "./actionTypes";
import * as common from "./commonActions";

import Axios from '../axios'
import { deserialize, serialize } from '../utility/serialization'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleAPIError } from '../utility/actionHelper'

// ============
// RETURN STATEMENTS
// ============
export function successRequestingUsers(response) {
  if (response.status === 200) {
    return { type: constants.ADD_USERS, users: response.data.students };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingSearchedUsers(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_SEARCHED_USERS,
      searched_users: response.data.users
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// ============
// THUNKS HERE
// ============
export function getStudentsForCourse(courseId) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestStudentsForCourse(courseId)
      .then(response => dispatch(successRequestingUsers(response)))
      .then(response => dispatch(common.loadingChanged(false)));
  };
}

export function getEnrolledStudentsForCommunity(communityId) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestEnrolledStudentsForCommunity(communityId)
      .then(response => dispatch(successRequestingUsers(response)))
      .then(response => dispatch(common.loadingChanged(false)));
  };
}

export function searchUsers(searchTerm) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestSearchedUsers(searchTerm)
      .then(response => dispatch(successRequestingSearchedUsers(response)))
      .then(response => dispatch(common.loadingChanged(false)));
  };
}

export const getStudentsDualPanelSelector = createAsyncThunk(
  'user/getStudentsDualPanelSelector',
  async ({ communityId, searchTerm, page, perPage }, { rejectWithValue }) => {
    try {
      const { data } = await requestGetAllStudentsFromCommunity(communityId, page, perPage, searchTerm)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

// ============
// API CALLS
// ============
function requestStudentsForCourse(courseId) {
  let requestUrl = "/api/v1/courses/" + courseId + "/students";
  return Axios.get(requestUrl);
}

function requestEnrolledStudentsForCommunity(communityId) {
  let requestUrl = "/api/v1/communities/" + communityId + "/enrolled_students";
  return Axios.get(requestUrl);
}

function requestSearchedUsers(searchTerm) {
  let requestUrl = `/api/v1/users?search=${searchTerm}`;

  return Axios.get(requestUrl);
}

const requestGetAllStudentsFromCommunity = (communityId, page, perPage, search) => {
  const requestUrl = '/api/v1/communities/' + communityId + '/students'

  return Axios.get(requestUrl, { params: serialize({ page, perPage, search }) })
}
