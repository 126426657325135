import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ReactComponent as ShieldCheckMark } from '../../../../assets/images/common/icons/shield-check-mark.svg'
import { ReactComponent as QuestionMarkCircle } from '../../../../assets/images/common/icons/question-mark-circle.svg'
import { setEditingUserField, editingUserFormData } from '../../../../reducers/signUpReducer'
import colors from '../../../../assets/styles/globals.scss'
import Toggle from '../../../common/form/Toggle'
import MCElement from '../../../common/MCElement'
import SocialSettingsModal from '../../Helpers/SocialSettingsModal'
import StepButton from '../../Helpers/StepButton'

import classes from './ChildSocialSettings.module.scss'

const ChildSocialSettings = ({ loader, onCreateUser }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formData = useSelector(editingUserFormData)
  const { allowPostingContent, allowPostingComments } = useSelector(state => state.signUp.editingUser)
  const [consentConfirmation, setConsentConfirmation] = useState(false)
  const [showSocialSettingsModal, setShowSocialSettingsModal] = useState(false)

  return (
    <div className={classNames(classes.childSocialSettings, 'd-flex flex-column')}>
      <form className={classNames(classes.mainContent, 'd-flex flex-column align-items-center justify-content-start')}>
        <div className='w-100'>
          <h1 className={classes.titleText}>Add Family Member</h1>
        </div>

        <div className='w-100'>
          <aside className={classNames(classes.socialFeaturesWrapper, 'd-flex align-items-center')}>
            <ShieldCheckMark className={classes.shieldCheckMark}/>
            <h3 className={classes.socialFeaturesTitle}>Child Social Feature Settings</h3>
            <QuestionMarkCircle className={classes.QuestionMarkCircle} onClick={() => setShowSocialSettingsModal(true)} aria-hidden='true'/>

            <SocialSettingsModal
              showModal={showSocialSettingsModal}
              closeModal={() => setShowSocialSettingsModal(false)}
            />
          </aside>

          <section className='row mb-2'>
            <div className={classNames(classes.toggleWrapper, 'col-12 col-lg-6 mb-3')}>
              <div className='d-flex align-items-center'>
                <Toggle
                  name='allowPostingContent'
                  checked={allowPostingContent}
                  onChange={() => dispatch(setEditingUserField({ field: 'allowPostingContent', value: !allowPostingContent }))}
                />

                <label className={classes.toggleLabel} htmlFor='allowPostingContent'>
                  Allow your student to include Images & Description on their golf activities
                </label>
              </div>

              <p className={classes.toggleSubtitle}>
                When making a post, you have the option to add a picture and description to your activity. Turn this off to remove this feature.
                Note - If turned off you as a parent cannot log a picture or description as well.
              </p>
            </div>

            <div className={classNames(classes.toggleWrapper, 'col-12 col-lg-6 mb-3')}>
              <div className='d-flex align-items-center'>
                <Toggle
                  name='allowPostingComments'
                  checked={allowPostingComments}
                  onChange={() => dispatch(setEditingUserField({ field: 'allowPostingComments', value: !allowPostingComments }))}
                />

                <label className={classes.toggleLabel} htmlFor='allowPostingComments'>
                  Allow commenting on Posts
                </label>
              </div>

              <p className={classes.toggleSubtitle}>
                You will be able to see activities logged by other participants at your facility in the community feed.
                By turning this off your junior account will not be able to log comments on others posts.  Note - If turned off you as a parent
                cannot log a comment on their behalf.
              </p>
            </div>

            <section className={classNames(classes.checkboxSection, 'col-12 col-lg-6 col-md-offset-6')}>
              <div className={classNames(classes.checkboxWrapper, 'd-flex align-items-center')}>
                <MCElement
                  type='checkbox'
                  isSelected={consentConfirmation}
                  clicked={() => setConsentConfirmation(prevState => !prevState)}
                  primaryColor={colors.primaryOrange}
                  secondaryColor={colors.darkNavy}
                />
                <label className={classNames(classes.checkboxLabel, 'ml-3')} htmlFor='consentCheckbox'>
                  I consent that I am the guardian of this user
                </label>
              </div>
            </section>
          </section>
        </div>

        <StepButton
          onClick={() => onCreateUser(formData)}
          color='orange'
          message='Add Member'
          disabled={!consentConfirmation}
          displayLoader
          loader={loader}
        />

        {!localStorage.getItem('embed') && (
          <StepButton onClick={() => navigate(-1)} color='navy' message='Go Back' newStep={6}/>
        )}
      </form>
    </div>
  )
}

ChildSocialSettings.propTypes = {
  loader: PropTypes.bool,
  onCreateUser: PropTypes.func,
}

export default ChildSocialSettings
