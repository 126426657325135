import PropTypes from 'prop-types'
import avatarPlaceholder from '../../../assets/images/mock/default-profile-icon.png'
import SmallMilestoneIcon from '../../common/SmallMilestoneIcon'
import { ReactComponent as XIcon } from '../../../assets/images/common/icons/X-icon.svg'
import { ReactComponent as DragIcon } from '../../../assets/images/common/icons/drag-icon.svg'
import { genderLabel } from '../../../utility/userHelper'
import styles from './Studentcard.module.scss'
import classNames from 'classnames'
import colors from '../../../assets/styles/globals.scss'

const RSVP_STATUS_MAP = {
  created: 'No Response',
  confirmed: 'Attending',
  declined: 'Not Attending',
}

const STATUS_COLOR = {
  created: colors.lightNavy,
  confirmed: colors.darkTeal,
  declined: colors.redDarker,
}

const StudentCard = ({ student, dragging, removeStudent, dragged, listeners, attributes }) => (
  <div className={classNames(
    'd-flex align-items-center',
    styles.studentCard,
    dragged && styles.dragged,
    !dragging && !dragged && styles.grab,
    dragging && styles.grabbing)}
  >
    <div className={classNames('d-flex align-items-center', styles.studentData)} {...listeners} {...attributes}>
      <DragIcon className={styles.dragIcon}/>
      <div className='mr-3 smaller-round-image-wrapper' >
        <img
          alt='student'
          className='round-image'
          src={student.avatar?.fileUrl || avatarPlaceholder}
        />
      </div>
      <SmallMilestoneIcon fromAvatar className={styles.studentLevel} division={student.currentFormalDivision} />
      <div>
        <p className={styles.studentTitle}>{student.firstName} {student.lastName}</p>
        <p className={styles.studentInfo}>
          {`Age ${student.age} / ${genderLabel[student.gender]}`}
        </p>
        <p className={styles.studentStatus} style={{ color: STATUS_COLOR[student.status] }}>
          {RSVP_STATUS_MAP[student.status]}
        </p>
      </div>
    </div>
    <XIcon className={classNames('pull-right', styles.removeStudentButton)} onClick={removeStudent}/>
  </div>
)

StudentCard.propTypes = {
  student: PropTypes.object.isRequired,
  dragging: PropTypes.bool,
  dragged: PropTypes.bool,
  listeners: PropTypes.object,
  attributes: PropTypes.object,
  removeStudent: PropTypes.func,
}

export default StudentCard
