import PropTypes from 'prop-types'
import './PageFooter.scss'

const PageFooter = ({ actionButtons, reversed }) => (
  <div className='page-footer-container fixed-bottom'>
    <div className='h-100 d-flex align-items-center'>
      <div className='col-12 footer-buttons-container'>
        <div className={`d-flex flex-row${reversed && '-reverse'} pull-right `}>
          {actionButtons.map((actionButton, index) => (
            <button
              key={index}
              className='btn text-white pull-right ml-3'
              onClick={() => actionButton.onClick()}
              disabled={actionButton.disabled}
              style={actionButton.style}
            >
              <i className={actionButton.icon}></i>
              <span className='ml-2'>{actionButton.text}</span>
            </button>
          ))
          }
        </div>
      </div>
    </div>
  </div>
)

PageFooter.propTypes = {
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      style: PropTypes.object,
      icon: PropTypes.string,
    })),
  reversed: PropTypes.bool,
}

export default PageFooter
