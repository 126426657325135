import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import classNames from 'classnames'

import { Outlet } from 'react-router-dom'

import classes from './SignUpPage.module.scss'

const UTM_PARAMETERS = [
  { key: 'utmSource', param: 'utm_source' },
  { key: 'utmCampaign', param: 'utm_campaign' },
  { key: 'utmMedium', param: 'utm_medium' },
  { key: 'utmTerm', param: 'utm_term' },
  { key: 'utmContent', param: 'utm_content' },
  { key: 'utmId', param: 'utm_id' },
]

const SignUpPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const loggedInUser = useSelector(state => state.user.current_user)

  const navigateToDashboard = loggedInUser?.id && !loggedInUser.account_segment
  const dashboardUrl = localStorage.getItem('embed')
    ? `/dashboard?email=${loggedInUser?.email}&token=${loggedInUser?.authentication_token}`
    : '/dashboard'

  useEffect(() => {
    if (searchParams.get('embed')) {
      localStorage.setItem('embed', true)
    }

    if (searchParams.get('community_id')) {
      localStorage.setItem('communityId', searchParams.get('community_id'))
    }

    if (searchParams.get('invitation_id')) {
      localStorage.setItem('invitationId', searchParams.get('invitation_id'))
    }
  }, [searchParams])

  useEffect(() => {
    if (searchParams.get('community_id')) {
      localStorage.setItem('origin', 'invitation')
    } else if (searchParams.get('embed')) {
      localStorage.setItem('origin', 'free_mobile')
    } else if (localStorage.getItem('redirectUrl')) {
      localStorage.setItem('origin', 'program_registration')
    } else if (searchParams.get('utm_source')) {
      localStorage.setItem('origin', 'campaign')

      UTM_PARAMETERS.forEach(({ key, param }) => {
        const value = searchParams.get(param)
        if (value) {
          localStorage.setItem(key, value)
        }
      })
    }
  }, [dispatch, searchParams])

  useEffect(() => {
    if (navigateToDashboard) {
      navigate(dashboardUrl, { replace: true })
    }
  }, [navigate, navigateToDashboard, dashboardUrl])

  return (
    <>
      {!navigateToDashboard && (
        <>
          <main className={classes.signUpPage}>
            <section className={classNames(classes.currentStep, 'd-flex')}>
              <Outlet />
            </section>

            <ToastContainer theme='colored' />
          </main>
        </>
      )}
    </>
  )
}

export default SignUpPage
