import { handleAPIError } from '../utility/actionHelper'
import { serialize, deserialize } from '../utility/serialization'
import { createAsyncThunk } from '@reduxjs/toolkit'

import Axios from '../axios'

export const createPairings = createAsyncThunk(
  'nineHoleEvent/createPairings',
  async ({ pairings }, { rejectWithValue }) => {
    try {
      const { data } = await requestCreatePairings(pairings)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const updatePairings = createAsyncThunk(
  'nineHoleEvent/updatePairings',
  async ({ pairings }, { rejectWithValue }) => {
    try {
      const { data } = await requestUpdatePairings(pairings)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

// API CALLS

function requestCreatePairings(pairings) {
  const requestUrl = '/api/v1/pairings/'
  return Axios.post(requestUrl, serialize({ pairings: {
    ...pairings,
    pairingGroupsAttributes: pairings.pairingGroupsAttributes
      .sort((a, b) => a._destroy ? 1 : b._destroy ? -1 : 0)
      .map((pairingGroup, index) => ({ ...pairingGroup, order: index + 1 })),
  } }))
}

function requestUpdatePairings(pairings) {
  const requestUrl = `/api/v1/pairings/${pairings.id}`
  const data = { pairings: {
    ...pairings,
    pairingGroupsAttributes: pairings.pairingGroupsAttributes
      .sort((a, b) => a._destroy ? 1 : b._destroy ? -1 : 0)
      .map((pairingGroup, index) => ({ ...pairingGroup, order: index + 1 })),
  } }
  return Axios.put(requestUrl, serialize(data))
}
