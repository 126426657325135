import React, { Component } from 'react'
import PropTypes from 'prop-types'

// ASSETS
import './EducationProgressBar.scss'

class EducationProgressBar extends Component {
  calculateLessonCount = () => {
    const { education } = this.props
    let lessonCount = 0

    education.education_sections.map(
      section => (lessonCount += section.education_lessons.length)
    )

    return lessonCount
  }

  calculatePercentage = () => {
    const { userEducation } = this.props
    const completedLessonCount = userEducation?.completed_lessons?.length || 0

    return Math.round(
      (completedLessonCount / this.calculateLessonCount()) * 100
    )
  }

  calculateEducationSectionCount = () => {
    const { education } = this.props
    const sectionCount = education.education_sections.length

    return `${sectionCount} sections`
  }

  calculateEducationLessonCount = () => {
    const { education } = this.props
    let lessonCount = 0

    education.education_sections.map(
      section => (lessonCount += section.education_lessons.length)
    )

    return `${lessonCount} lessons`
  }

  render() {
    const { userEducation, isCard } = this.props
    return (
      <div id='EducationProgressBar' style={isCard ? { marginTop: '10px' } : {}}>
        {!isCard && (
          <p>
            <span className='completed-lessons'>{userEducation?.completed_lessons?.length || 0}</span> /{' '}
            {this.calculateLessonCount()} Complete
          </p>
        )}

        <div className='progress'>
          <div
            className='progress-bar'
            role='progressbar'
            style={{
              width: `${this.calculatePercentage()}%`,
              backgroundColor: 'var(--teal-dark)',
            }}
            aria-valuemin='0'
            aria-valuemax='100'
          >
          </div>
        </div>
      </div>
    )
  }
}

EducationProgressBar.propTypes = {
  education: PropTypes.shape({
    education_sections: PropTypes.array,
  }),
  userEducation: PropTypes.shape({
    completed_lessons: PropTypes.array,
  }),
  isCard: PropTypes.bool,
}

export default EducationProgressBar
