import { useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import classNames from 'classnames'

import { setUserField, setUserError, userFormData } from '../../../../reducers/signUpReducer'
import { GENDER_SELECT_OPTIONS } from '../../../../misc/Constants'
import { createUserSignIn } from '../../../../actions/authActions'
import colors from '../../../../assets/styles/globals.scss'
import MCElement from '../../../common/MCElement'
import ProgressBar from '../../Helpers/ProgressBar'
import StepButton from '../../Helpers/StepButton'
import AvatarInput from '../../Helpers/AvatarInput'
import FormInput from '../../Helpers/FormInput'
import FormSelect from '../../Helpers/FormSelect'

import classes from './SecondaryInformation.module.scss'

const customErrorMessages = {
  password: 'Password must have at least 6 characters',
  passwordConfirmation: 'Passwords do not match',
}

const SecondaryInformation = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formData = useSelector(userFormData)
  const user = useSelector(state => state.signUp.user)
  const [ageConfirmation, setAgeConfirmation] = useState(false)
  const [termsConfirmation, setTermsConfirmation] = useState(false)
  const [loader, setLoader] = useState(false)

  const { errors, gender, password, passwordConfirmation, avatarUrl } = { ...user }

  const formInvalid = useMemo(() => {
    const anyEmptyFields = !ageConfirmation || !termsConfirmation || !password || !passwordConfirmation || gender === null
    const anyErrors = password.length < 6 || password !== passwordConfirmation

    return anyEmptyFields || anyErrors
  }, [ageConfirmation, termsConfirmation, gender, password, passwordConfirmation])

  const handleInputChange = e => {
    const field = e.target.name
    const value = e.target.value

    dispatch(setUserField({ field, value }))
  }

  const handleAvatarChange = e => {
    if (e.target.value) {
      const file = e.target.files[0]

      dispatch(setUserField({ field: 'avatar', value: file }))
      dispatch(setUserField({ field: 'avatarUrl', value: URL.createObjectURL(file) }))
    }
  }

  const handleAvatarRemove = () => {
    dispatch(setUserField({ field: 'avatar', value: null }))
    dispatch(setUserField({ field: 'avatarUrl', value: '' }))
  }

  const validatePassword = () => {
    if (password && password.length < 6) {
      dispatch(setUserError({ field: 'password', value: customErrorMessages.password }))
    } else {
      dispatch(setUserError({ field: 'password', value: null }))
    }
  }

  const validatePasswordConfirmation = () => {
    if (password && passwordConfirmation && password !== passwordConfirmation) {
      dispatch(setUserError({ field: 'passwordConfirmation', value: customErrorMessages.passwordConfirmation }))
    } else {
      dispatch(setUserError({ field: 'passwordConfirmation', value: null }))
    }
  }

  const handleCreateUser = (user) => {
    setLoader(true)

    dispatch(createUserSignIn(user)).unwrap()
      .then(() => {
        navigate('/sign-up/email-verification-required', { replace: true, state: { from: 'sign-up', email: user.email, firstName: user.firstName } })

        const localStorageItems = ['communityId', 'invitationId', 'origin', 'utmSource', 'utmCampaign', 'utmMedium', 'utmTerm', 'utmContent', 'utmId']
        localStorageItems.forEach(item => localStorage.removeItem(item))
      })
      .catch(() => toast.error('Something went wrong. Please check all steps for errors and fix them.', { position: toast.POSITION.TOP_RIGHT }))
      .finally(() => setLoader(false))
  }

  return (
    <div className={classNames(classes.secondaryInformation, 'd-flex flex-column')}>
      <header className={classes.header}>
        <ProgressBar step={3} label='Your Information'/>
      </header>

      <form className={classNames(classes.mainContent, 'd-flex flex-column align-items-center justify-content-start')}>
        <div className='w-100'>
          <h1 className={classes.titleText}>Final step. Let&apos;s set the password for your account.</h1>
        </div>

        <div className='w-100'>
          <section className='row mb-2'>
            <div className='col-12 col-lg-6'>
              <label htmlFor='gender' className={classes.inputLabel}>
                Gender <span className='required-text'>*</span>
              </label>

              <FormSelect
                id='gender'
                isSearchable={false}
                placeholder='Choose Gender'
                value={GENDER_SELECT_OPTIONS.find((g) => g.value === gender)}
                onChange={(e) => handleInputChange({ target: { name: 'gender', value: e.value } })}
                options={GENDER_SELECT_OPTIONS}
              />

              <label htmlFor='password' className={classes.inputLabel}>
                Password <span className='required-text'>*</span>
              </label>

              <FormInput
                name='password'
                type='password'
                onChange={handleInputChange}
                onBlur={() => { validatePassword(); validatePasswordConfirmation() }}
                value={password}
                error={errors.password}
                symbol={<i className={classNames(classes.inputSymbol, 'fa fa-lock')}/>}
                autoComplete='new-password'
              />

              <label htmlFor='passwordConfirmation' className={classes.inputLabel}>
                Confirm Password <span className='required-text'>*</span>
              </label>

              <FormInput
                name='passwordConfirmation'
                type='password'
                onChange={handleInputChange}
                onBlur={validatePasswordConfirmation}
                value={passwordConfirmation}
                error={errors.passwordConfirmation}
                symbol={<i className={classNames(classes.inputSymbol, 'fa fa-lock')}/>}
                autoComplete='new-password'
              />
            </div>

            <div className='col-12 col-lg-6 mt-4'>
              <AvatarInput
                avatarUrl={avatarUrl}
                onAvatarChange={handleAvatarChange}
                onRemoveAvatar={handleAvatarRemove}
                error={errors.photo}
              />

              <section className={classes.checkboxSection}>
                <div className={classNames(classes.checkboxWrapper, 'd-flex align-items-center')}>
                  <MCElement
                    type='checkbox'
                    isSelected={ageConfirmation}
                    clicked={() => setAgeConfirmation(prevState => !prevState)}
                    primaryColor={colors.primaryOrange}
                    secondaryColor={colors.darkNavy}
                  />
                  <label className={classNames(classes.checkboxLabel, 'ml-3')} htmlFor='ageCheckbox'>I agree that I am over 13 years old</label>
                </div>

                <div className={classNames(classes.checkboxWrapper, 'd-flex align-items-center')}>
                  <MCElement
                    type='checkbox'
                    isSelected={termsConfirmation}
                    clicked={() => setTermsConfirmation(prevState => !prevState)}
                    primaryColor={colors.primaryOrange}
                    secondaryColor={colors.darkNavy}
                  />
                  <label className={classNames(classes.checkboxLabel, 'ml-3')} htmlFor='termsCheckbox'>
                    I agree to the &nbsp;
                    <a href='https://operation36.golf/legal/privacy/' target='_blank' rel='noreferrer' className={classes.checkboxLink}>
                       Privacy Policy &nbsp;
                    </a>
                    and &nbsp;
                    <a href='https://operation36.golf/legal/terms/' target='_blank' rel='noreferrer' className={classes.checkboxLink}>
                      Terms of Use
                    </a>
                  </label>
                </div>
              </section>
            </div>
          </section>
        </div>


        <StepButton onClick={() => handleCreateUser(formData)} color='orange' message='Sign Up' disabled={formInvalid} loader={loader} displayLoader/>

        {!localStorage.getItem('embed') && (
          <StepButton onClick={() => navigate(-1)} color='navy' message='Go Back' />
        )}
      </form>
    </div>
  )
}

export default SecondaryInformation
