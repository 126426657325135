import InfiniteScrollComponent from 'react-infinite-scroll-component'
import PropTypes from 'prop-types'
import LoadingSpinner from '../common/LoadingSpinner'
import styles from './InfiniteScroll.module.scss'
import { ReactComponent as FlagIcon } from '../../assets/images/common/icons/Flag.svg'

const InfiniteScroll = ({ loaderClassName, endOfList, children, ...props }) => (
  <InfiniteScrollComponent
    loader={<LoadingSpinner className={loaderClassName} />}
    {...props}
  >
    {children}
    {endOfList &&
      <div className={styles.endOfList}>
        <FlagIcon className={styles.endIcon} width={19} height={16} />
        <span>End of list</span>
      </div>
    }
  </InfiniteScrollComponent>
)

InfiniteScroll.propTypes = {
  loaderClassName: PropTypes.string,
  endOfList: PropTypes.bool,
  children: PropTypes.node,
}

export default InfiniteScroll
