import PropTypes from 'prop-types'

import classes from './ChoiceInput.module.scss'
import clsNames from 'classnames'

import { ReactComponent as CheckMarkIcon } from '../../../assets/images/common/icons/checkbox-checkmark.svg'


const ChoiceInput = ({ id, type = 'checkbox', classNames, label, readOnly, color, ...props }) => (
  <div className={clsNames(classes.wrapper, 'd-flex align-items-center')}>
    <input
      className={
        clsNames({
          [classNames?.checkBoxInput]: type === 'checkbox',
          [classNames?.radioInput]: type === 'radio',
          [classes[color]]: !!color,
        })
      }
      id={id}
      type={type}
      readOnly={readOnly}
      {...props}
    />
    {type === 'checkbox' && (
      <span
        className={
          clsNames(classes.checkMark, {
            [classes.checkMarkReadOnly]: readOnly,
            [classes[color]]: !!color,
            [classes.checked]: props.checked,
            [classes.disabled]: props.disabled,
          }, classNames?.checkMark)
        }
      >
        <CheckMarkIcon />
      </span>
    )}
    {type === 'radio' && (
      <span className={clsNames(classes.radioDot, classNames?.radioDot)} />
    )}
    {!!label && <label htmlFor={id}>{label}</label>}
  </div>
)

ChoiceInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.any,
  classNames: PropTypes.object,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
}

export default ChoiceInput
