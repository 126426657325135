import PropTypes from 'prop-types'
import StudentCard from './StudentCard'
import ProgramCard from './ProgramCard'
import { requiredIf } from '../../../../utility/prop-types-helper'
import { DUAL_PANEL_SELECTABLE_TYPES } from '../../../../constants/dual-panel-selector-constants'
import SeriesCard from './SeriesCard'

const ItemCard = ({ type, ...props }) => {
  switch (type) {
    case DUAL_PANEL_SELECTABLE_TYPES.students:
      return <StudentCard {...props} />
    case DUAL_PANEL_SELECTABLE_TYPES.programs:
      return <ProgramCard {...props} />
    case DUAL_PANEL_SELECTABLE_TYPES.programsWithPackages:
      return <ProgramCard withAdditionalSelect {...props} />
    case DUAL_PANEL_SELECTABLE_TYPES.series:
      return <SeriesCard {...props} />
    default:
      return null
  }
}

export const ItemCardProps = {
  item: PropTypes.object.isRequired,
  isSelectable: PropTypes.bool,
  isChecked: requiredIf(PropTypes.bool, ({ isSelectable }) => isSelectable),
  onCheckChange: requiredIf(PropTypes.func, ({ isSelectable }) => isSelectable),
  withAdditionalSelect: PropTypes.bool,
  onSelect: requiredIf(PropTypes.func, ({ withAdditionalSelect }) => withAdditionalSelect),
  onSelectDelete: requiredIf(PropTypes.func, ({ withAdditionalSelect }) => withAdditionalSelect),
  className: PropTypes.string,
  hasNewCategory: PropTypes.bool,
}

ItemCard.propTypes = {
  ...ItemCardProps,
  type: PropTypes.oneOf(Object.values(DUAL_PANEL_SELECTABLE_TYPES)),
}

export default ItemCard
