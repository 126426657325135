import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import classNames from 'classnames'

import { showSideBar, showNavBar, setSideBarState } from '../../../actions/commonActions'
import { initializePendo } from '../../../utility/pendo'
import { NAVIGATION } from '../../../misc/Constants'
import { confirmUserEmail } from '../../../actions/authActions'
import Loader from '../../common/Op36Loader-web'

import classes from './ConfirmAccount.module.scss'
import ButtonLink from '../../common/buttons/ButtonLink'

const ConfirmAccount = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loader, setLoader] = useState(false)
  const [error, setError] = useState('')
  const [searchParams] = useSearchParams()
  const confirmationToken = searchParams.get('confirmation_token')

  useEffect(() => {
    setLoader(true)

    dispatch(confirmUserEmail(confirmationToken))
      .then((response) => {
        initializePendo(window.pendo, response)

        dispatch(showSideBar(true))
        dispatch(showNavBar(true))
        dispatch(setSideBarState(NAVIGATION.COLLAPSED))

        navigate('/sign-up/summary', { replace: true })
      })
      .catch((err) => {
        setError(
          err.response.data.errors.email ?
            'The email has already been verified. Please log in.' :
            'The confirmation token is invalid. Please follow the link sent to your email or try to log in.')
      })
      .finally(() => setLoader(false))
  }, [dispatch, confirmationToken, navigate])


  return (
    <div className={classNames(classes.mainCard, 'd-flex align-self-center m-auto')}>
      <div className={classNames(classes.confirmAccount, 'd-flex flex-column')}>
        <main className={classNames(classes.mainContent, 'd-flex flex-column align-items-center justify-content-start')}>
          <div className='w-100 h-100'>
            {(loader && !error) ? (
              <Loader message='loading' />
            ) : (
              <main className='d-flex flex-column w-100 h-100'>
                <h1 className={classes.titleText}>The account could not be confirmed</h1>

                <h4 className={classes.subtitleText}>{error}</h4>

                <div className='w-100 d-flex justify-content-center align-items-center mt-auto'>
                  <ButtonLink navigateTo='/log-in' className={classes.actionLink}>
                    Log In
                  </ButtonLink>
                </div>
              </main>
            )}
          </div>
        </main>
      </div>
    </div>
  )
}

export default ConfirmAccount
