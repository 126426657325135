import { useParams, Link } from 'react-router-dom'
import classNames from 'classnames'

import { ReactComponent as BackArrowIcon } from '../../../../assets/images/common/icons/back-arrow-icon.svg'
import SendEmail from './SendEmail'
import ChangePassword from './ChangePassword'

import classes from './ForgotPasswordPage.module.scss'

const ForgotPasswordPage = () => {
  const params = useParams()

  return (
    <div className={classNames(classes.forgotPasswordPage, 'd-flex')}>
      <aside className={classNames(classes.mainCard, 'm-auto')}>
        {params.token ? (
          <ChangePassword />
        ) :
          <>
            <Link to={'/log-in'}>
              <BackArrowIcon className={classes.backArrowIcon} />
            </Link>
            <SendEmail />
          </>
        }
      </aside>
    </div>
  )
}

export default ForgotPasswordPage
