import PropTypes from 'prop-types'
import classes from './CoachHeaderInfo.module.scss'
import Avatar from '../common/avatar/avatar'
import { ReactComponent as AnnouncementIcon } from '../../assets/images/common/icons/announcement-icon.svg'

const CoachHeaderInfo = ({ coach }) => (
  <div className={classes.container}>
    <div className={classes.coachAvatarContainer}>
      <Avatar
        className='img-responsive'
        imageUrl={coach?.avatar?.file_url}
        hasCoachLabel
        hasBorder
        altName={coach.first_name + ' ' + coach.last_name}
      />
      <div className={classes.profileInfoContainer}>
        <div className={classes.coachNameContainer}>
          <h5 className={classes.coachName}>
            {coach.first_name} {coach.last_name}
          </h5>
          <div className={classes.coachPill}>
            {'Coach'}
          </div>
        </div>
        <div className={classes.coachTitle}>
          {'Coach Announcement'}
        </div>
      </div>
    </div>
    <div className={classes.announcementIcon}>
      <AnnouncementIcon />
    </div>
  </div>
)

CoachHeaderInfo.propTypes = {
  coach: PropTypes.object.isRequired,
}

export default CoachHeaderInfo

