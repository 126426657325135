import PropTypes from 'prop-types'
import Modal from '../../common/Modal'
import classes from './PostAnnouncementModal.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useCallback } from 'react'
import { ReactComponent as FlagIcon } from '../../../assets/images/common/icons/Flag.svg'
import classNames from 'classnames'
import DualPanelSelector from '../../common/dual-panel-selector/DualPanelSelector'
import { DUAL_PANEL_SELECTABLE_TYPES, PER_PAGE } from '../../../constants/dual-panel-selector-constants'
import { getProgramsDualPanelSelectorThunk } from '../../../actions/programActions'
import { toast } from 'react-toastify'
import { categoryCompare } from '../../../utility/programHelper'
import { selectProgramsData, selectSeriesData, setSearchValue } from '../../../reducers/dualPanelSelectorReducer'
import SubmitButton from '../../common/buttons/SubmitButton'
import { getAllSeries } from '../../../actions/seriesActions'
import PostAnnouncementForm from './PostAnnouncementForm'
import { addSelectedProgram, deleteSelectedProgram, selectSelectedPrograms } from '../../../reducers/programDashboardReducer'
import { addSelectedSeries, deleteSelectedSeries, selectSelectedSeries } from '../../../reducers/programDashboardReducer'

const PostAnnouncementModal = ({ isOpen, onClose, isProgram }) => {
  const [formStep, setFormStep] = useState(0)
  const [programsText, setProgramsText] = useState('Select Programs / 9 Hole Events')
  const dispatch = useDispatch()
  const programsData = useSelector(selectProgramsData)
  const selectedPrograms = useSelector(selectSelectedPrograms)
  const seriesData = useSelector(selectSeriesData)
  const selectedSeries = useSelector(selectSelectedSeries)
  const program = useSelector(state => state.programDashboard.program)

  const handleProgramsTextChange = useCallback(() => {
    const selectedProgramsCount = selectedPrograms.length + selectedSeries.length
    const othersText = ` + ${selectedProgramsCount - 1} Others`

    if (selectedPrograms.length || selectedSeries.length) {
      setProgramsText(`${selectedPrograms[0]?.name || selectedSeries[0]?.title}` + `${selectedProgramsCount > 1 ? othersText : ''}`)
    } else {
      setProgramsText('Select Programs / 9 Hole Events')
    }
  }, [selectedPrograms, selectedSeries])

  useEffect(() => {
    if (isProgram) {
      dispatch(addSelectedProgram(program))
    }
    handleProgramsTextChange()
  }, [handleProgramsTextChange, program, dispatch, isProgram])

  const fetchPrograms = useCallback((page, searchTerm, completed) =>
    dispatch(getProgramsDualPanelSelectorThunk({ page, perPage: PER_PAGE, searchTerm, completed }))
      .unwrap()
      .catch(() => toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT })), [dispatch])


  const fetchSeries = useCallback((page, searchTerm, completed) =>
    dispatch(getAllSeries({ filters: { search: searchTerm, completed }, page: page, perPage: PER_PAGE }))
      .unwrap()
      .catch(() => toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT })), [dispatch])

  const handleCheckProgram = (checked, course) => {
    if (checked) {
      dispatch(deleteSelectedProgram(course.id))
    } else { dispatch(addSelectedProgram(course)) }
  }

  const handleCheckSeries = (checked, series) => {
    if (checked) {
      dispatch(deleteSelectedSeries(series.id))
    } else {
      dispatch(addSelectedSeries(series))
    }
  }

  const leftPanelProps = {
    tabTypes: [DUAL_PANEL_SELECTABLE_TYPES.programs, DUAL_PANEL_SELECTABLE_TYPES.series],
    tabProps: {
      [DUAL_PANEL_SELECTABLE_TYPES.programs]: {
        titleText: `Select Programs (${selectedPrograms.length})`,
        allItems: programsData.items,
        withCompleted: true,
        searchValue: programsData.searchValue,
        onSearchValueChange: (searchValue) => dispatch(setSearchValue({ searchValue, key: DUAL_PANEL_SELECTABLE_TYPES.programs })),
        getSelectedItem: (id) => selectedPrograms.find(program => program.id === id),
        fetchItems: fetchPrograms,
        pagination: programsData.pagination,
        onCheckChange: handleCheckProgram,
        itemCategoryTitle: (program) => program.category ? program.category.name : 'Other Programs',
        itemCategoryCompare: categoryCompare,
      },
      [DUAL_PANEL_SELECTABLE_TYPES.series]: {
        titleText: `Select Series (${selectedSeries.length})`,
        allItems: seriesData.items,
        withCompleted: false,
        searchValue: seriesData.searchValue,
        onSearchValueChange: (searchValue) => dispatch(setSearchValue({ searchValue, key: DUAL_PANEL_SELECTABLE_TYPES.series })),
        getSelectedItem: (id) => selectedSeries.find(series => series.id === id),
        fetchItems: fetchSeries,
        pagination: seriesData.pagination,
        onCheckChange: handleCheckSeries,
      },
    },
    withSwitcherInsteadOfTabs: false,
    switcherPageTitle: 'Title',
    switcherPageInfoText: 'Text',
  }

  const SummaryPlaceholder = () => (
    <div className={classes.placeholder}>
      <FlagIcon className={classes.icon} />
      <h2 className={classes.title}>No Programs / Events Added</h2>
      <p className={classes.description}>Add your events or programs to continue</p>
    </div>
  )

  const totalStudents =
   selectedPrograms.reduce((acc, program) => acc + program.enrollments.length, 0) +
   selectedSeries.reduce((acc, series) => acc + series.students.length, 0)

  const rightPanelText =
  `Summary (${selectedPrograms.length} Programs,
   ${selectedSeries.length} Events, ${totalStudents} Students)`

  const rightPanelProps = {
    isAnnouncement: true,
    selectedItems: [...selectedPrograms, ...selectedSeries],
    selectedItemType: DUAL_PANEL_SELECTABLE_TYPES.programs,
    summaryTitle: rightPanelText,
    summaryPlaceholder: <SummaryPlaceholder />,
  }

  const programSelectionForm = () => ( <div className={classNames('d-flex flex-column', classes.modalContentWrapper)}>
    <DualPanelSelector className={classes.dualPanelSelector}{...leftPanelProps} {...rightPanelProps}/>
    <div className={classes.submitButtonContainer}>
      <div className={classes.button}>
        <SubmitButton disabled={false} buttonMessage='Continue' onClick={() => setFormStep(0)}/>
      </div>
    </div>
  </div>)

  const renderForm = () => {
    switch (formStep) {
      case 0:
        return <PostAnnouncementForm isProgram={isProgram} nextStep={setFormStep} onClose={onClose} programsText={programsText}/>
      case 1:
        return programSelectionForm()
      default: null
    }
  }

  return <Modal isOpen={isOpen} onClose={onClose} className={classNames(classes.modal, { [classes.modalPrograms]: formStep === 1 })}>
    {renderForm()}
  </Modal>
}

PostAnnouncementModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isProgram: PropTypes.bool,
}

export default PostAnnouncementModal
