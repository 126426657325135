import PropTypes from 'prop-types'
import classNames from 'classnames'

import classes from './StepButton.module.scss'

const StepButton = ({ onClick, color, message, disabled, loader, displayLoader }) => (
  <button
    type='button'
    onClick={onClick}
    className={classNames(classes.stepButton, 'btn btn-primary', color === 'orange' ? classes.orange : classes.navy)}
    disabled={disabled || loader}
  >
    {loader && displayLoader && <i className='fa fa-spinner fa-spin mr-2' />}
    {message}
  </button>
)

StepButton.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
  message: PropTypes.string,
  disabled: PropTypes.bool,
  loader: PropTypes.bool,
  displayLoader: PropTypes.bool,
}

export default StepButton
