/* eslint-disable complexity */
/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as userActions from '../actions/userActions'
import * as consentActions from '../actions/consentActions'

import '../assets/styles/components/App.scss'
// import { DragDropContext } from "react-beautiful-dnd";
import Modal from 'react-modal'

import LoadingBar from 'react-redux-loading-bar'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// import CONSENT from "../constants/consentDocuments.json";

import CommunityBlockModal from './common/CommunityBlockModal'
import ConsentContainer from './consent/ConsentContainer'
import { Outlet } from 'react-router-dom'
import { withRouting } from './common/hooks'
import { initializePendo } from '../utility/pendo'
import { startIntercom } from '../utility/intercom'
import ExpandableSideBar from './common/SideBar/ExpandableSideBar'
import Loader from './common/Op36Loader-web'

// const modalStyles = {
//   content: {
//     zIndex: 999999,
//     height: "500px",
//     width: "50%",
//     marginTop: "5%",
//     overflowX: "scroll",
//     borderRadius: "8px",
//   },
//   overlay: {
//     zIndex: 999999,
//   },
// };

class AppWrapper extends Component {
  state = {
    showConsentModal: false,
    showCommunityBlockModal: false,
    getConsent: [],
    consentRequested: false,
    consentType: null,
    embed: localStorage.getItem('embed'),
  }

  componentDidMount() {
    const { user } = this.props
    if (user) {
      this.props.user_actions.refreshCurrentUser(user.id).then(() => {
        initializePendo(window.pendo, user)

        if (user.type === 'Coach') {
          startIntercom(user)
        }
      })
    }

    localStorage.removeItem('embed')
  }

  componentDidUpdate(prevProps) { // TODO: Check this, replaced componentWillReceiveProps
    if (!this.props.user || this.props.user === prevProps.user) {
      return
    }

    if (!window.pendo) {
      // eslint-disable-next-line no-console
      console.log('no pendo')
    }

    this.getUserConsent(this.props.user)

    if (
      this.props.user &&
      this.props.user.type !== 'Admin' &&
      this.props.user.communities &&
      this.props.user.communities.length > 0
    ) {
      this.checkActiveCommunity(this.props.user)
    }

    if (this.props.user && this.props.user.type === 'Coach') {
      if (window.$zopim && window.$zopim.livechat) {
        window.$zopim.livechat.button.show()
      }
      if (
        window.$zopim &&
        window.$zopim.livechat &&
        window.$zopim.livechat.button
      ) {
        window.$zopim.livechat.button.setOffsetHorizontal(65)
        window.$zopim.livechat.window.setOffsetHorizontal(65)
      }
    } else if (this.props.user && this.props.user.type === 'Student') {
      if (window.$zopim && window.$zopim.livechat) { window.$zopim.livechat.hideAll() }
    } else if (this.props.user && this.props.user.type === 'Admin') {
      if (window.$zopim && window.$zopim.livechat) { window.$zopim.livechat.hideAll() }
    }
  }

  checkActiveCommunity = (user) => {
    const { location } = this.props
    const COMMUNITY_ACTIVE =
      user.communities.length > 0 && user.communities[0].is_active

    if (
      (user.type === 'Coach' || user.type === 'Student') &&
      !COMMUNITY_ACTIVE &&
      location.pathname !== '/log-in'
    ) { this.launchCommunityBlockModal() }

    if (location.pathname === '/log-in') { this.closeCommunityBlockModal() }
    if (location.pathname.indexOf('/sign-up') > -1) { this.closeConsentModal() }
  }

  getUserConsent = (user, forceUpdate = false) => {
    const { consentRequested } = this.state
    const { location } = this.props
    if (
      (!this.props.consents.length > 0 && !consentRequested) ||
      user.id !== consentRequested ||
      forceUpdate
    ) {
      this.setState({ consentRequested: user.id }, () => {
        this.props.consent_actions
          .getUserConsents(user.id)
          .then(this._checkConsent)
      })
    }

    if (location.pathname === '/log-in') { this.closeConsentModal() }
    if (location.pathname.indexOf('/sign-up') > -1) { this.closeConsentModal() }
  }

  _checkConsent = () => {
    const { consents } = this.props

    if (consents.age_needed) {
      return this.setState({ showConsentModal: true, consentType: 'userAge' })
    }
    if (consents.contact_support) {
      return this.setState({
        showConsentModal: true,
        consentType: 'contactSupport',
      })
    }
    if (consents.username_needed) {
      return this.setState({
        showConsentModal: true,
        consentType: 'familyAdminDowngrade',
      })
    }
    if (consents.needs_proxy) {
      return this.setState({
        showConsentModal: true,
        consentType: 'needsProxy',
      })
    }
    if (this._needsToShowConsent(consents)) {
      return this.setState({
        // showConsentModal: true,
        consentType: null,
      })
    }
  }

  _needsToShowConsent = (consents) => {
    const familyAge =
      consents.family_ages_needed && consents.family_ages_needed.length > 0
    const familyConsents =
      consents.family_consents_needed &&
      consents.family_consents_needed.length > 0
    const personalConsents =
      !consents.privacy_policy.consented ||
      !consents.eula.consented ||
      !consents.saas_agreement.consented
    return familyAge || familyConsents || personalConsents
  }

  // _showPersonalConsent = () => {
  //   const { consents } = this.props;
  //   let needsConsentModal = false;
  //   for (const [consentName, consentData] of Object.entries(consents)) {
  //     if (!consentData.consented) {
  //       needsConsentModal = true;
  //       break;
  //     }
  //   }
  //   this.setState({ showConsentModal: needsConsentModal });
  // };

  launchCommunityBlockModal = () => {
    this.setState({
      showCommunityBlockModal: true,
    })
  }

  launchConsentModal = (getConsent) => {
    this.setState({
      getConsent,
      showConsentModal: true,
    })
  }

  closeConsentModal = () => {
    this.setState({ showConsentModal: false })
  }

  closeCommunityBlockModal = () => {
    this.setState({
      showCommunityBlockModal: false,
    })
  }

  showNav = () => {
    if ((!this.props.ui.showNavBar && !this.props.ui.showTabBar) || this.props.user.type === 'Student') {
      return 'no-nav'
    } else {
      return ''
    }
  }

  render() {
    const { consentType, embed } = this.state

    return (
      <div className='content'>
        <div className='App'>
          {embed ? (
            <Loader />
          ) : (
            <>
              {this.props.user.type !== 'Student' && (
                <ExpandableSideBar />
              )}

              <div id='appWebBody' className={this.showNav()}>
                <LoadingBar
                  className='loading-bar'
                  updateTime={50}
                  maxProgress={95}
                  progressIncrease={10}
                  showFastActions
                />
                <div className='container-fluid'>
                  {/* <DragDropContext> */}
                  <Outlet />
                  {/* </DragDropContext> */}
                </div>
              </div>
              <Modal
                isOpen={this.state.showConsentModal}
                // onRequestClose={this.closeConsentModal}
                contentLabel='Consent Modal'
                // style={modalStyles}
                className='customModal'
              >
                <ConsentContainer
                  currentUser={this.props.user}
                  consentType={consentType}
                  consents={this.props.consents}
                  submitUsername={this._submitUsername}
                  submitOwnBirthdate={this._submitOwnBirthdate}
                  submitFamilyBirthdates={this._submitFamilyBirthdates}
                  submitConsents={this._submitConsents}
                  submitFamilyConsents={this._submitFamilyConsents}
                  closeModal={this.closeConsentModal}
                />
              </Modal>
              <CommunityBlockModal
                showModal={this.state.showCommunityBlockModal}
                closeModal={this.closeCommunityBlockModal}
              />
            </>
          )}
        </div>
        <ToastContainer theme='colored' />
      </div>
    )
  }

  _submitOwnBirthdate = (birthdate) => {
    const { user, user_actions } = this.props
    user_actions
      .updateCurrentUser({ id: user.id, birthdate: birthdate })
      .then(() => {
        this.setState({ consentType: null, showConsentModal: false })
      })
      .finally(() => {
        this.getUserConsent(user, true)
      })
  }

  _submitUsername = (username) => {
    const { user, user_actions } = this.props
    user_actions
      .updateCurrentUser({
        id: user.id,
        username: username,
        family_admin: false,
        email: null,
      })
      .then(() => {
        this.setState({ consentType: null, showConsentModal: false })
      })
      .finally(() => {
        this.getUserConsent(user, true)
      })
  }

  _submitFamilyBirthdates = (birthdates) => {
    const { user, user_actions } = this.props
    Promise.all(
      birthdates.map((u) => user_actions.updateUser(u))
    )
      .then(() => {
        this.setState({ consentType: null, showConsentModal: false })
      })
      .finally(() => this.getUserConsent(user, true))
  }

  _submitFamilyConsents = () => {
    const { user, consent_actions } = this.props
    consent_actions
      .postFamilyConsents()
      .then(() => {
        this.setState({ consentType: null, showConsentModal: false })
      })
      .finally(() => this.getUserConsent(user, true))
  }

  _submitConsents = (consentArray) => {
    const { user, consent_actions } = this.props
    consent_actions
      .postConsents(consentArray, user.id)
      .then(this.closeConsentModal)
  }
}

AppWrapper.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    created_at: PropTypes.string,
    intercom_hash: PropTypes.string,
    user_type: PropTypes.string,
    lead_coach: PropTypes.bool,
    communities: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      license_type: PropTypes.string,
      representative_name: PropTypes.string,
      student_count: PropTypes.number,
      created_at: PropTypes.string,
    })),
  }),
}

function mapStateToProps(state, _ownProps) {
  return {
    user: state.user.current_user,
    isLoading: state.loading.isLoading,
    consents: state.consents,
    ui: state.ui,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    user_actions: bindActionCreators(userActions, dispatch),
    consent_actions: bindActionCreators(consentActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(AppWrapper))
