import DatePicker from '../../common/form/DatePicker'
import Droppable from './Droppable'
import StudentCard from '../Pairings/StudentCard'
import DraggableStudent from './DraggableStudent'
import _ from 'lodash'
import moment from 'moment'
import { ReactComponent as XIcon } from '../../../assets/images/common/icons/X-icon.svg'
import { ReactComponent as TimeIcon } from '../../../assets/images/common/icons/time-icon.svg'
import { getMomentInCommunityTimezoneReversed, DATETIME_FORMAT } from '../../../utility/datesHelper'
import classNames from 'classnames'
import { DragOverlay, defaultDropAnimation, defaultDropAnimationSideEffects } from '@dnd-kit/core'


import styles from './ManagePairings.module.scss'
import { useSelector } from 'react-redux'

const TeeTimesPairings = ({
  event,
  pairings,
  filteredPairingGroups,
  handlePairTimeChange,
  removePairingGroup,
  removeStudent,
  isOver,
  draggingFromGroupId,
}) => {
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)

  return filteredPairingGroups.map((pairingGroup, pairIndex) => (
    <div key={pairIndex} className={classNames('d-flex flex-column mt-3 p-3', styles.pairWrapper)}>
      <div className='d-flex justify-content-between ml-2 w-100'>
        <div className='d-flex align-items-center'>
          <span className='mr-4'>Pairing Group {pairIndex + 1}</span><TimeIcon className={classNames('mr-3', styles.timeIcon)}/>
          <div className='w-25'>
            <DatePicker
              selected={getMomentInCommunityTimezoneReversed(moment(pairingGroup.startTime), timezone, DATETIME_FORMAT).toDate()}
              className={styles.pairTime}
              inputClassNames={{ inputContainer: styles.pairTimeContainer }}
              onChange={(startTime) => handlePairTimeChange(startTime, pairingGroup)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={1}
              timeCaption='time'
              dateFormat='h:mmaaa'
              autoComplete='off'
            />
          </div>
        </div>
        <XIcon className={classNames('pull-right', styles.removePairButton)} onClick={() => removePairingGroup(pairingGroup)}/>
      </div>
      <div className='d-flex flex-wrap w-100'>
        {_.range(0, pairings.golfersCount.value).map((index) => (
          <Droppable key={`${pairIndex}${index}`} id={`${pairIndex}${index}`} data={{ index: index, pairingGroup: pairingGroup }}>
            <div
              key={index}
              className={classNames(
                'mx-2 mt-4',
                styles.golferWrapper,
                isOver.index === index && isOver.groupIndex === pairingGroup.index && styles.isOver
              )}>
              {pairingGroup.users[index] &&
              <>
                <DraggableStudent
                  key={index}
                  student={pairingGroup.users[index]}
                  draggingId={draggingFromGroupId}
                  draggedFrom={{ pairIndex: pairingGroup.index, index: index }}
                  dragged={true}
                  removeStudent={() => removeStudent(pairingGroup, index)}
                />
                <DragOverlay dropAnimation={{
                  ...defaultDropAnimation,
                  duration: 200,
                  sideEffects: defaultDropAnimationSideEffects({
                    styles: {
                      active: {
                        opacity: '1',
                      },
                    },
                  }),
                }}>
                  {draggingFromGroupId !== -1 && <StudentCard student={event.students.find((s) => s.id === draggingFromGroupId)}/>}
                </DragOverlay>
              </>
              }
            </div>
          </Droppable>
        ))}
      </div>
    </div>
  ))
}



export default TeeTimesPairings
