import classnames from 'classnames'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import { deserialize } from '../../../../utility/serialization'
import { requestGetProgramEnrollmentsSummary } from '../../../../actions/programActions'
import LeaderboardWidget from './LeaderboardWidget'
import StudentInformationWidget from './StudentInformationWidget'
import BusinessSummaryWidget from './BusinessSummaryWidget'

import classes from './StatsPanel.module.scss'

const StatsPanel = () => {
  const { program } = useSelector(state => state.programDashboard)

  const [enrollmentsSummary, setEnrollmentsSummary] = useState(null)
  const [isLoadingSummary, setIsLoadingSummary] = useState(true)

  useEffect(() => {
    requestGetProgramEnrollmentsSummary(program?.id)
      .then(response => {
        setEnrollmentsSummary(deserialize(response.data).enrollmentsSummary)
        setIsLoadingSummary(false)
      })
      .catch(() => toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT }))
  }, [program?.id])

  return (
    <div className={classnames('h-100 p-3 d-flex justify-content-center', classes.statsPanel)}>
      <LeaderboardWidget />
      <div className={classnames('pl-4 d-flex flex-column', classes.summarySection)}>
        <BusinessSummaryWidget
          isLoading={isLoadingSummary}
          businessSummary={enrollmentsSummary?.businessSummary}
          packages={enrollmentsSummary?.packages}
        />
        <StudentInformationWidget
          isLoading={isLoadingSummary}
          studentsInfo={enrollmentsSummary?.studentsInfoSummary}
        />
      </div>
    </div>
  )
}

export default StatsPanel
