import React, { Component, Fragment } from 'react';
import html2canvas from 'html2canvas';
import QRCode from 'qrcode.react';
import { jsPDF } from 'jspdf'

import './HowToUseAppGuide.scss';

import HorizontalLogo from '../../common/logoSVGs/HorizontalLogo';
import registrationMockup from './01-register-for-account.png';
import downloadMockup from './02-download-mobile-app.png';
import featuresMockup from './03-app-features.png';
// import qrCircleOne from './qr-code-1-circle.png';
// import qrCircleTwo from './qr-code-2-circle.png';

const MOBILE_DOWNLOAD_LINK = 'https://qrco.de/bbQ6EO';
const MOBILE_DOWNLOAD_LINK_SHORT =
  MOBILE_DOWNLOAD_LINK.substring(MOBILE_DOWNLOAD_LINK.indexOf('://') + 3);
const FEATURES = [
  {
    title: 'Check for Coach Announcements',
    desc: 'Stay up-to-date with coach program communication by visiting your Class/Group page and viewing the Announcements tab. Coaches centralize communication for the program here.'
  },
  {
    title: 'Play Golf - Find Your Tee-Box & Log Scores',
    desc: 'Earn 50 Points for your community when you play golf! ' +
          'The Play feature allows you to use the GPS to find your Op 36 Level Tee-Box and digitally track your stats.',
  }, {
    title: 'Train Your Skills - Know What to Practice',
    desc: 'Earn 25 Points for your community when you train your skills! Level up your skills by tracking time put into each skill.'
  }, {
    title: 'Learn Skills - The Op 36 Curriculum Videos',
    desc: 'Every student has access to Level 1 of the Op 36 Curriculum. Coaches will cover these skills in classes and mark them complete when you master a skill. Complete all 12 to unlock Level 2!'
  }, {
    title: 'The Global Challenge - Connect & Encourage',
    desc: 'Every golf action you log earns points for your community and helps it climb the Monthly Global Challenge Leaderboard! Be sure to connect and encourage others in your community feed as well!'
  }
];

class HowToUseAppGuide extends Component {

  state = {
    downloadPDFLoader: false
  }

  _generateFeaturesList = () => {
    return (
      FEATURES.map((item, idx) => (
        <Fragment key={idx}>
          <h6 id={'title' + idx}>{item.title}</h6>
          <p id={'desc' + idx}>{item.desc}</p>
        </Fragment>
      ))
    );
  };

  _getHowToUseAppPDF = () => {
    this.setState({ downloadPDFLoader: true }, () => {
      const report = document.getElementById('how-to-use-app-page');
      html2canvas(report, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        // const pdf = new window.jspdf.jsPDF("p", "in", [8.5, 11], true);
        const pdf = new jsPDF({
          unit: 'in',
          format: [8.5, 11],
          compress: true
        })
        pdf.addImage(imgData, "PNG", 0, 0, 8.5, 11, "", "FAST");
        pdf.save("How-to-Use-Op-36-Guide");
        this.setState({ downloadPDFLoader: false });
      });

      // html2canvas(report, {
      //   scale: 2,
      //   onclone: function(clone) {
      //     clone.getElementById('how-to-use-app-page').style.display = 'block';
      //   }
      // }).then((canvas) => {
      //   const imgData = canvas.toDataURL("image/png");
      //   const pdf = new window.jspdf.jsPDF("p", "in", [8.5, 11], true);
      //   pdf.addImage(imgData, "PNG", 0, 0, 8.5, 11, "", "FAST");
      //   pdf.save("How-to-Use-Op-36-Guide");
      //   this.setState({ downloadPDFLoader: false });
      // });
    });
  };

  render() {
    const { commId } = this.props;
    const { downloadPDFLoader } = this.state;

    let url = window.location.origin

    const regFormLink = `${url}/sign-up?step=1&community_id=${commId}`
    const regFormLinkShort = regFormLink.substring(regFormLink.indexOf('://') + 3);

    return (
      <div className='how-to-use-app-wrapper'>
        <h2>Get your families to register for Op 36 accounts, download the mobile app, and explore its different features!</h2>
        <button
          className='btn btn-primary'
          onClick={this._getHowToUseAppPDF}
          disabled={downloadPDFLoader}
        >
          <i className='fa fa-file-pdf-o' aria-hidden='true' />{" "}
          {downloadPDFLoader ? "Downloading PDF guide..." : "Download this PDF guide to share"}
        </button>
        <div id='how-to-use-app-page-scroll-container'>
          <div id='how-to-use-app-page'>
            <div id='how-to-use-app-page-content'>
              <div className='how-to-use-app-title'>
                <h3>Getting Started with the Operation 36 Golf Mobile App</h3>
                <HorizontalLogo colorScheme='light' classes='how-to-use-app-title-logo'/>
              </div>
              <div className='how-to-use-app-steps'>
                <div className='how-to-use-app-part-1'>
                  <div className='how-to-use-app-step-1'>
                    <h4>Step 1: Create your account!</h4>
                    <div
                      className='how-to-use-app-qr-phone'
                      id='how-to-use-app-qr-phone-1'>
                      <img
                        className='how-to-use-app-qr-mockup'
                        src={registrationMockup}
                        alt='Op 36 acount registration form' />
                      <QRCode
                        value={`${url}/sign-up?step=1&community_id=${commId}`}
                        fgColor={"#1d2933"}
                        renderAs={"svg"}
                        includeMargin />
                    </div>
                    <p className='how-to-use-app-url'>
                      <a href={regFormLink} target='_blank' rel='noreferrer'>{regFormLinkShort}</a>
                    </p>
                    <p>Scan the QR code or enter the link. Then fill out the form to <strong>create profiles for yourself and your family!</strong></p>
                  </div>
                  <i className='fa fa-arrow-right' aria-hidden='true' />
                  <div className='how-to-use-app-step-2'>
                    <h4>Step 2: Download the Op 36 mobile app!</h4>
                    <div
                      className='how-to-use-app-qr-phone'
                      id='how-to-use-app-qr-phone-2'>
                      <img
                        className='how-to-use-app-qr-mockup'
                        src={downloadMockup}
                        alt='Op 36 mobile app login screen' />
                      <QRCode
                        value={MOBILE_DOWNLOAD_LINK}
                        fgColor={"#1d2933"}
                        renderAs={"svg"}
                        includeMargin />
                    </div>
                    <p className='how-to-use-app-url'>
                      <a href={MOBILE_DOWNLOAD_LINK} target='_blank' rel='noreferrer'>
                        {MOBILE_DOWNLOAD_LINK_SHORT}
                      </a>
                    </p>
                    <p>Scan the QR code or go to the App/Play Store, <strong>download the Op 36 Golf app, and log in with the family admin account</strong> that you created in Step 1.</p>
                  </div>
                </div>
                <div className='how-to-use-app-part-2'>
                  <h4>Step 3: Use the mobile app to guide and track your golf journey!</h4>
                  <h5>The Op 36 App is your tool to guide you outside of your program while connecting you with your coach.</h5>
                  <div className='how-to-use-app-feature-wrapper'>
                    <img
                      className='how-to-use-app-feature-graphic'
                      src={featuresMockup}
                      alt='Features in the Op 36 mobile app' />
                    <div className='how-to-use-app-feature-text'>
                      {this._generateFeaturesList()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HowToUseAppGuide;
