import classnames from 'classnames'
import Modal from '../../../common/Modal'
import SubmitButton from '../../../common/buttons/SubmitButton'
import Input from '../../../common/form/Input'
import { useState } from 'react'

import classes from './AddStudentsConfirmationModal.module.scss'
import { ReactComponent as CompletedIcon } from '../../../../assets/images/common/icons/check-circle-white.svg'
import PropTypes from 'prop-types'
import { selectSelectedStudents } from '../../../../reducers/programDashboardReducer'
import { useDispatch, useSelector } from 'react-redux'
import { addEnrollmentManually } from '../../../../actions/enrollmentActions'
import { toast } from 'react-toastify'

const AddStudentsConfirmationModal = props => {
  const dispatch = useDispatch()

  const program = useSelector(state => state.programDashboard.program)
  const selectedStudents = useSelector(selectSelectedStudents)

  const [message, setMessage] = useState('')

  const handleConfirmButtonClick = () => {
    const actions = selectedStudents.map(user => dispatch(addEnrollmentManually({ user, program, message })))

    Promise.all(actions)
      .then(() => toast.success('Enrollments added successfully.', { position: toast.POSITION.TOP_RIGHT }))
      .catch(() => toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT }))
      .finally(() => props.onClose())

    setMessage('')
  }

  return (
    <Modal className={classnames('px-5', classes.modal)} {...props}>
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <CompletedIcon className={classnames('mb-4', classes.completedIcon)} />
        <h1 className={classes.title}>Final Review Would you like to add a Message?</h1>
        <p className={classnames('my-3', classes.subTitle)}>
          {selectedStudents.length} students are going to be added to this program and will be notified. Feel free to write a custom message to
          include in the email, or simply hit &#34;Add Students&#34; button
        </p>
        <Input
          name='description'
          type='textarea'
          onChange={e => setMessage(e.target.value)}
          placeholder='Insert your message to golfers here (optional)'
          value={message}
          classNames={{ inputContainer: classes.coachMessageInputContainer }}
        />
      </div>
      <div>
        <div className='row py-3'>
          <div className='col-12'>
            <SubmitButton
              onClick={() => handleConfirmButtonClick()}
              buttonMessage='Add Students'
              buttonColor='orange-button'
            />
          </div>
          <div className='col-12'>
            <SubmitButton
              onClick={() => props.onClose()}
              buttonMessage='Cancel'
              buttonColor='navy-button'
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

AddStudentsConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default AddStudentsConfirmationModal
