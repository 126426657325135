import { useSearchParams } from 'react-router-dom'
import Modal from '../common/Modal'
import classNames from 'classnames'
import AccountSidebar from '../account/AccountForm/AccountSidebar'
import { useSelector } from 'react-redux'
import { updateUser } from '../../actions/userActions'

import classes from './EditGolferModal.module.scss'
import { AccountContext } from '../account/AccountContext'
import AccountFormDispatcher from '../account/AccountFormDispatcher'
import { hasUserWriteAccess } from '../../utility/userHelper'
import { ToastContainer } from 'react-toastify'


const EditGolferModal = () => {
  const user = useSelector(state => state.user.user)
  const currentUser = useSelector(state => state.user.current_user)
  const [searchParams, setSearchParams] = useSearchParams()

  const isOpen = searchParams.has('editTab') && hasUserWriteAccess(currentUser, user)
  const submitDisabled = !hasUserWriteAccess(currentUser, user) && currentUser.type === 'Coach' && user.type !== 'Coach'

  const handleClose = () => {
    if (searchParams.has('editTab')) {
      searchParams.delete('editTab')
      setSearchParams(searchParams)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      contentLabel='EditGolferModal'
      className={classNames(classes['edit-golfer-modal-container'])}
      title='Edit Golfer Account'
      onClose={handleClose}
    >
      <div className={classNames(classes.content, 'd-flex col-md-9 mx-auto')}>
        <AccountContext.Provider value={{
          user,
          isProfile: true,
          submitDisabled,
          updateAction: updateUser,
        }}>
          <div className='col-md-4'>
            <AccountSidebar />
          </div>
          <div className='min-h-100 border-left' />
          <div className='col-md-8'>
            <ToastContainer theme='colored' />
            <AccountFormDispatcher />
          </div>
        </AccountContext.Provider>
      </div>
    </Modal>
  )
}

export default EditGolferModal
