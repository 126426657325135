import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ReactComponent as RoundedPlusIcon } from '../../../assets/images/common/icons/rounded-plus-icon.svg'
import { ReactComponent as CloseIcon } from '../../../assets/images/common/icons/modal-X-icon.svg'
import AvatarPlaceholder from '../../../assets/images/mock/default-profile-icon.png'
import FieldError from '../../common/FieldError'

import classes from './AvatarInput.module.scss'

const AvatarInput = ({ avatarUrl, onAvatarChange, onRemoveAvatar, error, imageSize }) => (
  <div className={classNames(classes.avatarInput, 'd-flex')}>
    <div className={classes.avatarWrapper}>
      <div className={classNames(classes.avatar, `${imageSize || 'medium'}-round-image-wrapper`)}>
        <img
          className='round-image'
          src={avatarUrl || AvatarPlaceholder}
          alt='User Avatar'
        />
      </div>

      {avatarUrl && (
        <button type='button' className={classNames(classes.removeAvatarButton, 'btn btn-primary')} onClick={onRemoveAvatar}>
          <CloseIcon className={classNames(classes.removeAvatarIcon)} />
        </button>
      )}
    </div>

    <input id='avatar' type='file' accept='image/*' className='d-none' onChange={onAvatarChange} />
    <label htmlFor='avatar' className={classNames(classes.avatarLabel, 'd-flex align-items-center')}>
      <RoundedPlusIcon className={classes.roundedPlusIcon}/>
      <span className={classes.avatarText}>
        {avatarUrl ? 'Upload Another Photo' : 'Add Photo (Optional)'}
      </span>

      {error && <FieldError message={error} />}
    </label>
  </div>
)

AvatarInput.propTypes = {
  avatarUrl: PropTypes.string,
  onAvatarChange: PropTypes.func,
  onRemoveAvatar: PropTypes.func,
  error: PropTypes.string,
  imageSize: PropTypes.string,
}

export default AvatarInput
