import { createSlice, createSelector } from '@reduxjs/toolkit'
import { deleteEnrollment, getEvent, createEvent, updateEvent, deleteEvent } from '../actions/eventActions'
import { createPairings, updatePairings } from '../actions/pairingsActions'
import { updateSeriesEnrollment } from '../actions/seriesEnrollmentActions'
import { getLevel, yardageByLevel } from '../utility/curriculumHelper'
import moment from 'moment'
import { DATETIME_FORMAT, getMomentInCommunityTimezoneReversed } from '../utility/datesHelper'

const PAIRINGS_INIT_VALUE = {
  id: -1,
  groupsIndex: 0,
  format: '',
  startTime: '',
  interval: 10,
  teeTimeSlots: 1,
  golfersCount: { label: '3', value: 3 },
  holesCount: {
    1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0, 13: 0, 14: 0, 15: 0, 16: 0, 17: 0, 18: 0,
  },
  status: '',
  pairingGroups: [],
  notifyStudents: true,
}

const initialState = {
  id: -1,
  selectedEvent: {},
  eventLoader: false,
  deleteLoader: false,
  playTypeLoader: true,
  students: [],
  courses: [],
  enrollments: [],
  holes: 9,
  playTypeId: -1,
  description: '',
  eventDate: new Date(),
  location: '',
  name: '',
  isEmail: true,
  uploadPhotoName: '',
  uploadPhotoFile: '',
  uploadPhotoSrc: '',
  indexName: '',
  series: null,
  creatorId: -1,
  isStudentError: false,
  isEventError: false,
  status: null,
  pairings: PAIRINGS_INIT_VALUE,
  errors: {},
}

const statusPriority = {
  confirmed: 1,
  created: 2,
  declined: 3,
}

const sortEnrollments = (a, b) => {
  if (statusPriority[a.status] > statusPriority[b.status]) {
    return 1
  } else if (statusPriority[a.status] < statusPriority[b.status]) {
    return -1
  }

  if (a.pairingGroupId && !b.pairingGroupId) {
    return 1
  } else if (!a.pairingGroupId && b.pairingGroupId) {
    return -1
  } else {
    return 0
  }
}

const nineHoleEventReducer = createSlice({
  name: 'nineHoleEvent',
  initialState,
  reducers: {
    resetState: () => initialState,
    setField: (state, action) => {
      const { field, value } = action.payload
      state[field] = value
    },

    resetPairingsField: (state) => {
      state.pairings = { ...PAIRINGS_INIT_VALUE, startTime: state.pairings.startTime }
    },

    updateFields: (state, action) => {
      const fields = action.payload
      return {
        ...state,
        ...fields,
      }
    },

    addStudent: (state, action) => {
      const std = action.payload
      state.students.push(std)
    },

    updateStudent: (state, action) => {
      const student = action.payload
      const newStudents = [...state.students]
      const index = newStudents.findIndex(s => s.id === student.id)
      newStudents[index] = student
      state.students = newStudents
    },

    deleteStudent: (state, action) => {
      const id = action.payload
      const index = state.students.findIndex(s => s.id === id)

      state.students.splice(index, 1)
    },
    addCourse: (state, action) => {
      const course = { ...action.payload, selectedPackages: [] }
      state.courses.push(course)
    },
    updateCourse: (state, action) => {
      const course = action.payload
      const newCourses = [...state.courses]
      const index = newCourses.findIndex(c => c.id === course.id)
      newCourses[index] = course
      state.courses = newCourses
    },
    deleteCourse: (state, action) => {
      const courseId = action.payload
      const index = state.courses.findIndex(c => c.id === courseId)

      state.courses.splice(index, 1)
    },
    deletePairingGroup: (state, action) => {
      const pairingGroup = action.payload
      const index = state.pairings.pairingGroups.findIndex(group => group.index === pairingGroup.index)

      if (state.pairings.pairingGroups[index].id) {
        state.pairings.pairingGroups[index]._destroy = true
      } else {
        state.pairings.pairingGroups.splice(index, 1)
      }
    },
    deleteAllPairingGroups: (state, _) => {
      const newGroups = []
      state.pairings.pairingGroups.forEach((group) => {
        if (group.id) {
          newGroups.push({ ...group, _destroy: true })
        }
      })
      state.pairings.pairingGroups = newGroups
    },
  },
  extraReducers: {
    // Delete Enrollment
    [deleteEnrollment.fulfilled]: (state, action) => {
      const filteredStudents = state.students.filter(std => std.id !== action.payload.seriesEnrollment.userId)
      state.students = filteredStudents
    },
    [deleteEnrollment.rejected]: (state, action) => {
      state.errors = action.payload
    },

    //UpdateEnrollment
    [updateSeriesEnrollment.fulfilled]: (state, action) => {
      const index = state.students.findIndex(std => std.id === action.payload.seriesEnrollment.userId)
      state.students[index].status = action.payload.seriesEnrollment.status
    },
    [updateSeriesEnrollment.rejected]: (state, action) => {
      state.errors = action.payload
    },

    //Create Pairings
    [createPairings.fulfilled]: (state, action) => {
      const pairings = action.payload.pairings
      state.pairings = {
        ...PAIRINGS_INIT_VALUE,
        id: pairings.id,
        status: pairings.status,
        format: pairings.format,
        startTime: pairings.startTime,
        interval: pairings.interval,
        teeTimeSlots: pairings.teeTimeSlotsCount,
        holesCount: pairings.shotgunSlotsCount || PAIRINGS_INIT_VALUE.holesCount,
        golfersCount: { value: pairings.golfersCount, label: pairings.golfersCount },
        pairingGroups: pairings ? pairings.pairingGroups.map( (pairingGroup, index) => ({
          ...pairingGroup,
          index: index,
          startHole: { value: pairingGroup.startHole, label: pairingGroup.startHole },
          startTime: pairingGroup.startTime,
          playersGroup: { value: pairingGroup.playersGroup, label: pairingGroup.playersGroup },
          users: pairingGroup.users.map(user => ({
            ...user,
            level: user.currentFormalDivision,
            enrollmentId: user.enrollment.id,
            status: user.enrollment.status,
            age: user.age || '-',
          })),
        })) : [],
        groupsIndex: pairings.pairingGroups.length,
        notifyStudents: state.pairings.notifyStudents,
      }
    },

    //Update Pairings
    [updatePairings.fulfilled]: (state, action) => {
      const pairings = action.payload.pairings
      state.pairings = {
        ...PAIRINGS_INIT_VALUE,
        id: pairings.id,
        status: pairings.status,
        format: pairings.format,
        startTime: pairings.startTime,
        interval: pairings.interval,
        teeTimeSlots: pairings.teeTimeSlotsCount,
        holesCount: pairings.shotgunSlotsCount || PAIRINGS_INIT_VALUE.holesCount,
        golfersCount: { value: pairings.golfersCount, label: pairings.golfersCount },
        pairingGroups: pairings ? pairings.pairingGroups.map( (pairingGroup, index) => ({
          ...pairingGroup,
          index: index,
          startHole: { value: pairingGroup.startHole, label: pairingGroup.startHole },
          startTime: pairingGroup.startTime,
          playersGroup: { value: pairingGroup.playersGroup, label: pairingGroup.playersGroup },
          users: pairingGroup.users.map(user => ({
            ...user,
            level: user.currentFormalDivision,
            enrollmentId: user.enrollment.id,
            status: user.enrollment.status,
            age: user.age || '-',
          })),
        })) : [],
        groupsIndex: pairings.pairingGroups.length,
        notifyStudents: state.pairings.notifyStudents,
      }
    },

    // Get Event
    [getEvent.fulfilled]: (state, action) => {
      const event = action.payload.event
      const activities = event.activities
      state.id = event.id
      state.name = event.name
      state.eventDate = event.eventDate
      state.location = event.location
      state.description = event.description
      state.status = event.status
      state.uploadPhotoSrc = event.photo
      state.indexName = `Event #${event.order}`,
      state.nextRsvp = event.nextRsvp
      state.daysUntilEvent = event.daysUntilEvent
      state.confirmedEnrollmentsCount = event.confirmedEnrollmentsCount
      state.respondedEnrollmentsCount = event.respondedEnrollmentsCount
      state.series = event.series
      state.activityEvent = event.activityEvent
      state.format = event.format

      state.pairings = event.pairings ? {
        ...PAIRINGS_INIT_VALUE,
        id: event.pairings.id,
        status: event.pairings.status,
        format: event.format,
        startTime: event.pairings.startTime,
        interval: event.pairings.interval,
        teeTimeSlots: event.pairings.teeTimeSlotsCount,
        holesCount: event.pairings.shotgunSlotsCount || PAIRINGS_INIT_VALUE.holesCount,
        golfersCount: { value: event.pairings.golfersCount, label: event.pairings.golfersCount },
        pairingGroups: event.pairings ? event.pairings.pairingGroups.map( (pairingGroup, index) => ({
          ...pairingGroup,
          index: index,
          startHole: { value: pairingGroup.startHole, label: pairingGroup.startHole },
          startTime: pairingGroup.startTime,
          playersGroup: { value: pairingGroup.playersGroup, label: pairingGroup.playersGroup },
          users: pairingGroup.users.map(user => ({
            ...user,
            level: user.currentFormalDivision,
            enrollmentId: user.enrollment.id,
            status: user.enrollment.status,
            age: user.age || '-',
          })),
        })) : [],
        groupsIndex: event.pairings.pairingGroups.length,
      } : {
        ...PAIRINGS_INIT_VALUE,
        format: event.format,
        timezone: event.timezone,
        startTime: event.eventDate,
        teeTimeSlots: Math.ceil((event.enrollments.length - (event.respondedEnrollmentsCount - event.confirmedEnrollmentsCount))
          / PAIRINGS_INIT_VALUE.golfersCount.value) || 1,
      }

      state.students = []
      if (!event.series) {
        activities?.forEach((activity) => {
          if (activity.type === 'ActivityPlay') {
            const student = activity.user
            if (student) {

              state.students.push({
                ...student,
                milestone: activity.milestone,
                level: getLevel(activity.yardage),
                greens: activity.greens || '',
                putts: activity.putts || '',
                yardage: activity.isFullTee ? 'fullTee' : activity.yardage,
                fullTeeYardage: activity.isFullTee ? activity.yardage : '',
                score: activity.score,
                age: student.age || '-',
              })
            }
          }
        })


      } else {
        event?.enrollments?.sort(sortEnrollments).forEach((e) => {
          const student = e.student
          let addedFrom = null
          if (e.course) {
            if (e.package) {
              addedFrom = { [e.course.id]: { [e.package.id]: { packageName: e.package.name, courseName: e.course.name } } }
            } else {
              addedFrom = { [e.course.id]: e.course.name }
            }
          } else {
            addedFrom = { students: true }
          }

          let studentData = {
            ...student,
            yardage: yardageByLevel[student.currentFormalDivision],
            level: student.currentFormalDivision,
            age: student.age || '-',
            courseName: e.courseName,
            addedFrom: addedFrom,
            status: e.status,
            enrollmentId: e.id,
            note: e.note,
            pairingGroupId: e.pairingGroupId,
          }

          const activity = activities.find((a) => a.userId === student.id)
          if (activity) {
            studentData = {
              ...studentData,
              score: activity.score,
              greens: activity.greens || '',
              putts: activity.putts || '',
              yardage: activity.isFullTee ? 'fullTee' : activity.yardage,
              fullTeeYardage: activity.isFullTee ? activity.yardage : '',
              milestone: activity.milestone,
              level: getLevel(activity.yardage),
            }
          }
          state.students.push(studentData)
        })
      }
    },
    [getEvent.rejected]: (state, action) => {
      state.errors = action.payload
    },
    [createEvent.rejected]: (state, action) => {
      state.errors = action.payload
    },
    [updateEvent.rejected]: (state, action) => {
      state.errors = action.payload
    },
    [deleteEvent.rejected]: (state, action) => {
      state.errors = action.payload
    },
  },
})

export const pairingsFormData = createSelector(
  state => state.nineHoleEvent,
  state => state.user.current_user.communities[0].timezone,
  (fields, timezone) => ({
    eventId: fields.id,
    id: fields.pairings.id,
    format: fields.pairings.format,
    interval: fields.pairings.interval,
    status: fields.pairings.status,
    startTime: fields.pairings.startTime,
    teeTimeSlotsCount: fields.pairings.teeTimeSlots,
    shotgunSlotsCount: JSON.stringify(fields.pairings.holesCount),
    golfersCount: fields.pairings.golfersCount.value,
    pairingGroupsAttributes: fields.pairings.pairingGroups.map((pairingGroup) => ({
      ...pairingGroup,
      startTime: pairingGroup.startTime || getMomentInCommunityTimezoneReversed(moment(fields.eventDate), timezone, DATETIME_FORMAT).toDate(),
      startHole: pairingGroup.startHole?.value,
      playersGroup: pairingGroup.playersGroup?.value,
      seriesEnrollmentIds: pairingGroup.users?.map(user => user?.enrollmentId).filter(id => id),
    })),
  })
)

export const {
  resetState,
  setField,
  resetPairingsField,
  updateFields,
  addStudent,
  updateStudent,
  deleteStudent,
  addCourse,
  updateCourse,
  deleteCourse,
  deletePairingGroup,
  deleteAllPairingGroups,
} = nineHoleEventReducer.actions

export const eventFormData = createSelector(
  state => state.nineHoleEvent,
  eventFields => ({
    id: eventFields.id === -1 ? null : eventFields.id,
    name: eventFields.name,
    isEmail: eventFields.isEmail,
    description: eventFields.description,
    location: eventFields.location,
    creatorId: eventFields.creatorId,
    playTypeId: eventFields.playTypeId,
    eventDate: eventFields.eventDate,
    photoAttributes: eventFields.uploadPhotoFile
      ? { photo: eventFields.uploadPhotoFile, name: eventFields.uploadPhotoFile.name }
      : !eventFields.uploadPhotoSrc ? { _destroy: true } : null,
    activities: eventFields.students?.length > 0
      ? eventFields.students.filter(s => s.status !== 'declined').map((student) => ({
        userId: student.id,
        yardage: student.yardage,
        fullTeeYardage: student.fullTeeYardage || '',
        greens: student.greens || '',
        putts: student.putts || '',
        addedFrom: student.addedFrom,
        score: student.score,
      }))
      : [],
  })
)

export default nineHoleEventReducer.reducer
