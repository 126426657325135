import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import InfiniteScroll from '../../../infinite-scroll/InfiniteScroll'
import Activity from '../../../activity/Activity'
import classes from './ActivityFeed.module.scss'
import ActivityAnnouncement from '../../../activity/ActivityAnnouncementCard'
import PostAnnouncementButton from '../../../dashboard/coachDashboard/PostAnnouncementButton'
import PostAnnouncementModal from '../../../dashboard/coachDashboard/PostAnnouncementModal'
import ActivitySkeleton from '../../../activity/ActivitySkeleton'
import HorizontalSelector from './HorizontalSelector'
import classNames from 'classnames'
import { ReactComponent as PlaceholderIcon } from '../../../../assets/images/common/icons/chat-boxes-icon.svg'

const PER_PAGE = 6

const ActivityFeed = ({ id, loadingFeedActivities, activitiesTotalPages,
  clearActivities, getActivities, feedActivities, removeActivity, isProgram }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [postAnnouncementModa, setPostAnnouncementModal] = useState(false)
  const [page, setPage] = useState(0)
  const [filter, setFilter] = useState('all')

  const filters = [
    { label: 'All', key: 'all' },
    { label: 'Announcements', key: 'announcement' },
    { label: 'Play', key: 'play' },
    { label: 'Train', key: 'train' },
    { label: 'Events', key: 'event' },
  ]

  const dispatch = useDispatch()

  const fetchActivities = () => {
    dispatch(getActivities({ id: id, page: page + 1, perPage: PER_PAGE, type: filter }))
    setPage((prevState) => prevState + 1)
  }

  const onChangeFilter = (value) => {
    setFilter(value)
    setPage(0)
    dispatch(clearActivities())
  }

  useEffect(() => {
    fetchActivities()
    if (window.innerWidth < 1200) {
      setCollapsed(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const handleModalClose = () => {
    setPostAnnouncementModal(false)
  }

  const activitySkeletons = () => (
    <div className={classNames('d-flex flex-column align-items-center', classes.feedContainer, classes.loadingSection)}>
      <ActivitySkeleton />
      <ActivitySkeleton />
      <ActivitySkeleton />
    </div>)

  const placeholder = () =>
    <div className={classNames('d-flex flex-column align-items-center', classes.feedContainer)}>
      <PlaceholderIcon className='mt-5' />
      <div className={classes.text}>
      This is your Op 36 Programs Community Feed. Every activity logged by your students will be shared here.
      Be sure to like and comment to engage your golfers!
      </div>
    </div>


  const isFirstPageLoading = loadingFeedActivities && page <= 1

  return (
    <div
      className={classnames(classes.communityFeed, { [classes.collapsed]: collapsed })}
      onClick={() => collapsed && setCollapsed(false)}
      role='button'
      tabIndex={0}
      onKeyDown={(e) => {
        e.key === 'Enter' && collapsed && setCollapsed(false)
      }}
    >


      <div className={classes.arrowWrapper}>
        <button className={classes.arrowButton} style={{ transform: collapsed ? 'rotate(180deg)' : 'none' }} onClick={() => setCollapsed(true)} />
      </div>
      <PostAnnouncementModal onClose={handleModalClose} isOpen={postAnnouncementModa} isProgram={isProgram}/>
      {!collapsed && (<PostAnnouncementButton openModal={setPostAnnouncementModal}/>)}
      {!collapsed && (<HorizontalSelector tabs={filters} onSelect={onChangeFilter} />)}
      {!collapsed && isFirstPageLoading && activitySkeletons() }
      {!collapsed && feedActivities.length === 0 && placeholder()}
      {!collapsed && feedActivities.length > 0 &&
        <div className={classes.feedContainer} id='feedContainer'>
          <InfiniteScroll
            dataLength={feedActivities.length}
            next={fetchActivities}
            hasMore={activitiesTotalPages >= page}
            loaderClassName={classes.loader}
            scrollableTarget='feedContainer'
          >
            {feedActivities.map((activity) => activity.type === 'ActivityAnnouncement' ?
              <ActivityAnnouncement
                key={activity.id}
                activity={activity}
                removeActivity={removeActivity}
              /> :
              <Activity
                key={activity.id}
                displayComments={false}
                activity={activity}
                isStudentJourney
              />
            )}
          </InfiniteScroll>
        </div>
      }
    </div>
  )
}

ActivityFeed.propTypes = {
  id: PropTypes.number,
  loadingFeedActivities: PropTypes.bool,
  activitiesTotalPages: PropTypes.number,
  fetchActivities: PropTypes.func,
  clearActivities: PropTypes.func,
  getActivities: PropTypes.func,
  feedActivities: PropTypes.array,
  removeActivity: PropTypes.func,
  isProgram: PropTypes.bool,
}

export default ActivityFeed
