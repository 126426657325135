import * as constants from "./actionTypes";
import * as common from "./commonActions";
import { stopIntercom } from '../utility/intercom'
import { createAsyncThunk } from '@reduxjs/toolkit'

// import CONSENT_VERSIONS from '../constants/consentVersions';

import Axios from "../axios";
import { deserialize, serialize } from '../utility/serialization'
import { handleAPIError } from '../utility/actionHelper'
import { objectToFormData } from '../utility/formHelper'

export function successRequestingUserToken(response) {
  if (response.status === 200) {
    return { type: constants.SIGNIN_USER, user: response.data.user };
  } else {
    console.log("ERROR ON SIGNIN");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export const successConfirmingUserEmail = (response) => {
  if (response.status === 200) {
    return { type: constants.SIGNIN_USER, user: response.data.user }
  } else {
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successRequestingNewUser(response) {
  if (response.status === 201) {
    return { type: constants.SIGNIN_USER, user: response.data.user };
  } else {
    console.log("ERROR ON SIGNIN");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingNewFamily(response) {
  if (response.status === 201) {
    return { type: constants.ADD_USER, user: response.data.user };
  } else {
    // This don't make no sense-- why is it called "SUCCESSrequestingNewFamily" but it's handling an error? Anyway, the way these are set up, they'll never receive an error. They only ever receieve the success response.
    console.log("ERROR ON SIGNIN");
    return { type: constants.UI_ERROR, error: response.data };
  }
}
// Following the convention of this file, adding a general failure option
export const failure = (error) => ({ type: constants.UI_ERROR, error });

export function successRequestingResetPassword(response) {
  if (response.status === 200) {
    return { type: constants.PASSWORD_RESET };
  } else {
    console.log("ERROR ON RESETTING PASSWORD");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingTriggerResetPassword(response) {
  if (response.status === 200) {
    return { type: constants.PASSWORD_RESET_REQUEST };
  } else {
    console.log("ERROR ON RESETTING PASSWORD REQUEST");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingTriggerForgotUsername(response) {
  if (response.status === 200) {
    return { type: constants.FORGOT_USERNAME_REQUEST };
  } else {
    console.log("ERROR ON FORGOT USERNAME REQUEST");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successSigningOutUser() {
  return { type: constants.SIGN_OUT };
}

export function successCreatingConsent(response) {
  if (response.status === 200) {
    return {
      type: constants.UPDATE_CURRENT_USER,
      current_user: response.data.user,
    };
  } else {
    console.log("ERROR REGISTERING CONSENT");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingGuardianConsent(response) {
  if (response.status === 200) {
    return { type: constants.GUARDIAN_CONSENT_REQUEST };
  } else {
    console.log("ERROR SENDING REQUEST TO GUARDIAN FOR CONSENT");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// THUNKS HERE
export function registerConsent(consents) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));

    return createConsent(consents)
      .then((response) => dispatch(successCreatingConsent(response)))
      .then(() => dispatch(common.loadingChanged(false)))
      .catch((err) => {
        dispatch(common.loadingChanged(false));
        throw err;
      });
  };
}

export function signInUser(email, password) {
  return function (dispatch) {
    // BODY OF THUNK

    // TURN SPINNER ON...
    dispatch(common.loadingChanged(true));
    // FETCH API CALL FROM SERVER THEN DISPATCH UI ACTION
    // THEN TURN SPINNER OFF
    return requestGetToken(email, password)
      .then((response) => { dispatch(successRequestingUserToken(response)); return response })
      .then((response) => { dispatch(common.loadingChanged(false)); return response })
      .catch((err) => {
        dispatch(common.loadingChanged(false));
        throw err;
      });
  };
}

export const confirmUserEmail = (confirmationToken) => (
  function (dispatch) {
    dispatch(common.loadingChanged(true))

    return requestConfirmUserEmail(confirmationToken)
      .then((response) => { dispatch(successConfirmingUserEmail(response)); return response })
      .then((response) => { dispatch(common.loadingChanged(false)); return response })
      .catch((err) => {
        dispatch(common.loadingChanged(false))
        throw err
      })
  }
)

export const resendConfirmationEmail = (email) => (
  function (dispatch) {
    dispatch(common.loadingChanged(true))

    return requestResendConfirmationEmail(email)
      .then((response) => { dispatch(common.loadingChanged(false)); return response })
      .catch((err) => {
        dispatch(common.loadingChanged(false))
        throw err
      })
  }
)

export function tokenAuth(token) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return getUserFromToken(token)
      .then((response) => {
        dispatch(successRequestingUserToken(response))
        dispatch(common.loadingChanged(false))
      })
      .catch(() => {
        dispatch(common.loadingChanged(false))
      })
  }
}

export function signInSuperUser(data) {
  return function (dispatch) {
    // BODY OF THUNK

    // TURN SPINNER ON...
    dispatch(common.loadingChanged(true));
    // FETCH API CALL FROM SERVER THEN DISPATCH UI ACTION
    // THEN TURN SPINNER OFF
    return getSuperUserToken(data)
      .then((response) => dispatch(successRequestingUserToken(response)))
      .then((turnOffSpinner) => dispatch(common.loadingChanged(false)))
      .catch((err) => {
        dispatch(common.loadingChanged(false));
        throw err;
      });
  };
}

export function signOutUser() {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(successSigningOutUser());
      stopIntercom()
      resolve();
    });
  };
}

export function createAdminUser(user) {
  return function (dispatch) {
    dispatch({ type: constants.UI_ERROR, error: {} });
    return registerUser(user)
      .then((response) => dispatch(successRequestingNewUser(response)))
      .catch((err) => {
        dispatch({ type: constants.UI_ERROR, error: err.response.data.errors });
      });
  };
}

export function createFamilyUser(user, successCallback) {
  return function (dispatch) {
    // dispatch(failure(''))
    dispatch(common.loadingChanged(true));
    return registerUser(user)
      .then((response) => {
        dispatch(successRequestingNewFamily(response));
        dispatch(common.loadingChanged(false));
        successCallback();
      })
      .catch((err) => {
        dispatch(failure(err.response.data.errors));
        dispatch(common.loadingChanged(false));
        // throw err;
      });
  };
}

export const createUser = createAsyncThunk(
  'authentication/createUser',
  async (user, { rejectWithValue }) => {
    try {
      const userFormData = objectToFormData(serialize(user))

      const { data } = await registerUser(userFormData)

      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const createUserSignIn = createAsyncThunk(
  'authentication/createUserSignIn',
  async (user, { rejectWithValue }) => {
    try {
      const userFormData = objectToFormData(serialize(user))

      const { data } = await registerUser(userFormData)

      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const createAuthFamilyMember = createAsyncThunk(
  'auth/createFamilyMember',
  async (user, { rejectWithValue }) => {
    try {
      const userFormData = objectToFormData(serialize(user))

      const { data } = await registerUser(userFormData)

      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export function triggerResetPassword(email) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return registerTriggerResetPassword(email)
      .then((response) =>
        dispatch(successRequestingTriggerResetPassword(response))
      )
      .then((turnOffSpinner) => dispatch(common.loadingChanged(false)));
  };
}

export function triggerForgotUsername(email) {
  return function (dispatch) {
    return registerTriggerForgotUsername(email).then((response) =>
      dispatch(successRequestingTriggerForgotUsername(response))
    );
  };
}

export function resetPassword(
  password,
  passwordConfirmation,
  passwordResetToken
) {
  return function (dispatch) {
    return registerResetPassword(
      password,
      passwordConfirmation,
      passwordResetToken
    ).then((response) => dispatch(successRequestingResetPassword(response)));
  };
}

export function requestGuardianConsent(userId) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return sendGuardiantConsentRequest(userId)
      .then((response) => dispatch(successRequestingGuardianConsent(response)))
      .then(() => dispatch(common.loadingChanged(false)));
  };
}

// API CALLS
function registerUser(user) {
  let requestUrl = "/api/v1/users";

  // console.log("user: ", user);
  return Axios.post(requestUrl, user);
  // return Axios.post(requestUrl, {
  //   first_name: user.first_name,
  //   last_name: user.last_name,
  //   community_id: user.community_id,
  //   email: user.email,
  //   type: user.type,
  //   password: user.password,
  //   family_admin: user.family_admin
  // })
}

const requestConfirmUserEmail = (confirmationToken) => {
  const requestUrl = '/api/v1/users/confirm_email'

  return Axios.post(requestUrl, serialize({ confirmationToken }))
}

const requestResendConfirmationEmail = (email) => {
  const requestUrl = '/api/v1/users/resend_confirmation_email'

  return Axios.post(requestUrl, { email })
}

function requestGetToken(email, password) {
  let requestUrl = "/api/v1/users/get_token.json";

  return Axios.post(requestUrl, { auth: { email, password } })
}

function getUserFromToken(token) {
  let requestUrl = '/api/v1/users/token_authentication'

  return Axios.post(requestUrl, {token})
}

function getSuperUserToken(data) {
  let requestUrl = "/api/v1/users/get_token.json";
  return Axios.post(requestUrl, {
    community_id: data.id,
  });
}

function registerTriggerResetPassword(email) {
  let requestUrl = "/api/v1/users/forgot_password";
  return Axios.get(requestUrl, { params: { email: email } });
}

function registerTriggerForgotUsername(email) {
  let requestUrl = "/api/v1/users/forgot_username";
  return Axios.get(requestUrl, { params: { email: email } });
}

function registerResetPassword(
  password,
  passwordConfirmation,
  passwordResetToken
) {
  let requestUrl = "/api/v1/users/reset_forgotten_password";
  return Axios.post(requestUrl, {
    password: password,
    password_confirmation: passwordConfirmation,
    reset_password_token: passwordResetToken,
  });
}

function createConsent(consents) {
  return Axios.post("/api/v1/consents", consents);
}

function sendGuardiantConsentRequest(userId) {
  return Axios.post("/api/v1/consents/get_guardian_consent", userId);
}
