import Modal from '../../common/Modal'
import PropTypes from 'prop-types'

import SubmitButton from '../../common/buttons/SubmitButton'

import styles from './ExitModal.module.scss'
import classNames from 'classnames'

const ExitModal = ({ showModal, closeModal, onSubmit, title, message, submitButtonMessage, icon }) => (
  <Modal
    isOpen={showModal}
    onClose={closeModal}
    contentLabel='Exit Event Modal'
    className='exit-modal'
    autoFocus
  >
    <div className={styles.exitModal}>
      <div className={classNames('d-flex flex-column align-items-center', styles.modalContainer)}>
        <div className='d-flex justify-content-center align-items-center'>
          {icon}
        </div>
        <div className={styles.modalContent}>
          <span className={styles.title}>{title}</span>
          <p className='my-3'>{message}</p>
        </div>
        <div className='w-100 pull-bottom'>
          <SubmitButton
            onClick={closeModal}
            buttonMessage={submitButtonMessage}
            buttonColor='orange-button'
          />
          <SubmitButton
            onClick={onSubmit}
            buttonMessage='Exit'
            buttonColor='navy-button'
          />
        </div>
      </div>
    </div>
  </Modal>
)

ExitModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  submitButtonMessage: PropTypes.string,
  icon: PropTypes.object,
}

export default ExitModal
