import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { ReactComponent as CircleLogo } from '../../../assets/images/common/icons/circle-logo.svg'
import { signInUser } from '../../../actions/authActions'
import { showSideBar, showNavBar, setSideBarState } from '../../../actions/commonActions'
import { signOutUser } from '../../../actions/authActions'
import { initializePendo } from '../../../utility/pendo'
import SubmitButton from '../../common/buttons/SubmitButton'
import FormInput from '../Helpers/FormInput'
import FieldError from '../../common/FieldError'

import classes from './LogInPage.module.scss'
import { NAVIGATION } from '../../../misc/Constants'

const LogInPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loader, setLoader] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    dispatch(signOutUser())
  }, [dispatch])

  const handleLogIn = () => {
    setLoader(true)
    dispatch(signInUser(email, password))
      .then((response) => {
        initializePendo(window.pendo, response)

        dispatch(showSideBar(true))
        dispatch(showNavBar(true))
        dispatch(setSideBarState(NAVIGATION.COLLAPSED))

        const redirectUrl = localStorage.getItem('redirectUrl') || '/'
        localStorage.removeItem('redirectUrl')
        navigate(redirectUrl, { replace: true })
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.errors?.general?.at(0)
        if (errorMessage.includes('confirm')) {
          navigate('/sign-up/email-verification-required', { replace: true, state: { from: 'log-in', email: email } })
        } else {
          setError('Incorrect email or password')
        }
      })
      .finally(() => {
        setLoader(false)
      })
  }

  return (
    <div className={classNames(classes.logInPage, 'd-flex')}>
      <aside className={classNames(classes.mainCard, 'd-flex flex-column justify-content-center align-items-center m-auto')}>
        <header className='d-flex flex-column justify-content-center align-items-center'>
          <CircleLogo className={classes.circleLogo} />
          <h2 className={classes.titleText}>Log In or Sign Up!</h2>
        </header>

        <form className='row w-100 mt-2'>
          <div className='col-12 col-lg-6 offset-lg-3'>
            {error && <FieldError message={error} style={{ paddingBottom: '5px' }} general/>}

            <label htmlFor='email' className={classes.inputLabel}>
              Email or Username <span className='required-text'>*</span>
            </label>

            <FormInput
              name='email'
              type='text'
              onChange={(e) => { setEmail(e.target.value); setError('') }}
              value={email}
              symbol={<i className={classNames(classes.inputSymbol, 'fa fa-envelope')}/>}
            />

            <label htmlFor='password' className={classes.inputLabel}>
              Password <span className='required-text'>*</span>
            </label>

            <FormInput
              name='password'
              type='password'
              onChange={(e) => { setPassword(e.target.value); setError('') }}
              value={password}
              symbol={<i className={classNames(classes.inputSymbol, 'fa fa-lock')}/>}
              autoComplete='new-password'
            />

            <SubmitButton
              onClick={(e) => { e.preventDefault(); handleLogIn() }}
              buttonMessage='Log In'
              buttonColor='orange-button'
              className={classes.logInButton}
              disabled={loader || !email || !password || !!error}
            />
          </div>
        </form>

        <footer className={classNames(classes.footer, 'row d-flex flex-column align-items-center')}>
          <div className='col-12 col-lg-6'>
            <section className={classes.forgotSection}>
              <Link to={'/forgot-password'} className={classes.forgotLink}>Forgot Password?</Link>
              <Link to={'/forgot-username'} className={classes.forgotLink}>Forgot Username?</Link>
            </section>

            <span>Don&apos;t have an account? <Link to={'/sign-up?step=1'} className={classes.signUpLink}>Sign Up Here</Link></span>
          </div>
        </footer>
      </aside>
    </div>
  )
}

export default LogInPage
