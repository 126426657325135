import { useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { Progress } from 'reactstrap'
import classNames from 'classnames'

import { ReactComponent as CollapseIcon } from '../../../../assets/images/common/icons/arrow-up.svg'
import { ReactComponent as LockIcon } from '../../../../assets/images/common/icons/lock-icon.svg'
import { ReactComponent as ClapIconOutlined } from '../../../../assets/images/common/icons/clap-icon-outlined.svg'
import CustomCheckInput from '../../../common/form/CustomCheckInput'
import CoachOnboardingLoadingSkeleton from './CoachOnboardingLoadingSkeleton'

import {
  updateOnboardingStatus,
  updateItemStatus,
} from '../../../../actions/coachOnboardingActions'
import {
  selectCompleteItemsCount,
  selectFirstUncompletedStepIndex,
  selectIsFocusedStepCompleted,
  selectIsOnboardingComplete,
  selectOnboardingItems,
  selectOnboardingProgress,
  selectTotalItemsCount,
  setFocusedStep,
  setIsModalOpen,
} from '../../../../reducers/coachOnboardingReducer'

import classes from './CoachOnboardingWidget.module.scss'


const CoachOnboardingWidget = () => {
  const dispatch = useDispatch()
  const stepsRef = useRef()
  const focusedStep = useSelector(state => state.coachOnboarding.focusedStep)
  const currentUser = useSelector(state => state.user.current_user)
  const onboardingSteps = useSelector(state => state.coachOnboarding.onboardingSteps)
  const isOnboardingStepsLoading = useSelector(state => state.coachOnboarding.isOnboardingStepsLoading)
  const onboardingItems = useSelector(selectOnboardingItems)
  const isOnboardingComplete = useSelector(selectIsOnboardingComplete)
  const completedItemsCount = useSelector(selectCompleteItemsCount)
  const totalItemsCount = useSelector(selectTotalItemsCount)
  const onboardingProgress = useSelector(selectOnboardingProgress)
  const firstUncompletedStepIndex = useSelector(selectFirstUncompletedStepIndex)
  const isFocusedStepCompleted = useSelector(selectIsFocusedStepCompleted)

  const isStepComplete = (step) => step.coachOnboardingItems.every(item => item.completed)

  useEffect(() => {
    if (isFocusedStepCompleted && firstUncompletedStepIndex !== -1) {
      dispatch(setFocusedStep(firstUncompletedStepIndex))
    } else if (firstUncompletedStepIndex === -1) {
      dispatch(setFocusedStep(-1))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingItems])

  useEffect(() => {
    const scrollTimeout = setTimeout(() => {
      if (focusedStep !== -1) {
        const step = document.getElementById(`step-${focusedStep}`)
        stepsRef.current?.scrollTo({ behavior: 'smooth', top: step.offsetTop })
      }
    }, 350)

    return () => clearTimeout(scrollTimeout)
  }, [focusedStep])

  const handleItemComplete = (itemId) => (e) => {
    const currentCheckbox = e.target

    dispatch(updateItemStatus({ itemId, completed: currentCheckbox.checked }))
      .unwrap()
      .catch(() => {
        toast.error('Something went wrong. Please refresh the page and try again.', { position: toast.POSITION.TOP_RIGHT })
        // Revert changes if request failed
        currentCheckbox.checked = onboardingItems.find(item => item.id === itemId)?.completed
      })
  }

  const completeOnboarding = () => {
    dispatch(updateOnboardingStatus({ userId: currentUser.id, actionType: 'complete' }))
  }

  if (isOnboardingStepsLoading || onboardingSteps.length === 0) {
    return (
      <div className={classes.widgetContainer}>
        <CoachOnboardingLoadingSkeleton />
      </div>
    )
  }

  return (
    <aside className={classes.widgetContainer}>
      <div className={classes.upperPart}>
        <div>
          {isOnboardingComplete ? (
            <div className='d-flex flex-column align-items-center animated fadeIn'>
              <ClapIconOutlined className='mb-4' width={72} height={72} color='#12A482' />
              <h4 className={classNames(classes.title, 'font-weight-bold w-100')}>
                Congratulations!<br /> You finished your onboarding
              </h4>
            </div>
          ) : (
            <>
              <h4 className={classNames(classes.title, 'font-weight-bold')}>
                Hi {currentUser?.first_name},<br />Welcome to Operation 36!
              </h4>
              <p className={classNames(classes.captionText, 'font-weight-bold')}>
                To help you get your first enrollment, we&apos;ve created a simple checklist
                that will guide you through the Op 36 basics,
                setting up registration, and launching your marketing.
              </p>
            </>
          )}

          <div className={classNames(classes.progressWrapper, 'd-flex align-items-center w-100')}>
            <Progress value={onboardingProgress} barClassName={classes.progressBar} className={classes.progressContainer} />
            <p className={classNames(classes.totalSteps, 'mb-0 ml-4 font-weight-bold')}>
              <span className={classes.stepsComplete}>{completedItemsCount}</span>/{totalItemsCount} Complete
            </p>
          </div>
        </div>

        <div ref={stepsRef} className={classes.stepsWrapper}>
          <div className={classes.stepsContainer} id='accordion'>
            {onboardingSteps.map((step, idx) => (
              <div role='list' id={`step-${idx}`} key={step.id} className={classNames(classes.onboardingStep, 'card')}>
                <button
                  onClick={() => dispatch(setFocusedStep(focusedStep === idx ? -1 : idx))}
                  className={classNames(classes.stepHeader, 'card-header border-bottom-0')}
                  aria-expanded={idx === focusedStep}
                >
                  <h5 className='mb-0'>
                    <div className={classNames(classes.buttonStep, 'd-flex align-items-center font-weight-bold')}>
                      <CollapseIcon
                        className={classNames({ [classes.stepArrowCollapsed]: idx !== focusedStep })}
                        color='white'
                        width={16}
                        height={9}
                      />
                      <CustomCheckInput
                        type='checkbox'
                        classNames={{ checkBoxInput: isStepComplete(step) ? classes.roundedCheckboxComplete : '' }}
                        id={`step-check-${step.id}`}
                        checked={true}
                        readOnly
                      />
                      {step.title}
                    </div>
                  </h5>
                </button>

                <div
                  className={classNames({ [classes.stepExpanded]: idx === focusedStep }, classes.stepTransition, classes.stepCollapsed)}
                  data-parent='#accordion'
                >
                  <div className='card-body'>
                    {step.coachOnboardingItems.map(item => (
                      <div key={item.id} className={classNames(classes.onboardingItem, 'd-flex align-items-center')}>
                        <CustomCheckInput
                          type='checkbox'
                          id={`item-${item.id}`}
                          onChange={handleItemComplete(item.id)}
                          defaultChecked={item.completed}
                        />
                        <button
                          id={`onboarding-item-guide-${item.id}`}
                          className={classNames(classes.onboardingItemTitle, 'ml-2')}
                        >
                          {item.title}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {isOnboardingComplete && (
          <div className='mt-5 animated fadeIn'>
            <h4 className={classNames(classes.title, 'font-weight-bold')}>
              Unlock your<br /> community feed now!
            </h4>
            <button className={classes.unlockFeedButton} onClick={completeOnboarding}>Unlock my feed</button>
          </div>
        )}
      </div>

      {!isOnboardingComplete && (
        <div className={classNames(classes.lowerPart, 'd-flex align-items-center')}>
          <LockIcon className={classNames(classes.lockIcon)} />
          <div>
            <p className={classNames(classes.secondaryText, 'font-weight-bold mb-0')}>Community Activity Feed Locked</p>
            <button
              className={classNames(classes.dismissText, 'font-weight-bold')}
              onClick={() => dispatch(setIsModalOpen(true))}
            >
              Are you an already experienced Op 36 Coach?
            </button>
          </div>
        </div>
      )}
    </aside>
  )
}

export default CoachOnboardingWidget
