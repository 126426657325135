import { deserialize } from '../utility/serialization'
import Axios from '../axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleAPIError } from '../utility/actionHelper'
import { refreshCurrentUser } from './userActions'
import { getCommunityEngagementReport } from './reportsActions'

// THUNKS

export const getCoachOnboardingSteps = createAsyncThunk(
  'coachOnboarding/getCoachOnboardingSteps',
  async (userId, { rejectWithValue }) => {
    try {
      const { data } = await requestGetCoachOnboardingSteps(userId)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const updateItemStatus = createAsyncThunk(
  'coachOnboarding/updateItemStatus',
  async ({ itemId, completed }, { rejectWithValue }) => {
    try {
      const requestUpdateItemStatus = completed ? requestCompleteItem : requestDeleteItem
      const { data } = await requestUpdateItemStatus(itemId)

      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const updateOnboardingStatus = createAsyncThunk(
  'coachOnboarding/updateOnboardingStatus',
  async ({ userId, actionType }, { dispatch, rejectWithValue }) => {
    try {
      const requestUpdateOnboardingStatus = {
        complete: requestCompleteOnboarding,
        dismiss: requestDismissOnboarding,
      }[actionType]

      const { data } = await requestUpdateOnboardingStatus(userId)

      dispatch(refreshCurrentUser(userId))

      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const updateOnboardingStatusForAdmin = createAsyncThunk(
  'coachOnboarding/updateOnboardingStatusForAdmin',
  async ({ userId, actionType, communityId }, { dispatch, rejectWithValue }) => {
    try {
      const requestUpdateOnboardingStatus = {
        reset: requestResetOnboarding,
        complete: requestCompleteOnboarding,
      }[actionType]

      const { data } = await requestUpdateOnboardingStatus(userId)

      dispatch(getCommunityEngagementReport(communityId))
      dispatch(getCoachOnboardingSteps(userId))

      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

// API CALLS

const requestGetCoachOnboardingSteps = (userId) => Axios.get(`/api/v1/coach_onboarding_steps?user_id=${userId}`)

const requestCompleteItem = (itemId) => {
  const requestUrl = `/api/v1/coach_onboarding_items/${itemId}/complete_item`

  return Axios.post(requestUrl)
}

const requestDeleteItem = (itemId) => {
  const requestUrl = `/api/v1/coach_onboarding_items/${itemId}/uncomplete_item`

  return Axios.post(requestUrl)
}

const requestCompleteOnboarding = (userId) => {
  const requestUrl = `/api/v1/users/${userId}/complete_coach_onboarding`

  return Axios.post(requestUrl)
}

const requestDismissOnboarding = (userId) => {
  const requestUrl = `/api/v1/users/${userId}/dismiss_coach_onboarding`

  return Axios.post(requestUrl)
}

const requestResetOnboarding = (userId) => {
  const requestUrl = `/api/v1/admin/users/${userId}/reset_coach_onboarding`

  return Axios.post(requestUrl)
}
