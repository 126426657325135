import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as commonActions from '../../actions/commonActions'
import * as communitiesActions from '../../actions/communitiesActions'
import * as reportsActions from '../../actions/reportsActions'
import * as representativesActions from '../../actions/representativesActions'
import * as representativeActionTypesActions from '../../actions/representativeActionTypesActions'
import * as representativeNotesActions from '../../actions/representativeNotesActions'
import * as currenciesActions from '../../actions/currenciesActions'

import StateSwitcher from '../common/StateSwitcher'
import CommunityRepDetail from './Detail/CommunityRepDetail'
import CommunityForm from './CommunityForm'
import { useParams } from 'react-router-dom'
import './CommunityDetailPage.scss'
import PageHeader from '../common/PageHeader/PageHeader'
import CoachOnboardingStepsAdminModal from './Detail/CoachOnboardingAdmin/CoachOnboardingStepsAdminModal'

const CommunityDetailPage = () => {
  const [pageState, setPageState] = useState('overview')
  const [, setRepLoader] = useState(false)
  const [, setCommunityLoader] = useState(false)
  const [loader, setLoader] = useState(true)
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()
  const community = useSelector(state => state.community)
  const reports = useSelector(state => state.reports)
  const representatives = useSelector(state => state.representatives)
  const currencies = useSelector(state => state.currencies)
  const representativeActionTypes = useSelector(state => state.representative_action_types)
  const [supportRating, setsupportRating] = useState(null)
  const { communityId } = useParams()

  useEffect(() => {
    dispatch(commonActions.setCurrentTab('Communities'))
    dispatch(commonActions.showSideBar(true))
    dispatch(commonActions.showNavBar(true))

    if (!representatives || representatives.length === 0) {
      fetchRepresentatives()
    }

    if (!representativeActionTypes || representativeActionTypes.length === 0) {
      fetchRepresentativeActionTypes()
    }

    fetchCurrencies()

    setCommunityLoader(true)
    dispatch(communitiesActions.getCommunity(communityId))
      .then(() => toggleReviewModal(communityId))
      .finally(() => setCommunityLoader(false))
  }, [])

  const scrollToTop = () => {
    window.scrollTo({ top: 0 })
  }


  const handleSubmitCommunity = (event, community) => {
    setLoader(true)
    dispatch(communitiesActions.updateCommunity(community))
      .then(() => dispatch(reportsActions.getCommunityEngagementReport(communityId)))
      .then(() => {
        dispatch(communitiesActions.getCommunity(communityId))
          .then(() => {
            pageStateToggle('overview')
            scrollToTop()
            setErrors({})
            setLoader(false)
          })
      })
      .catch((err) => { setErrors(err.response.data.errors); setLoader(false) })
  }

  const fetchCurrencies = () => {
    dispatch(currenciesActions.getCurrencies())
  }

  const fetchRepresentatives = () => {
    setRepLoader(true)
    dispatch(representativesActions.getRepresentatives())
      .then(() => {
        setRepLoader(false)
      })
  }

  const fetchRepresentativeActionTypes = () => {
    setRepLoader(true)
    dispatch(representativeActionTypesActions.getRepresentativeActionTypes())
      .then(() => {
        setRepLoader(false)
      })
  }

  const toggleReviewModal = (communityId) => {
    setLoader(true)
    dispatch(representativeNotesActions.getRepresentativeNotes(communityId))
      .then(() => {
        dispatch (reportsActions.getCommunityEngagementReport(communityId))
          .then(() => {
            setCommunityRating()
            if (!representatives) {
              fetchRepresentatives()
            }
          })
      })
    dispatch(communitiesActions.getCommunityKeyMetrics(communityId))
  }

  const setCommunityRating = () => {
    setsupportRating(community && community.support_rating ? community.support_rating : 0)
    setLoader(false)
  }

  const pageStateToggle = type => {
    dispatch(communitiesActions.getCommunityKeyMetrics(communityId))
    setPageState(type)
  }

  const headerActionComponent = (
    <StateSwitcher
      options={[
        {
          type: 'overview',
          text: 'Overview',
        },
        {
          type: 'edit',
          text: 'Edit',
        },
      ]}
      initialTypeSelected={'overview'}
      selectedType={pageState}
      stateSwitcher={pageStateToggle}
      position={'right'}
      disabled={loader}
    />
  )

  return (
    <div id='CommunityDetailPage'>
      <PageHeader title='Review Community' actionComponent={headerActionComponent} />
      <CoachOnboardingStepsAdminModal />
      <div className='community-content'>
        {pageState === 'overview' && (
          <CommunityRepDetail
            loader={loader}
            reports={reports}
            representatives={representatives}
            support_rating={supportRating}
          />
        ) || pageState === 'edit' && (
          <div className='row'>
            <div className='col-md-6 mx-auto'>
              <CommunityForm
                editCommunity={community}
                handleSubmitCommunity={handleSubmitCommunity}
                representatives={representatives}
                currencies={currencies}
                errors={errors}
                loader={loader}
              />
            </div>
          </div>
        ) || pageState === 'leads' && (
          <p>leads</p>
        )}
      </div>
    </div>
  )
}

export default CommunityDetailPage
